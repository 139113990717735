import React, { useContext } from "react";
import "./style.css";
import { UserContext } from "../../../../../../../../User";

function Menu() {
  const userContext = useContext(UserContext);

  return (
    <div className="menu-area">
      <div
        className={`single-menu${
          userContext.pengumumanContentActive === "Info" ? " active" : ""
        }`}
        onClick={() => userContext.dispatchPengumumanContentActive("Info")}
      >
        <span className="menu-text">Info</span>
      </div>
      <div
        className={`single-menu${
          userContext.pengumumanContentActive === "Poll" ? " active" : ""
        }`}
        onClick={() => userContext.dispatchPengumumanContentActive("Poll")}
      >
        <span className="menu-text">Poll</span>
      </div>
      <div
        className={`single-menu${
          userContext.pengumumanContentActive === "Survey" ? " active" : ""
        }`}
        onClick={() => userContext.dispatchPengumumanContentActive("Survey")}
      >
        <span className="menu-text">Survey</span>
      </div>
      <div
        className={`single-menu${
          userContext.pengumumanContentActive === "Recruitment" ? " active" : ""
        }`}
        onClick={() =>
          userContext.dispatchPengumumanContentActive("Recruitment")
        }
      >
        <span className="menu-text">Recruitment</span>
      </div>
    </div>
  );
}

export default Menu;
