import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { DropzoneDialog } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { DatePicker } from "@material-ui/pickers";
import {
  getOptionLabel,
  filterOptions,
  handleChangeSelectItem,
  handleClickClearTextField,
  handleMouseDownClearTextFieldDate,
  handleCloseDialog,
  handleChangeTextField, 
  handleMouseEnterTextField, 
  handleMouseLeaveTextField, 
  handleToggleDialogActive
} from "../../../../../../../Services/FormHandler";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert";
import { numericRegExp } from "../../../../../../../Services/RegExp";
import { 
  insertPenyuluhan,
  deleteDetailPenyuluhanByIdPenyuluhan,
  insertDetailPenyuluhan,
  updatePenyuluhan
} from "../../../../../../../Services/API/pengabdian";
import { 
  uploadSertifikatPenyuluhan, 
  deleteSertifikatPenyuluhan,
} from "../../../../../../../Services/API/file";
import { dateFormat } from "../../../../../../../Services/Format/date";

function PenyuluhanDialog(props) {
  let mahasiswaTerlibatRef = useRef(null);

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [idPenyuluhan, setIdPenyuluhan] = useState("");

  const [namaKegiatan, setNamaKegiatan] = useState("");

  const [isNamaKegiatanClearActive, setIsNamaKegiatanClearActive] = useState(
    false
  );

  const [penyelenggaraKegiatan, setPenyelenggaraKegiatan] = useState("");

  const [
    isPenyelenggaraKegiatanClearActive,
    setIsPenyelenggaraKegiatanClearActive,
  ] = useState(false);

  const [lokasi, setLokasi] = useState("");

  const [isLokasiClearActive, setIsLokasiClearActive] = useState(false);

  const [waktuPelaksanaan, setWaktuPelaksanaan] = useState(null);

  const [
    isWaktuPelaksanaanClearActive,
    setIsWaktuPelaksanaanClearActive,
  ] = useState(false);

  const [
    isWaktuPelaksanaanDialogActive,
    setIsWaktuPelaksanaanDialogActive,
  ] = useState(false);

  const [jumlahMasyarakat, setJumlahMasyarakat] = useState("");

  const [
    isJumlahMasyarakatClearActive,
    setIsJumlahMasyarakatClearActive,
  ] = useState(false);

  const [mahasiswaTerlibat, setMahasiswaTerlibat] = useState(null);

  const [mahasiswaTerlibatSelected, setMahasiswaTerlibatSelected] = useState(
    []
  );

  const [jumlahMahasiswaTerlibat, setJumlahMahasiswaTerlibat] = useState("");

  const [listDetail, setListDetail] = useState([]);

  const [listUploadSertifikat, setListUploadSertifikat] = useState([]);

  const [listDeleteSertifikat, setListDeleteSertifikat] = useState([]);

  const [
    isMahasiswaTerlibatEnterClicked,
    setIsMahasiswaTerlibatEnterClicked,
  ] = useState(false);

  const [
    isTambahMahasiswaTerlibatClicked,
    setIsTambahMahasiswaTerlibatClicked,
  ] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("namaKegiatan")) {
      if (!namaKegiatan) {
        _errorList.push({
          type: "required",
          message: "Nama Kegiatan wajib diisi.",
        });
      }
    }

    if (target.includes("penyelenggaraKegiatan")) {
      if (!penyelenggaraKegiatan) {
        _errorList.push({
          type: "required",
          message: "Penyelenggara Kegiatan wajib diisi.",
        });
      }
    }

    if (target.includes("lokasi")) {
      if (!lokasi) {
        _errorList.push({
          type: "required",
          message: "Lokasi wajib diisi.",
        });
      }
    }

    if (target.includes("waktuPelaksanaan")) {
      if (!waktuPelaksanaan) {
        _errorList.push({
          type: "required",
          message: "Waktu Pelaksanaan wajib diisi.",
        });
      }
    }

    if (target.includes("mahasiswaTerlibat")) {
      if (!mahasiswaTerlibat) {
        _errorList.push({
          type: "required",
          message: "Mahasiswa Terlibat wajib dipilih.",
        });
      }
    }

    if(target.includes("jumlahMahasiswaTerlibat")) {
      if(!jumlahMahasiswaTerlibat) {
        _errorList.push({
          type: "required",
          message: "Jumlah Mahasiswa Terlibat masih kosong."
        });
      }
    }

    if (target.includes("jumlahMasyarakat")) {
      if (!jumlahMasyarakat) {
        _errorList.push({
          type: "required",
          message: "Jumlah Masyarakat wajib diisi.",
        });
      } else {
        if (!numericRegExp.test(jumlahMasyarakat)) {
          _errorList.push({
            type: "pattern",
            message: "Jumlah Masyarakat wajib berupa angka.",
          });
        }
      }
    }

    return _errorList;
  };

  const handleKeyUpMahasiswaTerlibat = (event) => {
    if (event.keyCode === 13) {
      setIsMahasiswaTerlibatEnterClicked(true);
    }
  };

  const handleClickTambahMahasiswaTerlibat = () => {
    setIsTambahMahasiswaTerlibatClicked(true);
  };

  const handleClickSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleClickDeleteMahasiswaTerlibat = (id_pengguna) => {
    if(props.dialogActiveActionType === "Update") {
      const detailTarget = listDetail.find(item => item.id_pengguna === id_pengguna);

      if(detailTarget.sertifikat) {
        setListDeleteSertifikat([
          ...listDeleteSertifikat,
          {
            id_pengguna,
            fileName: detailTarget.sertifikat
          }
        ]);
      }
    }

    setMahasiswaTerlibatSelected(
      mahasiswaTerlibatSelected.filter((item) => item.value !== id_pengguna)
    );
    setListDetail(
      listDetail.filter(item => item.id_pengguna !== id_pengguna)
    );
    setListUploadSertifikat(
      listUploadSertifikat.filter(item => item.id_pengguna !== id_pengguna)
    );
  };

  const handleMouseEnterSertifikatTextField = (id_pengguna) => {
    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          isSertifikatClearActive: item.sertifikat ? true : false
        };
      } else {
        return item;
      }
    }));
  };

  const handleMouseLeaveSertifikatTextField = (id_pengguna) => {
    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          isSertifikatClearActive: false
        };
      } else {
        return item;
      }
    }));
  };

  const handleClickClearSertifikatTextField = (id_pengguna, fileName) => {
    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          sertifikat: "",
          isSertifikatClearActive: false
        };
      } else {
        return item;
      }
    }));
    setListUploadSertifikat(listUploadSertifikat.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          file: null
        };
      } else {
        return item;
      }
    }));

    if(props.dialogActiveActionType === "Update") {
      setListDeleteSertifikat([
        ...listDeleteSertifikat,
        {
          id_pengguna,
          fileName
        }
      ]);
    }
  };

  const handleOpenSertifikatDialog = (id_pengguna) => {
    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          isSertifikatDialogActive: true
        };
      } else {
        return item;
      }
    }));
  };

  const handleCloseSertifikatDialog = (id_pengguna) => {
    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          isSertifikatDialogActive: false
        };
      } else {
        return item;
      }
    }));
  };

  const handleSaveSertifikatFile = (id_pengguna, files) => {
    if(props.dialogActiveActionType === "Update") {
      const detailTarget = listDetail.find(item => item.id_pengguna === id_pengguna);

      if(detailTarget.sertifikat) {
        setListDeleteSertifikat([
          ...listDeleteSertifikat,
          {
            id_pengguna,
            fileName: detailTarget.sertifikat
          }
        ]);
      }
    }

    setListDetail(listDetail.map(item => {
      if(item.id_pengguna === id_pengguna) {
        return {
          ...item,
          sertifikat: files[0].name,
          isSertifikatDialogActive: false
        };
      } else {
        return item;
      }
    }));
    setListUploadSertifikat([
      ...listUploadSertifikat,
      {
        id_pengguna,
        file: files[0]
      }
    ]);
  };

  const handleResetData = () => {
    setIdPenyuluhan("");
    setNamaKegiatan("");
    setPenyelenggaraKegiatan("");
    setLokasi("");
    setWaktuPelaksanaan(null);
    setMahasiswaTerlibat(null);
    setMahasiswaTerlibatSelected([]);
    setListDetail([]);
    setListUploadSertifikat([]);
    setListDeleteSertifikat([]);
    setJumlahMahasiswaTerlibat("");
    setJumlahMasyarakat("");

    props.setDialogActiveActionType("");
    props.setDialogTitleActive("");
    props.setDialogDataActive(null);
  };

  useDidMountEffect(() => {
    setIsNamaKegiatanClearActive(namaKegiatan ? true : false);
  }, [namaKegiatan]);

  useDidMountEffect(() => {
    setIsPenyelenggaraKegiatanClearActive(penyelenggaraKegiatan ? true : false);
  }, [penyelenggaraKegiatan]);

  useDidMountEffect(() => {
    setIsLokasiClearActive(lokasi ? true : false);
  }, [lokasi]);

  useDidMountEffect(() => {
    setIsJumlahMasyarakatClearActive(jumlahMasyarakat ? true : false);
  }, [jumlahMasyarakat]);

  useDidMountEffect(() => {
    setJumlahMahasiswaTerlibat(mahasiswaTerlibatSelected.length);
  }, [mahasiswaTerlibatSelected]);

  useDidMountEffect(() => {
    if (isMahasiswaTerlibatEnterClicked) {
      setErrorList(getErrorList(["mahasiswaTerlibat"]));
    }
  }, [isMahasiswaTerlibatEnterClicked]);

  useDidMountEffect(() => {
    if (isTambahMahasiswaTerlibatClicked) {
      setErrorList(getErrorList(["mahasiswaTerlibat"]));
    }
  }, [isTambahMahasiswaTerlibatClicked]);

  useDidMountEffect(() => {
    if (isFormSubmitted) {
      setErrorList(
        getErrorList([
          "namaKegiatan",
          "penyelenggaraKegiatan",
          "lokasi",
          "waktuPelaksanaan",
          "jumlahMahasiswaTerlibat",
          "jumlahMasyarakat",
        ])
      );
    }
  }, [isFormSubmitted]);

  useDidMountEffect(async () => {
    setIsErrorSnackbarActive(errorList.length > 0);

    if (isFormSubmitted) {
      setIsFormSubmitted(false);

      if(errorList.length === 0) {
        setIsSubmitButtonLoading(true);

        let cloneListDetail = Object.values(listDetail);
        let cloneListDeleteSertifikat = Object.values(listDeleteSertifikat);

        Promise.allSettled(listUploadSertifikat.map(async item => {
          let formData = new FormData();
          formData.append("single_file", item.file);

          const upload = await uploadSertifikatPenyuluhan(formData);

          if(upload.success) {
            const indexTarget = listDetail.findIndex(x => x.id_pengguna === item.id_pengguna);

            const detailTarget = cloneListDetail[indexTarget];
            
            cloneListDetail[indexTarget] = {
              ...detailTarget,
              sertifikat: upload.data
            };
          }
        })).then(async () => {
          if(props.dialogActiveActionType === "Tambah") {
            const insert = await insertPenyuluhan({
              nama_kegiatan: namaKegiatan,
              penyelenggara_kegiatan: penyelenggaraKegiatan,
              lokasi,
              waktu_pelaksanaan: dateFormat(new Date(waktuPelaksanaan), "yyyy-MM-dd"),
              jumlah_masyarakat: jumlahMasyarakat,
              detail: cloneListDetail
            });
  
            if(insert.success) {
              swal("Sukses!", "Data Penyuluhan berhasil ditambahkan.", "success").then(() => {
                props.setIsDialogFullScreenActive(false);
                props.handleLoadPenyuluhan("REFRESH");
              });
            } else {
              swal("Oops!", insert.message ? insert.message : "Internal Server Error", "warning");
            }
          } else if(props.dialogActiveActionType === "Update") {
            const deleteDetail = await deleteDetailPenyuluhanByIdPenyuluhan(idPenyuluhan);
  
            if(deleteDetail.success) {
              const [update, insertDetail] = await Promise.all([
                updatePenyuluhan({
                  nama_kegiatan: namaKegiatan,
                  penyelenggara_kegiatan: penyelenggaraKegiatan,
                  lokasi,
                  waktu_pelaksanaan: dateFormat(new Date(waktuPelaksanaan), "yyyy-MM-dd"),
                  jumlah_masyarakat: jumlahMasyarakat,
                  id_penyuluhan: idPenyuluhan
                }),
                insertDetailPenyuluhan({
                  id_penyuluhan: idPenyuluhan,
                  detail: cloneListDetail
                })
              ]);
  
              if(update.success && insertDetail.success) {
                swal("Sukses!", "Data Penyuluhan berhasil diupdate.", "success").then(() => {
                  props.setIsDialogFullScreenActive(false);
                  props.handleLoadPenyuluhan("REFRESH");
                });
              } else {
                swal("Oops!", "Data Penyuluhan gagal diupdate.", "warning");
              }
            } else {
              swal("Oops!", "Data Penyuluhan gagal diupdate.", "warning");
            }
          }
  
          setIsSubmitButtonLoading(false);
  
          Promise.allSettled(
            cloneListDeleteSertifikat.length > 0 
              ? cloneListDeleteSertifikat.map(item => {
                  return deleteSertifikatPenyuluhan(item.fileName);
                })
              : []
          );
        });
      }
    } else if (isTambahMahasiswaTerlibatClicked || isMahasiswaTerlibatEnterClicked) {
      setIsTambahMahasiswaTerlibatClicked(false);
      setIsMahasiswaTerlibatEnterClicked(false);

      if (errorList.length === 0) {
        if (
          mahasiswaTerlibatSelected.find(
            (item) => item.npm === mahasiswaTerlibat.npm
          )
        ) {
          swal("Oops!", "Mahasiswa tersebut telah dipilih.", "warning");
        } else {
          setMahasiswaTerlibatSelected([
            ...mahasiswaTerlibatSelected,
            mahasiswaTerlibat,
          ]);
          setListDetail([
            ...listDetail,
            {
              id_pengguna: mahasiswaTerlibat.value,
              isSertifikatClearActive: false,
              isSertifikatDialogActive: false,
              penggunaText: `${mahasiswaTerlibat.nama_lengkap} - ${mahasiswaTerlibat.npm}`,
              sertifikat: ""
            }
          ]);

          setMahasiswaTerlibat(null);
          mahasiswaTerlibatRef.focus();
        }
      }
    }
  }, [errorList]);

  useDidMountEffect(() => {
    setIdPenyuluhan(
      props.dialogDataActive ? props.dialogDataActive.idPenyuluhan : ""
    );

    setNamaKegiatan(
      props.dialogDataActive ? props.dialogDataActive.namaKegiatan : ""
    );

    setPenyelenggaraKegiatan(
      props.dialogDataActive ? props.dialogDataActive.penyelenggaraKegiatan : ""
    );

    setLokasi(props.dialogDataActive ? props.dialogDataActive.lokasi : "");

    setWaktuPelaksanaan(
      props.dialogDataActive ? props.dialogDataActive.waktuPelaksanaan : null
    );

    setMahasiswaTerlibatSelected(
      props.dialogDataActive
        ? props.dialogDataActive.mahasiswaTerlibatSelected
        : []
    );

    setJumlahMahasiswaTerlibat(
      props.dialogDataActive
        ? props.dialogDataActive.jumlahMahasiswaTerlibat
        : ""
    );

    setListDetail(
      props.dialogDataActive 
        ? props.dialogDataActive.listDetail
        : []
    );

    setJumlahMasyarakat(
      props.dialogDataActive ? props.dialogDataActive.jumlahMasyarakat : ""
    );

    setTimeout(() => {
      setIsNamaKegiatanClearActive(false);
      setIsPenyelenggaraKegiatanClearActive(false);
      setIsLokasiClearActive(false);
      setIsJumlahMasyarakatClearActive(false);
    }, 0);
  }, [props.dialogDataActive]);

  useDidMountEffect(() => {
    if(!props.isDialogFullScreenActive) {
      handleResetData();
    }
  }, [props.isDialogFullScreenActive]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isDialogFullScreenActive}
        onClose={() => handleCloseDialog(props.setIsDialogFullScreenActive)}
        className={`full-screen-app-bar${
          props.dialogFullScreenClassName
            ? " " + props.dialogFullScreenClassName
            : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(props.setIsDialogFullScreenActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              {props.dialogTitleActive}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClickSubmitAction}
              className="single-button-right-top-custom"
            >
              {isSubmitButtonLoading ? (
                <CircularProgress size={18} className="loading-circle" />
              ) : props.dialogActiveActionType === "Tambah" ? (
                "Submit"
              ) : (
                "Update"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <form className="fullscreen-modal-form-body" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <div className="form-group required">
            <TextField 
              id="penyuluhan-nama-kegiatan"
              label="Nama Kegiatan"
              variant="outlined"
              value={namaKegiatan || ""}
              onChange={(event) => handleChangeTextField(event, setNamaKegiatan)}
              onMouseEnter={() => handleMouseEnterTextField(namaKegiatan, setIsNamaKegiatanClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsNamaKegiatanClearActive)}
              InputProps={{
                endAdornment: isNamaKegiatanClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setNamaKegiatan,
                          setIsNamaKegiatanClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="penyuluhan-penyelenggara-kegiatan"
              label="Penyelenggara Kegiatan"
              variant="outlined"
              value={penyelenggaraKegiatan || ""}
              onChange={(event) => handleChangeTextField(event, setPenyelenggaraKegiatan)}
              onMouseEnter={() => handleMouseEnterTextField(penyelenggaraKegiatan, setIsPenyelenggaraKegiatanClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsPenyelenggaraKegiatanClearActive)}
              InputProps={{
                endAdornment: isNamaKegiatanClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setPenyelenggaraKegiatan,
                          setIsPenyelenggaraKegiatanClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="penyuluhan-lokasi"
              label="Lokasi"
              variant="outlined"
              value={lokasi || ""}
              onChange={(event) => handleChangeTextField(event, setLokasi)}
              onMouseEnter={() => handleMouseEnterTextField(lokasi, setIsLokasiClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsLokasiClearActive)}
              InputProps={{
                endAdornment: isLokasiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setLokasi,
                          setIsLokasiClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <DatePicker 
              label="Waktu Pelaksanaan"
              format="dd-MM-yyyy"
              openTo="year"
              views={["year", "month", "date"]}
              maxDate={new Date()}
              value={waktuPelaksanaan || null}
              inputVariant="outlined"
              onChange={(selected) => handleChangeSelectItem(selected, setWaktuPelaksanaan)}
              onMouseEnter={() => handleMouseEnterTextField(waktuPelaksanaan, setIsWaktuPelaksanaanClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsWaktuPelaksanaanClearActive)}
              open={isWaktuPelaksanaanDialogActive}
              onOpen={() => handleToggleDialogActive(isWaktuPelaksanaanDialogActive, setIsWaktuPelaksanaanDialogActive)}
              onClose={() => handleToggleDialogActive(isWaktuPelaksanaanDialogActive, setIsWaktuPelaksanaanDialogActive)}
              InputProps={{
                endAdornment: isWaktuPelaksanaanClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() =>
                        handleMouseDownClearTextFieldDate(
                          setWaktuPelaksanaan,
                          setIsWaktuPelaksanaanClearActive,
                          setIsWaktuPelaksanaanDialogActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <div className="inner-form-group-wrapper">
              <div className="inner-form-group mahasiswa-terlibat-form-group">
                <Autocomplete 
                  id="penyuluhan-mahasiswa-terlibat"
                  options={props.optionsMahasiswa}
                  filterOptions={filterOptions}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) => handleChangeSelectItem(selected, setMahasiswaTerlibat)}
                  onKeyUp={handleKeyUpMahasiswaTerlibat}
                  value={mahasiswaTerlibat || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mahasiswa Terlibat"
                      variant="outlined"
                      inputRef={(ref) => {
                        mahasiswaTerlibatRef = ref;
                      }}
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              <div className="mahasiswa-terlibat-button-form-group">
                <div className="add-button" onClick={handleClickTambahMahasiswaTerlibat}>
                  <span className="button-text">Tambah Mahasiswa</span>
                </div>
              </div>
            </div>
          </div>
          {mahasiswaTerlibatSelected.length > 0 && (
            <div className="form-group required">
              <div className="text-area-list-mahasiswa-terlibat">
                {mahasiswaTerlibatSelected.map((item, index) => (
                  <div className="single-list-little-box" key={index}>
                    <span className="list-text">
                      {item.nama_lengkap} - {item.npm}
                    </span>
                    <span
                      className="delete-mahasiswa-terlibat-icon-area"
                      onClick={() => handleClickDeleteMahasiswaTerlibat(item.value)}
                    >
                      <span className="delete-icon">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {mahasiswaTerlibatSelected.length > 0 && (
             <div className="form-group required disabled">
              <TextField 
                id="penyuluhan-jumlah-mahasiswa"
                label="Jumlah Mahasiswa"
                variant="outlined"
                value={mahasiswaTerlibatSelected.length || ""}
              />
            </div>
          )}
          <div className="form-group required">
            <TextField 
              id="penyuluhan-jumlah-masyarakat"
              label="Jumlah Masyarakat"
              variant="outlined"
              value={jumlahMasyarakat || ""}
              onChange={(event) => handleChangeTextField(event, setJumlahMasyarakat)}
              onMouseEnter={() => handleMouseEnterTextField(jumlahMasyarakat, setIsJumlahMasyarakatClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsJumlahMasyarakatClearActive)}
              InputProps={{
                endAdornment: isJumlahMasyarakatClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setJumlahMasyarakat,
                          setIsJumlahMasyarakatClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          {listDetail.length > 0 && (
            <div className="sertifikat-area">
              {listDetail.map((item, index) => (
                <div className={`form-group upload-area${item.isSertifikatClearActive ? " pointer-active" : ""}${item.sertifikat ? "" : " disabled"}`} key={item.id_pengguna} onMouseEnter={() => handleMouseEnterSertifikatTextField(item.id_pengguna)} onMouseLeave={() => handleMouseLeaveSertifikatTextField(item.id_pengguna)}>
                  <TextField 
                    label={`[Sertifikat] ${item.penggunaText}`}
                    variant="outlined"
                    value={item.sertifikat || ""}
                    readOnly
                  />
                  {item.isSertifikatClearActive && (
                    <div className="clear-icon">
                      <ClearIcon 
                        onClick={() => handleClickClearSertifikatTextField(item.id_pengguna, item.sertifikat)}
                      />
                    </div>
                  )}
                  <div className="upload-button" onClick={() => handleOpenSertifikatDialog(item.id_pengguna)}>
                    <span className="upload-text">Upload</span>
                  </div>
                  <DropzoneDialog 
                    acceptedFiles={[".pdf"]}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Upload"}
                    maxFileSize={5000000}
                    filesLimit={1}
                    open={item.isSertifikatDialogActive}
                    onClose={() => handleCloseSertifikatDialog(item.id_pengguna)}
                    onSave={(files) => handleSaveSertifikatFile(item.id_pengguna, files)}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                  />
                </div>
              ))}
            </div>
          )}
        </form>
      </Dialog>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseDialog(setIsErrorSnackbarActive)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </React.Fragment>
  );
}

export default PenyuluhanDialog;