import React, { useReducer, useState, useEffect } from "react";
import "./style.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  pageActive,
  menuActive,
  userActive,
  pengumumanContentActive,
  isHeaderNotifActionActive,
  isNotifContentActive,
  isUserThumbnailActionActive,
} from "../../Stores/User";
import {
  pageActiveReducer,
  menuActiveReducer,
  userActiveReducer,
  pengumumanContentActiveReducer,
  isHeaderNotifActionActiveReducer,
  isNotifContentActiveReducer,
  isUserThumbnailActionActiveReducer,
} from "../../Reducers/User";
import useRouter from "../../Hooks/useRouter";
import Beranda from "./Beranda";
import Profil from "./Profil";
import Telusuri from "./Telusuri";
import PemiraIKMFKUI2020 from "./PemiraIKMFKUI2020/index";
import { getPenggunaActive } from "../../Services/API/pengguna";
import { getFotoProfilPengguna } from "../../Services/API/file";
import { getRankingLigaKreasi } from "../../Services/API/liga_kreasi";
import DetailInfo from "./Beranda/Components/BodyContent/Components/Pengumuman/Component/DetailInfo";
import DetailSurvey from "./Beranda/Components/BodyContent/Components/Pengumuman/Component/DetailSurvey";
import DetailRecruitment from "./Beranda/Components/BodyContent/Components/Pengumuman/Component/DetailRecruitment";
import NotFound from "./NotFound";
import BallClipRotateMultipleCustom from "../../Components/BallClipRotateMultipleCustom";
import { useCookies, CookiesProvider } from 'react-cookie';
import { loadUserByToken } from "../../Services/API/auth";

const UserContext = React.createContext();

function User() {
  const router = useRouter();
  const [cookies] = useCookies(['onedatamakara']);
  
  const [_pageActive, dispatchPageActive] = useReducer(
    pageActiveReducer,
    pageActive
  );

  const [_menuActive, dispatchMenuActive] = useReducer(
    menuActiveReducer,
    menuActive
  );

  const [_userActive, dispatchUserActive] = useReducer(
    userActiveReducer,
    userActive
  );

  const [
    _pengumumanContentActive,
    dispatchPengumumanContentActive,
  ] = useReducer(pengumumanContentActiveReducer, pengumumanContentActive);

  const [
    _isHeaderNotifActionActive,
    dispatchIsHeaderNotifActionActive,
  ] = useReducer(isHeaderNotifActionActiveReducer, isHeaderNotifActionActive);

  const [_isNotifContentActive, dispatchIsNotifContentActive] = useReducer(
    isNotifContentActiveReducer,
    isNotifContentActive
  );

  const [
    _isUserThumbnailActionActive,
    dispatchIsUserThumbnailActionActive,
  ] = useReducer(
    isUserThumbnailActionActiveReducer,
    isUserThumbnailActionActive
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if(cookies && cookies.onedatamakara) {
        try {
          const userByToken = await loadUserByToken(cookies["onedatamakara"]);
          
          if(userByToken.success) {
            const status = userByToken?.data?.status;

            if(status === "verified") {
              const user = await getPenggunaActive(userByToken.data.npm);
              let previewFotoPengguna;
              let rankingLigaKreasiByAngkatan;
              let rankPengguna;
              let jumlahSeangkatan;

              if (user.success) {
                [previewFotoPengguna, rankingLigaKreasiByAngkatan] = await Promise.all([
                  user.data.foto_pengguna
                    ? getFotoProfilPengguna(user.data.foto_pengguna)
                    : null,
                  getRankingLigaKreasi({
                    searchPengguna: {
                      groupOp: "",
                      rules: [
                        {
                          field: "angkatan",
                          op: "=",
                          data: user.data.angkatan,
                        },
                      ],
                    },
                    order: {
                      by: "rank",
                      mode: "ASC",
                    },
                  }),
                ]);

                if (rankingLigaKreasiByAngkatan.success) {
                  const _pengguna = await rankingLigaKreasiByAngkatan.data.find(
                    (item) => item.id_pengguna === user.data.id_pengguna
                  );

                  if (_pengguna) {
                    rankPengguna = _pengguna.rank;
                  }

                  jumlahSeangkatan = rankingLigaKreasiByAngkatan.data.length;
                }

                dispatchUserActive({
                  data: {
                    ...user.data,
                    preview_foto_pengguna: previewFotoPengguna
                      ? previewFotoPengguna.success
                        ? `url(${previewFotoPengguna.data})`
                        : null
                      : null,
                    rank: rankPengguna ? rankPengguna : 0,
                    jumlah_seangkatan: jumlahSeangkatan ? jumlahSeangkatan : 0,
                  },
                });

                setIsLoading(false);
              } else {
                router.push("/landing");
              }
            } else {
              router.push("/landing");
            }
          } else {
            router.push("/landing");
          }
        } catch (e) {
          router.push('/landing');
        }
      } else {
        router.push("/landing");
      }
    })();
  }, [cookies]);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <CookiesProvider>
          <UserContext.Provider
            value={{
              pageActive: _pageActive,
              dispatchPageActive,
              menuActive: _menuActive,
              dispatchMenuActive,
              userActive: _userActive,
              dispatchUserActive,
              pengumumanContentActive: _pengumumanContentActive,
              dispatchPengumumanContentActive,
              isHeaderNotifActionActive: _isHeaderNotifActionActive,
              dispatchIsHeaderNotifActionActive,
              isNotifContentActive: _isNotifContentActive,
              dispatchIsNotifContentActive,
              isUserThumbnailActionActive: _isUserThumbnailActionActive,
              dispatchIsUserThumbnailActionActive,
            }}
          >
            <Router>
              <Switch>
                <Route exact path="/">
                  <Beranda />
                </Route>
                <Route exact path="/info/:id">
                  <DetailInfo />
                </Route>
                <Route exact path="/survey/:slug">
                  <DetailSurvey />
                </Route>
                <Route exact path="/recruitment/:id">
                  <DetailRecruitment />
                </Route>
                <Route exact path="/telusuri">
                  <Telusuri />
                </Route>
                <Route exact path="/404">
                  <NotFound />
                </Route>
                <Route path="/telusuri/mahasiswa">
                  <Profil />
                </Route>
                <Route path="/profil">
                  <Profil />
                </Route>
                <Route path="/pemira-ikm-fkui-2020">
                  <PemiraIKMFKUI2020 />
                </Route>
                <Redirect to="/" />
              </Switch>
            </Router>
          </UserContext.Provider>
        </CookiesProvider>
      )}
    </React.Fragment>
  );
}

export { UserContext };
export default User;
