const config = {
  // PRODUCTION MODE
  CLIENT_URI: "https://onedatamakara.com",
  SERVER_URI: "https://service.onedatamakara.com/api/v1",

  // DEVELOPMENT MODE
  // CLIENT_URI: "http://localhost:3000",
  // SERVER_URI: "http://localhost:8000/api/v1"
};

export default config;
