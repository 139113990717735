import React, { useReducer, useContext } from "react";
import "./style.css";
import { UserContext } from "../../../../User";

function Menu() {
  const userContext = useContext(UserContext);

  const handleClickBoxMenu = (target) => {
    userContext.dispatchMenuActive(target);
    window.scrollTo(0, 0);
  };

  return (
    <div className="user-page-menu">
      <ul className="menu-list">
        <li className="single-list pengumuman-box">
          <div
            className={`menu-box${
              userContext.menuActive === "Pengumuman" ? " active" : ""
            }`}
            onClick={() => handleClickBoxMenu("Pengumuman")}
          >
            <div className="content-wrapper">
              <span className="title-content">Pengumuman</span>
              <span className="sub-title-content">
                Info, Poll, Survey, Recruitment
              </span>
            </div>
            <div className="illustration">
              <img src={require("../../../../../Assets/Illustrations/1.svg")} />
            </div>
          </div>
        </li>
        <li className="single-list liga-kreasi-box">
          <div
            className={`menu-box${
              userContext.menuActive === "LigaKreasi" ? " active" : ""
            }`}
            onClick={() => handleClickBoxMenu("LigaKreasi")}
          >
            <div className="content-wrapper">
              <span className="title-content">Liga Kreasi</span>
              <span className="sub-title-content">Bidang Minat Bakat</span>
            </div>
            <div className="illustration x3">
              <img src={require("../../../../../Assets/Illustrations/2.png")} />
            </div>
          </div>
        </li>
        <li className="single-list bisnis-box">
          <div
            className={`menu-box${
              userContext.menuActive === "Bisnis" ? " active" : ""
            }`}
            onClick={() => handleClickBoxMenu("Bisnis")}
          >
            <div className="content-wrapper">
              <span className="title-content">Bisnis</span>
              <span className="sub-title-content">Pendataan Bisnis</span>
            </div>
            <div className="illustration">
              <img src={require("../../../../../Assets/Illustrations/3.svg")} />
            </div>
          </div>
        </li>
        <li className="single-list beasiswa-box">
          <div
            className={`menu-box${
              userContext.menuActive === "Beasiswa" ? " active" : ""
            }`}
            onClick={() => handleClickBoxMenu("Beasiswa")}
          >
            <div className="content-wrapper">
              <span className="title-content">Beasiswa</span>
              <span className="sub-title-content">Pendataan Beasiswa</span>
            </div>
            <div className="illustration">
              <img src={require("../../../../../Assets/Illustrations/5.svg")} />
            </div>
          </div>
        </li>
        <li className="single-list kaderisasi-berkelanjutan-box">
          <div
            className={`menu-box${
              userContext.menuActive === "KaderisasiBerkelanjutan"
                ? " active"
                : ""
            }`}
            onClick={() => handleClickBoxMenu("KaderisasiBerkelanjutan")}
          >
            <div className="content-wrapper">
              <span className="title-content">Kaderisasi Berkelanjutan</span>
              <span className="sub-title-content">Pendataan KKM dan SKP</span>
            </div>
            <div className="illustration">
              <img src={require("../../../../../Assets/Illustrations/4.svg")} />
            </div>
          </div>
        </li>
        <li className="single-list publikasi-box">
          <div
            className={`menu-box${
              userContext.menuActive === "Publikasi" ? " active" : ""
            }`}
            onClick={() => handleClickBoxMenu("Publikasi")}
          >
            <div className="content-wrapper">
              <span className="title-content">Publikasi</span>
              <span className="sub-title-content">Pendataan Publikasi</span>
            </div>
            <div className="illustration">
              <img src={require("../../../../../Assets/Illustrations/6.jpg")} />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
