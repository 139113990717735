import axios from "axios";
import config from "../../Config";

const getRankingLigaKreasi = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi/ranking`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getLigaKreasiByKelompok = async (id) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi/by_kelompok/${id}`,
  });

  const response = request.data;
  return response;
};

const getLigaKreasiByPengguna = async (id) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getLigaKreasi = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getPenggunaLigaKreasi = async (idLigaKreasi) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi/pengguna`,
    params: {
      id_liga_kreasi: idLigaKreasi
    }
  });

  const response = request.data;
  return response;
};

const getTotalLigaKreasi = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/liga_kreasi/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const resetLigaKreasi = async () => {
  const request = await axios({
    method: "PATCH",
    baseURL: `${config.SERVER_URI}/liga_kreasi/reset`
  });

  const response = request.data;
  return response;
};

const deleteLigaKreasi = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/liga_kreasi`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const deleteKelompokLigaKreasi = async ({
  id_liga_kreasi, id_pengguna
}) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/kelompok_liga_kreasi`,
    data: {
      id_liga_kreasi,
      id_pengguna
    }
  });

  const response = request.data;
  return response;
};

const insertLigaKreasi = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/liga_kreasi`,
    data,
  });

  const response = request.data;
  return response;
};

const verifikasiLigaKreasi = async (data) => {
  const request = await axios({
    method: "put",
    baseURL: `${config.SERVER_URI}/liga_kreasi/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const updateSertifikatPialaMedali = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/liga_kreasi/sertifikat_piala_medali`,
    data,
  });

  const response = request.data;
  return response;
};

const updateFotoPenyerahanPenghargaan = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/liga_kreasi/foto_penyerahan_penghargaan`,
    data,
  });

  const response = request.data;
  return response;
};

const updateManuskripKarya = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/liga_kreasi/manuskrip_karya`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getRankingLigaKreasi,
  getLigaKreasiByKelompok,
  getLigaKreasiByPengguna,
  getLigaKreasi,
  getPenggunaLigaKreasi,
  getTotalLigaKreasi,
  insertLigaKreasi,
  resetLigaKreasi,
  deleteLigaKreasi,
  deleteKelompokLigaKreasi,
  verifikasiLigaKreasi,
  updateSertifikatPialaMedali,
  updateFotoPenyerahanPenghargaan,
  updateManuskripKarya
};
