import React, { useState } from "react";
import "../../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import {
  getOptionLabel,
  handleChangeSelectItem,
} from "../../../../../../../Services/FormHandler";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import swal from "sweetalert";

function Search(props) {
  const handleSearchData = async () => {
    props.setIsDataTableLoading(true);

    if(typeof props.setDataSelected === "function") {
      props.setDataSelected([]);
    } 

    const [result, totalAllData] = await Promise.all([
      props.getData({
        fields: props.dataFields.map((item) => item.value),
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
        order: props.sortItem,
        limit: props.numberPerPage,
        offset: 0,
      }),
      props.getTotalData({
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
      }),
    ]);

    if (result.success && totalAllData.success) {
      props.setData(result.data);
      props.setTotalData(totalAllData.data.total);
      props.setTotalPage(
        Math.ceil(totalAllData.data.total / props.numberPerPage)
      );
      props.setCurrentPage(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageFrom(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageTo(
        totalAllData.data.total > 0 ? result.data.length : 0
      );
    }

    props.setIsDataTableLoading(false);
  };

  const handleKeyUpSearchInput = (event) => {
    if (event.keyCode === 13) {
      handleSearchData();
    }
  };

  useDidMountEffect(() => {
    if (props.searchFilterBy.length > 2) {
      swal("Oops!", "Filter Pencarian maksimal 2", "warning").then(() => {
        props.setSearchFilterBy(
          props.searchFilterBy.slice(0, props.searchFilterBy.length - 1)
        );
      });
    } else {
      handleSearchData();
    }
  }, [props.searchFilterBy]);

  return (
    <div
      className={`search-area${
        props.isDataTableLoading ? " pointer-disabled" : ""
      }`}
    >
      <div className="search-input">
        <span className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <input
          autoComplete="off"
          type="text"
          id="search-input-field"
          placeholder="Type and Enter..."
          value={props.searchInput || ""}
          onChange={(event) => props.setSearchInput(event.target.value)}
          onKeyUp={handleKeyUpSearchInput}
        />
      </div>
      <div className="search-filter-by">
        <Autocomplete
          id="search-filter-by"
          options={props.optionsSearchFilterBy.filter(
            (item) =>
              !props.searchFilterBy
                .map((_item) => _item.value)
                .includes(item.value)
          )}
          noOptionsText="Tidak Ada Opsi"
          multiple
          clearOnBlur
          clearText="Bersihkan"
          openText="Pilih"
          getOptionLabel={getOptionLabel}
          onChange={(event, selected) =>
            handleChangeSelectItem(selected, props.setSearchFilterBy)
          }
          value={props.searchFilterBy || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cari Berdasarkan"
              variant="outlined"
            />
          )}
          PopperComponent={PopperCustom}
        />
      </div>
    </div>
  );
}

export default Search;
