import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Login";
import Panel from "./Panel";

function Admin() {
  return (
    <Router>
      <Switch>
        <Route exact path="/adminpanel/login">
          <Login />
        </Route>
        <Route path="/adminpanel">
          <Panel />
        </Route>
        <Redirect to="/adminpanel/login" />
      </Switch>
    </Router>
  );
}

export default Admin;
