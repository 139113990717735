import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { handleCloseDialog } from "../../../../../../../Services/FormHandler";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import {
  getOrganisasi,
  getTotalOrganisasi,
} from "../../../../../../../Services/API/organisasi";
import swal from "sweetalert";
import {
  getKeteranganKinerjaByOrganisasi,
  updateKeteranganKinerja,
} from "../../../../../../../Services/API/keterangan_kinerja";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";

function KeteranganKinerjaDialog(props) {
  const [errorList, setErrorList] = useState([]);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [
    isSubmitButtonActionLoading,
    setIsSubmitButtonActionLoading,
  ] = useState(false);

  const handleClickSubmitAction = async () => {
    setIsSubmitButtonActionLoading(true);

    const updateNilai = await Promise.allSettled(
      props.dataByOrganisasiActive.map((item) => {
        updateKeteranganKinerja({
          id_detail_organisasi: item.id_detail_organisasi,
          nilai: item.nilai,
        });
      })
    );

    setIsSubmitButtonActionLoading(false);
    swal("Sukses!", "Nilai Keterangan Kinerja berhasil diupdate.", "success");
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isKeteranganKinerjaDialogActive}
        onClose={() =>
          handleCloseDialog(props.setIsKeteranganKinerjaDialogActive)
        }
        className={`full-screen-app-bar${
          props.dialogFullScreenClassName
            ? " " + props.dialogFullScreenClassName
            : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(props.setIsKeteranganKinerjaDialogActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              Update Nilai Keterangan Kinerja
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClickSubmitAction}
              className="single-button-right-top-custom"
            >
              {isSubmitButtonActionLoading ? (
                <CircularProgress size={18} className="loading-circle" />
              ) : (
                "Update"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <form className="fullscreen-modal-form-body" autoComplete="off">
          {props.dataByOrganisasiActive &&
            props.dataByOrganisasiActive.map((item, index) => (
              <div className="single-form-group-wrapper" key={index}>
                <div className="form-group disabled required">
                  <TextField
                    id="keterangan-kinerja-anggota"
                    label="Anggota"
                    variant="outlined"
                    value={
                      `${item.nama_lengkap} - ${item.npm} (${item.jabatan})` ||
                      ""
                    }
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <TextField
                    name={item.name}
                    label="Nilai"
                    variant="outlined"
                    value={item.nilai || ""}
                    onChange={(event) => {
                      const value = event.target.value;
                      const name = event.target.name;

                      const oldInputState = props.dataByOrganisasiActive.find(
                        (_item) => _item.name === name
                      );

                      props.setDataByOrganisasiActive(
                        [
                          ...props.dataByOrganisasiActive.filter(
                            (x) => x.name !== name
                          ),
                          { ...oldInputState, nilai: value },
                        ].sort((a, b) => {
                          return (
                            parseInt(a.id_detail_organisasi) -
                            parseInt(b.id_detail_organisasi)
                          );
                        })
                      );
                    }}
                  />
                </div>
              </div>
            ))}
        </form>
      </Dialog>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseDialog(setIsErrorSnackbarActive)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </React.Fragment>
  );
}

function KeteranganKinerja() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Keterangan Kinerja -----------------------------------------------------------

  // @Table Section
  // Keterangan Kinerja

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Keterangan Kinerja - By Organisasi

  const [dataByOrganisasi, setDataByOrganisasi] = useState([]);

  const [dataByOrganisasiActive, setDataByOrganisasiActive] = useState(null);

  const dataFieldsByOrganisasi = [
    {
      primary: true,
      checkbox: false,
      value: "id_organisasi",
      type: "text",
      label: "ID Organisasi",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "jenis",
      type: "single-textbox",
      label: "Jenis",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Organisasi", name: "textbox-blue-smooth" },
        { value: "Kepanitiaan", name: "textbox-brown-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_organisasi",
      type: "text",
      label: "Nama Organisasi",
      size: "default",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "periode_awal",
      type: "text",
      label: "Periode Awal",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "periode_akhir",
      type: "text",
      label: "Periode Akhir",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "anggota",
      type: "multiple-textbox",
      label: "Anggota",
      size: "xxl",
      position: "right",
      status: 1,
      contentReplace: {
        from: /\d\d*##/gm,
        to: "",
      },
    },
    {
      primary: false,
      value: "jumlah_anggota",
      type: "text",
      label: "Jumlah Anggota",
      size: "s",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByOrganisasi, setSortItemByOrganisasi] = useState({
    by: "nama_organisasi",
    mode: "ASC",
  });

  const [searchInputByOrganisasi, setSearchInputByOrganisasi] = useState("");

  const optionsSearchFilterByOrganisasi = [
    { value: "jenis", label: "Jenis" },
    { value: "nama_organisasi", label: "Nama Organisasi" },
    { value: "periode_awal", label: "Periode Awal" },
    { value: "periode_akhir", label: "Periode Akhir" },
    { value: "anggota", label: "Anggota" },
    { value: "jumlah_anggota", label: "Jumlah Anggota" },
  ];

  const [searchFilterByOrganisasi, setSearchFilterByOrganisasi] = useState([
    optionsSearchFilterByOrganisasi[1],
  ]);

  // @Pagination Section
  // Keterangan Kinerja - By Organisasi

  const optionsNumberPerPageByOrganisasi = [10, 20, 30, 40, 50];

  const [numberPerPageByOrganisasi, setNumberPerPageByOrganisasi] = useState(
    optionsNumberPerPageByOrganisasi[0]
  );

  const [
    numberPerPageFromByOrganisasi,
    setNumberPerPageFromByOrganisasi,
  ] = useState(0);

  const [
    numberPerPageToByOrganisasi,
    setNumberPerPageToByOrganisasi,
  ] = useState(0);

  const [totalDataByOrganisasi, setTotalDataByOrganisasi] = useState(0);

  const [totalPageByOrganisasi, setTotalPageByOrganisasi] = useState(0);

  const [currentPageByOrganisasi, setCurrentPageByOrganisasi] = useState(0);

  // @Dialog Section
  // Keterangan Kinerja

  const dialogFullScreenClassName = "keterangan-kinerja-full-screen-bar";

  const [dialogTitleAppBar, setDialogTitleAppBar] = useState("");

  const [
    isKeteranganKinerjaDialogActive,
    setIsKeteranganKinerjaDialogActive,
  ] = useState(false);

  const handleClickSingleBodyContentByOrganisasi = async (item) => {
    setDialogTitleAppBar(item.nama_organisasi);

    const detailOrganisasi = await getKeteranganKinerjaByOrganisasi(
      item.id_organisasi
    );

    if (detailOrganisasi.success) {
      setDataByOrganisasiActive(
        detailOrganisasi.data.map((item) => {
          return { ...item, name: `id_det_${item.id_detail_organisasi}` };
        })
      );

      setIsKeteranganKinerjaDialogActive(true);
    }
  };

  // const handleUpdateKeteranganKinerja = async ({
  //   nilai,
  //   id_detail_organisasi,
  // }) => {
  //   const update = await updateKeteranganKinerja({
  //     nilai,
  //     id_detail_organisasi,
  //   });

  //   if (update.success) {
  //     swal("Sukses!", update.message, "success");
  //   } else {
  //     swal(
  //       "Oops!",
  //       update.message ? update.message : "Internal Server Error",
  //       "warning"
  //     );
  //   }
  // };

  // const handleClickSingleRowTable = async ({
  //   id_organisasi,
  //   nama_organisasi,
  // }) => {
  //   setModalFormNilaiTitle(nama_organisasi);
  //   setIsFormModalNilaiActive(true);

  //   const getDetail = await getKeteranganKinerjaByOrganisasi(id_organisasi);

  //   if (getDetail.success) {
  //     setKeteranganKinerja(
  //       getDetail.data.map((item) => {
  //         return { ...item, name: `id_det_${item.id_detail_organisasi}` };
  //       })
  //     );
  //   }
  // };

  const handleLoadData = async () => {
    const [byOrganisasi, totalByOrganisasi] = await Promise.all([
      getOrganisasi({
        order: sortItemByOrganisasi,
        limit: numberPerPageByOrganisasi,
        offset: 0,
      }),
      getTotalOrganisasi({}),
    ]);

    if (byOrganisasi.success && totalByOrganisasi.success) {
      // Set Keterangan Kinerja - By Organisasi
      setDataByOrganisasi(byOrganisasi.data);
      setTotalDataByOrganisasi(totalByOrganisasi.data.total);
      setTotalPageByOrganisasi(
        Math.ceil(totalByOrganisasi.data.total / numberPerPageByOrganisasi)
      );
      setCurrentPageByOrganisasi(totalByOrganisasi.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByOrganisasi(
        totalByOrganisasi.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByOrganisasi(
        totalByOrganisasi.data.total > 0 ? byOrganisasi.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper keterangan-kinerja-content-panel">
          <div className="header-panel">
            <h1 className="topic-panel">Keterangan Kinerja</h1>
            <div className="header-right-button-area"></div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setDataByOrganisasi}
                        getData={getOrganisasi}
                        dataFields={dataFieldsByOrganisasi}
                        sortItem={sortItemByOrganisasi}
                        searchInput={searchInputByOrganisasi}
                        setSearchInput={setSearchInputByOrganisasi}
                        optionsSearchFilterBy={optionsSearchFilterByOrganisasi}
                        searchFilterBy={searchFilterByOrganisasi}
                        setSearchFilterBy={setSearchFilterByOrganisasi}
                        numberPerPage={numberPerPageByOrganisasi}
                        setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                        setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                        getTotalData={getTotalOrganisasi}
                        setTotalData={setTotalDataByOrganisasi}
                        setTotalPage={setTotalPageByOrganisasi}
                        setCurrentPage={setCurrentPageByOrganisasi}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setDataByOrganisasi}
                        getData={getOrganisasi}
                        dataFields={dataFieldsByOrganisasi}
                        sortItem={sortItemByOrganisasi}
                        searchInput={searchInputByOrganisasi}
                        searchFilterBy={searchFilterByOrganisasi}
                        optionsNumberPerPage={optionsNumberPerPageByOrganisasi}
                        numberPerPage={numberPerPageByOrganisasi}
                        setNumberPerPage={setNumberPerPageByOrganisasi}
                        numberPerPageFrom={numberPerPageFromByOrganisasi}
                        setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                        numberPerPageTo={numberPerPageToByOrganisasi}
                        setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                        totalData={totalDataByOrganisasi}
                        getTotalData={getTotalOrganisasi}
                        setTotalData={setTotalDataByOrganisasi}
                        totalPage={totalPageByOrganisasi}
                        setTotalPage={setTotalPageByOrganisasi}
                        currentPage={currentPageByOrganisasi}
                        setCurrentPage={setCurrentPageByOrganisasi}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={dataByOrganisasi}
                  setData={setDataByOrganisasi}
                  getData={getOrganisasi}
                  dataFields={dataFieldsByOrganisasi}
                  sortItem={sortItemByOrganisasi}
                  setSortItem={setSortItemByOrganisasi}
                  searchInput={searchInputByOrganisasi}
                  searchFilterBy={searchFilterByOrganisasi}
                  numberPerPage={numberPerPageByOrganisasi}
                  setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                  setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                  totalData={totalDataByOrganisasi}
                  getTotalData={getTotalOrganisasi}
                  setTotalData={setTotalDataByOrganisasi}
                  setTotalPage={setTotalPageByOrganisasi}
                  setCurrentPage={setCurrentPageByOrganisasi}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  setIsFullScreenLoading={setIsFullScreenLoading}
                  handleClickSingleBodyContent={
                    handleClickSingleBodyContentByOrganisasi
                  }
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <KeteranganKinerjaDialog
        isKeteranganKinerjaDialogActive={isKeteranganKinerjaDialogActive}
        setIsKeteranganKinerjaDialogActive={setIsKeteranganKinerjaDialogActive}
        dataByOrganisasiActive={dataByOrganisasiActive}
        setDataByOrganisasiActive={setDataByOrganisasiActive}
        dialogFullScreenClassName={dialogFullScreenClassName}
        dialogTitleAppBar={dialogTitleAppBar}
      />
    </React.Fragment>
  );
}

export default KeteranganKinerja;
