import React, { useContext, useEffect, useState, useRef } from "react";
import "../../style.css";
import "./style.css";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { DatePicker } from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { DropzoneDialog } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopperCustom from "../../../../../Components/PopperCustom";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import {
  handleOpenDialog,
  handleCloseDialog,
  getOptionLabel,
  handleChangeSelectItem,
  handleChangeTextField,
  handleMouseLeaveTextField,
  handleMouseEnterTextField,
  handleClickClearTextField,
  handleToggleDialogActive,
  handleMouseDownClearTextFieldDate,
  handleDialogSaveFile,
  handleCloseErrorSnackbar,
  handleFormSubmitAction,
} from "../../../../../Services/FormHandler";
import {
  numericRegExp,
  urlRegExp,
  emailRegExp,
} from "../../../../../Services/RegExp";
import { dateFormat } from "../../../../../Services/Format/date";
import swal from "sweetalert";
import useDidMountEffect from "../../../../../Components/useDidMountEffect";
import {
  updatePengguna,
  updateFotoPengguna,
} from "../../../../../Services/API/pengguna";
import {
  getFotoProfilPengguna,
  uploadFotoProfilPengguna,
  deleteFotoProfilPengguna,
} from "../../../../../Services/API/file";
import { getBase64FromFile } from "../../../../../Services/Format/base64";
import { ProfileContext } from "../../../Profil";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";

function MainBoxProfile() {
  const location = useLocation();

  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  const [isLoading, setIsLoading] = useState(false);

  const [edit_isButtonActionLoading, edit_setIsButtonActionLoading] = useState(
    false
  );

  const [edit_namaLengkap, edit_setNamaLengkap] = useState(
    userContext.userActive.nama_lengkap
  );

  const [edit_npm, edit_setNPM] = useState(userContext.userActive.npm);

  const [edit_programBelajar, edit_setProgramBelajar] = useState(
    userContext.userActive.program_belajar
  );

  const [edit_programPendidikan, edit_setProgramPendidikan] = useState(
    userContext.userActive.program_pendidikan
  );

  const [edit_angkatan, edit_setAngkatan] = useState(
    userContext.userActive.angkatan ? userContext.userActive.angkatan : ""
  );

  const [edit_isAngkatanClearActive, edit_setIsAngkatanClearActive] = useState(
    false
  );

  const [edit_fotoProfil, edit_setFotoProfil] = useState(
    userContext.userActive.foto_pengguna
      ? userContext.userActive.foto_pengguna
      : null
  );

  const [edit_previewFotoProfil, edit_setPreviewFotoProfil] = useState(
    userContext.userActive.preview_foto_pengguna
      ? userContext.userActive.preview_foto_pengguna
      : null
  );

  const [
    edit_isFotoProfilClearActive,
    edit_setIsFotoProfilClearActive,
  ] = useState(false);

  const [
    edit_isFotoProfilDialogActive,
    edit_setIsFotoProfilDialogActive,
  ] = useState(false);

  const [edit_tanggalLahir, edit_setTanggalLahir] = useState(
    userContext.userActive.tanggal_lahir
      ? new Date(userContext.userActive.tanggal_lahir)
      : null
  );

  const [
    edit_isTanggalLahirClearActive,
    edit_setIsTanggalLahirClearActive,
  ] = useState(false);

  const [
    edit_isTanggalLahirDialogActive,
    edit_setIsTanggalLahirDialogActive,
  ] = useState(false);

  const [edit_noHP, edit_setNoHP] = useState(
    userContext.userActive.no_hp || ""
  );

  const [edit_isNoHPClearActive, edit_setIsNoHPClearActive] = useState(false);

  const [edit_idLine, edit_setIdLine] = useState(
    userContext.userActive.id_line || ""
  );

  const [edit_isIdLineClearActive, edit_setIsIdLineClearActive] = useState(
    false
  );

  const [edit_usernameIG, edit_setUsernameIG] = useState(
    userContext.userActive.username_instagram || ""
  );

  const [
    edit_isUsernameIGClearActive,
    edit_setIsUsernameIGClearActive,
  ] = useState(false);

  const [edit_email, edit_setEmail] = useState(
    userContext.userActive.email || ""
  );

  const [edit_isEmailClearActive, edit_setIsEmailClearActive] = useState(false);

  const [edit_website, edit_setWebsite] = useState(
    userContext.userActive.website || ""
  );

  const [edit_isWebsiteClearActive, edit_setIsWebsiteClearActive] = useState(
    false
  );

  const [edit_ttRumah, edit_setTTRumah] = useState(
    userContext.userActive.tempat_tinggal_rumah || ""
  );

  const [edit_isTTRumahClearActive, edit_setIsTTRumahClearActive] = useState(
    false
  );

  const [edit_ttKuliah, edit_setTTKuliah] = useState(
    userContext.userActive.tempat_tinggal_kuliah || ""
  );

  const [edit_isTTKuliahClearActive, edit_setIsTTKuliahClearActive] = useState(
    false
  );

  const [edit_infoAktif, edit_setInfoAktif] = useState(
    userContext.userActive.info_tambahan_aktif
      ? userContext.userActive.info_tambahan_aktif.split(",").map((item) => {
          switch (item) {
            case "tanggal_lahir":
              return { value: item, label: "Tanggal Lahir" };
            case "no_hp":
              return { value: item, label: "No. HP" };
            case "id_line":
              return { value: item, label: "ID Line" };
            case "username_instagram":
              return { value: item, label: "Username Instagram" };
            case "email":
              return { value: item, label: "Email" };
            case "website":
              return { value: item, label: "Website" };
            case "tempat_tinggal_rumah":
              return { value: item, label: "Tempat Tinggal Rumah" };
            case "tempat_tinggal_kuliah":
              return { value: item, label: "Tempat Tinggal Kuliah" };
          }
        })
      : []
  );

  const [edit_optionsInfoAktif, edit_setOptionsInfoAktif] = useState([
    { value: "tanggal_lahir", label: "Tanggal Lahir" },
    { value: "no_hp", label: "No. HP" },
    { value: "id_line", label: "ID Line" },
    { value: "username_instagram", label: "Username Instagram" },
    { value: "email", label: "Email" },
    { value: "website", label: "Website" },
    { value: "tempat_tinggal_rumah", label: "Tempat Tinggal Rumah" },
    { value: "tempat_tinggal_kuliah", label: "Tempat Tinggal Kuliah" },
  ]);

  const [edit_tentang, edit_setTentang] = useState(
    userContext.userActive.tentang || ""
  );

  const [edit_isTentangClearActive, edit_setIsTentangClearActive] = useState(
    false
  );

  const [edit_tahunPencapaian, edit_setTahunPencapaian] = useState(null);

  const [
    edit_isTahunPencapaianClearActive,
    edit_setIsTahunPencapaianClearActive,
  ] = useState(false);

  const [
    edit_isTahunPencapaianDialogActive,
    edit_setIsTahunPencapaianDialogActive,
  ] = useState(false);

  const [edit_namaPencapaian, edit_setNamaPencapaian] = useState("");

  const [
    edit_isNamaPencapaianClearActive,
    edit_setIsNamaPencapaianClearActive,
  ] = useState(false);

  const [edit_prestasiAkademik, edit_setPrestasiAkademik] = useState(
    userContext.userActive.prestasi_akademik
      ? userContext.userActive.prestasi_akademik.split(",").map((item) => {
          return {
            tahun_pencapaian: item.split("##")[0],
            nama_pencapaian: item.split("##")[1],
          };
        })
      : []
  );

  const [
    edit_isTambahPrestasiAkademikClicked,
    edit_setIsTambahPrestasiAkademikClicked,
  ] = useState(false);

  const [edit_isErrorSnackbarActive, edit_setIsErrorSnackbarActive] = useState(
    false
  );

  const [edit_errorList, edit_setErrorList] = useState([]);

  const [edit_isFormSubmitted, edit_setIsFormSubmitted] = useState(false);

  const [
    edit_isEditProfileModalActive,
    edit_setIsEditProfileModalActive,
  ] = useState(false);

  const edit_getErrorList = (target) => {
    const _errorList = [];

    if (target.includes("angkatan")) {
      if (!edit_angkatan) {
        _errorList.push({
          type: "required",
          message: "Angkatan wajib diisi.",
        });
      } else {
        if (!numericRegExp.test(edit_angkatan)) {
          _errorList.push({
            type: "pattern",
            message: "Angkatan wajib berupa angka.",
          });
        }
      }
    }

    if (target.includes("tanggalLahir")) {
      if (!edit_tanggalLahir) {
        _errorList.push({
          type: "required",
          message: "Tanggal Lahir wajib diisi.",
        });
      }
    }

    if (target.includes("noHP")) {
      if (edit_noHP) {
        if (!numericRegExp.test(edit_noHP)) {
          _errorList.push({
            type: "pattern",
            message: "No. HP wajib berupa angka.",
          });
        }
      }
    }

    // if (target.includes("idLine")) {
    //   if (!edit_idLine) {
    //     _errorList.push({
    //       type: "required",
    //       message: "ID Line wajib diisi.",
    //     });
    //   }
    // }

    if (target.includes("email")) {
      if (!edit_email) {
        _errorList.push({
          type: "required",
          message: "Email wajib diisi.",
        });
      } else {
        if (!emailRegExp.test(edit_email)) {
          _errorList.push({
            type: "pattern",
            message: "Format Email tidak valid.",
          });
        }
      }
    }

    if (target.includes("website")) {
      if (edit_website) {
        if (!urlRegExp.test(edit_website)) {
          _errorList.push({
            type: "pattern",
            message: "Format Website tidak valid.",
          });
        }
      }
    }

    // if (target.includes("tempatTinggalRumah")) {
    //   if (!edit_ttRumah) {
    //     _errorList.push({
    //       type: "required",
    //       message: "Tempat Tinggal Rumah wajib diisi.",
    //     });
    //   }
    // }

    // if (target.includes("tempatTinggalKuliah")) {
    //   if (!edit_ttKuliah) {
    //     _errorList.push({
    //       type: "required",
    //       message: "Tempat Tinggal Kuliah wajib diisi.",
    //     });
    //   }
    // }

    if (target.includes("tentang")) {
      if (edit_tentang) {
        if (edit_tentang.length > 250) {
          _errorList.push({
            type: "maxLength",
            message: "Tentang Max. 250 Karakter.",
          });
        }
      }
    }

    if (target.includes("tahunPencapaian")) {
      if (!edit_tahunPencapaian) {
        _errorList.push({
          type: "required",
          message: "Tahun Pencapaian wajib dipilih.",
        });
      }
    }

    if (target.includes("namaPencapaian")) {
      if (!edit_namaPencapaian) {
        _errorList.push({
          type: "required",
          message: "Nama Pencapaian wajib diisi.",
        });
      }
    }

    return _errorList;
  };

  const handleResetData = () => {
    edit_setFotoProfil(
      userContext.userActive.foto_pengguna
        ? userContext.userActive.foto_pengguna
        : null
    );
    edit_setPreviewFotoProfil(
      userContext.userActive.preview_foto_pengguna
        ? userContext.userActive.preview_foto_pengguna
        : null
    );
    edit_setAngkatan(userContext.userActive.angkatan || "");
    edit_setTanggalLahir(
      userContext.userActive.tanggal_lahir
        ? new Date(userContext.userActive.tanggal_lahir)
        : null
    );
    edit_setNoHP(userContext.userActive.no_hp || "");
    edit_setIdLine(userContext.userActive.id_line || "");
    edit_setUsernameIG(userContext.userActive.username_instagram || "");
    edit_setEmail(userContext.userActive.email || "");
    edit_setWebsite(userContext.userActive.website || "");
    edit_setTTRumah(userContext.userActive.tempat_tinggal_rumah || "");
    edit_setTTKuliah(userContext.userActive.tempat_tinggal_kuliah || "");
    edit_setInfoAktif(
      userContext.userActive.info_tambahan_aktif
        ? userContext.userActive.info_tambahan_aktif.split(",").map((item) => {
            switch (item) {
              case "tanggal_lahir":
                return { value: item, label: "Tanggal Lahir" };
              case "no_hp":
                return { value: item, label: "No. HP" };
              case "id_line":
                return { value: item, label: "ID Line" };
              case "username_instagram":
                return { value: item, label: "Username Instagram" };
              case "email":
                return { value: item, label: "Email" };
              case "website":
                return { value: item, label: "Website" };
              case "tempat_tinggal_rumah":
                return { value: item, label: "Tempat Tinggal Rumah" };
              case "tempat_tinggal_kuliah":
                return { value: item, label: "Tempat Tinggal Kuliah" };
            }
          })
        : []
    );
    edit_setTentang(userContext.userActive.tentang || "");
    edit_setPrestasiAkademik(
      userContext.userActive.prestasi_akademik
        ? userContext.userActive.prestasi_akademik.split(",").map((item) => {
            return {
              tahun_pencapaian: item.split("##")[0],
              nama_pencapaian: item.split("##")[1],
            };
          })
        : []
    );
  };

  const handleUpdateProfileSucceed = (data) => {
    swal("Sukses!", "Profil berhasil diupdate.", "success");

    userContext.dispatchUserActive({
      data,
    });
  };

  const handleClickDeleteProfilePicture = () => {
    edit_setPreviewFotoProfil(null);
    edit_setFotoProfil(null);
  };

  const handleClickDeletePrestasiAkademik = async (item) => {
    edit_setPrestasiAkademik(
      await edit_prestasiAkademik.filter(
        (x) =>
          x.tahun_pencapaian !== item.tahun_pencapaian &&
          x.nama_pencapaian !== item.nama_pencapaian
      )
    );
  };

  const handleKeyUpNamaPencapaian = (event) => {
    if (event.keyCode === 13) {
      edit_setIsTambahPrestasiAkademikClicked(true);
    }
  };

  const handleClickTambahPrestasiAkademik = () => {
    edit_setIsTambahPrestasiAkademikClicked(true);
  };

  const generateResumePDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    const pageWidth = doc.internal.pageSize.width;

    doc.setFont("sans-serif");

    doc.setFontSize(24);
    doc.text(profileContext.pengguna.nama_lengkap, 40, 50, null, null, "left");

    doc.setFontSize(13);
    doc.text(
      `Fakultas Kedokteran Universitas Indonesia ${profileContext.pengguna.angkatan}`,
      40,
      80,
      null,
      null,
      "left"
    );

    doc.setFont("sans-serif", "italic");
    doc.text("Prestasi Akademik", 40, 110, null, null, "left");

    doc.line(40, 113, 140, 113);

    let yCurrent = 130;

    doc.setFont("sans-serif", "normal");

    if (profileContext.pengguna.prestasi_akademik) {
      profileContext.pengguna.prestasi_akademik.split(",").forEach((item) => {
        doc.text(
          `${item.split("##")[0]}     ${item.split("##")[1]}`,
          40,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 16;
      });
    } else {
      doc.text("-", 40, yCurrent, null, null, "left");
      yCurrent += 16;
    }

    yCurrent += 16;

    doc.setFont("sans-serif", "italic");
    doc.text("Prestasi Non-Akademik", 40, yCurrent, null, null, "left");

    doc.line(40, yCurrent + 3, 165, yCurrent + 3);

    yCurrent += 20;

    doc.setFont("sans-serif", "normal");

    if (profileContext.ligaKreasi.length > 0) {
      profileContext.ligaKreasi.forEach((item) => {
        doc.text(
          `${dateFormat(new Date(item.awal_pelaksanaan), "yyyy")}     ${
            item.capaian_prestasi
          } ${item.nama_perlombaan}`,
          40,
          yCurrent,
          null,
          null,
          "left"
        );
      });

      yCurrent += 16;
    } else {
      doc.text("-", 40, yCurrent, null, null, "left");
      yCurrent += 16;
    }

    yCurrent += 16;

    doc.setFont("sans-serif", "italic");
    doc.text(
      "Pengalaman Organisasi dan Kepanitiaan",
      40,
      yCurrent,
      null,
      null,
      "left"
    );

    doc.line(40, yCurrent + 3, 260, yCurrent + 3);

    yCurrent += 20;

    doc.setFont("sans-serif", "normal");

    if (profileContext.organisasi.length > 0) {
      profileContext.organisasi.forEach((item) => {
        doc.text(
          `${dateFormat(new Date(item.periode_awal), "yyyy")}-${dateFormat(
            new Date(item.periode_akhir),
            "yyyy"
          )}     ${item.nama_organisasi}`,
          40,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 16;
      });
    } else {
      doc.text("-", 40, yCurrent, null, null, "left");
      yCurrent += 16;
    }

    yCurrent += 16;

    doc.setFont("sans-serif", "italic");
    doc.text("Publikasi", 40, yCurrent, null, null, "left");

    doc.line(40, yCurrent + 3, 90, yCurrent + 3);

    yCurrent += 20;

    doc.setFont("sans-serif", "normal");

    if (profileContext.publikasi.length > 0) {
      profileContext.publikasi.forEach((item) => {
        doc.text(
          `${item.judul_publikasi} (${dateFormat(
            new Date(item.tanggal_publikasi),
            "yyyy"
          )})`,
          40,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 16;
      });
    } else {
      doc.text("-", 40, yCurrent, null, null, "left");
      yCurrent += 16;
    }

    doc.save(
      `Resume_${profileContext.pengguna.nama_lengkap}_${profileContext.pengguna.npm}.pdf`
    );
  };

  useDidMountEffect(() => {
    if (edit_isTambahPrestasiAkademikClicked) {
      edit_setErrorList(
        edit_getErrorList(["tahunPencapaian", "namaPencapaian"])
      );
    }
  }, [edit_isTambahPrestasiAkademikClicked]);

  useDidMountEffect(() => {
    handleResetData();
  }, [userContext.userActive]);

  useDidMountEffect(() => {
    if (
      edit_fotoProfil &&
      edit_fotoProfil !== userContext.userActive.foto_pengguna
    ) {
      getBase64FromFile(edit_fotoProfil).then((res) => {
        edit_setPreviewFotoProfil(`url(${res})`);
      });
    }
  }, [edit_fotoProfil]);

  useDidMountEffect(() => {
    if (edit_infoAktif.length > 5) {
      swal("Oops!", "Maaf, Max. Info Untuk Ditampilkan Hanya 5.", "warning");
      edit_setInfoAktif(edit_infoAktif.slice(0, 5));
    }
  }, [edit_infoAktif]);

  useDidMountEffect(() => {
    edit_setIsAngkatanClearActive(edit_angkatan ? true : false);
  }, [edit_angkatan]);

  useDidMountEffect(() => {
    edit_setIsNoHPClearActive(edit_noHP ? true : false);
  }, [edit_noHP]);

  useDidMountEffect(() => {
    edit_setIsIdLineClearActive(edit_idLine ? true : false);
  }, [edit_idLine]);

  useDidMountEffect(() => {
    edit_setIsUsernameIGClearActive(edit_usernameIG ? true : false);
  }, [edit_usernameIG]);

  useDidMountEffect(() => {
    edit_setIsEmailClearActive(edit_email ? true : false);
  }, [edit_email]);

  useDidMountEffect(() => {
    edit_setIsWebsiteClearActive(edit_website ? true : false);
  }, [edit_website]);

  useDidMountEffect(() => {
    edit_setIsTTRumahClearActive(edit_ttRumah ? true : false);
  }, [edit_ttRumah]);

  useDidMountEffect(() => {
    edit_setIsTTKuliahClearActive(edit_ttKuliah ? true : false);
  }, [edit_ttKuliah]);

  useDidMountEffect(() => {
    edit_setIsTentangClearActive(edit_tentang ? true : false);
  }, [edit_tentang]);

  useDidMountEffect(() => {
    edit_setIsNamaPencapaianClearActive(edit_namaPencapaian ? true : false);
  }, [edit_namaPencapaian]);

  useDidMountEffect(() => {
    edit_setIsErrorSnackbarActive(edit_errorList ? true : false);

    if (edit_isFormSubmitted) {
      edit_setIsFormSubmitted(false);

      setTimeout(async () => {
        if (edit_errorList.length === 0) {
          edit_setIsButtonActionLoading(true);

          const fotoProfilFormData = new FormData();

          const updateDataPengguna = {
            id_pengguna: userContext.userActive.id_pengguna,
            angkatan: edit_angkatan,
            tanggal_lahir: dateFormat(
              new Date(edit_tanggalLahir),
              "yyyy-MM-dd"
            ),
            no_hp: edit_noHP,
            id_line: edit_idLine,
            username_instagram: edit_usernameIG,
            email: edit_email,
            website: edit_website,
            tempat_tinggal_rumah: edit_ttRumah,
            tempat_tinggal_kuliah: edit_ttKuliah,
            tentang: edit_tentang,
            info_tambahan_aktif: edit_infoAktif
              .map((item) => item.value)
              .join(","),
            prestasi_akademik:
              edit_prestasiAkademik.length > 0
                ? edit_prestasiAkademik
                    .map((item) => {
                      return `${item.tahun_pencapaian}##${item.nama_pencapaian}`;
                    })
                    .join(",")
                : null,
          };

          if (
            edit_fotoProfil &&
            edit_fotoProfil !== userContext.userActive.foto_pengguna
          ) {
            fotoProfilFormData.append("single_file", edit_fotoProfil);
          }

          const [
            deleteOldFotoProfil,
            uploadNewFotoProfil,
            updateProfilPengguna,
          ] = await Promise.allSettled([
            deleteFotoProfilPengguna(
              edit_fotoProfil
                ? edit_fotoProfil === userContext.userActive.foto_pengguna
                  ? null
                  : userContext.userActive.foto_pengguna
                : userContext.userActive.foto_pengguna
                ? userContext.userActive.foto_pengguna
                : null
            ),
            uploadFotoProfilPengguna(fotoProfilFormData),
            updatePengguna(updateDataPengguna),
          ]);

          if (
            uploadNewFotoProfil.value.success &&
            updateProfilPengguna.value.success
          ) {
            if (
              uploadNewFotoProfil.value.data ||
              (!edit_fotoProfil && userContext.userActive.foto_pengguna)
            ) {
              const updateFotoProfil = await updateFotoPengguna({
                foto_pengguna: uploadNewFotoProfil.value.data
                  ? uploadNewFotoProfil.value.data
                  : null,
                id_pengguna: userContext.userActive.id_pengguna,
              });

              if (updateFotoProfil.success) {
                handleUpdateProfileSucceed({
                  ...userContext.userActive,
                  ...updateDataPengguna,
                  foto_pengguna: uploadNewFotoProfil.value.data
                    ? uploadNewFotoProfil.value.data
                    : !edit_fotoProfil && userContext.userActive.foto_pengguna
                    ? null
                    : userContext.userActive.foto_pengguna,
                  preview_foto_pengguna: edit_previewFotoProfil
                    ? edit_previewFotoProfil
                    : null,
                });
              } else {
                swal(
                  "Oops!",
                  updateFotoProfil.message
                    ? updateFotoProfil.message
                    : "Internal Server Error",
                  "warning"
                );
              }
            } else {
              handleUpdateProfileSucceed({
                ...userContext.userActive,
                ...updateDataPengguna,
                foto_pengguna: uploadNewFotoProfil.value.data
                  ? uploadNewFotoProfil.value.data
                  : !edit_fotoProfil && userContext.userActive.foto_pengguna
                  ? null
                  : userContext.userActive.foto_pengguna,
                preview_foto_pengguna: edit_previewFotoProfil
                  ? edit_previewFotoProfil
                  : null,
              });
            }
          } else {
            if (!uploadNewFotoProfil.value.success) {
              swal("Oops!", "Foto Profil gagal diupdate.", "warning");
            } else if (!updateProfilPengguna.value.success) {
              swal("Oops!", "Profil gagal diupdate.", "warning");
            }
          }

          edit_setIsButtonActionLoading(false);
        }
      }, 0);
    } else if (edit_isTambahPrestasiAkademikClicked) {
      edit_setIsTambahPrestasiAkademikClicked(false);

      if (edit_errorList.length === 0) {
        if (
          edit_prestasiAkademik.find(
            (item) =>
              item.tahun_pencapaian ===
                dateFormat(new Date(edit_tahunPencapaian), "yyyy") &&
              item.nama_pencapaian === edit_namaPencapaian
          )
        ) {
          swal("Oops!", "Prestasi tersebut telah ditambahkan.", "warning");
        } else {
          edit_setPrestasiAkademik([
            ...edit_prestasiAkademik,
            {
              tahun_pencapaian: dateFormat(
                new Date(edit_tahunPencapaian),
                "yyyy"
              ),
              nama_pencapaian: edit_namaPencapaian,
            },
          ]);

          edit_setTahunPencapaian(null);
          edit_setNamaPencapaian("");
        }
      }
    }
  }, [edit_errorList]);

  useDidMountEffect(() => {
    if (edit_isFormSubmitted) {
      edit_setErrorList(
        edit_getErrorList([
          "angkatan",
          "tanggalLahir",
          "no HP",
          // "idLine",
          "email",
          "website",
          // "tempatTinggalRumah",
          // "tempatTinggalKuliah",
          "tentang",
        ])
      );
    }
  }, [edit_isFormSubmitted]);

  return (
    !isLoading && (
      <React.Fragment>
        <div className="main-box-profile">
          <div className="profile-card">
            <div className="npm-user-area">
              <span className="npm-user-area">
                <span className="npm-label">NPM:</span>
                <span className="npm-value">
                  {location.pathname === "/profil"
                    ? userContext.userActive.npm
                    : profileContext.pengguna.npm}
                </span>
              </span>
            </div>
            <div className="profile-picture-area">
              <div
                className="profile-picture"
                style={{
                  backgroundImage: `${
                    location.pathname === "/profil"
                      ? userContext.userActive.preview_foto_pengguna
                        ? userContext.userActive.preview_foto_pengguna
                        : `url(${require("../../../../../Assets/Miscellaneous/no_image_profile.png")})`
                      : profileContext.pengguna.preview_foto_pengguna
                      ? profileContext.pengguna.preview_foto_pengguna
                      : `url(${require("../../../../../Assets/Miscellaneous/no_image_profile.png")})`
                  }`,
                }}
              ></div>
            </div>
            <div className="user-name-area">
              <span className="user-name">
                {location.pathname === "/profil"
                  ? userContext.userActive.nama_lengkap.toLowerCase()
                  : profileContext.pengguna.nama_lengkap.toLowerCase()}
              </span>
            </div>
            <div className="graduation-area">
              <span className="graduation">
                FKUI{" "}
                {location.pathname === "/profil"
                  ? userContext.userActive.angkatan
                  : profileContext.pengguna.angkatan}
              </span>
            </div>
            <div className="study-program-area">
              <span className="study-program">
                {location.pathname === "/profil"
                  ? userContext.userActive.program_belajar
                  : profileContext.pengguna.program_belajar}
              </span>
            </div>
            <div className="button-wrapper">
              {location.pathname === "/profil" && (
                <div className="single-button edit-profile">
                  <span className="button-icon">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                  <span
                    className="button-text"
                    onClick={() =>
                      handleOpenDialog(edit_setIsEditProfileModalActive)
                    }
                  >
                    Edit Profil
                  </span>
                </div>
              )}
              {/* {location.pathname !== "/profil" && (
                <div
                  className="single-button download-resume"
                  onClick={generateResumePDF}
                >
                  <span className="button-icon">
                    <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                  <span className="button-text">Download Resume</span>
                </div>
              )} */}
            </div>
          </div>
          <div className="detail-profile">
            <div className="about-area">
              <div className="about-title-area">
                <span className="title-text">Tentang Saya</span>
              </div>
              <div className="about-body-area">
                <span className="body-text">
                  {location.pathname === "/profil"
                    ? userContext.userActive.tentang
                      ? userContext.userActive.tentang
                      : "-"
                    : profileContext.pengguna.tentang
                    ? profileContext.pengguna.tentang
                    : "-"}
                </span>
              </div>
            </div>
            <div className="additional-info-area">
              <div className="ranking-area">
                <div className="ranking-title">Ranking Liga Kreasi</div>
                <div className="ranking-score-area">
                  <div className="score-area">
                    <span className="main-score">
                      {location.pathname === "/profil"
                        ? userContext.userActive.rank
                        : profileContext.pengguna.rank}
                    </span>
                    <span className="total-player">
                      /{" "}
                      {location.pathname === "/profil"
                        ? userContext.userActive.jumlah_seangkatan
                        : profileContext.pengguna.jumlah_seangkatan}
                    </span>
                  </div>
                  <span className="helper-text">Per Angkatan</span>
                </div>
              </div>
              <div className="info-list-area">
                <div className="info-list-title">Informasi Tambahan</div>
                <div className="list-wrapper">
                  {location.pathname === "/profil" ? (
                    userContext.userActive.info_tambahan_aktif ? (
                      <React.Fragment>
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "tanggal_lahir"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">
                              Tanggal Lahir:
                            </span>
                            <span className="single-list-value">
                              {dateFormat(
                                new Date(userContext.userActive.tanggal_lahir),
                                "dd-MM-yyyy"
                              )}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "no_hp"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">No. HP:</span>
                            <span className="single-list-value">
                              {userContext.userActive.no_hp}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "id_line"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">ID LINE:</span>
                            <span className="single-list-value">
                              {userContext.userActive.id_line}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "username_instagram"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">
                              Instagram:
                            </span>
                            <span className="single-list-value">
                              {userContext.userActive.username_instagram}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "email"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">Email:</span>
                            <span className="single-list-value">
                              {userContext.userActive.email}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "website"
                        ) && (
                          <div className="single-list website">
                            <span className="single-list-label">Website:</span>
                            <span className="single-list-value">
                              <a
                                href={userContext.userActive.website}
                                target="_blank"
                              >
                                {userContext.userActive.website}
                              </a>
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "tempat_tinggal_rumah"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">
                              Tempat Tinggal Rumah:
                            </span>
                            <span className="single-list-value">
                              {userContext.userActive.tempat_tinggal_rumah}
                            </span>
                          </div>
                        )}
                        {userContext.userActive.info_tambahan_aktif.includes(
                          "tempat_tinggal_kuliah"
                        ) && (
                          <div className="single-list">
                            <span className="single-list-label">
                              Tempat Tinggal Kuliah:
                            </span>
                            <span className="single-list-value">
                              {userContext.userActive.tempat_tinggal_kuliah}
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      "-"
                    )
                  ) : profileContext.pengguna.info_tambahan_aktif ? (
                    <React.Fragment>
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "tanggal_lahir"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">
                            Tanggal Lahir:
                          </span>
                          <span className="single-list-value">
                            {dateFormat(
                              new Date(profileContext.pengguna.tanggal_lahir),
                              "dd-MM-yyyy"
                            )}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "no_hp"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">No. HP:</span>
                          <span className="single-list-value">
                            {profileContext.pengguna.no_hp}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "id_line"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">ID LINE:</span>
                          <span className="single-list-value">
                            {profileContext.pengguna.id_line}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "username_instagram"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">Instagram:</span>
                          <span className="single-list-value">
                            {profileContext.pengguna.username_instagram}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "email"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">Email:</span>
                          <span className="single-list-value">
                            {profileContext.pengguna.email}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "website"
                      ) && (
                        <div className="single-list website">
                          <span className="single-list-label">Website:</span>
                          <span className="single-list-value">
                            <a
                              href={profileContext.pengguna.website}
                              target="_blank"
                            >
                              {profileContext.pengguna.website}
                            </a>
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "tempat_tinggal_rumah"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">
                            Tempat Tinggal Rumah:
                          </span>
                          <span className="single-list-value">
                            {profileContext.pengguna.tempat_tinggal_rumah}
                          </span>
                        </div>
                      )}
                      {profileContext.pengguna.info_tambahan_aktif.includes(
                        "tempat_tinggal_kuliah"
                      ) && (
                        <div className="single-list">
                          <span className="single-list-label">
                            Tempat Tinggal Kuliah:
                          </span>
                          <span className="single-list-value">
                            {profileContext.pengguna.tempat_tinggal_kuliah}
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          fullScreen
          open={edit_isEditProfileModalActive}
          onClose={() => {
            handleCloseDialog(edit_setIsEditProfileModalActive);
            handleResetData();
          }}
          aria-label="close"
          className="edit-profile-full-screen-bar full-screen-app-bar"
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  handleCloseDialog(edit_setIsEditProfileModalActive);
                  handleResetData();
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className="main-title-modal-form">
                Edit Profil
              </Typography>
              <Button
                onClick={(event) =>
                  handleFormSubmitAction(event, edit_setIsFormSubmitted)
                }
                className="single-button-right-top-custom"
              >
                {edit_isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  "Update"
                )}
              </Button>
            </Toolbar>
          </AppBar>
          <form
            className="fullscreen-modal-form-body edit-profile-body-form"
            autoComplete="off"
          >
            <div className="form-group profile-picture-wrapper">
              <div className="inner-form-group-wrapper">
                <div className="inner-profile-picture-area">
                  <div
                    className="profile-picture"
                    style={{
                      backgroundImage: edit_previewFotoProfil
                        ? edit_previewFotoProfil
                        : `url(${require("../../../../../Assets/Miscellaneous/no_image_profile.png")})`,
                    }}
                  ></div>
                </div>
                <div className="inner-profile-picture-action">
                  <div
                    className="single-button-action delete-profile-picture"
                    onClick={handleClickDeleteProfilePicture}
                  >
                    <span className="single-button-text">
                      Hapus Foto Profil
                    </span>
                  </div>
                  <div
                    className="single-button-action change-profile-picture"
                    onClick={() =>
                      handleOpenDialog(edit_setIsFotoProfilDialogActive)
                    }
                  >
                    <span className="single-button-text">
                      Ganti Foto Profil
                    </span>
                  </div>
                  <DropzoneDialog
                    acceptedFiles={["image/*"]}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Upload"}
                    maxFileSize={5000000}
                    filesLimit={1}
                    open={edit_isFotoProfilDialogActive}
                    onClose={() =>
                      handleCloseDialog(edit_setIsFotoProfilDialogActive)
                    }
                    onSave={(files) =>
                      handleDialogSaveFile(
                        files,
                        null,
                        edit_setFotoProfil,
                        edit_setIsFotoProfilDialogActive
                      )
                    }
                    showPreviews={true}
                    showFileNamesInPreview={true}
                  />
                </div>
              </div>
            </div>
            <div className="form-group required disabled nama-lengkap-area">
              <TextField
                id="edit-profil-nama-lengkap"
                label="Nama Lengkap"
                variant="outlined"
                value={
                  userContext.userActive.nama_lengkap
                    ? userContext.userActive.nama_lengkap.toLowerCase()
                    : ""
                }
                readOnly
              />
            </div>
            <div className="form-group required">
              <TextField
                id="edit-profil-angkatan"
                label="Angkatan"
                variant="outlined"
                value={edit_angkatan || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setAngkatan)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_angkatan,
                    edit_setIsAngkatanClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsAngkatanClearActive)
                }
                InputProps={{
                  endAdornment: edit_isAngkatanClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setAngkatan,
                            edit_setIsAngkatanClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group required disabled">
              <TextField
                id="edit-profil-npm"
                label="NPM"
                variant="outlined"
                value={userContext.userActive.npm || ""}
                readOnly
              />
            </div>
            <div className="form-group required disabled">
              <TextField
                id="edit-profil-program-belajar"
                label="Program Belajar"
                variant="outlined"
                value={userContext.userActive.program_belajar || ""}
                readOnly
              />
            </div>
            <div className="form-group required disabled">
              <TextField
                id="edit-profil-program-pendidikan"
                label="Program Pendidikan"
                variant="outlined"
                value={userContext.userActive.program_pendidikan || ""}
                readOnly
              />
            </div>
            <div className="form-group required">
              <DatePicker
                format="dd-MM-yyyy"
                label="Tanggal Lahir"
                maxDate={new Date()}
                openTo="year"
                views={["year", "month", "date"]}
                value={edit_tanggalLahir || null}
                inputVariant="outlined"
                onChange={(selected) =>
                  handleChangeSelectItem(selected, edit_setTanggalLahir)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_tanggalLahir,
                    edit_setIsTanggalLahirClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsTanggalLahirClearActive)
                }
                open={edit_isTanggalLahirDialogActive}
                onOpen={() =>
                  handleToggleDialogActive(
                    edit_isTanggalLahirDialogActive,
                    edit_setIsTanggalLahirDialogActive
                  )
                }
                onClose={() =>
                  handleToggleDialogActive(
                    edit_isTanggalLahirDialogActive,
                    edit_setIsTanggalLahirDialogActive
                  )
                }
                InputProps={{
                  endAdornment: edit_isTanggalLahirClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onMouseDown={() =>
                          handleMouseDownClearTextFieldDate(
                            edit_setTanggalLahir,
                            edit_setIsTanggalLahirClearActive,
                            edit_setIsTanggalLahirDialogActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-no-hp"
                label="No. HP"
                variant="outlined"
                value={edit_noHP || ""}
                onChange={(event) => handleChangeTextField(event, edit_setNoHP)}
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_noHP,
                    edit_setIsNoHPClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsNoHPClearActive)
                }
                InputProps={{
                  endAdornment: edit_isNoHPClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setNoHP,
                            edit_setIsNoHPClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-id-line"
                label="ID Line"
                variant="outlined"
                value={edit_idLine || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setIdLine)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_idLine,
                    edit_setIsIdLineClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsIdLineClearActive)
                }
                InputProps={{
                  endAdornment: edit_isIdLineClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setIdLine,
                            edit_setIsIdLineClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-username-instagram"
                label="Username Instagram"
                variant="outlined"
                value={edit_usernameIG || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setUsernameIG)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_usernameIG,
                    edit_setIsUsernameIGClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsUsernameIGClearActive)
                }
                InputProps={{
                  endAdornment: edit_isUsernameIGClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setUsernameIG,
                            edit_setIsUsernameIGClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group required">
              <TextField
                id="edit-profil-email"
                label="Email"
                variant="outlined"
                value={edit_email || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setEmail)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_email,
                    edit_setIsEmailClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsEmailClearActive)
                }
                InputProps={{
                  endAdornment: edit_isEmailClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setEmail,
                            edit_setIsEmailClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-website"
                label="Website"
                variant="outlined"
                value={edit_website || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setWebsite)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_website,
                    edit_setIsWebsiteClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsWebsiteClearActive)
                }
                InputProps={{
                  endAdornment: edit_isWebsiteClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setWebsite,
                            edit_setIsWebsiteClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-tempat-tinggal-rumah"
                label="Tempat Tinggal Rumah"
                variant="outlined"
                value={edit_ttRumah || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setTTRumah)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_ttRumah,
                    edit_setIsTTRumahClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsTTRumahClearActive)
                }
                InputProps={{
                  endAdornment: edit_isTTRumahClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setTTRumah,
                            edit_setIsTTRumahClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-tempat-tinggal-kuliah"
                label="Tempat Tinggal Kuliah"
                variant="outlined"
                value={edit_ttKuliah || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setTTKuliah)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_ttKuliah,
                    edit_setIsTTKuliahClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsTTKuliahClearActive)
                }
                InputProps={{
                  endAdornment: edit_isTTKuliahClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setTTKuliah,
                            edit_setIsTTKuliahClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group">
              <Autocomplete
                id="edit-profil-info-tambahan-aktif"
                multiple
                options={edit_optionsInfoAktif}
                noOptionsText="Tidak Ada Opsi"
                clearOnBlur
                clearText="Bersihkan"
                openText="Pilih"
                getOptionLabel={getOptionLabel}
                onChange={(event, selected) =>
                  handleChangeSelectItem(selected, edit_setInfoAktif)
                }
                value={edit_infoAktif || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Info Tambahan Untuk Ditampilkan"
                    variant="outlined"
                  />
                )}
                PopperComponent={PopperCustom}
              />
            </div>
            <div className="form-group">
              <TextField
                id="edit-profil-tentang"
                label="Tentang"
                variant="outlined"
                multiline
                rows={3}
                helperText="*Maksimal 250 Karakter."
                value={edit_tentang || ""}
                onChange={(event) =>
                  handleChangeTextField(event, edit_setTentang)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    edit_tentang,
                    edit_setIsTentangClearActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(edit_setIsTentangClearActive)
                }
                InputProps={{
                  endAdornment: edit_isTentangClearActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            edit_setTentang,
                            edit_setIsTentangClearActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="prestasi-akademik-form-wrapper">
              <div className="label-wrapper">
                <span className="label-text">Prestasi Akademik</span>
              </div>
              <div className="form-group">
                <div className="inner-form-group-wrapper">
                  <div className="inner-form-group prestasi-akademik-form-group">
                    <div className="inner-half-form">
                      <DatePicker
                        format="yyyy"
                        label="Tahun Pencapaian"
                        maxDate={new Date()}
                        openTo="year"
                        views={["year"]}
                        value={edit_tahunPencapaian || null}
                        inputVariant="outlined"
                        onChange={(selected) =>
                          handleChangeSelectItem(
                            selected,
                            edit_setTahunPencapaian
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseEnterTextField(
                            edit_tahunPencapaian,
                            edit_setIsTahunPencapaianClearActive
                          )
                        }
                        onMouseLeave={() =>
                          handleMouseLeaveTextField(
                            edit_setIsTahunPencapaianClearActive
                          )
                        }
                        open={edit_isTahunPencapaianDialogActive}
                        onOpen={() =>
                          handleToggleDialogActive(
                            edit_isTahunPencapaianDialogActive,
                            edit_setIsTahunPencapaianDialogActive
                          )
                        }
                        onClose={() =>
                          handleToggleDialogActive(
                            edit_isTahunPencapaianDialogActive,
                            edit_setIsTahunPencapaianDialogActive
                          )
                        }
                        InputProps={{
                          endAdornment: edit_isTahunPencapaianClearActive && (
                            <InputAdornment position="end">
                              <IconButton
                                onMouseDown={() =>
                                  handleMouseDownClearTextFieldDate(
                                    edit_setTahunPencapaian,
                                    edit_setIsTahunPencapaianClearActive,
                                    edit_setIsTahunPencapaianDialogActive
                                  )
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="inner-half-form">
                      <TextField
                        id="prestasi-akademik-nama-pencapaian"
                        label="Nama Pencapaian"
                        variant="outlined"
                        value={edit_namaPencapaian || ""}
                        onChange={(event) =>
                          handleChangeTextField(event, edit_setNamaPencapaian)
                        }
                        onMouseEnter={() =>
                          handleMouseEnterTextField(
                            edit_namaPencapaian,
                            edit_setIsNamaPencapaianClearActive
                          )
                        }
                        onMouseLeave={() =>
                          handleMouseLeaveTextField(
                            edit_setIsNamaPencapaianClearActive
                          )
                        }
                        onKeyUp={handleKeyUpNamaPencapaian}
                        InputProps={{
                          endAdornment: edit_isNamaPencapaianClearActive && (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  handleClickClearTextField(
                                    edit_setNamaPencapaian,
                                    edit_setIsNamaPencapaianClearActive
                                  )
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="prestasi-akademik-button-form-group">
                    <div
                      className="add-button"
                      onClick={handleClickTambahPrestasiAkademik}
                    >
                      <span className="button-text">Tambah Prestasi</span>
                    </div>
                  </div>
                </div>
              </div>
              {edit_prestasiAkademik.length > 0 && (
                <div className="form-group">
                  <div className="text-area-list-prestasi-akademik">
                    {edit_prestasiAkademik.map((item) => (
                      <div className="single-list-little-box">
                        <span className="list-text">
                          {item.tahun_pencapaian} - {item.nama_pencapaian}
                        </span>
                        <span
                          className="delete-prestasi-icon-area"
                          onClick={() =>
                            handleClickDeletePrestasiAkademik({
                              tahun_pencapaian: item.tahun_pencapaian,
                              nama_pencapaian: item.nama_pencapaian,
                            })
                          }
                        >
                          <span className="delete-icon">
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </form>
        </Dialog>
        <Portal>
          <Snackbar
            open={edit_isErrorSnackbarActive && edit_errorList.length > 0}
            onClose={() =>
              handleCloseErrorSnackbar(edit_setIsErrorSnackbarActive)
            }
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <MuiAlert variant="filled" severity="error">
              <ul className="error-list">
                {edit_errorList.map((element, index) => (
                  <li className="single-list" key={index}>
                    {element.message}
                  </li>
                ))}
              </ul>
            </MuiAlert>
          </Snackbar>
        </Portal>
      </React.Fragment>
    )
  );
}

export default MainBoxProfile;
