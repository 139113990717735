import axios from "axios";
import config from "../../Config";

// @pengguna

const getFotoProfilPengguna = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/pengguna/foto_profil/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const uploadFotoProfilPengguna = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengguna/foto_profil`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteFotoProfilPengguna = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengguna/foto_profil/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

// @ligaKreasi

const getSertifikatPialaMedali = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/liga_kreasi/sertifikat_piala_medali/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const getFotoPenyerahanPenghargaan = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/liga_kreasi/foto_penyerahan_penghargaan/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const getManuskripKarya = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/liga_kreasi/manuskrip_karya/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const uploadSertifikatPialaMedali = async (data) => {
  const fileTarget = data.get("single_file");

  if (fileTarget) {
    if(typeof fileTarget === "string") {
      return {
        success: true,
        message: "Tidak ada file yang perlu diupload.",
      };
    } else {
      const request = await axios({
        method: "POST",
        baseURL: `${config.SERVER_URI}/file/liga_kreasi/sertifikat_piala_medali`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      const response = request.data;
      return response;
    }
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const uploadFotoPenyerahanPenghargaan = async (data) => {
  const fileTarget = data.get("single_file");

  if(fileTarget) {
    if(typeof fileTarget === "string") {
      return {
        success: true,
        message: "Tidak ada file yang perlu diupload.",
      };
    } else {
      const request = await axios({
        method: "POST",
        baseURL: `${config.SERVER_URI}/file/liga_kreasi/foto_penyerahan_penghargaan`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      const response = request.data;
      return response;
    }
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const uploadManuskripKarya = async (data) => {
  const fileTarget = data.get("single_file");

  if (fileTarget) {
    if(typeof fileTarget === "string") {
      return {
        success: true,
        message: "Tidak ada file yang perlu diupload.",
      };
    } else {
      const request = await axios({
        method: "POST",
        baseURL: `${config.SERVER_URI}/file/liga_kreasi/manuskrip_karya`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      const response = request.data;
      return response;
    }
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

// @bisnis

const uploadLogoBisnis = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/bisnis/logo_bisnis`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const getLogoBisnis = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/bisnis/logo_bisnis/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

// @beasiswa

const uploadScreenshotStatusAkademik = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pendaftar_beasiswa/ss_status_akademik`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const uploadScreenshotIuranBOP = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pendaftar_beasiswa/ss_iuran_bop`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const getScreenshotIuranBOP = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/pendaftar_beasiswa/ss_iuran_bop/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const getScreenshotStatusAkademik = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/pendaftar_beasiswa/ss_status_akademik/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

// @kaderisasi_berkelanjutan

const uploadKKMBuktiKeikutsertaan = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/kaderisasi_berkelanjutan/kkm/bukti_keikutsertaan`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const getKKMBuktiKeikutsertaan = async (data) => {
  if (data) {
    const request = await axios({
      method: "GET",
      baseURL: `${config.SERVER_URI}/file/kaderisasi_berkelanjutan/kkm/bukti_keikutsertaan/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diambil.",
    };
  }
};

const uploadSertifikatPenyuluhan = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/penyuluhan/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatPenyuluhan = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/penyuluhan/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatKegiatanSosial = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/kegiatan_sosial/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatKegiatanSosial = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/kegiatan_sosial/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatScreening = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/screening/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatScreening = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/screening/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatPengobatanMassal = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/pengobatan_massal/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatPengobatanMassal = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/pengobatan_massal/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatSirkumsisi = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/sirkumsisi/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatSirkumsisi = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/sirkumsisi/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatKunjunganPasien = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/kunjungan_pasien/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatKunjunganPasien = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/kunjungan_pasien/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

const uploadSertifikatTimMedisFKUI = async (data) => {
  if (data.get("single_file")) {
    const request = await axios({
      method: "POST",
      baseURL: `${config.SERVER_URI}/file/pengabdian/tim_medis_fkui/sertifikat`,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu diupload.",
    };
  }
};

const deleteSertifikatTimMedisFKUI = async (data) => {
  if (data) {
    const request = await axios({
      method: "DELETE",
      baseURL: `${config.SERVER_URI}/file/pengabdian/tim_medis_fkui/sertifikat/${data}`,
    });

    const response = request.data;
    return response;
  } else {
    return {
      success: true,
      message: "Tidak ada file yang perlu dihapus.",
    };
  }
};

export {
  getFotoProfilPengguna,
  uploadFotoProfilPengguna,
  deleteFotoProfilPengguna,
  uploadSertifikatPialaMedali,
  getSertifikatPialaMedali,
  getFotoPenyerahanPenghargaan,
  getManuskripKarya,
  uploadFotoPenyerahanPenghargaan,
  uploadManuskripKarya,
  uploadLogoBisnis,
  getLogoBisnis,
  uploadScreenshotStatusAkademik,
  getScreenshotStatusAkademik,
  getScreenshotIuranBOP,
  uploadScreenshotIuranBOP,
  uploadKKMBuktiKeikutsertaan,
  getKKMBuktiKeikutsertaan,
  uploadSertifikatPenyuluhan,
  deleteSertifikatPenyuluhan,
  uploadSertifikatKegiatanSosial,
  deleteSertifikatKegiatanSosial,
  uploadSertifikatScreening,
  deleteSertifikatScreening,
  uploadSertifikatPengobatanMassal,
  deleteSertifikatPengobatanMassal,
  uploadSertifikatSirkumsisi,
  deleteSertifikatSirkumsisi,
  uploadSertifikatKunjunganPasien,
  deleteSertifikatKunjunganPasien,
  uploadSertifikatTimMedisFKUI,
  deleteSertifikatTimMedisFKUI
};
