import React, { useEffect, useState, useContext, useRef } from "react";
import "./style.css";
import Navbar from "../../../Components/Navbar";
import { UserContext } from "..";
import Axios from "axios";
import Verification from "./Components/Verification";
import PemilihanKabem from "./Components/PemilihanKabem";
import PemilihanBpm from "./Components/PemilihanBpm";
import { __RouterContext } from "react-router";
import config from "../../../Config";

function PemiraIKMFKUI2020() {
  // const routerContext = useContext(__RouterContext);
  const [comingSoon, setComingSoon] = useState(false);
  // setComingSoon to true to close the pemilihan page
  const [loadingSubmittingChoice, setLoadingSubmittingChoice] = useState(false);
  const [participationDone, setParticipationDone] = useState(false);
  const [bpmChoice, setBpmChoice] = useState(0);
  const [kabemChoice, setKabemChoice] = useState(0);

  async function getTimestamp() {
    const response = await Axios.get(
      "https://worldtimeapi.org/api/timezone/Asia/Jakarta"
    );
    return response.data.unixtime;
  }

  const namaSuratBpm = [
    null,
    "Anda memilih calon no-1 (Johannes Elia)",
    "Anda memilih kertas suara kosong",
  ];
  const namaKabem = [
    null,
    "Christopher Christian & Putri Saskia H",
    "Nadhif Wiratara & M. Dhawinanda",
  ];

  const userContext = useContext(UserContext);
  // console.log("userContext", userContext.userActive.npm);
  const npm = userContext.userActive.npm;
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [disableConfirmationButton, setDisableConfirmationButton] = useState(
    false
  );
  const [code, setCode] = useState("");

  const [ableToChoose, setAbleToChoose] = useState(false);

  useEffect(() => {
    (async () => {
      // check if the user with NPM = npm has verified = true

      const endpoint = `${config.SERVER_URI}/pemira/getVerificationStatus/` + npm;

      Axios.get(endpoint)
        .then(function (response) {
          // handle success
          // console.log(response.data.success);
          if (response.data.success) {
            setAbleToChoose(true);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });

      const participationStatusEndpoint =
        `${config.SERVER_URI}/pemira/getParticipationStatus/` + npm;
      Axios.get(participationStatusEndpoint)
        .then(function (response) {
          // handle success
          console.log(response.data.success);
          if (response.data.success) {
            setParticipationDone(true);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    })();
  }, []);

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    // console.log(code);
    // CEK APAKAH CURRENT TIMESTAMP - TIMESTAMP CODE > -1
    // verify.data.data[0].timestamp ==

    const date = new Date();
    const timestamp = date.getTime();
    try {
      const verify = await Axios.post(`${config.SERVER_URI}/pemira/getEmailAndCode`, {
        email: email,
        sixDigits: code,
      });
      // verify
      const isCodeValid = verify.data.data[0]?.sixDigits == code;
      if (isCodeValid) {
        // POST to verification tracker
        Axios.post(`${config.SERVER_URI}/pemira/updateVerificationStatus`, {
          npm,
        }).then((response) => {
          setAbleToChoose(true);
        });
      } else {
        alert("CODE INVALID, PLEASE TRY AGAIN");
      }
      // console.log(verify.data.data[0].sixDigits);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableConfirmationButton(true);
    console.log("email", email);

    // TODO: Check whether the email has waited 2 minutes yet or not

    const sixDigits = Math.floor(100000 + Math.random() * 900000);
    const bodySend = { emailAddress: email, verificationCode: sixDigits };
    // const date = new Date();
    // const timestamp = date.getTime();

    const date = new Date();
    const timestamp = date.getTime();
    // console.log("GENERATED TIMESTAMP BUAT DB", timestamp); // BENER

    Axios.post(`${config.SERVER_URI}/pemira/mailing`, bodySend)
      .then(function (response) {
        Axios.post(`${config.SERVER_URI}/pemira/insertCodeTracker`, {
          npm,
          email,
          sixDigits: sixDigits,
          timestamp,
        }).then((response) => {
          // console.log("TRACKER RES", response);
        });
        setEmailSent(true);
        // console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  const handleSubmitChoice = (e) => {
    e.preventDefault();
    setLoadingSubmittingChoice(true);
    // POST REQUEST insert npm and choices to choice_tracker
    // route = insertChoiceTracker
    Axios.post(`${config.SERVER_URI}/pemira/insertChoiceTracker`, {
      npm,
      executiveChoice: kabemChoice,
      legislativeChoice: bpmChoice,
    })
      .then((response) => {
        console.log("CHOICE TRACKER DONE", response.data);
        setParticipationDone(true);
      })
      .catch(function (error) {
        // handle error
        alert("ERROR, SILAKAN COBA KEMBALI");
      });
  };

  return (
    <React.Fragment>
      <Navbar />
      <div className="body-content-wrapper-pemilihan">
        {comingSoon ? (
          <div className="pemilihan-container">
            <div className="hero-center-pemira">
              <img
                src={require("../../../Assets/Logos/bpm-logo.jpg")}
                className="bpm-logo"
                alt="BPM Logo"
              />
              <img
                src={require("../../../Assets/Logos/pemira-logo.jpg")}
                className="pemira-logo"
                alt="Pemira Logo"
              />
              <h1 className="pemira-title">Closed</h1>
            </div>
          </div>
        ) : (
          <div className="pemilihan-container">
            <div className="hero-center-pemira">
              <img
                src={require("../../../Assets/Logos/bpm-logo.jpg")}
                className="bpm-logo"
                alt="BPM Logo"
              />
              <img
                src={require("../../../Assets/Logos/pemira-logo.jpg")}
                className="pemira-logo"
                alt="Pemira Logo"
              />
              <h1 className="pemira-title">
                Selamat Datang di Pemilihan Raya IKM FKUI 2020
              </h1>
            </div>
            <h2 className="pemira-title green-text-participation">
                Pemilihan telah ditutup
              </h2>
            {/* {participationDone ? (
              <h2 className="pemira-title green-text-participation">
                Anda telah memilih, terima kasih atas partisipasi Anda!
              </h2>
            ) : (
              <>
                <div className="pemira-body">
                  {ableToChoose ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <PemilihanKabem
                        kabemChoice={kabemChoice}
                        setKabemChoice={setKabemChoice}
                        namaKabem={namaKabem}
                      />
                      <PemilihanBpm
                        bpmChoice={bpmChoice}
                        setBpmChoice={setBpmChoice}
                        namaSuratBpm={namaSuratBpm}
                      />
                      <div className="rekap-pilihan">
                        <h1>Rekap Pilihan Anda</h1>
                        <p>Pasangan Calon BEM IKM FKUI 2021</p>
                        <div className="rekap-pilihan-text">
                          <p>
                            {kabemChoice > 0
                              ? namaKabem[kabemChoice]
                              : "Anda belum memilih"}
                          </p>
                        </div>
                        <p>Calon BPM IKM FKUI 2021</p>
                        <div className="rekap-pilihan-text">
                          <p>
                            {bpmChoice > 0
                              ? namaSuratBpm[bpmChoice]
                              : "Anda belum memilih"}
                          </p>
                        </div>
                        <div className="konfirmasi-button__container">
                          <button
                            className="konfirmasi-pilihan-button green-button"
                            onClick={handleSubmitChoice}
                            disabled={loadingSubmittingChoice}
                          >
                            Konfirmasi Pilihan
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Verification
                      emailSent={emailSent}
                      handleSubmitCode={handleSubmitCode}
                      setCode={setCode}
                      code={code}
                      email={email}
                      handleSubmit={handleSubmit}
                      disableConfirmationButton={disableConfirmationButton}
                      ableToChoose={ableToChoose}
                      setEmail={setEmail}
                    />
                  )}
                </div>
              </>
            )} */}
            <div className="footer-pemira">
              <p>Pemilihan Raya IKM FKUI 2020</p>
              <a href="https://www.instagram.com/pemira_fkui/">Instagram</a>
            </div>
          </div>
        )}
        {/* END OF PEMILIHAN CONTAINER */}
      </div>
    </React.Fragment>
  );
}

export default PemiraIKMFKUI2020;
