import React from "react";
import Popper from "@material-ui/core/Popper";

function PopperCustom(props) {
  return (
    <Popper
      {...props}
      placement="bottom"
      modifiers={{
        flip: {
          boundariesElement: "scrollParent",
          behavior: ["bottom"],
        },
        preventOverflow: {
          boundariesElement: "viewport",
        },
      }}
      disablePortal
    />
  );
}

export default PopperCustom;
