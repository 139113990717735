import React from "react";
import "./style.css";

function ComingSoon() {
  return (
    <div className="coming-soon-area">
      <span className="coming-soon-text">Coming Soon...</span>
    </div>
  );
}

export default ComingSoon;
