import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { handleChangeSelectObject } from "../../../../../../../Services/FormHandler";

function ShowDataToggle(props) {
  return (
    <div className="show-data-toggle-area">
      <FormControl variant="outlined">
        <InputLabel id="show-data-toggle-label">Tampilkan</InputLabel>
        <Select
          labelId="show-data-toggle-label"
          value={props.showDataActive.value}
          onChange={(event) => {
            props.setDataSelected([]);
            handleChangeSelectObject(event, props.setShowDataActive);
          }}
          label="Tampilkan"
        >
          {props.optionsShowData.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default ShowDataToggle;
