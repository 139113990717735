import React, { useRef, useContext, useEffect } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faBell,
  faEllipsisH,
  faTrash,
  faBookmark as fasBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { faBookmark as farBookmark } from "@fortawesome/free-regular-svg-icons";
import useOnClickOutside from "../useOnClickOutside";
import { UserContext } from "../../Scenes/User";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom"

function Navbar() {
  const [cookies, setCookies, removeCookies] = useCookies(["onedatamakara"]);

  const history = useHistory();

  const dummyBodyNotification = [
    {
      id: 1,
      text: "Lorem ipsum test 1",
      dateTime: "2 menit yang lalu",
      isRead: false,
    },
    {
      id: 2,
      text: "Lorem ipsum test 2",
      dateTime: "5 jam yang lalu",
      isRead: true,
    },
    {
      id: 3,
      text: "Lorem ipsum test 3",
      dateTime: "7 jam yang lalu",
      isRead: true,
    },
    {
      id: 4,
      text: "Lorem ipsum test 4",
      dateTime: "1 hari yang lalu",
      isRead: false,
    },
    {
      id: 5,
      text: "Lorem ipsum test 5",
      dateTime: "4 minggu yang lalu",
      isRead: true,
    },
    {
      id: 6,
      text: "Lorem ipsum test 6",
      dateTime: "6 bulan yang lalu",
      isRead: true,
    },
  ];

  const location = useLocation();

  const userContext = useContext(UserContext);

  const headerNotifActionContentRef = useRef(null);
  const notificationContentRef = useRef(null);
  const userThumbnailActionContentRef = useRef(null);

  const handleGoToProfilePage = () => {
    userContext.dispatchIsUserThumbnailActionActive({
      type: false,
    });
    userContext.dispatchPageActive("Profil");
  };

  const logoutOnClick = (event) => {
    event.preventDefault();
    removeCookies("onedatamakara", { path: "/" });
    userContext.dispatchUserActive(null);
    history.go(0);
  };

  useOnClickOutside(headerNotifActionContentRef, () => {
    if (userContext.isHeaderNotifActionActive)
      userContext.dispatchIsHeaderNotifActionActive({
        type: false,
      });
  });

  useOnClickOutside(notificationContentRef, () => {
    if (userContext.isNotifContentActive) {
      userContext.dispatchIsNotifContentActive({
        type: false,
      });
    }
  });

  useOnClickOutside(userThumbnailActionContentRef, () => {
    if (userContext.isUserThumbnailActionActive) {
      userContext.dispatchIsUserThumbnailActionActive({
        type: false,
      });
    }
  });

  return (
    <div className="navbar">
      <div className="logo-section">
        <a href="/">
          <img
            className="logo"
            src={require("../../Assets/Logos/odm-logo-title.png")}
          />
        </a>
      </div>
      <div className="navigation-section">
        <ul className="navigation-list">
          <li
            className={`single-list${
              location.pathname === "" || location.pathname === "/" || location.pathname.includes("/survey") ? " active" : ""
            }`}
            onClick={() => {
              userContext.dispatchPageActive("Beranda");
            }}
          >
            <Link to="/">Beranda</Link>
          </li>
          <li
            className={`single-list${
              location.pathname === "/telusuri" || location.pathname === "/telusuri/"
                ? " active"
                : ""
            }`}
            onClick={() => userContext.dispatchPageActive("Telusuri")}
          >
            <Link to="/telusuri">Telusuri</Link>
          </li>
          <li
            className={`single-list${
              location.pathname === "/profil" || location.pathname === "/profil/" ? " active" : ""
            }`}
            onClick={() => userContext.dispatchPageActive("Profil")}
          >
            <Link to="/profil">Profil</Link>
          </li>
          <li className={`single-list${location.pathname === "/pemira-ikm-fkui-2020" || location.pathname === "/pemira-ikm-fkui-2020/" ? " active" : ""}`} onClick={() => 
            userContext.dispatchPageActive("Pemira IKM FKUI 2020")
          }>
            <Link to="/pemira-ikm-fkui-2020">Pemira IKM FKUI 2020</Link>
          </li>
        </ul>
      </div>
      <div className="user-section">
        <div
          className="thumbnail"
          onMouseDown={() =>
            userContext.dispatchIsUserThumbnailActionActive({
              type: !userContext.isUserThumbnailActionActive,
            })
          }
        >
          <div
            className="profile-picture"
            style={{
              backgroundImage: `${
                userContext.userActive.preview_foto_pengguna
                  ? userContext.userActive.preview_foto_pengguna
                  : `url(${require("../../Assets/Photos/no-picture.png")})`
              }`,
            }}
          ></div>
          <div className="online-symbol"></div>
        </div>
        <div
          className={`user-action${
            userContext.isUserThumbnailActionActive ? " active" : ""
          }`}
          ref={userThumbnailActionContentRef}
        >
          <ul className="user-action-list">
            <Link to="/profil">
              <li className="single-list" onClick={handleGoToProfilePage}>
                <span className="action-icon">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span className="action-text">Profil Saya</span>
              </li>
            </Link>
          </ul>
          <div className="horizontal-line-separator"></div>
          <ul className="user-action-list">
            <span onClick={logoutOnClick}>
              <li className="single-list logout-action">
                <span className="action-icon">
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                <span className="action-text">Keluar</span>
              </li>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
