import React, { useContext, useState, useEffect } from "react";
import "./style.css";
import { UserContext } from "../../../../../../User";

function SelamatDatang() {
  const userContext = useContext(UserContext);

  return (
    <div className="user-page-selamat-datang">
      <div className="primary-greetings">
        <span className="greetings-text">
          Hello, {userContext.userActive.nama_lengkap.split(" ")[0]}
        </span>
      </div>
      <div className="secondary-greetings">
        <span className="greetings-text">
          Welcome to <span className="main-title">OneDataMakara</span>, a
          frictionless information gathering system for IKM FKUI.
        </span>
      </div>
      {/* <div className="button-wrapper">
        <div className="single-button">
          <span className="button-text">Take Tour</span>
        </div>
      </div> */}
    </div>
  );
}

export default SelamatDatang;
