const initialState = {
  pageActive: "Beranda",
  menuActive: "SelamatDatang",
  pengumumanContentActive: "Info",
  userActive: null,
  isHeaderNotifActionActive: false,
  isNotifContentActive: false,
  isUserThumbnailActionActive: false,
};

export const {
  pageActive,
  menuActive,
  userActive,
  pengumumanContentActive,
  isHeaderNotifActionActive,
  isNotifContentActive,
  isUserThumbnailActionActive,
} = initialState;
