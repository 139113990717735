import Axios from "axios";
import React, { useState } from "react";
import "../styles.css";

function Login({ setLoggedIn }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "pemilihanraya") {
      Axios.post("/api/pemira/loginPasswordPemira", { password }).then(
        (res) => {
          if (res.data.success) {
            setLoggedIn(true);
          } else {
            alert("PASSWORD SALAH");
          }
        }
      );
    }
  };
  return (
    <>
      <h1>Pemira Admin Login</h1>
      <form onSubmit={handleSubmit} className="login__container">
        <input
          autoFocus
          type="text"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          placeholder="Username"
        />
        <input
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
        />
        <button onClick={handleSubmit}>Login</button>
      </form>
    </>
  );
}

export default Login;
