import React, { useState, useEffect, useRef, useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { DropzoneDialog } from "material-ui-dropzone";
import { DatePicker } from "@material-ui/pickers";
import { UserContext } from "../../../../../../User";
import { dateFormat } from "../../../../../../../Services/Format/date";
import {
  getOptionLabel,
  handleChangeSelectItem,
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleOpenDialog,
  handleCloseDialog,
  handleDialogSaveFile,
  handleToggleDialogActive,
  handleMouseDownClearTextFieldDate,
  handleCloseErrorSnackbar,
  handleFormSubmitAction,
} from "../../../../../../../Services/FormHandler";
import {
  insertKKM,
  insertSKP,
  updateKKMBuktiKeikutsertaan,
} from "../../../../../../../Services/API/kaderisasi_berkelanjutan";
import swal from "sweetalert";
import { uploadKKMBuktiKeikutsertaan } from "../../../../../../../Services/API/file";

function KaderisasiBerkelanjutan() {
  const userContext = useContext(UserContext);

  const buktiKeikutsertaanRef = useRef(null);

  const [namaKegiatan, setNamaKegiatan] = useState("");
  const [tanggalKegiatan, setTanggalKegiatan] = useState(null);
  const [
    isTanggalKegiatanClearActive,
    setIsTanggalKegiatanClearActive,
  ] = useState(false);
  const [
    isTanggalKegiatanDialogActive,
    setIsTanggalKegiatanDialogActive,
  ] = useState(false);
  const [isNamaKegiatanClearActive, setIsNamaKegiatanClearActive] = useState(
    false
  );
  const [penyelenggara, setPenyelenggara] = useState("");
  const [isPenyelenggaraClearActive, setIsPenyelenggaraClearActive] = useState(
    false
  );

  const [jabatanInstansi, setJabatanInstansi] = useState("");
  const [
    isClearJabatanInstansiActive,
    setIsClearJabatanInstansiActive,
  ] = useState(false);

  const [buktiKeikutsertaan, setBuktiKeikutsertaan] = useState(false);
  const [
    isClearBuktiKeikutsertaanActive,
    setIsClearBuktiKeikutsertaanActive,
  ] = useState(false);
  const [
    isBuktiKeikutsertaanDialogActive,
    setIsBuktiKeikutsertaanDialogActive,
  ] = useState(false);

  const [tingkatKKM, setTingkatKKM] = useState(null);
  const [indikatorPencapaian, setIndikatorPencapaian] = useState([]);
  const [klaim, setKlaim] = useState(null);

  const [unsurKegiatan, setUnsurKegiatan] = useState(null);
  const [tingkat, setTingkat] = useState(null);
  const [posisi, setPosisi] = useState(null);

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);
  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [optionsIndikatorKKM1, setOptionsIndikatorKKM1] = useState([
    {
      value: "Sistem modul dalam Kurikulum Fakultas",
      label: "Sistem modul dalam Kurikulum Fakultas",
      tingkat: "Tingkat 1 Preklinik",
    },
    {
      value: "Mengetahui metode tipe gaya pembelajaran",
      label: "Mengetahui metode tipe gaya pembelajaran",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Mengetahui fasilitas dan cara mengakses student support di lingkungan FKUI",
      label: "Mengetahui fasilitas dan cara mengakses student support di lingkungan FKUI",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Mengetahui komponen karakter dokter yang ideal",
      label: "Mengetahui komponen karakter dokter yang ideal",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Mengenal UUD IKM (Struktur kelembagaan, peraturan perundang-undangan, keanggotaan IKM FKUI)",
      label: "Mengenal UUD IKM (Struktur kelembagaan, peraturan perundang-undangan, keanggotaan IKM FKUI)",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Mengikuti kepanitiaan, paling tidak di lingkungan FKUI",
      label: "Mengikuti kepanitiaan, paling tidak di lingkungan FKUI",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Mengetahui dan memanfaatkan fasilitas pembinaan rohani yang tersedia di lingkungan IKM FKUI",
      label: "Mengetahui dan memanfaatkan fasilitas pembinaan rohani yang tersedia di lingkungan IKM FKUI",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Memahami situasi dan membangun interaksi sosial-budaya dalam angkatan",
      label: "Memahami situasi dan membangun interaksi sosial-budaya dalam angkatan",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Menanamkan urgensi proses bimbingan yang diberikan oleh kakak tingkat terhadap adik tingkat",
      label: "Menanamkan urgensi proses bimbingan yang diberikan oleh kakak tingkat terhadap adik tingkat",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Tata krama komunikasi kepada dosen dan senior",
      label: "Tata krama komunikasi kepada dosen dan senior",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Memahami academic honesty, kredibilitas sumber, tingkat pembuktian (level of evidence), dan metode sitasi",
      label: "Memahami academic honesty, kredibilitas sumber, tingkat pembuktian (level of evidence), dan metode sitasi",
      tingkat: "Tingkat 1 Preklinik"
    },
    {
      value: "Memiliki wawasan terhadap isu kesehatan di masyarakat",
      label: "Memiliki wawasan terhadap isu kesehatan di masyarakat",
      tingkat: "Tingkat 1 Preklinik"
    }
  ]);

  const [optionsIndikatorKKM2, setOptionsIndikatorKKM2] = useState([
    {
      value: "Meyakini bahwa konsep beragama memberikan konsekuensi bagi pemeluknya untuk wajib menjalankan perintah Tuhan dan menjauhi hal-hal yang dilarang",
      label: "Meyakini bahwa konsep beragama memberikan konsekuensi bagi pemeluknya untuk wajib menjalankan perintah Tuhan dan menjauhi hal-hal yang dilarang",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Melaksanakan toleransi beragama",
      label: "Melaksanakan toleransi beragama",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Memahami etika berkomunikasi yang baik dengan sejawat",
      label: "Memahami etika berkomunikasi yang baik dengan sejawat",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Menunjukkan sikap disiplin dan bertanggung jawab dalam melaksanakan aktivitas sehari-hari sebagai mahasiswa",
      label: "Menunjukkan sikap disiplin dan bertanggung jawab dalam melaksanakan aktivitas sehari-hari sebagai mahasiswa",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Memahami situasi dan membangun interaksi sosial budaya lintas angkatan di dalam FKUI",
      label: "Memahami situasi dan membangun interaksi sosial budaya lintas angkatan di dalam FKUI",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Membantu kesulitan sejawat melalui berbagai aspek",
      label: "Membantu kesulitan sejawat melalui berbagai aspek",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Berpartisipasi aktif dalam acara/kegiatan/himpunan angkatan",
      label: "Berpartisipasi aktif dalam acara/kegiatan/himpunan angkatan",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Memberikan bimbingan terhadap adik dalam berbagai permasalahan yang dihadapi di FKUI",
      label: "Memberikan bimbingan terhadap adik dalam berbagai permasalahan yang dihadapi di FKUI",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mengetahui cara mengatasi kelemahan diri sendiri dalam menjalani kegiatan akademik dan non akademik",
      label: "Mengetahui cara mengatasi kelemahan diri sendiri dalam menjalani kegiatan akademik dan non akademik",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Fokus dalam peminatan yang dipilih",
      label: "Fokus dalam peminatan yang dipilih",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mengidentifikasi support system",
      label: "Mengidentifikasi support system",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mampu melaksanakan komunikasi efektif secara interpersonal",
      label: "Mampu melaksanakan komunikasi efektif secara interpersonal",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mampu melakukan komunikasi efektif dalam kelompok",
      label: "Mampu melakukan komunikasi efektif dalam kelompok",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mampu melakukan komunikasi efektif dengan dosen atau pihak lain yang dituakan",
      label: "Mampu melakukan komunikasi efektif dengan dosen atau pihak lain yang dituakan",
      tingkat: "Tingkat 2 Preklinik"
    },
    {
      value: "Mampu membuat tulisan tentang isu kesehatan yang dipublikasikan",
      label: "Mampu membuat tulisan tentang isu kesehatan yang dipublikasikan",
      tingkat: "Tingkat 2 Preklinik"
    }
  ]);
  
  const [optionsIndikatorKKM3, setOptionsIndikatorKKM3] = useState([
    {
      value: "Meyakini bahwa konsep beragama memberikan konsekuensi bagi pemeluknya untuk wajib menjalankan perintah Tuhan dan menjauhi hal-hal yang dilarang",
      label: "Meyakini bahwa konsep beragama memberikan konsekuensi bagi pemeluknya untuk wajib menjalankan perintah Tuhan dan menjauhi hal-hal yang dilarang",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Melaksanakan toleransi beragama",
      label: "Melaksanakan toleransi beragama",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Menjaga etika berkomunikasi dengan sejawat",
      label: "Menjaga etika berkomunikasi dengan sejawat",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Memahami situasi dan membangun interaksi sosial budaya di masyarakat secara umum / di luar FKUI",
      label: "Memahami situasi dan membangun interaksi sosial budaya di masyarakat secara umum / di luar FKUI",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Menunjukkan sikap disiplin, profesional, dan bertanggun jawab dalam melaksanakan aktivitas sehari-hari sebagai mahasiswa",
      label: "Menunjukkan sikap disiplin, profesional, dan bertanggun jawab dalam melaksanakan aktivitas sehari-hari sebagai mahasiswa",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Mengetahui dan menerapkan sikap profesional yang harus dimiliki sebagai calon dokter",
      label: "Mengetahui dan menerapkan sikap profesional yang harus dimiliki sebagai calon dokter",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Mampu evaluasi diri atas kelemahan dan kekuatan diri",
      label: "Mampu evaluasi diri atas kelemahan dan kekuatan diri",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Mampu membentuk rencana pengembangan diri yang SMART dari refleksi diri yang dilakukan",
      label: "Mampu membentuk rencana pengembangan diri yang SMART dari refleksi diri yang dilakukan",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Mampu melakukan komunikasi efektif terkait dengan kesehatan",
      label: "Mampu melakukan komunikasi efektif terkait dengan kesehatan",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Mampu mengedukasi individu tentang masalah kesehatan yang dialami dengan supervisi",
      label: "Mampu mengedukasi individu tentang masalah kesehatan yang dialami dengan supervisi",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Membantu menyelesaikan kesulitan sejawat melalui berbagai aspek",
      label: "Membantu menyelesaikan kesulitan sejawat melalui berbagai aspek",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Berpartisipasi aktif dalam acara angkatan, baik sebagai peserta atau penyelenggara",
      label: "Berpartisipasi aktif dalam acara angkatan, baik sebagai peserta atau penyelenggara",
      tingkat: "Tingkat 3 Preklinik"
    },
    {
      value: "Membimbing sejawat yang lebih junior",
      label: "Membimbing sejawat yang lebih junior",
      tingkat: "Tingkat 3 Preklinik"
    }
  ]);

  const [optionsKlaim, setOptionsKlaim] = useState([
    { value: "KKM", label: "KKM" },
    { value: "SKP", label: "SKP" },
  ]);

  const [optionsTingkatKKM, setOptionsTingkatKKM] = useState([
    { value: "Tingkat 1 Preklinik", label: "Tingkat 1 Preklinik" },
    { value: "Tingkat 2 Preklinik", label: "Tingkat 2 Preklinik" },
    { value: "Tingkat 3 Preklinik", label: "Tingkat 3 Preklinik" },
  ]);

  const [optionsUnsurKegiatan, setOptionsUnsurKegiatan] = useState([
    {
      value:
        "Menduduki jabatan pimpinan di badan/lembaga/organisasi kemahasiswaan",
      label:
        "Menduduki jabatan pimpinan di badan/lembaga/organisasi kemahasiswaan",
    },
    {
      value: "Menduduki jabatan di kepanitiaan/tim khusus",
      label: "Menduduki jabatan di kepanitiaan/tim khusus",
    },
    {
      value: "Mengikuti Latihan Kepemimpinan/Dasar Manajemen",
      label: "Mengikuti Latihan Kepemimpinan/Dasar Manajemen",
    },
    {
      value: "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan",
      label: "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan",
    },
  ]);

  const [
    optionsJabatanPimpinanTingkat,
    setOptionsJabatanPimpinanTingkat,
  ] = useState([
    { value: "Internasional", label: "Internasional" },
    { value: "Nasional/Universitas", label: "Nasional/Universitas" },
    { value: "Regional/BEM/BPM", label: "Regional/BEM/BPM" },
    {
      value: "Fakultas (Badan Kelengkapan) dan Stunica",
      label: "Fakultas (Badan Kelengkapan) dan Stunica",
    },
    { value: "Senat Tingkat", label: "Senat Tingkat" },
  ]);

  const [
    optionsJabatanPanitiaTingkat,
    setOptionsJabatanPanitiaTingkat,
  ] = useState([
    { value: "Internasional", label: "Internasional" },
    { value: "Nasional/Regional", label: "Nasional/Regional" },
    { value: "Universitas/Fakultas", label: "Universitas/Fakultas" },
  ]);

  const [optionsLatihanKepemimpinan, setOptionsLatihanKepemimpinan] = useState([
    { value: "Nasional", label: "Nasional" },
    { value: "Regional/Wilayah", label: "Regional/Wilayah" },
    { value: "Universitas/Fakultas", label: "Universitas/Fakultas" },
  ]);

  const [optionsPosisiA, setOptionsPosisiA] = useState([
    { value: "Ketua/Wakil Ketua/Sekjen", label: "Ketua/Wakil Ketua/Sekjen" },
    {
      value: "Pengurus Inti/Pengurus Harian",
      label: "Pengurus Inti/Pengurus Harian",
    },
    { value: "Anggota", label: "Anggota" },
  ]);

  const [optionsPosisiB, setOptionsPosisiB] = useState([
    { value: "Ketua/Wakil Ketua/Sekjen", label: "Ketua/Wakil Ketua/Sekjen" },
    {
      value: "PI/PH",
      label: "PI/PH",
    },
    { value: "Anggota", label: "Anggota" },
  ]);

  const [optionsPosisiC, setOptionsPosisiC] = useState([
    { value: "Pembicara", label: "Pembicaara" },
  ]);

  const handleResetData = () => {
    setNamaKegiatan("");
    setTanggalKegiatan(null);
    setPenyelenggara("");
    setJabatanInstansi("");
    setBuktiKeikutsertaan(null);
    setTingkatKKM(null);
    setIndikatorPencapaian(null);
    setKlaim(null);
    setUnsurKegiatan(null);
    setTingkat(null);
    setPosisi(null);
  };

  const getErrorList = (target) => {
    const _errorList = [];

    if (target.includes("klaim")) {
      if (!klaim) {
        _errorList.push({
          type: "required",
          message: "Sesuatu yang ingin diklaim wajib dipilih.",
        });
      }
    }

    if (target.includes("tingkatKKM")) {
      if (!tingkatKKM) {
        _errorList.push({
          type: "required",
          message: "Tingkat wajib dipilih.",
        });
      }
    }

    if (target.includes("indikatorPencapaian")) {
      if (!indikatorPencapaian || (indikatorPencapaian && indikatorPencapaian.length === 0)) {
        _errorList.push({
          type: "required",
          message: "Indikator Pencapaian wajib dipilih",
        });
      }
    }

    if (target.includes("namaKegiatan")) {
      if (!namaKegiatan) {
        _errorList.push({
          type: "required",
          message: "Nama Kegiatan wajib diisi.",
        });
      }
    }

    if (target.includes("tanggalKegiatan")) {
      if (!tanggalKegiatan) {
        _errorList.push({
          type: "required",
          message: "Tanggal Kegiatan wajib dipilih.",
        });
      }
    }

    if (target.includes("penyelenggara")) {
      if (!penyelenggara) {
        _errorList.push({
          type: "required",
          message: "Penyelenggara wajib dipilih.",
        });
      }
    }

    if (target.includes("jabatanInstansi")) {
      if (!jabatanInstansi) {
        _errorList.push({
          type: "required",
          message: "Jabatan Beserta Instansi wajib diisi.",
        });
      }
    }

    if (target.includes("buktiKeikutsertaan")) {
      if (!buktiKeikutsertaan) {
        _errorList.push({
          type: "required",
          message: "Bukti Keikutsertaan wajib diupload.",
        });
      }
    }

    if (target.includes("unsurKegiatan")) {
      if (!unsurKegiatan) {
        _errorList.push({
          type: "required",
          message: "Unsur Kegiatan wajib dipilih.",
        });
      }
    }

    if (target.includes("tingkat")) {
      if (!tingkat) {
        _errorList.push({
          type: "required",
          message: "Tingkat wajib dipilih.",
        });
      }
    }

    if (target.includes("posisi")) {
      if (!posisi) {
        _errorList.push({
          type: "required",
          message: "Posisi wajib dipilih.",
        });
      }
    }

    return _errorList;
  };

  useEffect(() => {
    if (isFormSubmitted) {
      setIsErrorSnackbarActive(errorList ? true : false);
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsButtonActionLoading(true);

          if (klaim.value === "KKM") {
            const buktiKeikutsertaanFormData = new FormData();

            const dataKKM = {
              tingkat: tingkatKKM.value,
              indikator_pencapaian: indikatorPencapaian.map(e => e.value).join("*; "),
              nama_kegiatan: namaKegiatan,
              tanggal_kegiatan: dateFormat(
                new Date(tanggalKegiatan),
                "yyyy-MM-dd"
              ),
              penyelenggara,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
              id_pengguna: userContext.userActive.id_pengguna,
            };

            if (buktiKeikutsertaan) {
              buktiKeikutsertaanFormData.append(
                "single_file",
                buktiKeikutsertaan
              );
            }

            const [
              _uploadBuktiKeikutsertaan,
              _insertKKM,
            ] = await Promise.allSettled([
              uploadKKMBuktiKeikutsertaan(buktiKeikutsertaanFormData),
              insertKKM(dataKKM),
            ]);

            if (
              _uploadBuktiKeikutsertaan.value.success &&
              _insertKKM.value.success
            ) {
              const _updateKKMBuktiKeikutsertaan = await updateKKMBuktiKeikutsertaan(
                {
                  bukti_keikutsertaan: _uploadBuktiKeikutsertaan.value.data
                    ? _uploadBuktiKeikutsertaan.value.data
                    : null,
                  id_kkm: _insertKKM.value.id,
                }
              );

              if (_updateKKMBuktiKeikutsertaan.success) {
                swal("Sukses!", "Data berhasil terkirim.", "success").then(() => window.scrollTo(0, 0));
              } else {
                swal("Oops!", "Internal Server Error", "warning");
              }
            } else {
              if (!_uploadBuktiKeikutsertaan.value.success) {
                swal("Oops!", "Bukti Keikutsertaan gagal diupload.", "warning");
              } else if (!_insertKKM.value.success) {
                swal("Oops!", "Data gagal dikirim", "warning");
              }
            }
          } else {
            let bobot;

            switch (unsurKegiatan.value) {
              case optionsUnsurKegiatan[0].value:
                switch (tingkat.value) {
                  case optionsJabatanPimpinanTingkat[0].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 50;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 40;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 30;
                        break;
                    }
                    break;
                  case optionsJabatanPimpinanTingkat[1].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 30;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 20;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 15;
                        break;
                    }
                    break;
                  case optionsJabatanPimpinanTingkat[2].value:
                    switch (posisi.value) {
                      case optionsPosisiB[0].value:
                        bobot = 25;
                        break;
                      case optionsPosisiB[1].value:
                        bobot = 15;
                        break;
                      case optionsPosisiB[2].value:
                        bobot = 7;
                        break;
                    }
                    break;
                  case optionsJabatanPimpinanTingkat[3].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 20;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 10;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 5;
                        break;
                    }
                    break;
                  case optionsJabatanPimpinanTingkat[4].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 15;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 7;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 3;
                        break;
                    }
                    break;
                }
                break;
              case optionsUnsurKegiatan[1].value:
                switch (tingkat.value) {
                  case optionsJabatanPanitiaTingkat[0].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 20;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 15;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 5;
                        break;
                    }
                    break;
                  case optionsJabatanPanitiaTingkat[1].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 17;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 10;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 3;
                        break;
                    }
                    break;
                  case optionsJabatanPanitiaTingkat[2].value:
                    switch (posisi.value) {
                      case optionsPosisiA[0].value:
                        bobot = 10;
                        break;
                      case optionsPosisiA[1].value:
                        bobot = 3;
                        break;
                      case optionsPosisiA[2].value:
                        bobot = 1;
                        break;
                    }
                    break;
                }
                break;
              case optionsUnsurKegiatan[2].value:
                switch (tingkat.value) {
                  case optionsLatihanKepemimpinan[0].value:
                    bobot = 8;
                    break;
                  case optionsLatihanKepemimpinan[1].value:
                    bobot = 6;
                    break;
                  case optionsLatihanKepemimpinan[2].value:
                    bobot = 4;
                    break;
                }
                break;
              case optionsUnsurKegiatan[3].value:
                bobot = 3;
                break;
            }

            const _insertSKP = await insertSKP({
              unsur_kegiatan: unsurKegiatan.value,
              tingkat:
                unsurKegiatan &&
                unsurKegiatan.value ===
                  "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan"
                  ? null
                  : tingkat.value,
              posisi:
                unsurKegiatan &&
                unsurKegiatan.value ===
                  "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan"
                  ? "Pembicara"
                  : unsurKegiatan &&
                    unsurKegiatan.value ===
                      "Mengikuti Latihan Kepemimpinan/Dasar Manajemen"
                  ? null
                  : posisi.value,
              bobot,
              jabatan_instansi: jabatanInstansi,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
              id_pengguna: userContext.userActive.id_pengguna,
            });

            if (_insertSKP.success) {
              swal("Sukses!", _insertSKP.message, "success");
            } else {
              swal(
                "Oops!",
                _insertSKP.message
                  ? _insertSKP.message
                  : "Internal Server Error",
                "warning"
              );
            }
          }

          setIsButtonActionLoading(false);
          setTimeout(handleResetData, 0);
        }
      }, 0);
    }
  }, [errorList]);

  useEffect(() => {
    setIsPenyelenggaraClearActive(penyelenggara ? true : false);
  }, [penyelenggara]);

  useEffect(() => {
    setIsClearJabatanInstansiActive(jabatanInstansi ? true : false);
  }, [jabatanInstansi]);

  useEffect(() => {
    setIsNamaKegiatanClearActive(namaKegiatan ? true : false);
  }, [namaKegiatan]);

  useEffect(() => {
    if (isFormSubmitted) {
      setErrorList(
        getErrorList(
          ["klaim"].concat(
            klaim && klaim.value === "KKM"
              ? [
                  "tingkatKKM",
                  "indikatorPencapaian",
                  "namaKegiatan",
                  "tanggalKegiatan",
                  "penyelenggara",
                  "buktiKeikutsertaan",
                ]
              : ["unsurKegiatan"]
                  .concat(
                    unsurKegiatan
                      ? unsurKegiatan.value ===
                        "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan"
                        ? []
                        : unsurKegiatan.value ===
                          "Mengikuti Latihan Kepemimpinan/Dasar Manajemen"
                        ? ["tingkat"]
                        : ["tingkat", "posisi"]
                      : []
                  )
                  .concat(["jabatanInstansi"])
          )
        )
      );
    }
  }, [isFormSubmitted]);

  return (
    <div className="user-page-kaderisasi-berkelanjutan">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/4.svg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => userContext.dispatchMenuActive("SelamatDatang")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">Kaderisasi Berkelanjutan</span>
        <span className="sub-title-text">Pendataan KKM dan SKP</span>
      </div>
      <div className="body-area">
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <div className="form-group required">
            <Autocomplete
              id="kaderisasi-berkelanjutan-klaim"
              options={optionsKlaim}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={(event, selected) =>
                handleChangeSelectItem(selected, setKlaim)
              }
              value={klaim || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Klaim Salah Satu"
                  variant="outlined"
                />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          {klaim && klaim.value === "KKM" && (
            <React.Fragment>
              <div className="form-group required">
                <Autocomplete
                  id="kaderisasi-berkelanjutan-tingkat"
                  options={optionsTingkatKKM}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) => {
                    setIndikatorPencapaian([]);
                    handleChangeSelectItem(selected, setTingkatKKM);
                  }}
                  value={tingkatKKM || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pilih Tingkat"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {tingkatKKM && (
                <React.Fragment>
                  <div className="form-group select-all-indicators">
                    <div className="helper-wrapper">
                      <span className="helper-text" onClick={() => {
                        setIndikatorPencapaian(
                          tingkatKKM.value === "Tingkat 1 Preklinik"
                            ? optionsIndikatorKKM1
                            : tingkatKKM.value === "Tingkat 2 Preklinik"
                            ? optionsIndikatorKKM2
                            : tingkatKKM.value === "Tingkat 3 Preklinik"
                            ? optionsIndikatorKKM3
                            : null
                        );
                      }}>Pilih semua indikator</span>
                    </div>
                  </div>
                  <div className="form-group required">
                    <Autocomplete
                      id="kaderisasi-berkelanjutan-indikator"
                      multiple
                      options={
                        tingkatKKM
                          ? tingkatKKM.value === "Tingkat 1 Preklinik"
                            ? optionsIndikatorKKM1
                            : tingkatKKM.value === "Tingkat 2 Preklinik"
                            ? optionsIndikatorKKM2
                            : tingkatKKM.value === "Tingkat 3 Preklinik"
                            ? optionsIndikatorKKM3
                            : null
                          : null
                      }
                      noOptionsText="Tidak Ada Opsi"
                      clearOnBlur
                      clearText="Bersihkan"
                      openText="Pilih"
                      getOptionLabel={getOptionLabel}
                      onChange={(event, selected) =>
                        handleChangeSelectItem(selected, setIndikatorPencapaian)
                      }
                      value={indikatorPencapaian || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Indikator Pencapaian"
                          variant="outlined"
                        />
                      )}
                      PopperComponent={PopperCustom}
                    />
                  </div>
                </React.Fragment>
              )}
              <div className="form-group required">
                <TextField
                  id="kaderisasi-berkelanjutan-nama-kegiatan"
                  label="Nama Kegiatan"
                  variant="outlined"
                  value={namaKegiatan || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setNamaKegiatan)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      namaKegiatan,
                      setIsNamaKegiatanClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsNamaKegiatanClearActive)
                  }
                  InputProps={{
                    endAdornment: isNamaKegiatanClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setNamaKegiatan,
                              setIsNamaKegiatanClearActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <DatePicker
                  format="dd-MM-yyyy"
                  label="Tanggal Kegiatan"
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "date"]}
                  value={tanggalKegiatan || null}
                  inputVariant="outlined"
                  onChange={(selected) =>
                    handleChangeSelectItem(selected, setTanggalKegiatan)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      tanggalKegiatan,
                      setIsTanggalKegiatanClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsTanggalKegiatanClearActive)
                  }
                  open={isTanggalKegiatanDialogActive}
                  onOpen={() =>
                    handleToggleDialogActive(
                      isTanggalKegiatanDialogActive,
                      setIsTanggalKegiatanDialogActive
                    )
                  }
                  onClose={() =>
                    handleToggleDialogActive(
                      isTanggalKegiatanDialogActive,
                      setIsTanggalKegiatanDialogActive
                    )
                  }
                  InputProps={{
                    endAdornment: isTanggalKegiatanClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={() =>
                            handleMouseDownClearTextFieldDate(
                              setTanggalKegiatan,
                              setIsTanggalKegiatanClearActive,
                              setIsTanggalKegiatanDialogActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="kaderisasi-berkelanjutan-penyelenggara"
                  label="Penyelenggara"
                  variant="outlined"
                  value={penyelenggara || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setPenyelenggara)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      penyelenggara,
                      setIsPenyelenggaraClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsPenyelenggaraClearActive)
                  }
                  InputProps={{
                    endAdornment: isPenyelenggaraClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setPenyelenggara,
                              setIsPenyelenggaraClearActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="kaderisasi-berkelanjutan-bukti-keikutsertaan"
                  label="Bukti Keikutsertaan"
                  variant="outlined"
                  value={buktiKeikutsertaan ? buktiKeikutsertaan.name : ""}
                  ref={buktiKeikutsertaanRef}
                  readOnly
                />
                {isClearBuktiKeikutsertaanActive && (
                  <div className="clear-icon">
                    <ClearIcon
                      onClick={() =>
                        handleClickClearTextField(
                          setBuktiKeikutsertaan,
                          setIsClearBuktiKeikutsertaanActive
                        )
                      }
                    />
                  </div>
                )}
                <div
                  className="upload-button"
                  onClick={() =>
                    handleOpenDialog(setIsBuktiKeikutsertaanDialogActive)
                  }
                >
                  <span className="upload-text">Upload</span>
                </div>
                <DropzoneDialog
                  acceptedFiles={["image/*", ".pdf"]}
                  cancelButtonText={"Cancel"}
                  submitButtonText={"Upload"}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={isBuktiKeikutsertaanDialogActive}
                  onClose={() =>
                    handleCloseDialog(setIsBuktiKeikutsertaanDialogActive)
                  }
                  onSave={(files) =>
                    handleDialogSaveFile(
                      files,
                      buktiKeikutsertaanRef,
                      setBuktiKeikutsertaan,
                      setIsBuktiKeikutsertaanDialogActive
                    )
                  }
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
            </React.Fragment>
          )}
          {klaim && klaim.value === "SKP" && (
            <React.Fragment>
              <div className="form-group required">
                <Autocomplete
                  id="kaderisasi-berkelanjutan-unsur-kegiatan"
                  options={optionsUnsurKegiatan}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setUnsurKegiatan)
                  }
                  value={unsurKegiatan || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unsur Kegiatan"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {unsurKegiatan &&
                unsurKegiatan.value !==
                  "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan" && (
                  <div className="form-group required">
                    <Autocomplete
                      id="kaderisasi-berkelanjutan-tingkat"
                      options={
                        unsurKegiatan &&
                        unsurKegiatan.value ===
                          "Menduduki jabatan pimpinan di badan/lembaga/organisasi kemahasiswaan"
                          ? optionsJabatanPimpinanTingkat
                          : unsurKegiatan &&
                            unsurKegiatan.value ===
                              "Menduduki jabatan di kepanitiaan/tim khusus"
                          ? optionsJabatanPanitiaTingkat
                          : unsurKegiatan &&
                            unsurKegiatan.value ===
                              "Mengikuti Latihan Kepemimpinan/Dasar Manajemen"
                          ? optionsLatihanKepemimpinan
                          : null
                      }
                      noOptionsText="Tidak Ada Opsi"
                      clearOnBlur
                      clearText="Bersihkan"
                      openText="Pilih"
                      getOptionLabel={getOptionLabel}
                      onChange={(event, selected) =>
                        handleChangeSelectItem(selected, setTingkat)
                      }
                      value={tingkat || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tingkat"
                          variant="outlined"
                        />
                      )}
                      PopperComponent={PopperCustom}
                    />
                  </div>
                )}
              {tingkat &&
                unsurKegiatan &&
                unsurKegiatan.value !==
                  "Mengikuti Latihan Kepemimpinan/Dasar Manajemen" &&
                unsurKegiatan.value !==
                  "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan" && (
                  <div className="form-group required">
                    <Autocomplete
                      id="kaderisasi-berkelanjutan-posisi"
                      options={
                        tingkat && tingkat.value === "Regional/BEM/BPM"
                          ? optionsPosisiB
                          : unsurKegiatan &&
                            unsurKegiatan.value ===
                              "Mengikuti Latihan Kepemimpinan/Dasar Manajemen"
                          ? optionsLatihanKepemimpinan
                          : unsurKegiatan &&
                            unsurKegiatan.value !==
                              "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan"
                          ? optionsPosisiA
                          : null
                      }
                      noOptionsText="Tidak Ada Opsi"
                      clearOnBlur
                      clearText="Bersihkan"
                      openText="Pilih"
                      getOptionLabel={getOptionLabel}
                      onChange={(event, selected) =>
                        handleChangeSelectItem(selected, setPosisi)
                      }
                      value={posisi || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Posisi"
                          variant="outlined"
                        />
                      )}
                      PopperComponent={PopperCustom}
                    />
                  </div>
                )}
              {unsurKegiatan &&
                unsurKegiatan.value ===
                  "Menjadi Narasumber/Pembicara di suatu kegiatan kemahasiswaan" && (
                  <div className="form-group required disabled">
                    <TextField
                      id="kaderisasi-berkelanjutan-posisi"
                      label="Posisi"
                      variant="outlined"
                      value={"Pembicara"}
                      readOnly
                    />
                  </div>
                )}
              <div className="form-group required">
                <TextField
                  id="kaderisasi-berkelanjutan-jabatan-instansi"
                  label="Jabatan Beserta Instansi"
                  helperText="*Contoh: Sekretaris Jenderal ISMKI Nasional"
                  variant="outlined"
                  value={jabatanInstansi || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setJabatanInstansi)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      jabatanInstansi,
                      setIsClearJabatanInstansiActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsClearJabatanInstansiActive)
                  }
                  InputProps={{
                    endAdornment: isClearJabatanInstansiActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setJabatanInstansi,
                              setIsClearJabatanInstansiActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </React.Fragment>
          )}
          <div className="form-action-area">
            <div className="helper-wrapper"></div>
            <div className="button-wrapper">
              <button
                type="submit"
                className="submit-button"
                onClick={(event) =>
                  handleFormSubmitAction(event, setIsFormSubmitted)
                }
              >
                {isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  <span className="button-text">Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseErrorSnackbar(setIsErrorSnackbarActive)}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </div>
  );
}

export default KaderisasiBerkelanjutan;
