import axios from "axios";
import config from "../../Config";

const getPenggunaById = async (id) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getPenggunaActive = async (npm) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengguna/active/${npm}`,
  });

  const response = request.data;
  return response;
};

const getPengguna = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengguna`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPengguna = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengguna/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const updatePengguna = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengguna`,
    data,
  });

  const response = request.data;
  return response;
};

const updateFotoPengguna = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengguna/foto_profil`,
    data,
  });

  const response = request.data;
  return response;
};

const updateIdLinePengguna = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengguna/id_line`,
    data,
  });

  const response = request.data;
  return response;
};

const updateNoHPPengguna = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengguna/no_hp`,
    data,
  });

  const response = request.data;
  return response;
};

const deletePengguna = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengguna`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

export {
  getPenggunaById,
  getPenggunaActive,
  getPengguna,
  getTotalPengguna,
  updatePengguna,
  updateFotoPengguna,
  updateIdLinePengguna,
  updateNoHPPengguna,
  deletePengguna
};
