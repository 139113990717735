import React, { useContext, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import { ProfileContext } from "../../../Profil";

function Bisnis() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  return (
    <div className="bisnis-box box-wrapper">
      <div className="box-header-area">
        <span className="box-title-text">Bisnis</span>
        <div className="box-action-area">
          {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
        </div>
      </div>
      <div className="box-body-area">
        <div className="box-body-top">
          <div className="inner-box-body-top-left">
            <div className="single-info total">
              <span className="info-label">Total :</span>
              <span className="info-value">{profileContext.bisnis.length}</span>
            </div>
          </div>
          <div className="inner-box-body-top-right">
            {/* <div className="single-action pagination">
              <div className="prev-button-area">
                <div className="single-button prev-button">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
              </div>
              <div className="page-info-area">
                <span className="page-info">Page 1 of 2</span>
              </div>
              <div className="next-button-area">
                <div className="single-button next-button active">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          {profileContext.bisnis.map((item) => (
            <div
              className="single-box col-lg-4 col-md-6 col-12"
              key={item.id_bisnis}
            >
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">{item.nama_bisnis}</span>
                </div>
                <div className="type-area">
                  <span
                    className={`type-value${
                      item.jenis_bisnis ? "" : " undefined"
                    }`}
                  >
                    {item.jenis_bisnis ? item.jenis_bisnis : "Undefined"}
                  </span>
                </div>
                <div className="contact-person-area">
                  <span className="contact-person-value">
                    {item.contact_person_bisnis}
                  </span>
                </div>
                <div className="logo-area">
                  <div className="float-box-logo">
                    <img
                      src={`${require("../../../../../Assets/Miscellaneous/no_image_bisnis.png")}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Bisnis;
