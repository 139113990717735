import axios from "axios";
import config from "../../Config";

const getOrganisasiByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/organisasi/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getOrganisasi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/organisasi`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalOrganisasi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/organisasi/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getDetailOrganisasi = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/organisasi/${id}`,
  });

  const response = request.data;
  return response;
};

const updateOrganisasi = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/organisasi`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteOrganisasi = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/organisasi`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const insertOrganisasi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/organisasi`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailOrganisasi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/organisasi/detail`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteDetailOrganisasiByOrganisasi = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/organisasi/detail/by_organisasi/${id}`,
  });

  const response = request.data;
  return response;
};

export {
  getOrganisasiByPengguna,
  getOrganisasi,
  getTotalOrganisasi,
  getDetailOrganisasi,
  deleteDetailOrganisasiByOrganisasi,
  updateOrganisasi,
  deleteOrganisasi,
  insertOrganisasi,
  insertDetailOrganisasi,
};
