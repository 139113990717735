import axios from "axios";
import config from "../../Config";

const loadUserByToken = async (token) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/verify-token/${token}`
  });

  const response = request.data;
  return response;
};

export { loadUserByToken };