import Axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./counter.styles.css";
import { __RouterContext } from "react-router";
function Settings() {
  const [submitting, setSubmitting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const routerContext = useContext(__RouterContext);
  // console.log("routerContext", routerContext);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    console.log("oldPassword, newPassword", oldPassword, newPassword);
    setSubmitting(true);
    if (newPassword.length < 6) {
      alert("Password baru anda harus lebih dari 6 karakter");
      setOldPassword("")
      setSubmitting(false);
      return;
    }

    Axios.post("/api/pemira/loginPasswordPemira", {
      password: oldPassword,
    }).then((res) => {
      console.log(res.data.success);
      if (res.data.success) {
        //Update Password with the new one
        Axios.post("/api/pemira/updatePasswordPemira", {
          password: newPassword,
        }).then((res) => {
          console.log("UPDATING", res.data);
          if (res.data.success) {
            alert("Penggantian Password Berhasil!");
            setOldPassword("")
            setNewPassword("")
            setSubmitting(false);
          } else {
            alert("Pengubahan password gagal, silakan gunakan password lama");
            setSubmitting(false);
          }
        });
      } else {
        alert("Password lama anda salah");
        setOldPassword("")
        setNewPassword("")
        setSubmitting(false);
      }
    });
  };

  return (
    <div className="counter-pemira__page">
      <div className="counter-pemira__container">
        <div className="header">
          <h1>Settings</h1>
          <p>Ubah Password</p>
        </div>
        <div className="counter counter-narrow">
          <div className="space-between">
            <label htmlFor="">Password Lama</label>
            <input
              autoFocus
              type="password"
              placeholder="Password Lama"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="space-between">
            <div>Password Baru</div>
            <input
              autoFocus
              type="password"
              placeholder="Password Baru"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="change-password-button-container">
            <button
              className="change-password-button"
              onClick={handleChangePassword}
              disabled={submitting}
            >
              Ubah Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
