import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filterOptions = createFilterOptions({
  limit: 150,
});

const getOptionLabel = (option) => {
  return option.label;
};

const handleChangeSelectObject = (event, valueSetter) => {
  valueSetter({
    value: event.target.value,
    label: event.target.value,
  });
};

const handleChangeTextField = (event, valueSetter) => {
  valueSetter(event.target.value);
};

const handleChangeCurrencyTextField = (value, valueSetter) => {
  valueSetter(value);
};

const handleChangeSelectItem = (selected, valueSetter) => {
  valueSetter(selected);
};

const handleMouseEnterTextField = (value, clearActiveSetter) => {
  clearActiveSetter(value ? true : false);
};

const handleMouseLeaveTextField = (clearActiveSetter) => {
  clearActiveSetter(false);
};

const handleClickClearTextField = (valueSetter, clearActiveSetter) => {
  valueSetter("");
  clearActiveSetter(false);
};

const handleMouseDownClearTextFieldDate = (
  valueSetter,
  clearActiveSetter,
  dialogActiveSetter
) => {
  valueSetter(null);
  clearActiveSetter(false);
  dialogActiveSetter(false);
};

const handleToggleDialogActive = (value, dialogActiveSetter) => {
  dialogActiveSetter(!value);
};

const handleOpenDialog = (dialogActiveSetter) => {
  dialogActiveSetter(true);
};

const handleCloseDialog = (dialogActiveSetter) => {
  dialogActiveSetter(false);
};

const handleDialogSaveFile = (
  files,
  valueRef,
  valueSetter,
  dialogActiveSetter
) => {
  valueSetter(files[0]);
  if (valueRef) valueRef.current.focus();
  dialogActiveSetter(false);
};

const handleCloseErrorSnackbar = (errorSnackbarActiveSetter) => {
  errorSnackbarActiveSetter(false);
};

const handleFormSubmitAction = (event, formSubmitSetter) => {
  event.preventDefault();
  formSubmitSetter(true);
};

export {
  filterOptions,
  getOptionLabel,
  handleChangeCurrencyTextField,
  handleChangeSelectObject,
  handleChangeTextField,
  handleChangeSelectItem,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleMouseDownClearTextFieldDate,
  handleToggleDialogActive,
  handleOpenDialog,
  handleCloseDialog,
  handleDialogSaveFile,
  handleCloseErrorSnackbar,
  handleFormSubmitAction,
};
