import React, { useContext } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import { ProfileContext } from "../../../Profil";
import { dateFormat } from "../../../../../Services/Format/date";

function OrganisasiKepanitiaan() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  return (
    <div className="organisasi-kepanitiaan-box box-wrapper">
      <div className="box-header-area">
        <span className="box-title-text">Organisasi Dan Kepanitiaan</span>
        <div className="box-action-area">
          {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
        </div>
      </div>
      <div className="box-body-area">
        <div className="box-body-top">
          <div className="inner-box-body-top-left">
            <div className="single-info total">
              <span className="info-label">Total :</span>
              <span className="info-value">
                {profileContext.organisasi.length}
              </span>
            </div>
          </div>
          <div className="inner-box-body-top-right">
            {/* <div className="single-action pagination">
              <div className="prev-button-area">
                <div className="single-button prev-button">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
              </div>
              <div className="page-info-area">
                <span className="page-info">Page 1 of 2</span>
              </div>
              <div className="next-button-area">
                <div className="single-button next-button active">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="row">
          {profileContext.organisasi.map((item, index) => (
            <div className="single-box col-lg-4 col-md-6 col-12" key={index}>
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">{item.nama_organisasi}</span>
                </div>
                <div className="type-area">
                  <span className={`type-value ${item.jenis.toLowerCase()}`}>
                    {item.jenis}
                  </span>
                </div>
                <div className="period-area">
                  <span className="first-period-value">
                    {dateFormat(new Date(item.periode_awal), "dd-MM-yyyy")}
                  </span>
                  <span className="separator-horizontal">-</span>
                  <span className="last-period-value">
                    {dateFormat(new Date(item.periode_akhir), "dd-MM-yyyy")}
                  </span>
                </div>
                <div className="position-area">
                  <div className="float-box-position">
                    <span className="position-value">{item.jabatan}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}
        {profileContext.organisasi.length > 0 && (
          <div className="table-wrapper">
            <table id="organisasi-table">
              <thead>
                <tr>
                  <th>Jenis</th>
                  <th>Nama Organisasi/Kepanitiaan</th>
                  <th>Periode</th>
                  <th>Jabatan</th>
                </tr>
              </thead>
              <tbody>
                {profileContext.organisasi.map((item, index) => (
                  <tr key={index}>
                    <td>{item.jenis}</td>
                    <td>{item.nama_organisasi}</td>
                    <td>
                      {dateFormat(new Date(item.periode_awal), "yyyy") ===
                      dateFormat(new Date(item.periode_akhir), "yyyy")
                        ? dateFormat(new Date(item.periode_awal), "yyyy")
                        : dateFormat(new Date(item.periode_awal), "yyyy") +
                          " - " +
                          dateFormat(new Date(item.periode_akhir), "yyyy")}
                    </td>
                    <td>{item.jabatan}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganisasiKepanitiaan;
