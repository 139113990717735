import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import Navbar from "../../../Components/Navbar";
import MainBoxProfile from "./Components/MainBoxProfile";
import MyLigaKreasi from "./Components/LigaKreasi";
import Bisnis from "./Components/Bisnis";
import Pengabdian from "./Components/Pengabdian";
import OrganisasiKepanitiaan from "./Components/OrganisasiKepanitiaan";
import Publikasi from "./Components/Publikasi";
import Beasiswa from "./Components/Beasiswa";
import KaderisasiBerkelanjutan from "./Components/KaderisasiBerkelanjutan";
import KeteranganKinerja from "./Components/KeteranganKinerja";
import { UserContext } from "../../User";
import { getPenggunaById } from "../../../Services/API/pengguna";
import {
  getLigaKreasiByPengguna,
  getLigaKreasiByKelompok,
  getRankingLigaKreasi,
} from "../../../Services/API/liga_kreasi";
import { getBisnisByPengguna } from "../../../Services/API/bisnis";
import { getKKMByPengguna } from "../../../Services/API/kaderisasi_berkelanjutan";
import { getKeteranganKinerjaByPengguna } from "../../../Services/API/keterangan_kinerja";
import { getSKPByPengguna } from "../../../Services/API/kaderisasi_berkelanjutan";
import { getOrganisasiByPengguna } from "../../../Services/API/organisasi";
import { getPublikasiByPengguna } from "../../../Services/API/publikasi";
import {
  getPendaftarBeasiswaByPengguna,
  getPenerimaBeasiswaByPengguna,
} from "../../../Services/API/beasiswa";
import {
  getPenyuluhanByPengguna,
  getKegiatanSosialByPengguna,
  getScreeningByPengguna,
  getPengobatanMassalByPengguna,
  getSirkumsisiByPengguna,
  getKunjunganPasienByPengguna,
  getTimMedisFKUIByPengguna,
} from "../../../Services/API/pengabdian";
import { useQuery } from "../../../Services/Params";
import { getFotoProfilPengguna } from "../../../Services/API/file";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";

const ProfileContext = React.createContext();

function Profil() {
  const location = useLocation();
  const query = useQuery();

  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const [pengguna, setPengguna] = useState(null);

  const [ligaKreasi, setLigaKreasi] = useState(null);

  const [pengabdian, setPengabdian] = useState(null);

  const [organisasi, setOrganisasi] = useState(null);

  const [bisnis, setBisnis] = useState(null);

  const [publikasi, setPublikasi] = useState(null);

  const [beasiswa, setBeasiswa] = useState(null);

  const [kkm, setKKM] = useState(null);

  const [skp, setSKP] = useState(null);

  const [keteranganKinerja, setKeteranganKinerja] = useState(null);

  useEffect(() => {
    (async () => {
      const isBrowseUserActive = query.get("id");

      const [
        penggunaById,
        ligaKreasiByPengguna,
        ligaKreasiByKelompok,
        penyuluhanByPengguna,
        kegiatanSosialByPengguna,
        screeningByPengguna,
        pengobatanMassalByPengguna,
        sirkumsisiByPengguna,
        kunjunganPasienByPengguna,
        timMedisFKUIByPengguna,
        organisasiByPengguna,
        bisnisByPengguna,
        publikasiByPengguna,
        pendaftarBeasiswaByPengguna,
        penerimaBeasiswaByPengguna,
        kkmByPengguna,
        skpByPengguna,
        keteranganKinerjaByPengguna,
      ] = await Promise.all([
        getPenggunaById(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getLigaKreasiByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getLigaKreasiByKelompok(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getPenyuluhanByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getKegiatanSosialByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getScreeningByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getPengobatanMassalByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getSirkumsisiByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getKunjunganPasienByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getTimMedisFKUIByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getOrganisasiByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getBisnisByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getPublikasiByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getPendaftarBeasiswaByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getPenerimaBeasiswaByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getKKMByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getSKPByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
        getKeteranganKinerjaByPengguna(
          isBrowseUserActive
            ? query.get("id")
            : userContext.userActive.id_pengguna
        ),
      ]);

      if (
        penggunaById.success &&
        ligaKreasiByPengguna.success &&
        ligaKreasiByKelompok.success &&
        penyuluhanByPengguna.success &&
        kegiatanSosialByPengguna.success &&
        screeningByPengguna.success &&
        pengobatanMassalByPengguna.success &&
        sirkumsisiByPengguna.success &&
        kunjunganPasienByPengguna.success &&
        timMedisFKUIByPengguna.success &&
        organisasiByPengguna.success &&
        bisnisByPengguna.success &&
        publikasiByPengguna.success &&
        pendaftarBeasiswaByPengguna.success &&
        penerimaBeasiswaByPengguna.success &&
        kkmByPengguna.success &&
        skpByPengguna.success &&
        keteranganKinerjaByPengguna.success
      ) {
        let rankPengguna;
        let jumlahSeangkatan;

        const [
          previewFotoPengguna,
          rankingLigaKreasiByAngkatan,
        ] = await Promise.all([
          penggunaById.data.foto_pengguna && location.pathname !== "/profil"
            ? getFotoProfilPengguna(penggunaById.data.foto_pengguna)
            : null,
          location.pathname !== "/profil"
            ? getRankingLigaKreasi({
                searchPengguna: {
                  groupOp: "",
                  rules: [
                    {
                      field: "angkatan",
                      op: "=",
                      data: penggunaById.data.angkatan,
                    },
                  ],
                },
                order: {
                  by: "rank",
                  mode: "ASC",
                },
              })
            : null,
        ]);

        if (
          rankingLigaKreasiByAngkatan &&
          rankingLigaKreasiByAngkatan.success
        ) {
          const _pengguna = await rankingLigaKreasiByAngkatan.data.find(
            (item) => item.id_pengguna === penggunaById.data.id_pengguna
          );

          if (_pengguna) {
            rankPengguna = _pengguna.rank;
          }

          jumlahSeangkatan = rankingLigaKreasiByAngkatan.data.length;
        }

        await Promise.all([
          setPengguna({
            ...penggunaById.data,
            preview_foto_pengguna: previewFotoPengguna
              ? previewFotoPengguna.success
                ? `url(${previewFotoPengguna.data})`
                : null
              : null,
            rank: rankPengguna ? rankPengguna : 0,
            jumlah_seangkatan: jumlahSeangkatan ? jumlahSeangkatan : 0,
          }),
          setLigaKreasi(
            ligaKreasiByPengguna.data.map((item) => {
              return {
                ...item,
                is_creator: true
              }
            }).concat(ligaKreasiByKelompok.data.map((item) => {
              return {
                ...item,
                is_creator: false
              }
            }))
          ),
          setPengabdian(
            penyuluhanByPengguna.data
              .concat(kegiatanSosialByPengguna.data)
              .concat(screeningByPengguna.data)
              .concat(pengobatanMassalByPengguna.data)
              .concat(sirkumsisiByPengguna.data)
              .concat(kunjunganPasienByPengguna.data)
              .concat(timMedisFKUIByPengguna.data)
          ),
          setOrganisasi(organisasiByPengguna.data),
          setBisnis(bisnisByPengguna.data),
          setPublikasi(publikasiByPengguna.data),
          setBeasiswa(
            pendaftarBeasiswaByPengguna.data.concat(
              penerimaBeasiswaByPengguna.data
            )
          ),
          setKKM(kkmByPengguna.data),
          setSKP(skpByPengguna.data),
          setKeteranganKinerja(keteranganKinerjaByPengguna.data),
        ]);

        setIsLoading(false);
      }
    })();
  }, [location.pathname]);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <React.Fragment>
          <Navbar />
          <div className="body-content-wrapper-profile">
            <div className="profile-container">
              <ProfileContext.Provider
                value={{
                  pengguna,
                  ligaKreasi,
                  setLigaKreasi,
                  pengabdian,
                  organisasi,
                  bisnis,
                  publikasi,
                  beasiswa,
                  kkm,
                  skp,
                  keteranganKinerja,
                }}
              >
                <MainBoxProfile />
                <MyLigaKreasi />
                <Bisnis />
                <Pengabdian />
                <OrganisasiKepanitiaan />
                <Publikasi />
                {location.pathname === "/profil" && (
                  <React.Fragment>
                    <Beasiswa />
                    <KaderisasiBerkelanjutan />
                    <KeteranganKinerja />
                  </React.Fragment>
                )}
              </ProfileContext.Provider>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export { ProfileContext };
export default Profil;
