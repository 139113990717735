import React, { useState } from "react";
import "../../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faFileImage,
  faFilePdf,
  faCheck,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import swal from "sweetalert";
import {
  getBase64Mime,
  base64toBlob,
} from "../../../../../../../Services/Format/base64";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";

const Table = React.forwardRef((props, ref) => {
  const unsortedTypes = ["file", "text-group", "multiple-textbox"];

  const [tableScrollTop, setTableScrollTop] = useState(0);

  const [tableScrollLeft, setTableScrollLeft] = useState(0);

  const handleTableOnScroll = (e) => {
    setTableScrollTop(e.target.scrollTop);
    setTableScrollLeft(e.target.scrollLeft);
  };

  const handleSortData = async (target) => {
    if (
      props.sortItem.by === target.by &&
      props.sortItem.mode === target.mode
    ) {
      return;
    }

    props.setSortItem(target);
    props.setIsDataTableLoading(true);

    const [result, totalAllData] = await Promise.all([
      props.getData({
        fields: props.dataFields.map((item) => item.value),
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
        order: target,
        limit: props.numberPerPage,
        offset: 0,
      }),
      props.getTotalData({
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
      }),
    ]);

    if (result.success && totalAllData.success) {
      props.setData(result.data);
      props.setTotalData(totalAllData.data.total);
      props.setTotalPage(
        Math.ceil(totalAllData.data.total / props.numberPerPage)
      );
      props.setCurrentPage(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageFrom(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageTo(
        totalAllData.data.total > 0 ? result.data.length : 0
      );
    }

    props.setIsDataTableLoading(false);
  };

  const handleClickViewFile = async (event, fileName, contentGetFile) => {
    event.stopPropagation();

    props.setIsFullScreenLoading(true);

    const result = await contentGetFile(fileName);

    if (result.success) {
      if (result.data) {
        const mime = await getBase64Mime(result.data);
        const blob = await base64toBlob(result.data, mime);

        props.setObjectMime(mime);
        props.setObjectURL(URL.createObjectURL(blob));
        props.setObjectFilename(fileName);
        props.setIsViewFileDialogActive(true);
      } else {
        swal("Oops!", "File tidak tersedia.", "warning");
      }
    } else {
      swal("Oops!", "Internal Server Error", "warning");
    }

    props.setIsFullScreenLoading(false);
  };

  useDidMountEffect(() => {
    if (!props.isViewFileDialogActive) {
      props.setObjectMime(null);
      props.setObjectURL(null);
      props.setObjectFilename(null);
      setTableScrollTop(0);
      setTableScrollLeft(0);
    }
  }, [props.isViewFileDialogActive]);

  useDidMountEffect(() => {
    setTableScrollTop(0);
    setTableScrollLeft(0);
  }, [props.data]);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom
        isLoading={props.isDataTableLoading}
        styleCustom={{
          position: "absolute",
          top: "56px",
          left: "initial",
          height: "calc(100% - 56px)",
        }}
      />
      {!props.isDataTableLoading && (
        <div className="table-wrapper" onScroll={handleTableOnScroll} ref={ref}>
          <div className="inner-table-wrapper">
            <div
              className="table-head-area"
              style={{
                backfaceVisibility: "hidden",
                perspective: 1000,
                transform: `translate3d(0px, ${tableScrollTop}px, 0px)`,
              }}
            >
              <div
                className="left-table-head"
                style={{
                  backfaceVisibility: "hidden",
                  perspective: 1000,
                  transform: `translate3d(${tableScrollLeft}px, 0px, 0px)`,
                }}
              >
                {props.dataFields.find(o => o.primary === true).checkbox && (
                  <div className="inner-body-content-wrapper">
                    <div className="single-left-table-head inner-content-left fixed-checkbox-area">
                      <div className="checkbox" onClick={(event) => {
                        event.stopPropagation();

                        if(props.dataSelected.length > 0) {
                          props.setDataSelected([]);
                        } else {
                          const id = props.dataFields.find(z => z.primary === true).value;

                          const selected = props.data.map(x => x[id]);

                          props.setDataSelected(selected);
                        }
                      }}>
                        {props.dataSelected.length > 0 ? props.dataSelected.length === props.data.length ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
                {props.dataFields
                  .filter(
                    (_item) => _item.position === "left" && _item.status === 1
                  )
                  .map((item, index) => (
                    <div className="inner-body-content-wrapper" key={index}>
                      <div
                        className={`inner-content-left single-left-table-head${
                          item.size === "default" ? "" : " " + item.size
                        }`}
                      >
                        <div
                          className={`title-area${
                            unsortedTypes.includes(item.type)
                              ? " pointer-disabled"
                              : ""
                          }`}
                          onClick={() => {
                            if (unsortedTypes.includes(item.type)) {
                              return;
                            }

                            handleSortData({
                              by: item.value,
                              mode:
                                props.sortItem.by === item.value
                                  ? props.sortItem.mode === "ASC"
                                    ? "DESC"
                                    : "ASC"
                                  : "ASC",
                            });
                          }}
                        >
                          <span className="title-text">{item.label}</span>
                          {!unsortedTypes.includes(item.type) && (
                            <div
                              className={`sort-area${
                                props.sortItem.by === item.value
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <div
                                className={`asc-sort${
                                  props.sortItem.by === item.value
                                    ? props.sortItem.mode === "ASC"
                                      ? " active"
                                      : ""
                                    : " active"
                                }`}
                              >
                                <FontAwesomeIcon icon={faCaretUp} />
                              </div>
                              <div
                                className={`desc-sort${
                                  props.sortItem.by === item.value &&
                                  props.sortItem.mode === "DESC"
                                    ? " active"
                                    : ""
                                }`}
                              >
                                <FontAwesomeIcon icon={faCaretDown} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="right-table-head">
                {props.dataFields
                  .filter(
                    (_item) => _item.position === "right" && _item.status === 1
                  )
                  .map((item, index) => (
                    <div className="inner-body-content-wrapper" key={index}>
                      <div
                        className={`single-right-table-head inner-content-right${
                          item.size === "default" ? "" : " " + item.size
                        }`}
                      >
                        <div
                          className={`title-area${
                            unsortedTypes.includes(item.type)
                              ? " pointer-disabled"
                              : ""
                          }`}
                          onClick={() => {
                            if (unsortedTypes.includes(item.type)) {
                              return;
                            }

                            handleSortData({
                              by: item.value,
                              mode:
                                props.sortItem.by === item.value
                                  ? props.sortItem.mode === "ASC"
                                    ? "DESC"
                                    : "ASC"
                                  : "ASC",
                            });
                          }}
                        >
                          <span className="title-text">{item.label}</span>
                          {!unsortedTypes.includes(item.type) && (
                            <div
                              className={`sort-area${
                                props.sortItem.by === item.value
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <div
                                className={`asc-sort${
                                  props.sortItem.by === item.value
                                    ? props.sortItem.mode === "ASC"
                                      ? " active"
                                      : ""
                                    : " active"
                                }`}
                              >
                                <FontAwesomeIcon icon={faCaretUp} />
                              </div>
                              <div
                                className={`desc-sort${
                                  props.sortItem.by === item.value &&
                                  props.sortItem.mode === "DESC"
                                    ? " active"
                                    : ""
                                }`}
                              >
                                <FontAwesomeIcon icon={faCaretDown} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {props.data.map((item, index) => (
              <div
                className={`single-body-content${
                  !props.handleClickSingleBodyContent ? " pointer-disabled" : ""
                }`}
                onClick={() => {
                  if (!props.handleClickSingleBodyContent) {
                    return;
                  }

                  props.handleClickSingleBodyContent(item);
                }}
                key={index}
              >
                <div
                  className="left-single-body-content"
                  style={{
                    backfaceVisibility: "hidden",
                    perspective: 1000,
                    transform: `translate3d(${tableScrollLeft}px, 0px, 0px)`,
                  }}
                >
                  {props.dataFields.find(p => p.primary === true).checkbox && (
                    <div className="inner-body-content-wrapper">
                      <div className="inner-body-content">
                        <div className="checkbox" onClick={(event) => {
                          event.stopPropagation();

                          const id = item[props.dataFields.find(z => z.primary === true).value];
                          const key = props.dataSelected.indexOf(id);

                          if(key === -1) {
                            props.setDataSelected([
                              ...props.dataSelected,
                              id
                            ]);
                          } else {
                            props.setDataSelected(props.dataSelected.filter(v => v !== id));
                          }
                        }}>
                          {props.dataSelected.find(y => y === item[props.dataFields.find(z => z.primary === true).value]) ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  {props.dataFields
                    .filter(
                      (_elem) => _elem.position === "left" && _elem.status === 1
                    )
                    .map((elem, _index) => (
                      <div className="inner-body-content-wrapper" key={_index}>
                        <div
                          className={`inner-content-left inner-body-content${
                            elem.size === "default" ? "" : " " + elem.size
                          }${
                            elem.contentFontType
                              ? " " + elem.contentFontType
                              : ""
                          }`}
                        >
                          {elem.type === "text" ? (
                            <span
                              className={
                                !item[elem.value] 
                                  ? "textbox-grey-smooth" 
                                  : elem.textFormat && elem.textFormat === "pre"
                                      ? "preformatted-text" 
                                      : ""
                              }
                            >
                              {elem.textFormat && elem.textFormat === "pre" ? (
                                <pre>
                                  {
                                    item[elem.value]
                                      ? elem.contentFontType
                                        ? elem.contentFontType === "capitalize" ||
                                          elem.contentFontType === "lowercase"
                                            ? item[elem.value].toLowerCase()
                                            : elem.contentFontType === "uppercase"
                                              ? item[elem.value].toUppercase()
                                              : item[elem.value]
                                        : item[elem.value]
                                      : "Undefined"
                                  }
                                </pre>
                              ) : (
                                item[elem.value]
                                  ? elem.contentFontType
                                    ? elem.contentFontType === "capitalize" ||
                                      elem.contentFontType === "lowercase"
                                        ? item[elem.value].toLowerCase()
                                        : elem.contentFontType === "uppercase"
                                          ? item[elem.value].toUppercase()
                                          : item[elem.value]
                                    : item[elem.value]
                                  : "Undefined"
                              )}
                            </span>
                          ) : elem.type === "textlink" ? (
                            <span
                              className={
                                !item[elem.value] ? "textbox-grey-smooth" : ""
                              }
                              onClick={(event) => event.stopPropagation()}
                            >
                              {item[elem.value] ? (
                                <a
                                  href={item[elem.value]}
                                  target="_blank"
                                  className="link-text"
                                >
                                  {item[elem.value]}
                                </a>
                              ) : (
                                "Undefined"
                              )}
                            </span>
                          ) : elem.type === "single-textbox" ? (
                            item[elem.value] ? (
                              <span
                                className={
                                  elem.textboxClass.find(
                                    (x) => x.value === item[elem.value]
                                  ).name
                                }
                              >
                                {item[elem.value]}
                              </span>
                            ) : (
                              <span className="textbox-grey-smooth">
                                Undefined
                              </span>
                            )
                          ) : elem.type === "multiple-textbox" ? (
                            <span
                              className={
                                !item[elem.value] ? "textbox-grey-smooth" : ""
                              }
                            >
                              {item[elem.value]
                                ? item[elem.value].split(elem.separator || ", ").map((x, idx) => (
                                    <span
                                      className={`little-box${
                                        elem.contentFontType === "capitalize" ||
                                        elem.contentFontType === "lowercase"
                                          ? " capitalize"
                                          : elem.contentFontType === "uppercase"
                                          ? " uppercase"
                                          : ""
                                      }`}
                                      key={idx}
                                    >
                                      {elem.contentFontType === "capitalize" ||
                                      elem.contentFontType === "lowercase"
                                        ? (elem.contentReplace
                                            ? x.replace(
                                                elem.contentReplace.from,
                                                elem.contentReplace.to
                                              )
                                            : x
                                          ).toLowerCase()
                                        : elem.contentFontType === "uppercase"
                                        ? (elem.contentReplace
                                            ? x.replace(
                                                elem.contentReplace.from,
                                                elem.contentReplace.to
                                              )
                                            : x
                                          ).toUpperCase()
                                        : elem.contentReplace
                                        ? x.replace(
                                            elem.contentReplace.from,
                                            elem.contentReplace.to
                                          )
                                        : x}
                                    </span>
                                  ))
                                : "Undefined"}
                            </span>
                          ) : elem.type === "file" ? (
                            elem.action ? (
                              elem.action === "view" ? (
                                <span
                                className="view-file-button"
                                onClick={(event) =>
                                  handleClickViewFile(
                                    event,
                                    item[elem.value],
                                    elem.contentGetFile
                                  )
                                }
                                >
                                  <span className="file-icon">
                                    <FontAwesomeIcon icon={faFileImage} />
                                  </span>
                                  <span className="file-action-text">
                                    Lihat File
                                  </span>
                                </span>
                              ) : elem.action === "download" ? (
                                <span className="download-file-button" onClick={event => elem.downloadFile(event, item)}>
                                  <span className="file-icon">
                                    {elem.fileType === "pdf" ? (
                                      <FontAwesomeIcon icon={faFilePdf} />
                                    ) : (
                                      <FontAwesomeIcon icon={faFileImage} />
                                    )}
                                  </span>
                                  <span className="file-action-text">
                                    Download
                                  </span>
                                </span>
                              ) : (
                                <span className="textbox-grey-smooth">
                                  Undefined
                                </span>
                              )
                            ) : (
                              <span className="textbox-grey-smooth">
                                Undefined
                              </span>
                            )
                          ) : null}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="right-single-body-content">
                  {props.dataFields
                    .filter(
                      (_elem) =>
                        _elem.position === "right" && _elem.status === 1
                    )
                    .map((elem, _index) => (
                      <div className="inner-body-content-wrapper" key={_index}>
                        <div
                          className={`inner-body-content inner-content-right${
                            elem.size === "default" ? "" : " " + elem.size
                          }${
                            elem.contentFontType
                              ? " " + elem.contentFontType
                              : ""
                          }`}
                        >
                          {elem.type === "text" ? (
                            <span
                              className={
                                !item[elem.value] 
                                  ? "textbox-grey-smooth" 
                                  : elem.textFormat && elem.textFormat === "pre"
                                      ? "preformatted-text" 
                                      : ""
                              }
                            >
                              {
                                item[elem.value]
                                  ? elem.contentFontType
                                    ? elem.contentFontType === "capitalize" ||
                                      elem.contentFontType === "lowercase"
                                        ? item[elem.value].toLowerCase()
                                        : elem.contentFontType === "uppercase"
                                          ? item[elem.value].toUppercase()
                                          : item[elem.value]
                                    : item[elem.value]
                                  : "Undefined"
                              }
                            </span>
                          ) : elem.type === "textlink" ? (
                            <span
                              className={
                                !item[elem.value] ? "textbox-grey-smooth" : ""
                              }
                              onClick={(event) => event.stopPropagation()}
                            >
                              {item[elem.value] ? (
                                <a
                                  href={item[elem.value]}
                                  target="_blank"
                                  className="link-text"
                                >
                                  {item[elem.value]}
                                </a>
                              ) : (
                                "Undefined"
                              )}
                            </span>
                          ) : elem.type === "single-textbox" ? (
                            item[elem.value] ? (
                              <span
                                className={
                                  elem.textboxClass.find(
                                    (x) => x.value === item[elem.value]
                                  ).name
                                }
                              >
                                {item[elem.value]}
                              </span>
                            ) : (
                              <span className="textbox-grey-smooth">
                                Undefined
                              </span>
                            )
                          ) : elem.type === "multiple-textbox" ? (
                            <span
                              className={
                                !item[elem.value] ? "textbox-grey-smooth" : ""
                              }
                            >
                              {item[elem.value]
                                ? item[elem.value].split(elem.separator || ", ").map((x, idx) => (
                                    <span className="little-box" key={idx}>
                                      {elem.contentReplace
                                        ? x.replace(
                                            elem.contentReplace.from,
                                            elem.contentReplace.to
                                          )
                                        : x}
                                    </span>
                                  ))
                                : "Undefined"}
                            </span>
                          ) : elem.type === "file" ? (
                            item[elem.value] ? (
                              <span
                                className="view-file-button"
                                onClick={(event) =>
                                  handleClickViewFile(
                                    event,
                                    item[elem.value],
                                    elem.contentGetFile
                                  )
                                }
                              >
                                <span className="file-icon">
                                  <FontAwesomeIcon icon={faFileImage} />
                                </span>
                                <span className="file-action-text">
                                  Lihat File
                                </span>
                              </span>
                            ) : (
                              <span className="textbox-grey-smooth">
                                Undefined
                              </span>
                            )
                          ) : null}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default Table;
