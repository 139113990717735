import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import User from "./Scenes/User";
import Admin from "./Scenes/Admin";
import PemiraAdmin from "./Scenes/PemiraAdmin";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import UnsupportedEmail from "./Scenes/User/UnsupportedEmail";
import UnverifiedUser from "./Scenes/User/UnverifiedUser";
import LoggedIn from "./Scenes/User/LoggedIn";
import Landing from "./Scenes/User/Landing";

function App() {
  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Route path="/pemira-admin">
              <PemiraAdmin />
            </Route>
            <Route path="/adminpanel">
              <Admin />
            </Route>
            <Route exact path="/landing">
              <Landing />
            </Route>
            <Route exact path="/auth/unverified-user">
              <UnverifiedUser />
            </Route>
            <Route exact path="/auth/unsupported-email">
              <UnsupportedEmail />
            </Route>
            <Route exact path="/auth/logged-in">
              <LoggedIn />
            </Route>
            <Route path="/">
              <User />
            </Route>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
