import React from "react";
import ReactResizeDetector from "react-resize-detector";

function ReactResizeDetectorCustom(props) {
  const handleHeadBarOnResize = () => {
    if (
      props.headBarRef &&
      props.headBarRef.current &&
      props.tableWrapperRef &&
      props.tableWrapperRef.current
    ) {
      props.tableWrapperRef.current.style.top = `${props.headBarRef.current.clientHeight}px`;
      props.tableWrapperRef.current.style.height = `calc(100% - ${props.headBarRef.current.clientHeight}px)`;
    } else return;
  };

  return (
    <ReactResizeDetector onResize={handleHeadBarOnResize}>
      {props.children}
    </ReactResizeDetector>
  );
}

export default ReactResizeDetectorCustom;
