import axios from "axios";
import config from "../../Config";

const acceptPengguna = async (uuid) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/verify-user-status/accept/${uuid}`
  });

  const response = request.data;
  return response;
};

const rejectPengguna = async (uuid) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/verify-user-status/reject/${uuid}`
  });

  const response = request.data;
  return response;
};

export {
  acceptPengguna,
  rejectPengguna
};