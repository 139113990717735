const getToken = (key) => {
  const token = window.localStorage.getItem(key);

  return token;
};

const setToken = (key, value) => {
  window.localStorage.setItem(key, value);
};

const removeToken = (key) => {
  window.localStorage.removeItem(key);
};

export { getToken, setToken, removeToken };
