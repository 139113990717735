import "./style.scss";
import React, { useEffect, useState } from "react";
import useRouter from "../../../Hooks/useRouter";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";
import config from "../../../Config";
import { loadUserByToken } from "../../../Services/API/auth";
import { useCookies } from "react-cookie";

function LoggedIn() {
  const router = useRouter();
  const [cookies, setCookies] = useCookies(["onedatamakara"]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [userByToken, setUserByToken] = useState(null);

  const validateUserToken = async () => {
    if(router && router?.query?.token) {
      try {
        const _userByToken = await loadUserByToken(router.query.token);
        setUserByToken(_userByToken);

        if(_userByToken.success) {
          const status = _userByToken.data.status;
          
          if(status === "verified") {
            setCookies("onedatamakara", router.query.token, { path: "/" });
            router.push("/");
          } else if(["pending", "new"].includes(status)) {
            setShowLoginButton(false);
            router.push(`/auth/unverified-user?token=${router.query.token}`);
          } else {
            const _message = "Oops! Internal Server Error. Silahkan kembali login beberapa saat lagi.";
            setMessage(_message);
            setShowLoginButton(true);
          }
        } else {
          const _message = userByToken.message;
          setMessage(_message);
          setShowLoginButton(true);
        }
      } catch (e) {
        const _message = e?.response?.message;
        setMessage(_message);
        setShowLoginButton(true);
      }
    } else {
      const _message = "Halo! Token kamu belum diketahui sistem. Harap kembali login menggunakan Email Student.";
      setMessage(_message);
      setShowLoginButton(true);
    }

    setIsLoading(false);
  };

  const init = async () => {
    if(cookies && cookies.onedatamakara) {
      try {
        const userByToken = await loadUserByToken(cookies["onedatamakara"]);

        if(userByToken.success) {
          if(userByToken?.data?.status === "verified") {
            router.push("/");
          } else {
            validateUserToken();
          }
        } else {
          validateUserToken();
        }
      } catch (e) {
        validateUserToken();
      }
    } else {
      validateUserToken();
    }
  };

  useEffect(() => {
    init();
  }, [router]);

  return (
    <>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <div className="c-LoggedIn">
          {message && (
            <div className="c-LoggedIn__messageWrapper">
              <span className="c-LoggedIn__message">
                {message}
              </span>
            </div>
          )}
          {showLoginButton && (
            <div className="c-LoggedIn__actionArea">
              <a 
                href={`${config.SERVER_URI}/login`}
                className="c-LoggedIn__loginButton"
              >
                Login with Email Student
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LoggedIn;