import React, { useRef, useState, useEffect, useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import { DropzoneDialog } from "material-ui-dropzone";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { DatePicker } from "@material-ui/pickers";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { UserContext } from "../../../../../../User";
import { dateFormat } from "../../../../../../../Services/Format/date";
import {
  insertPenerimaBeasiswa,
  insertPendaftarBeasiswa,
  getAllBeasiswaTersedia,
  updateSSStatusAkademikPendaftarBeasiswa,
  updateSSIuranBOPPendaftarBeasiswa,
} from "../../../../../../../Services/API/beasiswa";
import {
  getPengguna,
  updateIdLinePengguna,
  updateNoHPPengguna,
} from "../../../../../../../Services/API/pengguna";
import swal from "sweetalert";
import {
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  filterOptions,
} from "../../../../../../../Services/FormHandler";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import {
  uploadScreenshotStatusAkademik,
  uploadScreenshotIuranBOP,
} from "../../../../../../../Services/API/file";
import { numericRegExp } from "../../../../../../../Services/RegExp";

function Beasiswa() {
  const userContext = useContext(UserContext);

  const screenshotStatusAkademikRef = useRef(null);
  const screenshotIuranBOPRef = useRef(null);

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);
  const [jenis, setJenis] = useState(null);

  const [screenshotStatusAkademik, setScreenshotStatusAkademik] = useState(
    null
  );
  const [
    isClearScreenshotStatusAkademikActive,
    setIsClearScreenshotStatusAkademikActive,
  ] = useState(false);
  const [
    openScreenshotStatusAkademikDialog,
    setOpenScreenshotStatusAkademikDialog,
  ] = useState(false);
  const [screenshotIuranBOP, setScreenshotIuranBOP] = useState(null);
  const [
    isClearScreenshotIuranBOPActive,
    setIsClearScreenshotIuranBOPActive,
  ] = useState(false);
  const [
    openScreenshotIuranBOPDialog,
    setOpenScreenshotIuranBOPDialog,
  ] = useState(false);
  const [pilihanBeasiswaDaftar, setPilihanBeasiswaDaftar] = useState(null);
  const [beasiswaDaftarLain, setBeasiswaDaftarLain] = useState("");
  const [
    isClearBeasiswaDaftarLainActive,
    setIsClearBeasiswaDaftarLainActive,
  ] = useState(false);
  const [pilihanBeasiswaTerima, setPilihanBeasiswaTerima] = useState(null);
  const [beasiswaTerimaLain, setBeasiswaTerimaLain] = useState("");
  const [
    isClearBeasiswaTerimaLainActive,
    setIsClearBeasiswaTerimaLainActive
  ] = useState(false);
  const [bentukBeasiswaTerima, setBentukBeasiswaTerima] = useState(null);
  const [bentukBeasiswaLainTerima, setBentukBeasiswaLainTerima] = useState("");
  const [
    isClearBentukBeasiswaLainTerimaActive,
    setIsClearBentukBeasiswaLainTerimaActive,
  ] = useState(false);
  const [besarBeasiswaTerima, setBesarBeasiswaTerima] = useState("");
  const [
    isClearBesarBeasiswaTerimaActive,
    setIsClearBesarBeasiswaTerimaActive,
  ] = useState(false);
  const [asalBeasiswaTerima, setAsalBeasiswaTerima] = useState(null);
  const [asalBeasiswaLainTerima, setAsalBeasiswaLainTerima] = useState("");
  const [
    isClearAsalBeasiswaLainTerimaActive,
    setIsClearAsalBeasiswaLainTerimaActive,
  ] = useState(false);
  const [pemberiBeasiswaTerima, setPemberiBeasiswaTerima] = useState("");
  const [
    isClearPemberiBeasiswaTerimaActive,
    setIsClearPemberiBeasiswaTerimaActive,
  ] = useState(false);
  const [periodeAwalBeasiswaTerima, setPeriodeAwalBeasiswaTerima] = useState(
    null
  );
  const [
    isClearPeriodeAwalBeasiswaTerimaActive,
    setIsClearPeriodeAwalBeasiswaTerimaActive,
  ] = useState(false);
  const [
    isPeriodeAwalBeasiswaTerimaDialogActive,
    setIsPeriodeAwalBeasiswaTerimaDialogActive,
  ] = useState(false);
  const [periodeAkhirBeasiswaTerima, setPeriodeAkhirBeasiswaTerima] = useState(
    null
  );
  const [
    isClearPeriodeAkhirBeasiswaTerimaActive,
    setIsClearPeriodeAkhirBeasiswaTerimaActive,
  ] = useState(false);
  const [
    isPeriodeAkhirBeasiswaTerimaDialogActive,
    setIsPeriodeAkhirBeasiswaTerimaDialogActive,
  ] = useState(false);

  const [idLineBeasiswaDaftar, setIdLineBeasiswaDaftar] = useState("");
  const [
    isClearIdLineBeasiswaDaftarActive,
    setIsClearIdLineBeasiswaDaftarActive,
  ] = useState(false);

  const [noHPBeasiswaDaftar, setNoHPBeasiswaDaftar] = useState("");
  const [
    isClearNoHPBeasiswaDaftarActive,
    setIsClearNoHPBeasiswaDaftarActive,
  ] = useState(false);

  const [idLineBeasiswaTerima, setIdLineBeasiswaTerima] = useState("");
  const [
    isClearIdLineBeasiswaTerimaActive,
    setIsClearIdLineBeasiswaTerimaActive,
  ] = useState(false);

  const [noHPBeasiswaTerima, setNoHPBeasiswaTerima] = useState("");
  const [
    isClearNoHPBeasiswaTerimaActive,
    setIsClearNoHPBeasiswaTerimaActive,
  ] = useState(false);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [optionsJenis, setOptionsJenis] = useState([
    { value: "Pendaftar Beasiswa", label: "Pendaftar Beasiswa" },
    { value: "Penerima Beasiswa", label: "Penerima Beasiswa" },
  ]);

  const [optionsBeasiswaTersedia, setOptionsBeasiswaTersedia] = useState([]);

  const [
    optionsBentukBeasiswaTerima,
    setOptionsBentukBeasiswaTerima,
  ] = useState([
    { value: "Biaya hidup bulanan", label: "Biaya hidup bulanan" },
    { value: "BOP", label: "BOP" },
    { value: "Lainnya", label: "Lainnya" },
  ]);

  const [optionsAsalBeasiswaTerima, setOptionsAsalBeasiswaTerima] = useState([
    {
      value: "Pemerintah (dana APBN atau APBD)",
      label: "Pemerintah (dana APBN atau APBD)",
    },
    { value: "Donatur perseorangan", label: "Donatur perseorangan" },
    { value: "Yayasan/Lembaga", label: "Yayasan/Lembaga" },
    { value: "Lainnya", label: "Lainnya" },
  ]);

  const getOptionLabel = (option) => {
    return option.label;
  };

  const handleChangeJenis = (event, selected) => {
    setJenis(selected);
  };

  const handleClickScreenshotStatusAkademik = () => {
    setOpenScreenshotStatusAkademikDialog(true);
  };

  const handleCloseScreenshotStatusAkademik = () => {
    setOpenScreenshotStatusAkademikDialog(false);
  };

  const handleSaveScreenshotStatusAkademik = (files) => {
    setScreenshotStatusAkademik(files[0]);
    screenshotStatusAkademikRef.current.focus();
    setOpenScreenshotStatusAkademikDialog(false);
  };

  const handleMouseEnterClearScreenshotStatusAkademik = () => {
    setIsClearScreenshotStatusAkademikActive(
      screenshotStatusAkademik ? true : false
    );
  };

  const handleMouseLeaveClearScreenshotStatusAkademik = () => {
    setIsClearScreenshotStatusAkademikActive(false);
  };

  const handleClickClearScreenshotStatusAkademik = () => {
    setScreenshotStatusAkademik(null);
    setIsClearScreenshotStatusAkademikActive(false);
  };

  const handleClickScreenshotIuranBOP = () => {
    setOpenScreenshotIuranBOPDialog(true);
  };

  const handleCloseScreenshotIuranBOP = () => {
    setOpenScreenshotIuranBOPDialog(false);
  };

  const handleSaveScreenshotIuranBOP = (files) => {
    setScreenshotIuranBOP(files[0]);
    screenshotIuranBOPRef.current.focus();
    setOpenScreenshotIuranBOPDialog(false);
  };

  const handleMouseEnterClearScreenshotIuranBOP = () => {
    setIsClearScreenshotIuranBOPActive(screenshotIuranBOP ? true : false);
  };

  const handleMouseLeaveClearScreenshotIuranBOP = () => {
    setIsClearScreenshotIuranBOPActive(false);
  };

  const handleClickClearScreenshotIuranBOP = () => {
    setScreenshotIuranBOP(null);
    setIsClearScreenshotIuranBOPActive(false);
  };

  const handleChangePilihanBeasiswDaftar = (event, selected) => {
    setPilihanBeasiswaDaftar(selected);
  };

  const handleChangePilihanBeasiswTerima = (event, selected) => {
    setPilihanBeasiswaTerima(selected);
  };

  const handleChangeBentukBeasiswaTerima = (event, selected) => {
    setBentukBeasiswaTerima(selected);
  };

  const handleChangeBentukBeasiswaLainTerima = (event) => {
    setBentukBeasiswaLainTerima(event.target.value);
  };

  const handleMouseEnterBentukBeasiswaLainTerima = () => {
    setIsClearBentukBeasiswaLainTerimaActive(
      bentukBeasiswaLainTerima ? true : false
    );
  };

  const handleMouseLeaveBentukBeasiswaLainTerima = () => {
    setIsClearBentukBeasiswaLainTerimaActive(false);
  };

  const handleClickClearBentukBeasiswaLainTerima = () => {
    setBentukBeasiswaLainTerima("");
    setIsClearBentukBeasiswaLainTerimaActive(false);
  };

  const handleChangeBesarBeasiswaTerima = (event, value) => {
    setBesarBeasiswaTerima(value);
  };

  const handleMouseEnterBesarBeasiswaTerima = () => {
    setIsClearBesarBeasiswaTerimaActive(besarBeasiswaTerima ? true : false);
  };

  const handleMouseLeaveBesarBeasiswaTerima = () => {
    setIsClearBesarBeasiswaTerimaActive(false);
  };

  const handleClickClearBesarBeasiswaTerima = () => {
    setBesarBeasiswaTerima("");
    setIsClearBesarBeasiswaTerimaActive(false);
  };

  const handleChangeAsalBeasiswaTerima = (event, selected) => {
    setAsalBeasiswaTerima(selected);
  };

  const handleChangeAsalBeasiswaLainTerima = (event) => {
    setAsalBeasiswaLainTerima(event.target.value);
  };

  const handleMouseEnterAsalBeasiswaLainTerima = () => {
    setIsClearAsalBeasiswaLainTerimaActive(
      asalBeasiswaLainTerima ? true : false
    );
  };

  const handleMouseLeaveAsalBeasiswaLainTerima = () => {
    setIsClearAsalBeasiswaLainTerimaActive(false);
  };

  const handleClickClearAsalBeasiswaLainTerima = () => {
    setAsalBeasiswaLainTerima("");
    setIsClearAsalBeasiswaLainTerimaActive(false);
  };

  const handleChangePemberiBeasiswaTerima = (event) => {
    setPemberiBeasiswaTerima(event.target.value);
  };

  const handleMouseEnterPemberiBeasiswaTerima = () => {
    setIsClearPemberiBeasiswaTerimaActive(pemberiBeasiswaTerima ? true : false);
  };

  const handleMouseLeavePemberiBeasiswaTerima = () => {
    setIsClearPemberiBeasiswaTerimaActive(false);
  };

  const handleClickClearPemberiBeasiswaTerima = () => {
    setPemberiBeasiswaTerima("");
    setIsClearPemberiBeasiswaTerimaActive(false);
  };

  const handleChangePeriodeAwalBeasiswaTerima = (selected) => {
    setPeriodeAwalBeasiswaTerima(selected);
  };

  const handleMouseEnterPeriodeAwalBeasiswaTerima = () => {
    setIsClearPeriodeAwalBeasiswaTerimaActive(
      periodeAwalBeasiswaTerima ? true : false
    );
  };

  const handleMouseLeavePeriodeAwalBeasiswaTerima = () => {
    setIsClearPeriodeAwalBeasiswaTerimaActive(false);
  };

  const handleTogglePeriodeAwalBeasiswaTerimaDialog = () => {
    setIsPeriodeAwalBeasiswaTerimaDialogActive(
      !isPeriodeAwalBeasiswaTerimaDialogActive
    );
  };

  const handleMouseDownClearPeriodeAwalBeasiswaTerima = () => {
    setPeriodeAwalBeasiswaTerima(null);
    setIsClearPeriodeAwalBeasiswaTerimaActive(false);
    setIsPeriodeAwalBeasiswaTerimaDialogActive(false);
  };

  const handleChangePeriodeAkhirBeasiswaTerima = (selected) => {
    setPeriodeAkhirBeasiswaTerima(selected);
  };

  const handleMouseEnterPeriodeAkhirBeasiswaTerima = () => {
    setIsClearPeriodeAkhirBeasiswaTerimaActive(
      periodeAkhirBeasiswaTerima ? true : false
    );
  };

  const handleMouseLeavePeriodeAkhirBeasiswaTerima = () => {
    setIsClearPeriodeAkhirBeasiswaTerimaActive(false);
  };

  const handleTogglePeriodeAkhirBeasiswaTerimaDialog = () => {
    setIsPeriodeAkhirBeasiswaTerimaDialogActive(
      !isPeriodeAkhirBeasiswaTerimaDialogActive
    );
  };

  const handleMouseDownClearPeriodeAkhirBeasiswaTerima = () => {
    setPeriodeAkhirBeasiswaTerima(null);
    setIsClearPeriodeAkhirBeasiswaTerimaActive(false);
    setIsPeriodeAkhirBeasiswaTerimaDialogActive(false);
  };

  const handleFormSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleCloseErrorSnackbar = () => {
    setIsErrorSnackbarActive(false);
  };

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("jenis")) {
      if (!jenis) {
        _errorList.push({
          type: "required",
          message: "Jenis wajib dipilih.",
        });
      }
    }

    if (target.includes("screenshotStatusAkademik")) {
      if (!screenshotStatusAkademik) {
        _errorList.push({
          type: "required",
          message: "Screenshot Status Akademik waib diupload.",
        });
      }
    }

    if (target.includes("screenshotIuranBOP")) {
      if (!screenshotIuranBOP) {
        _errorList.push({
          type: "required",
          message: "Screenshot Iuran BOP wajib diupload.",
        });
      }
    }

    if (target.includes("pilihanBeasiswaDaftar")) {
      if (!pilihanBeasiswaDaftar) {
        _errorList.push({
          type: "required",
          message: "Beasiswa yang ingin didaftarkan wajib dipilih.",
        });
      }
    }

    if (target.includes("beasiswaDaftarLain")) {
      if (!beasiswaDaftarLain) {
        _errorList.push({
          type: "required",
          message: "Nama Beasiswa Lainnya wajib diisi.",
        });
      }
    }

    if (target.includes("pilihanBeasiswaTerima")) {
      if (!pilihanBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Nama Beasiswa yang diterima wajib dipilih.",
        });
      }
    }

    if(target.includes("beasiswaTerimaLain")) {
      if(!beasiswaTerimaLain) {
        _errorList.push({
          type: "required",
          message: "Nama Beasiswa Lainnya wajib diisi."
        });
      }
    }

    if (target.includes("bentukBeasiswaTerima")) {
      if (!bentukBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Bentuk beasiswa yang diterima wajib diisi.",
        });
      }
    }

    if (target.includes("bentukBeasiswaLainTerima")) {
      if (!bentukBeasiswaLainTerima) {
        _errorList.push({
          type: "required",
          message: "Bentuk beasiswa lain yang diterima wajib diisi.",
        });
      }
    }

    if (target.includes("besarBeasiswaTerima")) {
      if (!besarBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Besar beasiswa yang diperoleh wajib diisi.",
        });
      }
    }

    if (target.includes("asalBeasiswaTerima")) {
      if (!asalBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Asal beasiswa yang diterima wajib diisi.",
        });
      }
    }

    if (target.includes("asalBeasiswaLainTerima")) {
      if (!asalBeasiswaLainTerima) {
        _errorList.push({
          type: "required",
          message: "Asal beasiswa lain yang diterima wajib diisi.",
        });
      }
    }

    if (target.includes("pemberiBeasiswaTerima")) {
      if (!pemberiBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Nama pemberi beasiswa wajib diisi.",
        });
      }
    }

    if (target.includes("periodeAwalBeasiswaTerima")) {
      if (!periodeAwalBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Periode Awal penerimaan beasiswa wajib diisi.",
        });
      }
    }

    if (target.includes("periodeAkhirBeasiswaTerima")) {
      if (!periodeAkhirBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "Periode Akhir penerimaan beasiswa wajib diisi.",
        });
      }
    }

    if (target.includes("idLineBeasiswaDaftar")) {
      if (!idLineBeasiswaDaftar) {
        _errorList.push({
          type: "required",
          message: "ID Line wajib diisi",
        });
      }
    }

    if (target.includes("noHPBeasiswaDaftar")) {
      if (!noHPBeasiswaDaftar) {
        _errorList.push({
          type: "required",
          message: "No HP wajib diisi.",
        });
      } else {
        if (!numericRegExp.test(noHPBeasiswaDaftar)) {
          _errorList.push({
            type: "required",
            message: "No. HP wajib berupa angka.",
          });
        }
      }
    }

    if (target.includes("idLineBeasiswaTerima")) {
      if (!idLineBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "ID Line wajib diisi",
        });
      }
    }

    if (target.includes("noHPBeasiswaTerima")) {
      if (!noHPBeasiswaTerima) {
        _errorList.push({
          type: "required",
          message: "No HP wajib diisi.",
        });
      } else {
        if (!numericRegExp.test(noHPBeasiswaTerima)) {
          _errorList.push({
            type: "required",
            message: "No. HP wajib berupa angka.",
          });
        }
      }
    }

    return _errorList;
  };

  const handleResetData = async (isAllDeleted) => {
    if (isAllDeleted) {
      setJenis(null);
    }

    setScreenshotStatusAkademik(null);
    setScreenshotIuranBOP(null);
    setPilihanBeasiswaDaftar(null);
    setBeasiswaDaftarLain("");
    setPilihanBeasiswaTerima(null);
    setBentukBeasiswaTerima(null);
    setBentukBeasiswaLainTerima("");
    setBesarBeasiswaTerima("");
    setAsalBeasiswaTerima(null);
    setAsalBeasiswaLainTerima("");
    setPemberiBeasiswaTerima("");
    setPeriodeAwalBeasiswaTerima(null);
    setPeriodeAkhirBeasiswaTerima(null);
  };

  useDidMountEffect(() => {
    handleResetData();
  }, [jenis]);

  useDidMountEffect(() => {
    setIsClearBeasiswaDaftarLainActive(beasiswaDaftarLain ? true : false);
  }, [beasiswaDaftarLain]);

  useDidMountEffect(() => {
    setIsClearBeasiswaTerimaLainActive(beasiswaTerimaLain ? true : false);
  }, [beasiswaTerimaLain]);

  useDidMountEffect(() => {
    setIsClearAsalBeasiswaLainTerimaActive(
      bentukBeasiswaLainTerima ? true : false
    );
  }, [bentukBeasiswaLainTerima]);

  useDidMountEffect(() => {
    setIsClearBesarBeasiswaTerimaActive(besarBeasiswaTerima ? true : false);
  }, [besarBeasiswaTerima]);

  useDidMountEffect(() => {
    setIsClearAsalBeasiswaLainTerimaActive(
      asalBeasiswaLainTerima ? true : false
    );
  }, [asalBeasiswaLainTerima]);

  useDidMountEffect(() => {
    setIsClearPemberiBeasiswaTerimaActive(pemberiBeasiswaTerima ? true : false);
  }, [pemberiBeasiswaTerima]);

  useDidMountEffect(() => {
    setIsClearIdLineBeasiswaDaftarActive(idLineBeasiswaDaftar ? true : false);
  }, [idLineBeasiswaDaftar]);

  useDidMountEffect(() => {
    setIsClearNoHPBeasiswaDaftarActive(noHPBeasiswaDaftar ? true : false);
  }, [noHPBeasiswaDaftar]);

  useDidMountEffect(() => {
    setIsClearIdLineBeasiswaTerimaActive(idLineBeasiswaTerima ? true : false);
  }, [idLineBeasiswaTerima]);

  useDidMountEffect(() => {
    setIsClearNoHPBeasiswaTerimaActive(noHPBeasiswaTerima ? true : false);
  }, [noHPBeasiswaTerima]);

  useDidMountEffect(() => {
    if (isFormSubmitted) {
      setIsErrorSnackbarActive(errorList ? true : false);
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsButtonActionLoading(true);

          if (jenis.value === "Pendaftar Beasiswa") {
            const screenshotStatusAkademikFormData = new FormData();
            const screenshotIuranBOPFormData = new FormData();

            const dataPendaftarBeasiswa = {
              nama_beasiswa:
                pilihanBeasiswaDaftar.value === "Beasiswa Lainnya"
                  ? beasiswaDaftarLain
                  : pilihanBeasiswaDaftar.value,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
              id_pengguna: userContext.userActive.id_pengguna,
            };

            if (screenshotStatusAkademik) {
              screenshotStatusAkademikFormData.append(
                "single_file",
                screenshotStatusAkademik
              );
            }

            if (screenshotIuranBOP) {
              screenshotIuranBOPFormData.append(
                "single_file",
                screenshotIuranBOP
              );
            }

            const [
              _uploadScreenshotStatusAkademik,
              _uploadScreenshotIuranBOP,
              _insertPendaftarBeasiswa,
              _updateIdLinePengguna,
              _updateNoHPPengguna,
            ] = await Promise.allSettled([
              uploadScreenshotStatusAkademik(screenshotStatusAkademikFormData),
              uploadScreenshotIuranBOP(screenshotIuranBOPFormData),
              insertPendaftarBeasiswa(dataPendaftarBeasiswa),
              updateIdLinePengguna({
                id_line: idLineBeasiswaDaftar,
                id_pengguna: userContext.userActive.id_pengguna,
              }),
              updateNoHPPengguna({
                no_hp: noHPBeasiswaDaftar,
                id_pengguna: userContext.userActive.id_pengguna,
              }),
            ]);

            if (
              _uploadScreenshotStatusAkademik.value.success &&
              _uploadScreenshotIuranBOP.value.success &&
              _insertPendaftarBeasiswa.value.success &&
              _updateIdLinePengguna.value.success &&
              _updateNoHPPengguna.value.success
            ) {
              const [
                _updateSSStatusAkademikPendaftarBeasiswa,
                _updateSSIuranBOPPendaftarBeasiswa,
              ] = await Promise.allSettled([
                updateSSStatusAkademikPendaftarBeasiswa({
                  ss_status_akademik: _uploadScreenshotStatusAkademik.value.data
                    ? _uploadScreenshotStatusAkademik.value.data
                    : null,
                  id_pendaftar_beasiswa: _insertPendaftarBeasiswa.value.id,
                }),
                updateSSIuranBOPPendaftarBeasiswa({
                  ss_iuran_bop: _uploadScreenshotIuranBOP.value.data
                    ? _uploadScreenshotIuranBOP.value.data
                    : null,
                  id_pendaftar_beasiswa: _insertPendaftarBeasiswa.value.id,
                }),
              ]);

              if (
                _updateSSStatusAkademikPendaftarBeasiswa.value.success &&
                _updateSSIuranBOPPendaftarBeasiswa.value.success
              ) {
                swal("Sukses!", "Data berhasil terkirim.", "success");
              } else {
                swal("Oops!", "Internal Server Error", "warning");
              }
            } else {
              if (!_uploadScreenshotStatusAkademik.value.success) {
                swal(
                  "Oops!",
                  "Screenshot Status Akademik gagal diupload.",
                  "warning"
                );
              } else if (!_uploadScreenshotIuranBOP.value.success) {
                swal(
                  "Oops!",
                  "Screenshot Iuran BOP gagal diupload.",
                  "warning"
                );
              } else if (!_insertPendaftarBeasiswa.value.success) {
                swal("Oops!", "Data gagal dikirim.", "warning");
              }
            }
          } else {
            const [
              _insertPenerimaBeasiswa,
              _updateIdLinePengguna,
              _updateNoHPPengguna,
            ] = await Promise.all([
              insertPenerimaBeasiswa({
                nama_beasiswa: 
                  pilihanBeasiswaTerima.value === "Beasiswa Lainnya"
                    ? beasiswaTerimaLain
                    : pilihanBeasiswaTerima.value,
                bentuk_beasiswa: bentukBeasiswaTerima.value,
                bentuk_beasiswa_lain: bentukBeasiswaLainTerima,
                besar_beasiswa: besarBeasiswaTerima,
                asal_beasiswa: asalBeasiswaTerima.value,
                asal_beasiswa_lain: asalBeasiswaLainTerima,
                pemberi_beasiswa: pemberiBeasiswaTerima,
                periode_awal_beasiswa: dateFormat(
                  new Date(periodeAwalBeasiswaTerima),
                  "yyyy-MM-dd"
                ),
                periode_akhir_beasiswa: dateFormat(
                  new Date(periodeAkhirBeasiswaTerima),
                  "yyyy-MM-dd"
                ),
                tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
                id_pengguna: userContext.userActive.id_pengguna,
              }),
              updateIdLinePengguna({
                id_line: idLineBeasiswaTerima,
                id_pengguna: userContext.userActive.id_pengguna,
              }),
              updateNoHPPengguna({
                no_hp: noHPBeasiswaTerima,
                id_pengguna: userContext.userActive.id_pengguna,
              }),
            ]);

            if (
              _insertPenerimaBeasiswa.success &&
              _updateIdLinePengguna.success &&
              _updateNoHPPengguna.success
            ) {
              swal("Sukses!", _insertPenerimaBeasiswa.message, "success");
            } else {
              swal(
                "Oops!",
                _insertPenerimaBeasiswa.message
                  ? _insertPenerimaBeasiswa.message
                  : "Internal Server Error",
                "warning"
              );
            }
          }

          setIsButtonActionLoading(false);
          handleResetData(true);
        }
      }, 0);
    }
  }, [errorList]);

  useEffect(() => {
    if (isFormSubmitted) {
      setErrorList(
        getErrorList(
          jenis
            ? jenis.value === "Pendaftar Beasiswa"
              ? [
                  "screenshotStatusAkademik",
                  "screenshotIuranBOP",
                  "pilihanBeasiswaDaftar",
                ]
                  .concat(
                    pilihanBeasiswaDaftar &&
                      pilihanBeasiswaDaftar.value === "Beasiswa Lainnya"
                      ? ["beasiswaDaftarLain"]
                      : []
                  )
                  .concat(["idLineBeasiswaDaftar", "noHPBeasiswaDaftar"])
              : ["pilihanBeasiswaTerima", "bentukBeasiswaTerima"]
                  .concat(
                    pilihanBeasiswaTerima &&
                      pilihanBeasiswaTerima.value === "Beasiswa Lainnya"
                      ? ["beasiswaTerimaLain"]
                      : []
                  )
                  .concat(
                    bentukBeasiswaTerima &&
                      bentukBeasiswaTerima.value === "Lainnya"
                      ? ["bentukBeasiswaLainTerima"]
                      : []
                  )
                  .concat(["besarBeasiswaTerima", "asalBeasiswaTerima"])
                  .concat(
                    asalBeasiswaTerima && asalBeasiswaTerima.value === "Lainnya"
                      ? ["asalBeasiswaLainTerima"]
                      : []
                  )
                  .concat([
                    "pemberiBeasiswaTerima",
                    "periodeAwalBeasiswaTerima",
                    "periodeAkhirBeasiswaTerima",
                    "idLineBeasiswaTerima",
                    "noHPBeasiswaTerima",
                  ])
            : "jenis"
        )
      );
    }
  }, [isFormSubmitted]);

  useEffect(() => {
    (async () => {
      const [beasiswaTersedia, penggunaContact] = await Promise.all([
        getAllBeasiswaTersedia(),
        getPengguna({
          fields: ["no_hp", "id_line"],
          search: {
            groupOp: "",
            rules: [
              {
                field: "id_pengguna",
                op: "=",
                data: userContext.userActive.id_pengguna,
              },
            ],
          },
        }),
      ]);

      if (beasiswaTersedia.success && penggunaContact.success) {
        setOptionsBeasiswaTersedia(
          [
            {
              value: "Beasiswa Lainnya",
              label: "Beasiswa Lainnya",
            },
          ].concat(
            beasiswaTersedia.data.map((item) => {
              return {
                value: item.nama_beasiswa,
                label: item.nama_beasiswa,
              };
            })
          )
        );

        setIdLineBeasiswaDaftar(penggunaContact.data[0].id_line);
        setNoHPBeasiswaDaftar(penggunaContact.data[0].no_hp);
        setIdLineBeasiswaTerima(penggunaContact.data[0].id_line);
        setNoHPBeasiswaTerima(penggunaContact.data[0].no_hp);

        setTimeout(() => {
          setIsClearIdLineBeasiswaDaftarActive(false);
          setIsClearNoHPBeasiswaDaftarActive(false);
          setIsClearIdLineBeasiswaTerimaActive(false);
          setIsClearNoHPBeasiswaTerimaActive(false);
        }, 0);
      }
    })();
  }, []);

  return (
    <div className="user-page-beasiswa">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/5.svg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => userContext.dispatchMenuActive("SelamatDatang")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">Beasiswa</span>
        <span className="sub-title-text">Pendataan Beasiswa</span>
      </div>
      <div className="body-area">
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <div className="form-group required">
            <Autocomplete
              id="beasiswa-jenis"
              options={optionsJenis}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={handleChangeJenis}
              value={jenis || null}
              renderInput={(params) => (
                <TextField {...params} label="Jenis" variant="outlined" />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          {jenis && jenis.value === "Pendaftar Beasiswa" && (
            <React.Fragment>
              <div
                className={`form-group required upload-area${
                  isClearScreenshotStatusAkademikActive ? " pointer-active" : ""
                }`}
                onMouseEnter={handleMouseEnterClearScreenshotStatusAkademik}
                onMouseLeave={handleMouseLeaveClearScreenshotStatusAkademik}
              >
                <TextField
                  id="beasiswa-screenshot-status-akademik"
                  label="Screenshot Status Akademik"
                  variant="outlined"
                  value={
                    screenshotStatusAkademik
                      ? screenshotStatusAkademik.name
                      : ""
                  }
                  ref={screenshotStatusAkademikRef}
                  readOnly
                />
                {isClearScreenshotStatusAkademikActive && (
                  <div className="clear-icon">
                    <ClearIcon
                      onClick={handleClickClearScreenshotStatusAkademik}
                    />
                  </div>
                )}
                <div
                  className="upload-button"
                  onClick={handleClickScreenshotStatusAkademik}
                >
                  <span className="upload-text">Upload</span>
                </div>
                <DropzoneDialog
                  acceptedFiles={["image/*"]}
                  cancelButtonText={"Cancel"}
                  submitButtonText={"Upload"}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={openScreenshotStatusAkademikDialog}
                  onClose={handleCloseScreenshotStatusAkademik}
                  onSave={handleSaveScreenshotStatusAkademik}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
              <div
                className={`form-group required upload-area${
                  isClearScreenshotIuranBOPActive ? " pointer-active" : ""
                }`}
                onMouseEnter={handleMouseEnterClearScreenshotIuranBOP}
                onMouseLeave={handleMouseLeaveClearScreenshotIuranBOP}
              >
                <TextField
                  id="beasiswa-screenshot-iuran-bop"
                  label="Screenshot Iuran BOP"
                  variant="outlined"
                  value={screenshotIuranBOP ? screenshotIuranBOP.name : ""}
                  ref={screenshotIuranBOPRef}
                  readOnly
                />
                {isClearScreenshotIuranBOPActive && (
                  <div className="clear-icon">
                    <ClearIcon onClick={handleClickClearScreenshotIuranBOP} />
                  </div>
                )}
                <div
                  className="upload-button"
                  onClick={handleClickScreenshotIuranBOP}
                >
                  <span className="upload-text">Upload</span>
                </div>
                <DropzoneDialog
                  acceptedFiles={["image/*"]}
                  cancelButtonText={"Cancel"}
                  submitButtonText={"Upload"}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={openScreenshotIuranBOPDialog}
                  onClose={handleCloseScreenshotIuranBOP}
                  onSave={handleSaveScreenshotIuranBOP}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
              <div className="form-group required">
                <Autocomplete
                  id="beasiswa-pilihan-beasiswa-daftar"
                  options={optionsBeasiswaTersedia}
                  filterOptions={filterOptions}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={handleChangePilihanBeasiswDaftar}
                  value={pilihanBeasiswaDaftar || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Beasiswa yang ingin didaftarkan"
                      variant="outlined"
                      helperText='*Pilih "Beasiswa Lainnya" jika beasiswa yang dimaksud tidak tersedia.'
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {pilihanBeasiswaDaftar &&
                pilihanBeasiswaDaftar.value === "Beasiswa Lainnya" && (
                  <div className="form-group required">
                    <TextField
                      id="beasiswa-beasiswa-daftar-lain"
                      label="Nama Beasiswa Lainnya"
                      variant="outlined"
                      value={beasiswaDaftarLain || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setBeasiswaDaftarLain)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          beasiswaDaftarLain,
                          setIsClearBeasiswaDaftarLainActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsClearBeasiswaDaftarLainActive
                        )
                      }
                      InputProps={{
                        endAdornment: isClearBeasiswaDaftarLainActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setBeasiswaDaftarLain,
                                  setIsClearBeasiswaDaftarLainActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              {jenis && jenis.value === "Pendaftar Beasiswa" && (
                <React.Fragment>
                  <div className="form-group required">
                    <TextField
                      id="beasiswa-id-line"
                      label="ID Line"
                      variant="outlined"
                      value={idLineBeasiswaDaftar || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setIdLineBeasiswaDaftar)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          idLineBeasiswaDaftar,
                          setIsClearIdLineBeasiswaDaftarActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsClearIdLineBeasiswaDaftarActive
                        )
                      }
                      InputProps={{
                        endAdornment: isClearIdLineBeasiswaDaftarActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setIdLineBeasiswaDaftar,
                                  setIsClearIdLineBeasiswaDaftarActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group required">
                    <TextField
                      id="beasiswa-no-hp"
                      label="No. HP"
                      variant="outlined"
                      value={noHPBeasiswaDaftar || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setNoHPBeasiswaDaftar)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          noHPBeasiswaDaftar,
                          setIsClearNoHPBeasiswaDaftarActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsClearNoHPBeasiswaDaftarActive
                        )
                      }
                      InputProps={{
                        endAdornment: isClearNoHPBeasiswaDaftarActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setNoHPBeasiswaDaftar,
                                  setIsClearNoHPBeasiswaDaftarActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {jenis && jenis.value === "Penerima Beasiswa" && (
            <React.Fragment>
              <div className="form-group required">
                <Autocomplete
                  id="beasiswa-pilihan-beasiswa-terima"
                  options={optionsBeasiswaTersedia}
                  filterOptions={filterOptions}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={handleChangePilihanBeasiswTerima}
                  value={pilihanBeasiswaTerima || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nama Beasiswa yang diterima"
                      variant="outlined"
                      helperText='*Pilih "Beasiswa Lainnya" jika beasiswa yang dimaksud tidak tersedia.'
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {pilihanBeasiswaTerima &&
                pilihanBeasiswaTerima.value === "Beasiswa Lainnya" && (
                  <div className="form-group required">
                    <TextField
                      id="beasiswa-beasiswa-terima-lain"
                      label="Nama Beasiswa Lainnya"
                      variant="outlined"
                      value={beasiswaTerimaLain || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setBeasiswaTerimaLain)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          beasiswaTerimaLain,
                          setIsClearBeasiswaTerimaLainActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsClearBeasiswaTerimaLainActive
                        )
                      }
                      InputProps={{
                        endAdornment: isClearBeasiswaTerimaLainActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setBeasiswaTerimaLain,
                                  setIsClearBeasiswaTerimaLainActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              <div className="form-group required">
                <Autocomplete
                  id="beasiswa-bentuk-beasiswa-terima"
                  options={optionsBentukBeasiswaTerima}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={handleChangeBentukBeasiswaTerima}
                  value={bentukBeasiswaTerima || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bentuk beasiswa yang diterima"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {bentukBeasiswaTerima &&
                bentukBeasiswaTerima.value === "Lainnya" && (
                  <div className="form-group required">
                    <TextField
                      id="beasiswa-bentuk-beasiswa-terima-lain"
                      label="Bentuk beasiswa lain yang diterima"
                      variant="outlined"
                      value={bentukBeasiswaLainTerima || ""}
                      onChange={handleChangeBentukBeasiswaLainTerima}
                      onMouseEnter={handleMouseEnterBentukBeasiswaLainTerima}
                      onMouseLeave={handleMouseLeaveBentukBeasiswaLainTerima}
                      InputProps={{
                        endAdornment: isClearBentukBeasiswaLainTerimaActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickClearBentukBeasiswaLainTerima}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              <div
                className="form-group required"
                onMouseEnter={handleMouseEnterBesarBeasiswaTerima}
                onMouseLeave={handleMouseLeaveBesarBeasiswaTerima}
              >
                <CurrencyTextField
                  label="Besar beasiswa yang diperoleh"
                  variant="outlined"
                  value={besarBeasiswaTerima || ""}
                  textAlign="left"
                  currencySymbol="Rp"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  onChange={handleChangeBesarBeasiswaTerima}
                  InputProps={{
                    endAdornment: isClearBesarBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickClearBesarBeasiswaTerima}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <Autocomplete
                  id="beasiswa-asal-beasiswa-terima"
                  options={optionsAsalBeasiswaTerima}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={handleChangeAsalBeasiswaTerima}
                  value={asalBeasiswaTerima || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asal beasiswa yang diterima"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {asalBeasiswaTerima && asalBeasiswaTerima.value === "Lainnya" && (
                <div className="form-group required">
                  <TextField
                    id="beasiswa-asal-beasiswa-terima-lain"
                    label="Asal beasiswa lain yang diterima"
                    variant="outlined"
                    value={asalBeasiswaLainTerima || ""}
                    onChange={handleChangeAsalBeasiswaLainTerima}
                    onMouseEnter={handleMouseEnterAsalBeasiswaLainTerima}
                    onMouseLeave={handleMouseLeaveAsalBeasiswaLainTerima}
                    InputProps={{
                      endAdornment: isClearAsalBeasiswaLainTerimaActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickClearAsalBeasiswaLainTerima}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="form-group required">
                <TextField
                  id="beasiswa-pemberi-beasiswa-terima"
                  label="Nama pemberi beasiswa"
                  variant="outlined"
                  helperText='*Nama donatur / yayasan / lembaga. Isi "-" jika berasal dari pemerintah.'
                  value={pemberiBeasiswaTerima || ""}
                  onChange={handleChangePemberiBeasiswaTerima}
                  onMouseEnter={handleMouseEnterPemberiBeasiswaTerima}
                  onMouseLeave={handleMouseLeavePemberiBeasiswaTerima}
                  InputProps={{
                    endAdornment: isClearPemberiBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickClearPemberiBeasiswaTerima}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <DatePicker
                  label="Periode Awal penerimaan beasiswa"
                  views={["year", "month"]}
                  maxDate={
                    periodeAkhirBeasiswaTerima
                      ? periodeAkhirBeasiswaTerima
                      : new Date()
                  }
                  openTo="year"
                  inputVariant="outlined"
                  value={periodeAwalBeasiswaTerima || null}
                  onChange={handleChangePeriodeAwalBeasiswaTerima}
                  onMouseEnter={handleMouseEnterPeriodeAwalBeasiswaTerima}
                  onMouseLeave={handleMouseLeavePeriodeAwalBeasiswaTerima}
                  open={isPeriodeAwalBeasiswaTerimaDialogActive}
                  onOpen={handleTogglePeriodeAwalBeasiswaTerimaDialog}
                  onClose={handleTogglePeriodeAwalBeasiswaTerimaDialog}
                  InputProps={{
                    endAdornment: isClearPeriodeAwalBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={
                            handleMouseDownClearPeriodeAwalBeasiswaTerima
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <DatePicker
                  label="Periode Akhir penerimaan beasiswa"
                  views={["year", "month"]}
                  minDate={
                    periodeAwalBeasiswaTerima
                      ? periodeAwalBeasiswaTerima
                      : new Date("1900-01-01")
                  }
                  maxDate={new Date()}
                  openTo="year"
                  inputVariant="outlined"
                  value={periodeAkhirBeasiswaTerima || null}
                  onChange={handleChangePeriodeAkhirBeasiswaTerima}
                  onMouseEnter={handleMouseEnterPeriodeAkhirBeasiswaTerima}
                  onMouseLeave={handleMouseLeavePeriodeAkhirBeasiswaTerima}
                  open={isPeriodeAkhirBeasiswaTerimaDialogActive}
                  onOpen={handleTogglePeriodeAkhirBeasiswaTerimaDialog}
                  onClose={handleTogglePeriodeAkhirBeasiswaTerimaDialog}
                  InputProps={{
                    endAdornment: isClearPeriodeAkhirBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={
                            handleMouseDownClearPeriodeAkhirBeasiswaTerima
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="beasiswa-id-line"
                  label="ID Line"
                  variant="outlined"
                  value={idLineBeasiswaTerima || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setIdLineBeasiswaTerima)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      idLineBeasiswaTerima,
                      setIsClearIdLineBeasiswaTerimaActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(
                      setIsClearIdLineBeasiswaTerimaActive
                    )
                  }
                  InputProps={{
                    endAdornment: isClearIdLineBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setIdLineBeasiswaTerima,
                              setIsClearIdLineBeasiswaTerimaActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="beasiswa-no-hp"
                  label="No. HP"
                  variant="outlined"
                  value={noHPBeasiswaTerima || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setNoHPBeasiswaTerima)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      noHPBeasiswaTerima,
                      setIsClearNoHPBeasiswaTerimaActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(
                      setIsClearNoHPBeasiswaTerimaActive
                    )
                  }
                  InputProps={{
                    endAdornment: isClearNoHPBeasiswaTerimaActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setNoHPBeasiswaTerima,
                              setIsClearNoHPBeasiswaTerimaActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </React.Fragment>
          )}
          <div className="form-action-area">
            <div className="helper-wrapper"></div>
            <div className="button-wrapper">
              <button
                type="submit"
                className="submit-button"
                onClick={handleFormSubmitAction}
              >
                {isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  <span className="button-text">Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={handleCloseErrorSnackbar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </div>
  );
}

export default Beasiswa;
