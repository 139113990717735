import React, { useRef, useState, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Pagination from "../Pagination";
import { handleCloseDialog } from "../../../../../../../Services/FormHandler";
import {
  getPengguna,
  getTotalPengguna,
  deletePengguna
} from "../../../../../../../Services/API/pengguna";
import { getFotoProfilPengguna } from "../../../../../../../Services/API/file";
import Table from "../Table";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { downloadBlob } from "../../../../../../../Services/Format/blob";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert";

function Pengguna() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Pengguna ------------------------------------------------------------

  // @Table Section
  // Pengguna

  const [data, setData] = useState([]);

  const dataFields = [
    {
      primary: true,
      checkbox: true,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "program_belajar",
      type: "text",
      label: "Program Belajar",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "program_pendidikan",
      type: "text",
      label: "Program Pendidikan",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "foto_pengguna",
      type: "file",
      label: "Foto Pengguna",
      contentGetFile: getFotoProfilPengguna,
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_lahir",
      type: "text",
      label: "Tanggal Lahir",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "no_hp",
      type: "text",
      label: "No. HP",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "id_line",
      type: "text",
      label: "ID Line",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "username_instagram",
      type: "text",
      label: "Username Instagram",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "email",
      type: "text",
      label: "Email",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "website",
      type: "textlink",
      label: "Website",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tempat_tinggal_rumah",
      type: "text",
      label: "Tempat Tinggal Rumah",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tempat_tinggal_kuliah",
      type: "text",
      label: "Tempat Tinggal Kuliah",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tentang",
      type: "text",
      label: "Tentang",
      size: "xl",
      position: "right",
      status: 1,
    },
  ];

  const [sortItem, setSortItem] = useState({
    by: "nama_lengkap",
    mode: "ASC",
  });

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Search Section
  // Pengguna

  const [searchInput, setSearchInput] = useState("");

  const optionsSearchFilterBy = [
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "program_belajar", label: "Program Belajar" },
    { value: "program_pendidikan", label: "Program Pendidikan" },
    { value: "tanggal_lahir", label: "Tanggal Lahir" },
    { value: "no_hp", label: "No. HP" },
    { value: "id_line", label: "ID Line" },
    { value: "username_instagram", label: "Username Instagram" },
    { value: "email", label: "Email" },
    { value: "website", label: "Website" },
    { value: "tempat_tinggal_rumah", label: "Tempat Tinggal Rumah" },
    { value: "tempat_tinggal_kuliah", label: "Tempat Tinggal Kuliah" },
    { value: "tentang", label: "Tentang" },
  ];

  const [searchFilterBy, setSearchFilterBy] = useState([
    optionsSearchFilterBy[0],
  ]);

  // @Pagination Section
  // Pengguna

  const optionsNumberPerPage = [10, 20, 30, 40, 50];

  const [numberPerPage, setNumberPerPage] = useState(optionsNumberPerPage[0]);

  const [numberPerPageFrom, setNumberPerPageFrom] = useState(0);

  const [numberPerPageTo, setNumberPerPageTo] = useState(0);

  const [totalData, setTotalData] = useState(0);

  const [totalPage, setTotalPage] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);

  // @Dialog Section
  // Pengguna

  const dialogFullScreenClassName = "pengguna-full-screen-bar";

  const [isViewFileDialogActive, setIsViewFileDialogActive] = useState(false);

  const [objectMime, setObjectMime] = useState(null);

  const [objectURL, setObjectURL] = useState(null);

  const [objectFilename, setObjectFilename] = useState(null);

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        setIsDeleteLoading(true);

        const _delete = await deletePengguna(dataSelected);

        setIsDeleteLoading(false);
        
        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [pengguna, totalAllData] = await Promise.all([
      getPengguna({
        fields: dataFields.map((item) => item.value),
        order: sortItem,
        limit: numberPerPage,
        offset: 0,
      }),
      getTotalPengguna({}),
    ]);

    if (pengguna.success && totalAllData.success) {
      setData(pengguna.data);
      setTotalData(totalAllData.data.total);
      setTotalPage(Math.ceil(totalAllData.data.total / numberPerPage));
      setCurrentPage(totalAllData.data.total > 0 ? 1 : 0);
      setNumberPerPageFrom(totalAllData.data.total > 0 ? 1 : 0);
      setNumberPerPageTo(
        totalAllData.data.total > 0 ? pengguna.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper pengguna-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Pengguna</h1>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setData}
                        getData={getPengguna}
                        dataFields={dataFields}
                        sortItem={sortItem}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        optionsSearchFilterBy={optionsSearchFilterBy}
                        searchFilterBy={searchFilterBy}
                        setSearchFilterBy={setSearchFilterBy}
                        numberPerPage={numberPerPage}
                        setNumberPerPageFrom={setNumberPerPageFrom}
                        setNumberPerPageTo={setNumberPerPageTo}
                        getTotalData={getTotalPengguna}
                        setTotalData={setTotalData}
                        setTotalPage={setTotalPage}
                        setCurrentPage={setCurrentPage}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setData}
                        getData={getPengguna}
                        dataFields={dataFields}
                        sortItem={sortItem}
                        searchInput={searchInput}
                        searchFilterBy={searchFilterBy}
                        optionsNumberPerPage={optionsNumberPerPage}
                        numberPerPage={numberPerPage}
                        setNumberPerPage={setNumberPerPage}
                        numberPerPageFrom={numberPerPageFrom}
                        setNumberPerPageFrom={setNumberPerPageFrom}
                        numberPerPageTo={numberPerPageTo}
                        setNumberPerPageTo={setNumberPerPageTo}
                        totalData={totalData}
                        getTotalData={getTotalPengguna}
                        setTotalData={setTotalData}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={data}
                  setData={setData}
                  getData={getPengguna}
                  dataFields={dataFields}
                  sortItem={sortItem}
                  setSortItem={setSortItem}
                  searchInput={searchInput}
                  searchFilterBy={searchFilterBy}
                  numberPerPage={numberPerPage}
                  setNumberPerPageFrom={setNumberPerPageFrom}
                  setNumberPerPageTo={setNumberPerPageTo}
                  totalData={totalData}
                  getTotalData={getTotalPengguna}
                  setTotalData={setTotalData}
                  setTotalPage={setTotalPage}
                  setCurrentPage={setCurrentPage}
                  objectMime={objectMime}
                  setObjectMime={setObjectMime}
                  objectURL={objectURL}
                  setObjectURL={setObjectURL}
                  objectFilename={objectFilename}
                  setObjectFilename={setObjectFilename}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  setIsFullScreenLoading={setIsFullScreenLoading}
                  isViewFileDialogActive={isViewFileDialogActive}
                  setIsViewFileDialogActive={setIsViewFileDialogActive}
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <Dialog
        fullScreen
        open={isViewFileDialogActive}
        onClose={() => handleCloseDialog(setIsViewFileDialogActive)}
        className={`full-screen-app-bar${
          dialogFullScreenClassName ? " " + dialogFullScreenClassName : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsViewFileDialogActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              File Viewer
            </Typography>
            {objectURL &&
              objectMime &&
              objectFilename &&
              objectMime.split("/")[0] === "image" && (
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => downloadBlob(objectURL, objectFilename)}
                  className="single-button-right-top-custom"
                >
                  Download
                </Button>
              )}
          </Toolbar>
        </AppBar>
        {objectURL && objectMime && objectMime === "application/pdf" && (
          <div className="fullscreen-pdf-file-viewer">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div className="pdf-file-viewer">
                <Viewer fileUrl={objectURL} />
              </div>
            </Worker>
          </div>
        )}
        {objectURL && objectMime && objectMime.split("/")[0] === "image" && (
          <div className="fullscreen-image-file-viewer">
            <img className="image-file" src={objectURL} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default Pengguna;
