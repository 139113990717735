import React from "react";
import "./navbarpemira.styles.css";
import { Link } from "react-router-dom";
function NavbarPemira({ setDarkmode, darkMode }) {
  const handleDarkMode = (e) => {
    e.preventDefault();
    if (darkMode) {
      setDarkmode(false);
    } else {
      setDarkmode(true);
    }
  };
  return (
    <div className="navbar-pemira__container">
      <nav>
        <Link to="/pemira-admin">Pemilih</Link>
        <Link to="/pemira-admin?show=kotaksuara">Kotak Suara</Link>
        <Link to="/pemira-admin?show=counter">Final Count</Link>
        <Link to="/pemira-admin?show=settings">Settings</Link>
        <a onClick={handleDarkMode}>DarkMode</a>
        <div id="indicator"></div>
      </nav>
    </div>
  );
}

export default NavbarPemira;
