export const pageActiveReducer = (state, action) => {
  switch (action) {
    case "Beranda":
    case "Telusuri":
    case "Profil":
    case "Pemira IKM FKUI 2020":
      return action;
    default:
      return state;
  }
};

export const menuActiveReducer = (state, action) => {
  switch (action) {
    case "SelamatDatang":
    case "Pengumuman":
    case "LigaKreasi":
    case "Bisnis":
    case "Beasiswa":
    case "KaderisasiBerkelanjutan":
    case "Publikasi":
      return action;
    default:
      return state;
  }
};

export const userActiveReducer = (state, action) => {
  return action ? action.data : state;
};

export const isHeaderNotifActionActiveReducer = (state, action) => {
  return action ? action.type : state;
};

export const isNotifContentActiveReducer = (state, action) => {
  return action ? action.type : state;
};

export const isUserThumbnailActionActiveReducer = (state, action) => {
  return action ? action.type : state;
};

export const pengumumanContentActiveReducer = (state, action) => {
  switch(action) {
    case "Info":
    case "Poll":
    case "Survey":
    case "Recruitment":
      return action;
    default:
      return state;
  }
};