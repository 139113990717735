import axios from "axios";
import config from "../../Config";

// @penyuluhan

const getPenyuluhanByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailPenyuluhanByIdPenyuluhan = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan/detail/by_penyuluhan/${id}`
  });

  const response = request.data;
  return response;
};

const getPenyuluhan = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPenyuluhan = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertPenyuluhan = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailPenyuluhan = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan/detail`,
    data
  });

  const response = request.data;
  return response;
};

const updatePenyuluhan = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan`,
    data
  });

  const response = request.data;
  return response;
};

const deletePenyuluhan = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const deleteDetailPenyuluhanByIdPenyuluhan = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/penyuluhan/detail/by_penyuluhan/${id}`
  });

  const response = request.data;
  return response;
};

// @kegiatanSosial

const getKegiatanSosialByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailKegiatanSosialByIdKegiatanSosial = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial/detail/by_kegiatan_sosial/${id}`
  });

  const response = request.data;
  return response;
};

const getKegiatanSosial = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalKegiatanSosial = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertKegiatanSosial = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailKegiatanSosial = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial/detail`,
    data
  });

  const response = request.data;
  return response;
};

const updateKegiatanSosial = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial`,
    data
  });

  const response = request.data;
  return response;
};

const deleteDetailKegiatanSosialByIdKegiatanSosial = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial/detail/by_kegiatan_sosial/${id}`
  });

  const response = request.data;
  return response;
};

const deleteKegiatanSosial = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/kegiatan_sosial`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @Screening

const getScreeningByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/screening/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailScreeningByIdScreening = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/screening/detail/by_screening/${id}`
  });

  const response = request.data;
  return response;
};

const getScreening = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/screening`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalScreening = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/screening/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertScreening = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/screening`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailScreening = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/screening/detail`,
    data
  });

  const response = request.data;
  return response;
};

const updateScreening = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/screening`,
    data
  });

  const response = request.data;
  return response;
};

const deleteDetailScreeningByIdScreening = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/screening/detail/by_screening/${id}`
  });

  const response = request.data;
  return response;
};

const deleteScreening = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/screening`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @pengobatanMassal

const getPengobatanMassalByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailPengobatanMassalByIdPengobatanMassal = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal/detail/by_pengobatan_massal/${id}`
  });

  const response = request.data;
  return response;
};

const getPengobatanMassal = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPengobatanMassal = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertPengobatanMassal = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailPengobatanMassal = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal/detail`,
    data
  });

  const response = request.data;
  return response;
};

const updatePengobatanMassal = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal`,
    data
  });

  const response = request.data;
  return response;
};

const deleteDetailPengobatanMassalByIdPengobatanMassal = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal/detail/by_pengobatan_massal/${id}`
  });

  const response = request.data;
  return response;
};

const deletePengobatanMassal = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/pengobatan_massal`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @Sirkumsisi

const getSirkumsisiByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailSirkumsisiByIdSirkumsisi = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi/detail/by_sirkumsisi/${id}`
  });

  const response = request.data;
  return response;
};

const getSirkumsisi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalSirkumsisi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertSirkumsisi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailSirkumsisi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi/detail`,
    data
  });

  const response = request.data;
  return response;
};

const updateSirkumsisi = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi`,
    data
  });

  const response = request.data;
  return response;
};

const deleteDetailSirkumsisiByIdSirkumsisi = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi/detail/by_sirkumsisi/${id}`
  });

  const response = request.data;
  return response;
};

const deleteSirkumsisi = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/sirkumsisi`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @kunjunganPasien

const getKunjunganPasienByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailKunjunganPasienByIdKunjunganPasien = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien/detail/by_kunjungan_pasien/${id}`
  });

  const response = request.data;
  return response;
};

const getKunjunganPasien = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalKunjunganPasien = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertKunjunganPasien = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailKunjunganPasien = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien/detail`,
    data,
  });

  const response = request.data;
  return response;
};

const updateKunjunganPasien = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteDetailKunjunganPasienByIdKunjunganPasien = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien/detail/by_kunjungan_pasien/${id}`
  });

  const response = request.data;
  return response;
};

const deleteKunjunganPasien = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/kunjungan_pasien`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @timMedisFKUI

const getTimMedisFKUIByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getDetailTimMedisFKUIByIdTimMedisFKUI = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui/detail/by_tim_medis_fkui/${id}`
  });

  const response = request.data;
  return response;
};

const getTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui/detail`,
    data,
  });

  const response = request.data;
  return response;
};

const updateTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteDetailTimMedisFKUIByIdTimMedisFKUI = async (id) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui/detail/by_tim_medis_fkui/${id}`
  });

  const response = request.data;
  return response;
};

const deleteTimMedisFKUI = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pengabdian/tim_medis_fkui`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

// @RekapIKM

const getRekapIKM = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengabdian/rekap_ikm`,
    params: data
  });

  const response = request.data;
  return response;
};

export {
  getPenyuluhanByPengguna,
  getDetailPenyuluhanByIdPenyuluhan,
  getPenyuluhan,
  getTotalPenyuluhan,
  insertPenyuluhan,
  updatePenyuluhan,
  deletePenyuluhan,
  deleteDetailPenyuluhanByIdPenyuluhan,
  insertDetailPenyuluhan,
  getKegiatanSosialByPengguna,
  getDetailKegiatanSosialByIdKegiatanSosial,
  getKegiatanSosial,
  getTotalKegiatanSosial,
  insertKegiatanSosial,
  insertDetailKegiatanSosial,
  updateKegiatanSosial,
  deleteKegiatanSosial,
  deleteDetailKegiatanSosialByIdKegiatanSosial,
  getScreeningByPengguna,
  getScreening,
  getDetailScreeningByIdScreening,
  getTotalScreening,
  insertScreening,
  insertDetailScreening,
  updateScreening,
  deleteScreening,
  deleteDetailScreeningByIdScreening,
  getPengobatanMassalByPengguna,
  getPengobatanMassal,
  getTotalPengobatanMassal,
  getDetailPengobatanMassalByIdPengobatanMassal,
  insertPengobatanMassal,
  insertDetailPengobatanMassal,
  updatePengobatanMassal,
  deletePengobatanMassal,
  deleteDetailPengobatanMassalByIdPengobatanMassal,
  getSirkumsisiByPengguna,
  getSirkumsisi,
  getDetailSirkumsisiByIdSirkumsisi,
  getTotalSirkumsisi,
  insertSirkumsisi,
  insertDetailSirkumsisi,
  updateSirkumsisi,
  deleteSirkumsisi,
  deleteDetailSirkumsisiByIdSirkumsisi,
  getKunjunganPasienByPengguna,
  getKunjunganPasien,
  getDetailKunjunganPasienByIdKunjunganPasien,
  getTotalKunjunganPasien,
  insertKunjunganPasien,
  insertDetailKunjunganPasien,
  updateKunjunganPasien,
  deleteKunjunganPasien,
  deleteDetailKunjunganPasienByIdKunjunganPasien,
  getTimMedisFKUIByPengguna,
  getTimMedisFKUI,
  getDetailTimMedisFKUIByIdTimMedisFKUI,
  getTotalTimMedisFKUI,
  insertTimMedisFKUI,
  insertDetailTimMedisFKUI,
  updateTimMedisFKUI,
  deleteTimMedisFKUI,
  deleteDetailTimMedisFKUIByIdTimMedisFKUI,
  getRekapIKM
};
