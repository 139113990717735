import React, { useContext, useState, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import {
  handleOpenDialog,
  handleCloseDialog,
} from "../../../../../Services/FormHandler";
import { dateFormat } from "../../../../../Services/Format/date";
import { ProfileContext } from "../../../Profil";

function KeteranganKinerja() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);
  const [
    isKeteranganKinerjaModalActive,
    setIsKeteranganKinerjaModalActive,
  ] = useState(false);

  return (
    <React.Fragment>
      <div className="kaderisasi-berkelanjutan-box box-wrapper">
        <div className="box-header-area">
          <span className="box-title-text">Keterangan Kinerja</span>
          <div className="box-action-area">
            {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
          </div>
        </div>
        <div className="box-body-area">
          <div className="row">
            <div className="single-box col-lg-4 col-md-6 col-12">
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">Hasil</span>
                </div>
                <div className="button-wrapper-area">
                  <div className="center-button">
                    <span className="icon-button">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                    <span
                      className="text-button"
                      onClick={() =>
                        handleOpenDialog(setIsKeteranganKinerjaModalActive)
                      }
                    >
                      Lihat Nilai
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={isKeteranganKinerjaModalActive}
        onClose={() => handleCloseDialog(setIsKeteranganKinerjaModalActive)}
        aria-label="close"
      >
        <AppBar className="keterangan-kinerja-full-screen-bar full-screen-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(setIsKeteranganKinerjaModalActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              Keterangan Kinerja
            </Typography>
          </Toolbar>
        </AppBar>
        <form className="fullscreen-modal-form-body" autoComplete="off">
          {profileContext.keteranganKinerja.map((item, index) => (
            <div className="single-form-group-wrapper" key={index}>
              <div className="form-group disabled">
                <div className="inner-form-group-wrapper">
                  <div className="inner-half-form">
                    <TextField
                      id="keterangan-kinerja-organisasi"
                      label="Organisasi / Kepanitiaan"
                      variant="outlined"
                      value={item.nama_organisasi}
                      readOnly
                    />
                  </div>
                  <div className="inner-half-form">
                    <TextField
                      id="keterangan-kinerja-jabatan"
                      label="Jabatan"
                      variant="outlined"
                      value={item.jabatan}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="form-group disabled">
                <div className="inner-form-group-wrapper">
                  <div className="inner-half-form">
                    <TextField
                      id="keterangan-kinerja-periode-awal"
                      label="Periode Awal"
                      variant="outlined"
                      value={dateFormat(
                        new Date(item.periode_awal),
                        "dd-MM-yyyy"
                      )}
                      readOnly
                    />
                  </div>
                  <div className="inner-half-form">
                    <TextField
                      id="keterangan-kinerja-periode-akhir"
                      label="Periode Akhir"
                      variant="outlined"
                      value={dateFormat(
                        new Date(item.periode_akhir),
                        "dd-MM-yyyy"
                      )}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div
                className={`form-group disabled${item.nilai ? "" : " pending"}`}
              >
                <TextField
                  id="keterangan-kinerja-nilai"
                  label="Nilai"
                  variant="outlined"
                  value={item.nilai ? item.nilai : "Belum Dinilai"}
                  readOnly
                />
              </div>
            </div>
          ))}
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default KeteranganKinerja;
