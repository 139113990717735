import axios from "axios";
import config from "../../Config";

const getKeteranganKinerjaByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/keterangan_kinerja/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getKeteranganKinerjaByOrganisasi = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/keterangan_kinerja/by_organisasi/${id}`,
  });

  const response = request.data;
  return response;
};

const updateKeteranganKinerja = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/keterangan_kinerja`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getKeteranganKinerjaByPengguna,
  getKeteranganKinerjaByOrganisasi,
  updateKeteranganKinerja,
};
