export const isHeaderNotifActionActiveReducer = (state, action) => {
  return action ? action.type : state;
};

export const isNotifContentActiveReducer = (state, action) => {
  return action ? action.type : state;
};

export const isUserThumbnailActionActiveReducer = (state, action) => {
  return action ? action.type : state;
};
