import axios from "axios";
import config from "../../Config";

const getAllInfo = async () => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pengumuman/info`,
  });
  const response = request.data;
  return response;
};

const getSurvey = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengumuman/survey`,
    params: data
  });

  const response = request.data;
  return response;
};

const getTotalSurvey = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengumuman/survey/total`,
    params: data
  });

  const response = request.data;
  return response;
};

const getSurveyByIdSurvey = async (idSurvey) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengumuman/survey/id/${idSurvey}`
  });

  const response = request.data;
  return response;
};

const getSurveyBySlug = async (slug) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pengumuman/survey/${slug}`
  });

  const response = request.data;
  return response;
};

const insertSurvey = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/pengumuman/survey`,
    data
  });

  const response = request.data;
  return response;
};

const updateSurvey = async (data) => {
  const request = await axios({
    method: "put",
    baseURL: `${config.SERVER_URI}/pengumuman/survey`,
    data
  });

  const response = request.data;
  return response;
};

const deleteSurvey = async (data) => {
  const request = await axios({
    method: "delete",
    baseURL: `${config.SERVER_URI}/pengumuman/survey`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

export { 
  getAllInfo,
  getSurvey,
  getTotalSurvey,
  getSurveyByIdSurvey,
  getSurveyBySlug,
  insertSurvey,
  updateSurvey,
  deleteSurvey
};
