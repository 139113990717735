const initialState = {
  isHeaderNotifActionActive: false,
  isNotifContentActive: false,
  isUserThumbnailActionActive: false,
};

export const {
  isHeaderNotifActionActive,
  isNotifContentActive,
  isUserThumbnailActionActive,
} = initialState;
