import axios from "axios";
import { removeToken } from "../Storage/token";
import config from "../../Config";

const authAdmin = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/admin/auth`,
    data,
  });

  const response = request.data;
  return response;
};

const loginAdmin = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/admin/login`,
    data,
  });

  const response = request.data;
  return response;
};

const logoutAdmin = (callback) => {
  removeToken("onedatamakara_adm");
  callback();
};

export { authAdmin, loginAdmin, logoutAdmin };
