import React, { useState, useRef } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import useOnClickOutside from "../../../../../../../Components/useOnClickOutside";

function Pagination(props) {
  const numberPerPageOptionRef = useRef(null);

  const [
    isNumberPerPageOptionActive,
    setIsNumberPerPageOptionActive,
  ] = useState(false);

  const handleClickNumberPerPage = (target) => {
    if (props.totalData > 0 && target !== props.numberPerPage) {
      props.setNumberPerPage(target);
    }

    setIsNumberPerPageOptionActive(false);
  };

  const handleClickPrevNextButton = async (target) => {
    props.setIsDataTableLoading(true);

    if(typeof props.setDataSelected === "function") {
      props.setDataSelected([]);
    }

    const [result, totalAllData] = await Promise.all([
      props.getData({
        fields: props.dataFields.map((item) => item.value),
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
        order: props.sortItem,
        limit: props.numberPerPage,
        offset:
          target === "Prev"
            ? (props.currentPage - 2) * props.numberPerPage
            : props.currentPage * props.numberPerPage,
      }),
      props.getTotalData({
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
      }),
    ]);

    if (result.success && totalAllData.success) {
      props.setData(result.data);
      props.setTotalData(
        totalAllData.data.total !== props.totalData
          ? totalAllData.data.total
          : props.totalData
      );
      props.setTotalPage(
        Math.ceil(
          (totalAllData.data.total !== props.totalData
            ? totalAllData.data.total
            : props.totalData) / props.numberPerPage
        )
      );
      props.setCurrentPage((prevState) =>
        totalAllData.data.total === props.totalData
          ? target === "Prev"
            ? prevState - 1
            : prevState + 1
          : totalAllData.data.total > 0
          ? 1
          : 0
      );
      props.setNumberPerPageFrom(
        totalAllData.data.total === props.totalData
          ? target === "Prev"
            ? (props.currentPage - 2) * props.numberPerPage + 1
            : props.currentPage * props.numberPerPage + 1
          : totalAllData.data.total > 0
          ? 1
          : 0
      );
      props.setNumberPerPageTo(
        totalAllData.data.total === props.totalData
          ? target === "Prev"
            ? (props.currentPage - 2) * props.numberPerPage + result.data.length
            : props.currentPage * props.numberPerPage + result.data.length
          : totalAllData.data.total > 0
          ? result.data.length
          : 0
      );
    }

    props.setIsDataTableLoading(false);
  };

  useOnClickOutside(numberPerPageOptionRef, () => {
    if (isNumberPerPageOptionActive) {
      setIsNumberPerPageOptionActive(false);
    }
  });

  useDidMountEffect(async () => {
    props.setIsDataTableLoading(true);

    if(typeof props.setDataSelected === "function") {
      props.setDataSelected([]);
    }

    const [result, totalAllData] = await Promise.all([
      props.getData({
        fields: props.dataFields.map((item) => item.value),
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
        order: props.sortItem,
        limit: props.numberPerPage,
        offset: 0,
      }),
      props.getTotalData({
        search: {
          groupOp: props.searchFilterBy.length > 1 ? "OR" : "",
          rules: props.searchFilterBy.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: props.searchInput,
            };
          }),
        },
      }),
    ]);

    if (result.success && totalAllData.success) {
      props.setData(result.data);
      props.setTotalData(totalAllData.data.total);
      props.setTotalPage(
        Math.ceil(totalAllData.data.total / props.numberPerPage)
      );
      props.setCurrentPage(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageFrom(totalAllData.data.total > 0 ? 1 : 0);
      props.setNumberPerPageTo(
        totalAllData.data.total > 0 ? result.data.length : 0
      );
    }

    props.setIsDataTableLoading(false);
  }, [props.numberPerPage]);

  return (
    <div
      className={`pagination-area${
        props.isDataTableLoading ? " pointer-disabled" : ""
      }`}
    >
      <div className="number-per-page">
        <div
          className="box-page-number"
          onMouseDown={() =>
            setIsNumberPerPageOptionActive(!isNumberPerPageOptionActive)
          }
        >
          <span className="number-text">{props.numberPerPage}</span>
          <span
            className={`caret-icon${
              isNumberPerPageOptionActive ? " rotate" : ""
            }`}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </div>
      </div>
      <div
        className={`number-per-page-option${
          isNumberPerPageOptionActive ? " active" : ""
        }`}
        ref={numberPerPageOptionRef}
      >
        <ul className="list-number-per-page-wrapper">
          {props.optionsNumberPerPage.map((item, index) => (
            <li
              className={`single-list${
                props.numberPerPage === item ? " active" : ""
              }`}
              onClick={() => handleClickNumberPerPage(item)}
              key={index}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="page-info">
        <span className="first-text">{props.numberPerPageFrom}</span>
        <span className="separator-horizontal"> - </span>
        <span className="end-text">{props.numberPerPageTo}</span>
        <span className="from-text"> dari </span>
        <span className="total-text">{props.totalData}</span>
      </div>
      <div className="page-button">
        <div
          className={`prev-button single-button${
            props.numberPerPageFrom <= 1 ? "" : " active"
          }`}
          onClick={() => handleClickPrevNextButton("Prev")}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div
          className={`next-button single-button${
            props.currentPage < props.totalPage ? " active" : ""
          }`}
          onClick={() => handleClickPrevNextButton("Next")}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
}

export default Pagination;
