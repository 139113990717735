import React, { useContext } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import { ProfileContext } from "../../../Profil";

function Beasiswa() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  return (
    <div className="beasiswa-box box-wrapper">
      <div className="box-header-area">
        <span className="box-title-text">Beasiswa</span>
        <div className="box-action-area">
          {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
        </div>
      </div>
      <div className="box-body-area">
        <div className="box-body-top">
          <div className="inner-box-body-top-left">
            <div className="single-info total">
              <span className="info-label">Total :</span>
              <span className="info-value">
                {profileContext.beasiswa.length}
              </span>
            </div>
          </div>
          <div className="inner-box-body-top-right"></div>
        </div>
        <div className="row">
          {profileContext.beasiswa.map((item, index) => (
            <div className="single-box col-lg-4 col-md-6 col-12" key={index}>
              {item.id_pendaftar_beasiswa ? (
                <div className="inner-box">
                  <div className="title-area">
                    <span className="title-text">{item.nama_beasiswa}</span>
                  </div>
                  <div className="type-area">
                    <span className="type-value pendaftar-beasiswa">
                      Pendaftar Beasiswa
                    </span>
                  </div>
                </div>
              ) : (
                <div className="inner-box">
                  <div className="title-area">
                    <span className="title-text">{item.nama_beasiswa}</span>
                  </div>
                  <div className="type-area">
                    <span className="type-value penerima-beasiswa">
                      Penerima Beasiswa
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Beasiswa;
