import React, { useState, useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./style.css";
import { getToken } from "../../../Services/Storage/token";
import { authAdmin } from "../../../Services/API/admin";
import Navbar from "./Components/Navbar";
import BodyContent from "./Components/BodyContent";
import {
  isHeaderNotifActionActive,
  isNotifContentActive,
  isUserThumbnailActionActive,
} from "../../../Stores/Admin";
import {
  isHeaderNotifActionActiveReducer,
  isNotifContentActiveReducer,
  isUserThumbnailActionActiveReducer,
} from "../../../Reducers/Admin";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";

const AdminContext = React.createContext();

function Panel() {
  const [
    _isHeaderNotifActionActive,
    dispatchIsHeaderNotifActionActive,
  ] = useReducer(isHeaderNotifActionActiveReducer, isHeaderNotifActionActive);

  const [_isNotifContentActive, dispatchIsNotifContentActive] = useReducer(
    isNotifContentActiveReducer,
    isNotifContentActive
  );

  const [
    _isUserThumbnailActionActive,
    dispatchIsUserThumbnailActionActive,
  ] = useReducer(
    isUserThumbnailActionActiveReducer,
    isUserThumbnailActionActive
  );

  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [username, setUsername] = useState("");
  const [level, setLevel] = useState("");

  useEffect(() => {
    (async () => {
      const token = getToken("onedatamakara_adm");

      if (!token) {
        setRedirect("/adminpanel/login");
      } else {
        const { username: _username, level: _level } = jwt_decode(token);

        if (!_username || !_level) {
          setRedirect("/adminpanel/login");
        } else {
          const auth = await authAdmin({ token });

          if (!auth.success) {
            setRedirect("/adminpanel/login");
          } else {
            await Promise.all([setUsername(_username), setLevel(_level)]);
          }
        }
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading &&
        (redirect ? (
          <Redirect to={redirect} />
        ) : (
          <AdminContext.Provider
            value={{
              username,
              level,
              isHeaderNotifActionActive: _isHeaderNotifActionActive,
              dispatchIsHeaderNotifActionActive,
              isNotifContentActive: _isNotifContentActive,
              dispatchIsNotifContentActive,
              isUserThumbnailActionActive: _isUserThumbnailActionActive,
              dispatchIsUserThumbnailActionActive,
            }}
          >
            <Navbar />
            <div className="body-content-wrapper-admin">
              <BodyContent />
            </div>
          </AdminContext.Provider>
        ))}
    </React.Fragment>
  );
}

export { AdminContext };
export default Panel;
