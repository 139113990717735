import React, { useEffect, useState, useRef } from "react";
import "../../style.css";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  faCaretDown,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Pagination from "../Pagination";
import Table from "../Table";
import swal from "sweetalert";
import { acceptPengguna, rejectPengguna } from "../../../../../../../Services/API/verify_status_pengguna";
import { getAllVerifiedPengguna, getTotalAllVerifiedPengguna, deleteVerifiedPengguna } from "../../../../../../../Services/API/verified_pengguna";
import { getAllUnverifiedPengguna, getTotalAllUnverifiedPengguna, deleteUnverifiedPengguna } from "../../../../../../../Services/API/unverified_pengguna";
import ShowDataToggle from "../ShowDataToggle";

function VerifikasiPengguna() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const headBarRef = useRef(null);
  const headerBarRef = useRef(null);
  const tableWrapperRef = useRef(null);
  
  const [dataSelected, setDataSelected] = useState([]);

  // Verifikasi Pengguna -------------------------------------------------------------------------------

  // @ShowData Section
  // Verifikasi Pengguna

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "Belum Diverifikasi", label: "Belum Diverifikasi" },
    { value: "Sudah Diverifikasi", label: "Sudah Diverifikasi" }
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Verifikasi Pengguna

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Verifikai Pengguna - Unverified Pengguna

  const [dataUnverifiedPengguna, setDataUnverifiedPengguna] = useState([]);

  const dataFieldsUnverifiedPengguna = [
    {
      primary: true,
      checkbox: true,
      value: "uuid",
      type: "text",
      label: "UUID",
      size: "default",
      posiiton: "left",
      status: 0
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama lengkap",
      size: "default",
      position: "left",
      status: 1
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "email",
      type: "email",
      label: "Email",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "tanggal_login",
      type: "text",
      label: "Tanggal Login",
      size: "default",
      position: "right",
      status: 1
    }
  ];

  const [sortItemUnverifiedPengguna, setSortItemUnverifiedPengguna] = useState({
    by: "tanggal_login",
    mode: "DESC"
  });

  const [searchInputUnverifiedPengguna, setSearchInputUnverifiedPengguna] = useState("");

  const optionsSearchFilterUnverifiedPengguna = [
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "npm", label: "NPM" },
    { value: "email", label: "Email" },
    { value: "tanggal_login", label: "Tanggal Login" }
  ];

  const [searchFilterUnverifiedPengguna, setSearchFilterUnverifiedPengguna] = useState([
    optionsSearchFilterUnverifiedPengguna[0]
  ]);

  // @Table Section
  // Verifikasi Pengguna - Verified Pengguna

  const [dataVerifiedPengguna, setDataVerifiedPengguna] = useState([]);

  const dataFieldsVerifiedPengguna = [
    {
      primary: true,
      checkbox: true,
      value: "uuid",
      type: "text",
      label: "UUID",
      size: "default",
      positin: "left",
      status: 0
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama lengkap",
      size: "default",
      position: "left",
      status: 1
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "email",
      type: "email",
      label: "Email",
      size: "default",
      position: "right",
      status: 1
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Verifikasi",
      size: "default",
      position: "right",
      status: 1
    }
  ];

  const [sortItemVerifiedPengguna, setSortItemVerifiedPengguna] = useState({
    by: "tanggal_verifikasi",
    mode: "DESC"
  });

  const [searchInputVerifiedPengguna, setSearchInputVerifiedPengguna] = useState("");

  const optionsSearchFilterVerifiedPengguna = [
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "npm", label: "NPM" },
    { value: "email", label: "Email" },
    { value: "tanggal_verifikasi", label: "Tanggal Verifikasi" }
  ];

  const [searchFilterVerifiedPengguna, setSearchFilterVerifiedPengguna] = useState([
    optionsSearchFilterVerifiedPengguna[0]
  ]);

  // @Pagination Section
  // Verifikasi Pengguna - Unverified Pengguna

  const optionsNumberPerPageUnverifiedPengguna = [10, 20, 30, 40, 50];

  const [numberPerPageUnverifiedPengguna, setNumberPerPageUnverifiedPengguna] = useState(
    optionsNumberPerPageUnverifiedPengguna[0]
  );
  const [
    numberPerPageFromUnverifiedPengguna, 
    setNumberPerPageFromUnverifiedPengguna
  ] = useState(0);

  const [numberPerPageToUnverifiedPengguna, setNumberPerPageToUnverifiedPengguna] = useState(0);

  const [totalDataUnverifiedPengguna, setTotalDataUnverifiedPengguna] = useState(0);

  const [totalPageUnverifiedPengguna, setTotalPageUnverifiedPengguna] = useState(0);

  const [currentPageUnverifiedPengguna, setCurrentPageUnverifiedPengguna] = useState(0);

  // @Pagination Section
  // Verifikasi Pengguna - Verified Pengguna

  const optionsNumberPerPageVerifiedPengguna = [10, 20, 30, 40, 50];

  const [numberPerPageVerifiedPengguna, setNumberPerPageVerifiedPengguna] = useState(
    optionsNumberPerPageVerifiedPengguna[0]
  );
  const [
    numberPerPageFromVerifiedPengguna, 
    setNumberPerPageFromVerifiedPengguna
  ] = useState(0);

  const [numberPerPageToVerifiedPengguna, setNumberPerPageToVerifiedPengguna] = useState(0);

  const [totalDataVerifiedPengguna, setTotalDataVerifiedPengguna] = useState(0);

  const [totalPageVerifiedPengguna, setTotalPageVerifiedPengguna] = useState(0);

  const [currentPageVerifiedPengguna, setCurrentPageVerifiedPengguna] = useState(0);


  // @Verification Section
  // Verifikasi Pengguna - Unverified Pengguna
  const handleClickSingleBodyContentByUnverifiedPengguna = ({ uuid }) => {
    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom"
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom"
        }
      }
    }).then(async (value) => {
      setIsDataTableLoading(true);

      if(value === "accept") {
        try {
          const verifyPengguna = await acceptPengguna(uuid);
          swal("Sukses!", "Verifikasi akun berhasil.", "success");
        } catch (e) {
          swal("Oops!", "Internal Server Error", "warning");
        }
      } else if(value === "reject") {
        try {
          const verifyPengguna = await rejectPengguna(uuid);
          swal("Sukses!", "Verifikasi akun berhasil.", "success");
        } catch (e) {
          swal("Oops!", "Internal Server Error", "warning");
        }
      }

      handleLoadData();
      setIsDataTableLoading(false);
    });
  };


  // @Delete Section
  // Verifikasi Pengguna
  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        switch(showDataActive.value) {
          case "Belum Diverifikasi":
            _delete = await deleteUnverifiedPengguna(dataSelected);
          break;
          case "Sudah Diverifikasi":
            _delete = await deleteVerifiedPengguna(dataSelected);
          break;
        }

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", "Internal Server Error", "warning");
        }
      }
    });
  };

  
  // @LoadData Section
  // Verifikasi Pengguna
  const handleLoadData = async () => {
    const [
      byUnverifiedPengguna, 
      totalByUnverifiedPengguna,
      byVerifiedPengguna,
      totalByVerifiedPengguna
    ] = await Promise.all([
      getAllUnverifiedPengguna({
        order: sortItemUnverifiedPengguna,
        limit: numberPerPageUnverifiedPengguna,
        offset: 0
      }),
      getTotalAllUnverifiedPengguna({}),
      getAllVerifiedPengguna({
        order: sortItemVerifiedPengguna,
        limit: numberPerPageVerifiedPengguna,
        offset: 0
      }),
      getTotalAllVerifiedPengguna({})
    ]);

    if(byUnverifiedPengguna.success && 
      totalByUnverifiedPengguna.success && 
      byVerifiedPengguna.success && 
      totalByVerifiedPengguna.success) {
      // Set Unverified Pengguna
      setDataUnverifiedPengguna(byUnverifiedPengguna.data);
      setTotalDataUnverifiedPengguna(totalByUnverifiedPengguna.data.total);
      setTotalPageUnverifiedPengguna(
        Math.ceil(
          totalByUnverifiedPengguna.data.total / numberPerPageUnverifiedPengguna
        )
      );
      setCurrentPageUnverifiedPengguna(
        totalByUnverifiedPengguna.data.total > 0 ? 1 : 0
      );
      setNumberPerPageFromUnverifiedPengguna(
        totalByUnverifiedPengguna.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToUnverifiedPengguna(
        totalByUnverifiedPengguna.data.total > 0
          ? byUnverifiedPengguna.data.length
          : 0
      );

      // Set Verified Pengguna
      setDataVerifiedPengguna(byVerifiedPengguna.data);
      setTotalDataVerifiedPengguna(totalByVerifiedPengguna.data.total);
      setTotalPageVerifiedPengguna(
        Math.ceil(
          totalByVerifiedPengguna.data.total / numberPerPageVerifiedPengguna
        )
      );
      setCurrentPageVerifiedPengguna(
        totalByVerifiedPengguna.data.total > 0 ? 1 : 0
      );
      setNumberPerPageFromVerifiedPengguna(
        totalByVerifiedPengguna.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToVerifiedPengguna(
        totalByVerifiedPengguna.data.total > 0
          ? byVerifiedPengguna.data.length 
          : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);
    
  return (
    <>
      <BallClipRotateMultipleCustom isLoding={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper verifikasi-pengguna-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Verifikasi Pengguna</h1>
          </div>
          <>
            <BallClipRotateMultipleCustom 
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headerBarRef={headerBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      {showDataActive.value === "Belum Diverifiasi" ? (
                        <Search 
                          setData={setDataUnverifiedPengguna}
                          getData={getAllUnverifiedPengguna}
                          dataFields={dataFieldsUnverifiedPengguna}
                          sortItem={sortItemUnverifiedPengguna}
                          searchInput={searchInputUnverifiedPengguna}
                          setSearchInput={setSearchInputUnverifiedPengguna}
                          optionsSearchFilterBy={optionsSearchFilterUnverifiedPengguna}
                          searchFilterBy={searchFilterUnverifiedPengguna}
                          setSearchFilterBy={setSearchFilterUnverifiedPengguna}
                          numberPerPage={numberPerPageUnverifiedPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromUnverifiedPengguna}
                          setNumberPerPageTo={setNumberPerPageToUnverifiedPengguna}
                          getTotalData={getTotalAllUnverifiedPengguna}
                          setTotalData={setTotalDataUnverifiedPengguna}
                          setTotalPage={setTotalPageUnverifiedPengguna}
                          setCurrentPage={setCurrentPageUnverifiedPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        /> 
                      ) : (
                        <Search 
                          setData={setDataVerifiedPengguna}
                          getData={getAllVerifiedPengguna}
                          dataFields={dataFieldsVerifiedPengguna}
                          sortItem={sortItemVerifiedPengguna}
                          searchInput={searchInputVerifiedPengguna}
                          setSearchInput={setSearchInputVerifiedPengguna}
                          optionsSearchFilterBy={optionsSearchFilterVerifiedPengguna}
                          searchFilterBy={searchFilterVerifiedPengguna}
                          setSearchFilterBy={setSearchFilterVerifiedPengguna}
                          numberPerPage={numberPerPageVerifiedPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromVerifiedPengguna}
                          setNumberPerPageTo={setNumberPerPageToVerifiedPengguna}
                          getTotalData={getTotalAllVerifiedPengguna}
                          setTotalData={setTotalDataVerifiedPengguna}
                          setTotalPage={setTotalPageVerifiedPengguna}
                          setCurrentPage={setCurrentPageVerifiedPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        /> 
                      )}
                      <ShowDataToggle 
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      {showDataActive.value === "Belum Diverifikasi" ? (
                        <Pagination 
                          setData={setDataUnverifiedPengguna}
                          getData={getAllUnverifiedPengguna}
                          dataFields={dataFieldsUnverifiedPengguna}
                          sortItem={sortItemUnverifiedPengguna}
                          searchInput={searchInputUnverifiedPengguna}
                          searchFilterBy={searchFilterUnverifiedPengguna}
                          optionsNumberPerPage={optionsNumberPerPageUnverifiedPengguna}
                          numberPerPage={numberPerPageUnverifiedPengguna}
                          setNumberPerPage={setNumberPerPageUnverifiedPengguna}
                          numberPerPageFrom={numberPerPageFromUnverifiedPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromUnverifiedPengguna}
                          numberPerPageTo={numberPerPageToUnverifiedPengguna}
                          setNumberPerPageTo={setNumberPerPageToUnverifiedPengguna}
                          totalData={totalDataUnverifiedPengguna}
                          getTotalData={getTotalAllUnverifiedPengguna}
                          setTotalData={setTotalDataUnverifiedPengguna}
                          totalPage={totalPageUnverifiedPengguna}
                          setTotalPage={totalPageUnverifiedPengguna}
                          currentPage={currentPageUnverifiedPengguna}
                          setCurrentPage={setCurrentPageUnverifiedPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      ) : (
                        <Pagination 
                          setData={setDataVerifiedPengguna}
                          getData={getAllVerifiedPengguna}
                          dataFields={dataFieldsVerifiedPengguna}
                          sortItem={sortItemVerifiedPengguna}
                          searchInput={searchInputVerifiedPengguna}
                          searchFilterBy={searchFilterVerifiedPengguna}
                          optionsNumberPerPage={optionsNumberPerPageVerifiedPengguna}
                          numberPerPage={numberPerPageVerifiedPengguna}
                          setNumberPerPage={setNumberPerPageVerifiedPengguna}
                          numberPerPageFrom={numberPerPageFromVerifiedPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromVerifiedPengguna}
                          numberPerPageTo={numberPerPageToVerifiedPengguna}
                          setNumberPerPageTo={setNumberPerPageToVerifiedPengguna}
                          totalData={totalDataVerifiedPengguna}
                          getTotalData={getTotalAllVerifiedPengguna}
                          setTotalData={setTotalDataVerifiedPengguna}
                          totalPage={totalPageVerifiedPengguna}
                          setTotalPage={totalPageVerifiedPengguna}
                          currentPage={currentPageVerifiedPengguna}
                          setCurrentPage={setCurrentPageVerifiedPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      )}
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                {showDataActive.value === "Belum Diverifikasi" ? (
                  <Table 
                    ref={tableWrapperRef}
                    data={dataUnverifiedPengguna}
                    setData={setDataUnverifiedPengguna}
                    getData={getAllUnverifiedPengguna}
                    dataFields={dataFieldsUnverifiedPengguna}
                    sortItem={sortItemUnverifiedPengguna}
                    setSortItem={setSortItemUnverifiedPengguna}
                    searchInput={searchInputUnverifiedPengguna}
                    searchFilterBy={searchFilterUnverifiedPengguna}
                    numberPerPage={numberPerPageUnverifiedPengguna}
                    setNumberPerPageFrom={setNumberPerPageFromUnverifiedPengguna}
                    setNumberPerPageTo={setNumberPerPageToUnverifiedPengguna}
                    totalData={totalDataUnverifiedPengguna}
                    getTotalData={getTotalAllUnverifiedPengguna}
                    setTotalData={setTotalDataUnverifiedPengguna}
                    setTotalPage={setTotalPageUnverifiedPengguna}
                    setCurrentPage={setCurrentPageUnverifiedPengguna}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={handleClickSingleBodyContentByUnverifiedPengguna}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : (
                  <Table 
                    ref={tableWrapperRef}
                    data={dataVerifiedPengguna}
                    setData={setDataVerifiedPengguna}
                    getData={getAllVerifiedPengguna}
                    dataFields={dataFieldsVerifiedPengguna}
                    sortItem={sortItemVerifiedPengguna}
                    setSortItem={setSortItemVerifiedPengguna}
                    searchInput={searchInputVerifiedPengguna}
                    searchFilterBy={searchFilterVerifiedPengguna}
                    numberPerPage={numberPerPageVerifiedPengguna}
                    setNumberPerPageFrom={setNumberPerPageFromVerifiedPengguna}
                    setNumberPerPageTo={setNumberPerPageToVerifiedPengguna}
                    totalData={totalDataVerifiedPengguna}
                    getTotalData={getTotalAllVerifiedPengguna}
                    setTotalData={setTotalDataVerifiedPengguna}
                    setTotalPage={setTotalPageVerifiedPengguna}
                    setCurrentPage={setCurrentPageVerifiedPengguna}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                )}
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
}

export default VerifikasiPengguna;