import React, { useState, useEffect, useRef, useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import { DropzoneDialog } from "material-ui-dropzone";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { DatePicker } from "@material-ui/pickers";
import { UserContext } from "../../../../../../User";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {
  getPenggunaLigaKreasi,
  insertLigaKreasi,
  deleteLigaKreasi,
  updateSertifikatPialaMedali,
  updateFotoPenyerahanPenghargaan,
  updateManuskripKarya
} from "../../../../../../../Services/API/liga_kreasi";
import { dateFormat } from "../../../../../../../Services/Format/date";
import swal from "sweetalert";
import {
  filterOptions,
  getOptionLabel,
  handleChangeCurrencyTextField,
  handleChangeSelectItem,
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleToggleDialogActive,
  handleMouseDownClearTextFieldDate,
  handleOpenDialog,
  handleCloseDialog,
  handleDialogSaveFile,
  handleCloseErrorSnackbar,
  handleFormSubmitAction,
} from "../../../../../../../Services/FormHandler";
import { numericRegExp, urlRegExp, emailRegExp } from "../../../../../../../Services/RegExp";
import { getPengguna } from "../../../../../../../Services/API/pengguna";
import { 
  insertReimburse, 
  insertReimburseDelegasi, 
  insertReimburseDanaSeleksi, 
  insertReimburseDanaLomba 
} from "../../../../../../../Services/API/reimburse";
import {
  uploadSertifikatPialaMedali,
  uploadFotoPenyerahanPenghargaan,
  uploadManuskripKarya,
} from "../../../../../../../Services/API/file";
import { getImgFromUrl } from "../../../../../../../Services/Image";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { rupiahCurrencyFormat } from "../../../../../../../Services/Format/currency";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import { userActive } from "../../../../../../../Stores/User";

function LigaKreasi(props) {
  const userContext = useContext(UserContext);

  const sertifPialaMedaliRef = useRef(null);
  const penyerahanPenghargaanRef = useRef(null);
  const manuSkripKaryaRef = useRef(null);

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);
  const [jenisPerlombaan, setJenisPerlombaan] = useState(null);
  const [anggotaKelompok, setAnggotaKelompok] = useState([]);
  const [bidangPerlombaan, setBidangPerlombaan] = useState(null);
  const [penyelenggaraPerlombaan, setPenyelenggaraPerlombaan] = useState(null);
  const [namaPerlombaan, setNamaPerlombaan] = useState("");
  const [
    isNamaPerlombaanClearActive,
    setIsNamaPerlombaanClearActive,
  ] = useState(false);
  const [tingkatPerlombaan, setTingkatPerlombaan] = useState(null);
  const [rangeNegaraTerlibat, setRangeNegaraTerlibat] = useState(null);
  const [rangeUniversitasTerlibat, setRangeUniversitasTerlibat] = useState(
    null
  );
  const [isJumlahPesertaClearActive, setIsJumlahPesertaClearActive] = useState(
    false
  );
  const [capaianPrestasi, setCapaianPrestasi] = useState(null);
  const [tanggalAwalPelaksanaan, setTanggalAwalPelaksanaan] = useState(null);
  const [
    isTanggalAwalPelaksanaanClearActive,
    setIsTanggalAwalPelaksanaanClearActive,
  ] = useState(false);
  const [
    isTanggalAwalPelaksanaanDialogActive,
    setIsTanggalAwalPelaksanaanDialogActive,
  ] = useState(false);
  const [tanggalAkhirPelaksanaan, setTanggalAkhirPelaksanaan] = useState(null);
  const [
    isTanggalAkhirPelaksanaanClearActive,
    setIsTanggalAkhirPelaksanaanClearActive,
  ] = useState(false);
  const [
    isTanggalAkhirPelaksanaanDialogActive,
    setIsTanggalAkhirPelaksanaanDialogActive,
  ] = useState(false);
  const [urlPenyelenggaraLomba, setUrlPenyelenggaraLomba] = useState("");
  const [
    isUrlPenyelenggaraLombaClearActive,
    setIsUrlPenyelenggaraLombaClearActive,
  ] = useState(false);
  const [sertifPialaMedali, setSertifPialaMedali] = useState(null);
  const [
    isSertifikatPialaMedaliActive,
    setIsSertifikatPialaMedaliActive,
  ] = useState(false);
  const [
    isSertifikatPialaMedaliDialogActive,
    setIsSertifikatPialaMedaliDialogActive,
  ] = useState(false);
  const [penyerahanPenghargaan, setPenyerahanPenghargaan] = useState(null);
  const [
    isPenyerahanPenghargaanDialogActive,
    setIsPenyerahanPenghargaanDialogActive,
  ] = useState(false);
  const [
    isPenyerahanPenghargaanActive,
    setIsPenyerahanPenghargaanActive,
  ] = useState(false);
  const [manuSkripKarya, setManuSkripKarya] = useState(null);
  const [
    isManuSkripKaryaDialogActive,
    setIsManuSkripKaryaDialogActive,
  ] = useState(false);
  const [isManuSkripKaryaActive, setIsManuSkripKaryaActive] = useState(false);

  // Form Reimburse

  const [r_nama, r_setNama] = useState([]);

  const [r_namaLomba, r_setNamaLomba] = useState("");

  const [r_isNamaLombaClearActive, r_setIsNamaLombaClearActive] = useState(false);

  const [r_cabangLomba, r_setCabangLomba] = useState("");

  const [r_isCabangLombaClearActive, r_setIsCabangLombaClearActive] = useState(false);

  const [r_penyelenggaraLomba, r_setPenyelenggaraLomba] = useState("");

  const [r_isPenyelenggaraLombaClearActive, r_setIsPenyelenggaraLombaClearActive] = useState(false);

  const [r_deskripsiLomba, r_setDeskripsiLomba] = useState("");

  const [r_isDeskripsiLombaClearActive, r_setIsDeskripsiLombaClearActive] = useState(false);

  const [r_lokasiLomba, r_setLokasiLomba] = useState("");

  const [r_isLokasiLombaClearActive, r_setIsLokasiLombaClearActive] = useState(false);

  const [r_awalPelaksanaanLomba, r_setAwalPelaksanaanLomba] = useState(null);

  const [r_isAwalPelaksanaanLombaClearActive, r_setIsAwalPelaksanaanLombaClearActive] = useState(false);

  const [r_isAwalPelaksanaanLombaDialogActive, r_setIsAwalPelaksanaanLombaDialogActive] = useState(false);

  const [r_akhirPelaksanaanLomba, r_setAkhirPelaksanaanLomba] = useState(null);

  const [r_isAkhirPelaksanaanLombaClearActive, r_setIsAkhirPelaksanaanLombaClearActive] = useState(false);

  const [r_isAkhirPelaksanaanLombaDialogActive, r_setIsAkhirPelaksanaanLombaDialogActive] = useState(false);

  const [r_tautanResmiLomba, r_setTautanResmiLomba] = useState("");

  const [r_isTautanResmiLombaClearActive, r_setIsTautanResmiLombaClearActive] = useState(false);

  const [r_penanggungJawabSeleksi, r_setPenanggungJawabSeleksi] = useState("");

  const [r_isPenanggungJawabSeleksiClearActive, r_setIsPenanggungJawabSeleksiClearActive] = useState(false);

  const [r_isSeleksiButuhDana, r_setIsSeleksiButuhDana] = useState(null);

  const r_optionsIsSeleksiButuhDana = [
    { value: true, label: "Ya" },
    { value: false, label: "Tidak" }
  ];

  const [r_jenisPengeluaranDanaSeleksi, r_setJenisPengeluaranDanaSeleksi] = useState("");

  const [r_isJenisPengeluaranDanaSeleksiClearActive, r_setIsJenisPengeluaranDanaSeleksiClearActive] = useState(false);

  const [r_totalDanaSeleksi, r_setTotalDanaSeleksi] = useState("");

  const [r_isTotalDanaSeleksiClearActive, r_setIsTotalDanaSeleksiClearActive] = useState(false);

  const [r_rincianDanaSeleksi, r_setRincianDanaSeleksi] = useState([]);
  
  const [r_fasilitasSeleksi, r_setFasilitasSeleksi] = useState("");

  const [r_isFasilitasSeleksiClearActive, r_setIsFasilitasSeleksiClearActive] = useState(false);

  const [r_namaPembimbing, r_setNamaPembimbing] = useState("");

  const [r_isNamaPembimbingClearActive, r_setIsNamaPembimbingClearActive] = useState(false);

  const [r_institusiPembimbing, r_setInstitusiPembimbing] = useState("");

  const [r_isInstitusiPembimbingClearActive, r_setIsInstitusiPembimbingClearActive] = useState(false);

  const [r_kontakPembimbing, r_setKontakPembimbing] = useState("");

  const [r_isKontakPembimbingClearActive, r_setIsKontakPembimbingClearActive] = useState(false);

  const [r_isLombaButuhDana, r_setIsLombaButuhDana] = useState(false);

  const r_optionsIsLombaButuhDana = [
    { value: true, label: "Ya" },
    { value: false, label: "Tidak" }
  ];

  const [r_jenisPengeluaranDanaLomba, r_setJenisPengeluaranDanaLomba] = useState("");

  const [r_isJenisPengeluaranDanaLombaClearActive, r_setIsJenisPengeluaranDanaLombaClearActive] = useState(false);

  const [r_totalDanaLomba, r_setTotalDanaLomba] = useState("");

  const [r_isTotalDanaLombaClearActive, r_setIsTotalDanaLombaClearActive] = useState(false);

  const [r_rincianDanaLomba, r_setRincianDanaLomba] = useState([]);

  const [r_bankTujuan, r_setBankTujuan] = useState("");

  const [r_isBankTujuanClearActive, r_setIsBankTujuanClearActive] = useState(false);

  const [r_nomorRekeningTujuan, r_setNomorRekeningTujuan] = useState("");

  const [r_isNomorRekeningTujuanClearActive, r_setIsNomorRekeningTujuanClearActive] = useState(false);

  const [r_pemilikRekeningTujuan, r_setPemilikRekeningTujuan] = useState("");

  const [r_isPemilikRekeningTujuanClearActive, r_setIsPemilikRekeningTujuanClearActive] = useState(false);

  const [r_namaPenanggungJawab, r_setNamaPenanggungJawab] = useState("");

  const [r_angkatanPenanggungJawab, r_setAngkatanPenanggungJawab] = useState("");

  const [r_npmPenanggungJawab, r_setNPMPenanggungJawab] = useState("");

  const [r_nomorHPPenanggungJawab, r_setNomorHPPenanggungJawab] = useState("");

  const [r_isNomorHPPenanggungJawabClearActive, r_setIsNomorHPPenanggungJawabClearActive] = useState(false);

  const [r_emailPenanggungJawab, r_setEmailPenanggungJawab] = useState("");

  const [r_isEmailPenanggungJawabClearActive, r_setIsEmailPenanggungJawabClearActive] = useState(false);

  const [r_isTambahRincianDanaSeleksiClicked, r_setIsTambahRincianDanaSeleksiClicked] = useState(false);

  const [r_isTambahRincianDanaLombaClicked, r_setIsTambahRincianDanaLombaClicked] = useState(false);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [formActive, setFormActive] = useState("Liga Kreasi");

  const [optionsJenisPerlombaan, setOptionsJenisPerlombaan] = useState([
    { value: "Individu", label: "Individu" },
    { value: "Kelompok", label: "Kelompok" },
  ]);

  const [optionsPengguna, setOptionsPengguna] = useState([]);

  const [optionsBidangPerlombaan, setOptionsBidangPerlombaan] = useState([
    { value: "Olahraga", label: "Olahraga" },
    { value: "Seni", label: "Seni" },
    { value: "Penalaran", label: "Penalaran" },
  ]);

  const [optionsPenyelenggaraLomba, setOptionsPenyelenggaraLomba] = useState([
    { value: "Kemenristekdikti", label: "Kemenristekdikti" },
    { value: "Non-Kemenristekdikti", label: "Non-Kemenristekdikti" },
  ]);

  const [optionsTingkatLomba, setOptionsTingkatLomba] = useState([
    { value: "Internasional", label: "Internasional" },
    { value: "Nasional", label: "Nasional" },
    { value: "Provinsi/Wilayah", label: "Provinsi/Wilayah" },
    { value: "Fakultas/Universitas", label: "Fakultas/Universitas" },
  ]);

  const [optionsRangeNegaraTerlibat, setOptionsRangeNegaraTerlibat] = useState([
    { value: ">= 20 negara", label: ">= 20 negara" },
    { value: "11-19 negara", label: "11-19 negara" },
    { value: "3-10 negara", label: "3-10 negara" },
    { value: "< 3 negara", label: "< 3 negara" },
  ]);

  const [optionsRangeUnivTerlibat, setOptionsRangeUnivTerlibat] = useState([
    { value: ">= 31 universitas", label: ">= 31 universitas" },
    { value: "21-30 universitas", label: "21-30 universitas" },
    { value: "11-20 universitas", label: "11-20 universitas" },
    { value: "< 10 universitas", label: "< 10 universitas" },
  ]);

  const [optionsCapaianPrestasi, setOptionsCapaianPrestasi] = useState([
    { value: "Juara Umum", label: "Juara Umum" },
    { value: "Juara I atau Winner", label: "Juara I atau Winner" },
    { value: "Juara II atau Runner Up I", label: "Juara II atau Runner Up I" },
    {
      value: "Juara III atau Runner Up II",
      label: "Juara III atau Runner Up II",
    },
    {
      value: "Juara Harapan I atau Runner Up III",
      label: "Juara Harapan I atau Runner Up III",
    },
    {
      value: "Pengakuan/Apresiasi (Best Category)",
      label: "Pengakuan/Apresiasi (Best Category)",
    },
    {
      value: "Finalis Lomba",
      label: "Finalis Lomba"
    }
  ]);

  const handleClickOpenFormReimburse = () => {
    const userActive = userContext?.userActive;

    handleResetData();
    setFormActive("Form Reimburse");

    if (userActive) {
      const { id_pengguna, nama_lengkap, angkatan, npm } = userActive;

      const userActiveMapped = {
        value: id_pengguna,
        label: `${nama_lengkap} - ${npm}`,
        nama_lengkap,
        angkatan,
        npm
      };

      const _optionsPengguna = [...optionsPengguna, userActiveMapped]
        .sort((a, b) => a.nama_lengkap.toLowerCase() - b.nama_lengkap.toLowerCase());

      setOptionsPengguna(_optionsPengguna);
    }

    window.scrollTo(0, 0);
  };

  const handleClickOpenFormLigaKreasi = () => {
    const userActive = userContext?.userActive;

    handleResetData();
    setFormActive("Liga Kreasi");

    if (userActive) {
      const { id_pengguna } = userActive;

      const _optionsPengguna = optionsPengguna.filter((item) => item.id_pengguna !== id_pengguna);
      
      setOptionsPengguna(_optionsPengguna);
    }

    window.scrollTo(0, 0);
  };

  const handleClickTambahRincianDanaSeleksi = () => {
    r_setIsTambahRincianDanaSeleksiClicked(true);
  };

  const handleClickDeleteRincianDanaSeleksi = (target) => {
    r_setRincianDanaSeleksi(r_rincianDanaSeleksi.filter((item) => item.jenis_pengeluaran !== target));
  };

  const handleClickTambahRincianDanaLomba = () => {
    r_setIsTambahRincianDanaLombaClicked(true);
  };

  const handleClickDeleteRincianDanaLomba = (target) => {
    r_setRincianDanaLomba(r_rincianDanaLomba.filter(item => item.jenis_pengeluaran !== target));
  };

  const handleResetData = () => {
    setJenisPerlombaan(null);
    setAnggotaKelompok([]);
    setBidangPerlombaan(null);
    setPenyelenggaraPerlombaan(null);
    setNamaPerlombaan("");
    setTingkatPerlombaan(null);
    setRangeNegaraTerlibat(null);
    setRangeUniversitasTerlibat(null);
    setCapaianPrestasi(null);
    setTanggalAwalPelaksanaan(null);
    setTanggalAkhirPelaksanaan(null);
    setUrlPenyelenggaraLomba("");
    setSertifPialaMedali(null);
    setPenyerahanPenghargaan(null);
    setManuSkripKarya(null);
    
    r_setNama([]);
    r_setNamaLomba("");
    r_setCabangLomba("");
    r_setPenyelenggaraLomba("");
    r_setDeskripsiLomba("");
    r_setLokasiLomba("");
    r_setAwalPelaksanaanLomba(null);
    r_setAkhirPelaksanaanLomba(null);
    r_setTautanResmiLomba("");
    r_setPenanggungJawabSeleksi("");
    r_setIsSeleksiButuhDana(null);
    r_setJenisPengeluaranDanaSeleksi("");
    r_setTotalDanaSeleksi("");
    r_setRincianDanaSeleksi([]);
    r_setFasilitasSeleksi("");
    r_setNamaPembimbing("");
    r_setInstitusiPembimbing("");
    r_setKontakPembimbing("");
    r_setIsLombaButuhDana(null);
    r_setJenisPengeluaranDanaLomba("");
    r_setTotalDanaLomba("");
    r_setRincianDanaLomba([]);
    r_setBankTujuan("");
    r_setNomorRekeningTujuan("");
    r_setPemilikRekeningTujuan("");
  };

  const getErrorList = (target) => {
    const _errorList = [];

    if (target.includes("jenisPerlombaan")) {
      if (!jenisPerlombaan) {
        _errorList.push({
          type: "required",
          message: "Jenis Perlombaan wajib dipilih.",
        });
      }
    }

    if (target.includes("bidangPerlombaan")) {
      if (!bidangPerlombaan) {
        _errorList.push({
          type: "Bidang Perlombaan",
          message: "Bidang Perlombaan wajib dipilih.",
        });
      }
    }

    if (target.includes("penyelenggaraPerlombaan")) {
      if (!penyelenggaraPerlombaan) {
        _errorList.push({
          type: "required",
          message: "Penyelenggara Perlombaan wajib dipilih.",
        });
      }
    }

    if (target.includes("namaPerlombaan")) {
      if (!namaPerlombaan) {
        _errorList.push({
          type: "required",
          message: "Nama Perlombaan wajib diisi.",
        });
      }
    }

    if (target.includes("tingkatPerlombaan")) {
      if (!tingkatPerlombaan) {
        _errorList.push({
          type: "required",
          message: "Tingkat Perlombaan wajib dipilh.",
        });
      }
    }

    if (target.includes("rangeNegaraTerlibat")) {
      if (!rangeNegaraTerlibat) {
        _errorList.push({
          type: "required",
          message: "Range Negara Yang Terlibat wajib diisi.",
        });
      }
    }

    if (target.includes("rangeUniversitasTerlibat")) {
      if (!rangeUniversitasTerlibat) {
        _errorList.push({
          type: "required",
          message: "Range Universitas Yang Terlibat wajib diisi.",
        });
      }
    }

    if (target.includes("capaianPrestasi")) {
      if (!capaianPrestasi) {
        _errorList.push({
          type: "required",
          message: "Capaian Prestasi wajib dipilih.",
        });
      }
    }

    if (target.includes("tanggalAwalPelaksanaanLomba")) {
      if (!tanggalAwalPelaksanaan) {
        _errorList.push({
          type: "required",
          message: "Tanggal Awal Pelaksanaan Lomba wajib dipilih.",
        });
      }
    }

    if (target.includes("tanggalAkhirPelaksanaanLomba")) {
      if (!tanggalAkhirPelaksanaan) {
        _errorList.push({
          type: "required",
          message: "Tanggal Akhir Pelaksanaan Lomba wajib dipilih.",
        });
      }
    }

    if (target.includes("urlLamanPenyelenggaraLomba")) {
      if (urlPenyelenggaraLomba) {
        if (!urlRegExp.test(urlPenyelenggaraLomba)) {
          _errorList.push({
            type: "pattern",
            message: "Format URL Laman Penyelenggara tidak valid.",
          });
        }
      }
    }

    if (target.includes("capaianPrestasi")) {
      if (!capaianPrestasi) {
        _errorList.push({
          type: "required",
          message: "Capaian Prestasi wajib dipilih.",
        });
      }
    }

    if (target.includes("sertifikatPialaMedali")) {
      if (!sertifPialaMedali) {
        _errorList.push({
          type: "required",
          message: "Sertifikat/Piala/Medali wajib diupload.",
        });
      }
    }

    if (target.includes("fotoPenyerahanPenghargaan")) {
      if (!penyerahanPenghargaan) {
        _errorList.push({
          type: "message",
          message: "Foto Penyerahan Penghargaan wajib diupload.",
        });
      }
    }

    if(target.includes("r_nama")) {
      if(!r_nama || (r_nama && r_nama.length === 0)) {
        _errorList.push({
          type: "required",
          message: "Mahasiswa Terlibat wajib dipilih"
        });
      }
    }

    if(target.includes("r_namaLomba")) {
      if(!r_namaLomba) {
        _errorList.push({
          type: "required",
          message: "Nama Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_penyelenggaraLomba")) {
      if(!r_penyelenggaraLomba) {
        _errorList.push({
          type: "required",
          message: "Penyelenggara Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_deskripsiLomba")) {
      if(!r_deskripsiLomba) {
        _errorList.push({
          type: "required",
          message: "Deskripsi Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_lokasiLomba")) {
      if(!r_lokasiLomba) {
        _errorList.push({
          type: "required",
          message: "Lokasi Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_awalPelaksanaanLomba")) {
      if(!r_awalPelaksanaanLomba) {
        _errorList.push({
          type: "required",
          message: "Awal Pelaksanaan Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_akhirPelaksanaanLomba")) {
      if(!r_akhirPelaksanaanLomba) {
        _errorList.push({
          type: "required",
          message: "Akhir Pelaksanaan Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_tautanResmiLomba")) {
      if(r_tautanResmiLomba) {
        if (!urlRegExp.test(r_tautanResmiLomba)) {
          _errorList.push({
            type: "pattern",
            message: "Format Tautan Resmi Lomba tidak valid.",
          });
        }
      }
    }

    if(target.includes("r_isSeleksiButuhDana")) {
      if(!r_isSeleksiButuhDana) {
        _errorList.push({
          type: "required",
          message: "Dibutuhkan atau tidaknya dana seleksi wajib dipilih."
        });
      }
    }

    if(target.includes("r_jenisPengeluaranDanaSeleksi")) {
      if(!r_jenisPengeluaranDanaSeleksi) {
        _errorList.push({
          type: "required",
          message: "Jenis Pengeluaran Dana Seleksi wajib diisi."
        });
      }
    }

    if(target.includes("r_totalDanaSeleksi")) {
      if(!r_totalDanaSeleksi) {
        _errorList.push({
          type: "required",
          message: "Total Dana Seleksi wajib diisi."
        });
      }
    }

    if(target.includes("r_rincianDanaSeleksi")) {
      if(!r_rincianDanaSeleksi || (r_rincianDanaSeleksi && r_rincianDanaSeleksi.length === 0)) {
        _errorList.push({
          type: "required",
          message: "Rincian Dana Seleksi masih kosong."
        });
      }
    }

    if(target.includes("r_isLombaButuhDana")) {
      if(!r_isLombaButuhDana) {
        _errorList.push({
          type: "required",
          message: "Dibutuhkan atau tidaknya dana lomba wajib dipilih."
        });
      } 
    }

    if(target.includes("r_jenisPengeluaranDanaLomba")) {
      if(!r_jenisPengeluaranDanaLomba) {
        _errorList.push({
          type: "required",
          message: "Jenis Pengeluaran Dana Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_totalDanaLomba")) {
      if(!r_totalDanaLomba) {
        _errorList.push({
          type: "required",
          message: "Total Dana Lomba wajib diisi."
        });
      }
    }

    if(target.includes("r_rincianDanaLomba")) {
      if(!r_rincianDanaLomba || (r_rincianDanaLomba && r_rincianDanaLomba.length === 0)) {
        _errorList.push({
          type: "required",
          message: "Rincian Dana Lomba masing kosong."
        });
      }
    }

    if(target.includes("r_bankTujuan")) {
      if(!r_bankTujuan) {
        _errorList.push({
          type: "required",
          message: "Bank Tujuan wajib diisi."
        });
      }
    }

    if(target.includes("r_nomorRekeningTujuan")) {
      if(!r_nomorRekeningTujuan) {
        _errorList.push({
          type: "required",
          message: "Nomor Rekening Tujuan wajib diisi."
        });
      } else {
        if (!numericRegExp.test(r_nomorRekeningTujuan)) {
          _errorList.push({
            type: "pattern",
            message: "Nomor Rekening Tujuan wajib berupa angka.",
          });
        }
      }
    }

    if(target.includes("r_pemilikRekeningTujuan")) {
      if(!r_pemilikRekeningTujuan) {
        _errorList.push({
          type: "required",
          message: "Pemilik Rekening Tujuan wajib diisi."
        });
      }
    }

    if(target.includes("r_nomorHPPenanggungJawab")) {
      if(!r_nomorHPPenanggungJawab) {
        _errorList.push({
          type: "required",
          message: "Nomor HP Penanggung Jawab wajib diisi."
        });
      } else {
        if(!numericRegExp.test(r_nomorHPPenanggungJawab)) {
          _errorList.push({
            type: "pattern",
            message: "Nomor HP Penanggung Jawab wajib berupa angka."
          });
        }
      }
    } 

    if(target.includes("r_emailPenanggungJawab")) {
      if(!r_emailPenanggungJawab) {
        _errorList.push({
          type: "required",
          message: "Email Penanggung Jawab wajib diisi."
        });
      } else {
        if(!emailRegExp.test(r_emailPenanggungJawab)) {
          _errorList.push({
            type: "pattern",
            message: "Format Email Penanggung Jawab tidak valid."
          });
        }
      }
    }

    return _errorList;
  };

  const generateReimbursePDF = async () => {
    const doc = new jsPDF("p", "pt", "a4");
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    let yCurrent = 180;

    const [reimburseHeader, ttdKetuaBEM] = await Promise.all([
      getImgFromUrl(
        require("../../../../../../../Assets/Miscellaneous/header-reimburse.png")
      ),
      getImgFromUrl(
        require("../../../../../../../Assets/Miscellaneous/ttd-ketua-bem.jpeg")
      )
    ]);

    const namaKetuaBEM = "Putri Amadea Gunawan";

    const validateContentHeight = (contentHeight) => {
      if(contentHeight > pageHeight) {
        doc.addPage();
        yCurrent = 0;
    
        doc.addImage(reimburseHeader, "jpg", 17, 20, pageWidth - 40, 80);
    
        doc.line(30, 110, pageWidth - 30, 110);

        yCurrent = 150;
      }
    };

    doc.addImage(reimburseHeader, "jpg", 17, 20, pageWidth - 40, 80);

    doc.line(30, 110, pageWidth - 30, 110);

    doc.setFontSize(15);
    doc.text(
      "Proposal Pengajuan Pengembalian Dana Delegasi IKM FKUI",
      pageWidth / 2,
      140,
      null,
      null,
      "center"
    );

    doc.setFontSize(12);

    doc.text(
      "A. INFORMASI DELEGASI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent = 210;

    let mahasiswaNumber = 1;

    if (r_nama && r_nama.length > 0) {
      r_nama.forEach((item) => {
        doc.text(
          mahasiswaNumber.toString() + ".",
          30,
          yCurrent,
          null,
          null,
          "left"
        );

        doc.text(
          `Nama           : ${item.nama_lengkap}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 20;
        
        doc.text(
          `Angkatan     : ${item.angkatan}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 20;

        doc.text(
          `NPM            : ${item.npm}`,
          65,
          yCurrent,
          null,
          null,
          "left"
        );

        yCurrent += 30;

        validateContentHeight(yCurrent + 44);

        mahasiswaNumber++;
      });
    } else {
      doc.text("Tidak terdefinisi", 30, yCurrent, null, null, "left");

      yCurrent += 20;
    }

    yCurrent += 14;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "B. INFORMASI PERLOMBAAN",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama Lomba        : ${r_namaLomba ? r_namaLomba : "Tidak tedefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Cabang                 : ${r_cabangLomba ? r_cabangLomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Penyelenggara     : ${r_penyelenggaraLomba ? r_penyelenggaraLomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "Deskripsi              :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    if(r_deskripsiLomba.length === 0) {
      doc.text(
        "Tidak terdefinisi",
        135,
        yCurrent,
        null,
        null,
        "left"
      );
    }

    let splitDeskripsiLomba = doc.splitTextToSize(r_deskripsiLomba.replace(/\n/g, " "), 430);

    doc.text(135, yCurrent, splitDeskripsiLomba);

    yCurrent += (19 * splitDeskripsiLomba.length);

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Lokasi                   : ${r_lokasiLomba ? r_lokasiLomba : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    let temp_awalPelaksanaanLomba = r_awalPelaksanaanLomba ? dateFormat(new Date(r_awalPelaksanaanLomba), "dd-MM-yyyy") : r_awalPelaksanaanLomba;
    let temp_akhirPelaksanaanLomba = r_akhirPelaksanaanLomba ? dateFormat(new Date(r_akhirPelaksanaanLomba), "dd-MM-yyyy") : r_akhirPelaksanaanLomba;

    doc.text(
      `Waktu                   : ${temp_awalPelaksanaanLomba && temp_akhirPelaksanaanLomba ? temp_awalPelaksanaanLomba === temp_akhirPelaksanaanLomba ? temp_awalPelaksanaanLomba : (temp_awalPelaksanaanLomba + " hingga " + temp_akhirPelaksanaanLomba) : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "Tautan Resmi       :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    if(r_tautanResmiLomba) {
      doc.textWithLink(
        r_tautanResmiLomba,
        134,
        yCurrent,
        { url: r_tautanResmiLomba }
      );
    } else {
      doc.text(
        "Tidak terdefinisi",
        134,
        yCurrent,
        null,
        null,
        "left"
      );
    }

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "C. INFORMASI SELEKSI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Penanggung Jawab       : ${r_penanggungJawabSeleksi ? r_penanggungJawabSeleksi : "Tidak ada penanggung jawab"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(r_rincianDanaSeleksi.length > 0 ? 21 * (r_rincianDanaSeleksi.length + 3) + yCurrent + 54 : 63 + yCurrent + 54);

    doc.text(
      "Rincian Dana Seleksi     :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 10;

    validateContentHeight(yCurrent + 44);

    const headTableDanaSeleksi = [
      [
        "Jenis Pengeluaran",
        "Total Dana"
      ]
    ];

    const bodyTableDanaSeleksi = 
      r_rincianDanaSeleksi.length > 0 ? r_rincianDanaSeleksi.map(item => {
        return [
          item.jenis_pengeluaran,
          rupiahCurrencyFormat(item.total_dana)
        ]
      }).concat([
        [
          "",
          rupiahCurrencyFormat(
            r_rincianDanaSeleksi.map(item => +item.total_dana).reduce(
              (acc, cur) => acc + cur, 0
            ).toString()
          )
        ]
      ]) : [
      ["Tidak Butuh Dana", "Rp 0,00"]
    ];

    doc.autoTable({
      theme: "grid",
      head: headTableDanaSeleksi,
      headStyles: { fillColor: [130, 130, 130] },
      bodyStyles: { fillColor: [250, 250, 250] },
      body: bodyTableDanaSeleksi,
      startY: yCurrent,
      startX: 0
    });

    yCurrent += r_rincianDanaSeleksi.length > 0 ? 21 * (r_rincianDanaSeleksi.length + 3) : 63;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Fasilitas Seleksi             : ${r_fasilitasSeleksi ? r_fasilitasSeleksi : "Tidak ada fasilitas"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "D. INFORMASI PEMBIMBING",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama                             : ${!r_namaPembimbing ? "Tidak ada pembimbing" : r_namaPembimbing}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Institusi                          : ${!r_namaPembimbing && !r_institusiPembimbing && !r_kontakPembimbing ? "Tidak ada pembimbing" : r_institusiPembimbing ? r_institusiPembimbing : "Tidak ada institusi pembimbing"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Kontak                           : ${!r_namaPembimbing && !r_institusiPembimbing && !r_kontakPembimbing ? "Tidak ada pembimbing" : r_kontakPembimbing ? r_kontakPembimbing : "Tidak ada kontak pembimbing"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "E. INFORMASI DANA",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(r_rincianDanaLomba.length > 0 ? 21 * (r_rincianDanaLomba.length + 3) + yCurrent + 54 : 63 + yCurrent + 54);

    doc.text(
      "Rincian Dana Lomba      :",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 10;

    validateContentHeight(yCurrent + 44);

    const headTableDanaLomba = [
      [
        "Jenis Pengeluaran",
        "Total Dana"
      ]
    ];

    const bodyTableDanaLomba = 
      r_rincianDanaLomba.length > 0 ? r_rincianDanaLomba.map(item => {
        return [
          item.jenis_pengeluaran,
          rupiahCurrencyFormat(item.total_dana)
        ]
      }).concat([
        [
          "",
          rupiahCurrencyFormat(
            r_rincianDanaLomba.map(item => +item.total_dana).reduce(
              (acc, cur) => acc + cur, 0
            ).toString()
          )
        ]
      ]) : [
      ["Tidak Butuh Dana", "Rp 0,00"]
    ];

    doc.autoTable({
      theme: "grid",
      head: headTableDanaLomba,
      headStyles: { fillColor: [130, 130, 130] },
      bodyStyles: { fillColor: [250, 250, 250] },
      body: bodyTableDanaLomba,
      startY: yCurrent,
      startX: 0
    });

    yCurrent += r_rincianDanaLomba.length > 0 ? 21 * (r_rincianDanaLomba.length + 3) : 63;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Total Dana (Seleksi + Lomba)   : ${
        rupiahCurrencyFormat(
          (r_rincianDanaSeleksi.map(item => +item.total_dana).reduce(
            (acc, cur) => acc + cur, 0
          ) + r_rincianDanaLomba.map(item => +item.total_dana).reduce(
            (acc, cur) => acc + cur, 0
          )).toString()
        )
      }`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Bank Tujuan                               : ${r_bankTujuan ? r_bankTujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nomor Rekening                        : ${r_nomorRekeningTujuan ? r_nomorRekeningTujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama Pemilik Rekening             : ${r_pemilikRekeningTujuan ? r_pemilikRekeningTujuan : "Tidak terdefinisi"}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 44;

    validateContentHeight(yCurrent + 44);

    doc.text(
      "F. PENANGGUNG JAWAB DELEGASI",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 30;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nama                      : ${r_namaPenanggungJawab ? r_namaPenanggungJawab : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Angkatan                 : ${r_angkatanPenanggungJawab ? r_angkatanPenanggungJawab : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `NPM                        : ${r_npmPenanggungJawab ? r_npmPenanggungJawab : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Nomor HP               : ${r_nomorHPPenanggungJawab ? r_nomorHPPenanggungJawab : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 20;

    validateContentHeight(yCurrent + 44);

    doc.text(
      `Email                       : ${r_emailPenanggungJawab ? r_emailPenanggungJawab : ""}`,
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    yCurrent += 40;

    validateContentHeight(yCurrent + 44);

    let splitPernyataanPenanggungJawab = doc.splitTextToSize("Saya selaku penganggung jawab delegasi menyatakan bahwa data yang saya berikan pada proposal delegasi ini benar. Saya juga menyatakan bahwa nomor rekening yang tertera sesuai dengan nama pemilik rekening dan merupakan rekening pribadi saya. Saya mengetahui bahwa permintaan pengembalian dana delegasi ini dapat ditolak apabila data yang saya isikan tidak sesuai.".replace(/\n/g, " "), 540);

    doc.text(30, yCurrent, splitPernyataanPenanggungJawab);

    yCurrent += (20 * splitPernyataanPenanggungJawab.length);

    validateContentHeight(yCurrent + 200);

    doc.text(
      "Badan Eksekutif Mahasiswa",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 15;

    doc.text(
      "Ikatan Keluarga Mahasiswa",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 15;

    doc.text(
      "Fakultas Kedokteran Universitas Indonesia",
      pageWidth / 2,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 20;

    doc.addImage(ttdKetuaBEM, "jpg", 50 + Math.ceil(namaKetuaBEM.length / 2), yCurrent, 100, 50);

    yCurrent += 65;

    doc.text(
      namaKetuaBEM,
      namaKetuaBEM.length + 90,
      yCurrent,
      null,
      null,
      "center"
    );

    doc.text(
      r_namaPenanggungJawab ? r_namaPenanggungJawab : "Anonymous",
      pageWidth - 131,
      yCurrent,
      null,
      null,
      "center"
    );

    yCurrent += 17;

    doc.setFontSize(10);
    doc.setTextColor(80, 80, 80);

    doc.text(
      "KETUA",
      namaKetuaBEM.length + 90,
      yCurrent,
      null,
      null,
      "center"
    );

    doc.text(
      "PENANGGUNG JAWAB DELEGASI",
      pageWidth - 50,
      yCurrent,
      null,
      null,
      "right"
    );

    yCurrent += 44;

    doc.text(
      "",
      30,
      yCurrent,
      null,
      null,
      "left"
    );

    doc.save(`Proposal Reimburse.pdf`);
  };

  useDidMountEffect(async () => {
    const data = props.dataActive;

    if(typeof data === "object" && data !== null) {
      setJenisPerlombaan(
        optionsJenisPerlombaan.find((item) => item.value === data.jenis_perlombaan)
      );

      setBidangPerlombaan(
        optionsBidangPerlombaan.find((item) => item.value === data.bidang_perlombaan)
      );

      setPenyelenggaraPerlombaan(
        optionsPenyelenggaraLomba.find((item) => item.value === data.penyelenggara_perlombaan)
      );

      setNamaPerlombaan(data.nama_perlombaan);
      
      setTingkatPerlombaan(
        optionsTingkatLomba.find((item) => item.value === data.tingkat_perlombaan)
      );
      
      switch(data.tingkat_perlombaan) {
        case "Internasional":
          setRangeNegaraTerlibat(
            optionsRangeNegaraTerlibat.find((item) => item.value === data.range_negara_terlibat)
          );
        break;
        case "Nasional":
        case "Provinsi/Wilayah":
          setRangeUniversitasTerlibat(
            optionsRangeUnivTerlibat.find((item) => item.value === data.range_universitas_terlibat)
          );
        break;
      }

      setCapaianPrestasi(
        optionsCapaianPrestasi.find((item) => item.value === data.capaian_prestasi)
      );

      setTanggalAwalPelaksanaan(data.awal_pelaksanaan);

      setTanggalAkhirPelaksanaan(data.akhir_pelaksanaan);

      setUrlPenyelenggaraLomba(data.url_penyelenggara);

      setSertifPialaMedali(data.sertifikat_piala_medali);

      setPenyerahanPenghargaan(data.foto_penyerahan_penghargaan);

      setManuSkripKarya(data.manuskrip_karya);
      
      if(data.jenis_perlombaan === "Kelompok") {
        const _getPenggunaLigaKreasi = await getPenggunaLigaKreasi(data.id_liga_kreasi);

        setAnggotaKelompok(_getPenggunaLigaKreasi.data.filter((item) => {
          return item.id_pengguna !== userContext.userActive.id_pengguna;
        }).map((item) => {
          return optionsPengguna.find((x) => x.value === item.id_pengguna);
        }));
      }
    }
  }, [props.dataActive]);

  useEffect(() => {
    (async () => {
      const pengguna = await getPengguna({
        fields: ["id_pengguna", "nama_lengkap", "npm", "angkatan"],
        search: null,
        order: {
          by: "nama_lengkap",
          mode: "ASC",
        },
        offset: null,
        limit: null,
      });

      const idPenggunaUserActive = userContext?.userActive?.id_pengguna;

      if (pengguna && pengguna.data) {
        setOptionsPengguna(
          pengguna.data.filter((item) => item.id_pengguna !== idPenggunaUserActive).map((element) => ({
            value: element.id_pengguna,
            label: `${element.nama_lengkap} - ${element.npm}`,
            nama_lengkap: element.nama_lengkap,
            angkatan: element.angkatan,
            npm: element.npm
          }))
        );
        r_setNamaPenanggungJawab(userContext.userActive.nama_lengkap);
        r_setAngkatanPenanggungJawab(userContext.userActive.angkatan);
        r_setNPMPenanggungJawab(userContext.userActive.npm);
        r_setNomorHPPenanggungJawab(userContext.userActive.noHP);
        r_setEmailPenanggungJawab(userContext.userActive.email);
      }
    })();
  }, []);

  useDidMountEffect(() => {
    if(r_isTambahRincianDanaSeleksiClicked) {
      setErrorList(getErrorList(["r_jenisPengeluaranDanaSeleksi", "r_totalDanaSeleksi"]));
    }
  }, [r_isTambahRincianDanaSeleksiClicked]);

  useDidMountEffect(() => {
    if(r_isTambahRincianDanaLombaClicked) {
      setErrorList(getErrorList(["r_jenisPengeluaranDanaLomba", "r_totalDanaLomba"]));
    }
  }, [r_isTambahRincianDanaLombaClicked]);

  useDidMountEffect(() => {
    setIsNamaPerlombaanClearActive(namaPerlombaan ? true : false);
  }, [namaPerlombaan]);

  useDidMountEffect(() => {
    setIsUrlPenyelenggaraLombaClearActive(urlPenyelenggaraLomba ? true : false);
  }, [urlPenyelenggaraLomba]);

  useDidMountEffect(() => {
    r_setIsNamaLombaClearActive(r_namaLomba ? true : false);
  }, [r_namaLomba]);

  useDidMountEffect(() => {
    r_setIsCabangLombaClearActive(r_cabangLomba ? true : false);
  }, [r_cabangLomba]);

  useDidMountEffect(() => {
    r_setIsPenyelenggaraLombaClearActive(r_penyelenggaraLomba ? true : false);
  }, [r_penyelenggaraLomba]);

  useDidMountEffect(() => {
    r_setIsDeskripsiLombaClearActive(r_deskripsiLomba ? true : false);
  }, [r_deskripsiLomba]);

  useDidMountEffect(() => {
    r_setIsLokasiLombaClearActive(r_lokasiLomba ? true : false);
  }, [r_lokasiLomba]);

  useDidMountEffect(() => {
    r_setIsTautanResmiLombaClearActive(r_tautanResmiLomba ? true : false);
  }, [r_tautanResmiLomba]);

  useDidMountEffect(() => {
    r_setIsPenanggungJawabSeleksiClearActive(r_penanggungJawabSeleksi ? true : false);
  }, [r_penanggungJawabSeleksi]);

  useDidMountEffect(() => {
    r_setIsJenisPengeluaranDanaSeleksiClearActive(r_jenisPengeluaranDanaSeleksi ? true : false);
  }, [r_jenisPengeluaranDanaSeleksi]);

  useDidMountEffect(() => {
    r_setIsTotalDanaSeleksiClearActive(r_totalDanaSeleksi ? true : false);
  }, [r_totalDanaSeleksi]);

  useDidMountEffect(() => {
    r_setIsFasilitasSeleksiClearActive(r_fasilitasSeleksi ? true : false);
  }, [r_fasilitasSeleksi]);

  useDidMountEffect(() => {
    r_setIsNamaPembimbingClearActive(r_namaPembimbing ? true : false);
  }, [r_namaPembimbing]);

  useDidMountEffect(() => {
    r_setIsInstitusiPembimbingClearActive(r_institusiPembimbing ? true : false);
  }, [r_institusiPembimbing]);

  useDidMountEffect(() => {
    r_setIsKontakPembimbingClearActive(r_kontakPembimbing ? true : false);
  }, [r_kontakPembimbing]);

  useDidMountEffect(() => {
    r_setIsJenisPengeluaranDanaLombaClearActive(r_jenisPengeluaranDanaLomba ? true : false);
  }, [r_jenisPengeluaranDanaLomba]);

  useDidMountEffect(() => {
    r_setIsTotalDanaLombaClearActive(r_totalDanaLomba ? true : false);
  }, [r_totalDanaLomba]);

  useDidMountEffect(() => {
    r_setIsBankTujuanClearActive(r_bankTujuan ? true : false);
  }, [r_bankTujuan]);

  useDidMountEffect(() => {
    r_setIsNomorRekeningTujuanClearActive(r_nomorRekeningTujuan ? true : false);
  }, [r_nomorRekeningTujuan]);

  useDidMountEffect(() => {
    r_setIsPemilikRekeningTujuanClearActive(r_pemilikRekeningTujuan ? true : false);
  }, [r_pemilikRekeningTujuan]);

  useEffect(() => {
    setIsErrorSnackbarActive(errorList ? true : false);

    if (isFormSubmitted) {
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsButtonActionLoading(true);

          if (formActive === "Liga Kreasi") {
            const sertifikatPialaMedaliFormData = new FormData();
            const penyerahanPenghargaanFormData = new FormData();
            const manuskripKaryaFormData = new FormData();

            const dataLigaKreasi = {
              jenis_perlombaan: jenisPerlombaan.value,
              anggota_kelompok:
                anggotaKelompok && anggotaKelompok.length > 0
                  ? anggotaKelompok
                  : null,
              bidang_perlombaan: bidangPerlombaan.value,
              penyelenggara_perlombaan: penyelenggaraPerlombaan.value,
              nama_perlombaan: namaPerlombaan,
              tingkat_perlombaan: tingkatPerlombaan.value,
              range_negara_terlibat: rangeNegaraTerlibat
                ? rangeNegaraTerlibat.value
                : null,
              range_universitas_terlibat: rangeUniversitasTerlibat
                ? rangeUniversitasTerlibat.value
                : null,
              capaian_prestasi: capaianPrestasi.value,
              awal_pelaksanaan: dateFormat(
                new Date(tanggalAwalPelaksanaan),
                "yyyy-MM-dd"
              ),
              akhir_pelaksanaan: dateFormat(
                new Date(tanggalAkhirPelaksanaan),
                "yyyy-MM-dd"
              ),
              url_penyelenggara: urlPenyelenggaraLomba,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
              id_pengguna: userContext.userActive.id_pengguna,
            };

            if (sertifPialaMedali) {
              sertifikatPialaMedaliFormData.append(
                "single_file",
                sertifPialaMedali
              );
            }

            if (penyerahanPenghargaan) {
              penyerahanPenghargaanFormData.append(
                "single_file",
                penyerahanPenghargaan
              );
            }

            if (manuSkripKarya) {
              manuskripKaryaFormData.append("single_file", manuSkripKarya);
            }

            if(props.actionType 
                && props.actionType === "Edit" 
                && props.dataActive.id_liga_kreasi
            ) {
              const _deleteLigaKreasi = await deleteLigaKreasi([ 
                props.dataActive.id_liga_kreasi
              ]);
            }

            const [
              _uploadSertifikatPialaMedali,
              _uploadFotoPenyerahanPenghargaan,
              _uploadManuskripKarya,
              _insertLigaKreasi,
            ] = await Promise.allSettled([
              uploadSertifikatPialaMedali(sertifikatPialaMedaliFormData),
              uploadFotoPenyerahanPenghargaan(penyerahanPenghargaanFormData),
              uploadManuskripKarya(manuskripKaryaFormData),
              insertLigaKreasi(dataLigaKreasi),
            ]);

            if (
              _uploadSertifikatPialaMedali.value.success &&
              _uploadFotoPenyerahanPenghargaan.value.success &&
              _uploadManuskripKarya.value.success &&
              _insertLigaKreasi.value.success
            ) {
              const [
                _updateSertifikatPialaMedali,
                _updateFotoPenyerahanPenghargaan,
                _updateManuskripKarya,
              ] = await Promise.allSettled([
                updateSertifikatPialaMedali({
                  sertifikat_piala_medali: _uploadSertifikatPialaMedali.value
                    .data
                    ? _uploadSertifikatPialaMedali.value.data
                    : typeof sertifPialaMedali === "string"
                      ? sertifPialaMedali
                      : null,
                  id_liga_kreasi: _insertLigaKreasi.value.id,
                }),
                updateFotoPenyerahanPenghargaan({
                  foto_penyerahan_penghargaan: _uploadFotoPenyerahanPenghargaan
                    .value.data
                    ? _uploadFotoPenyerahanPenghargaan.value.data
                    : typeof penyerahanPenghargaan === "string"
                      ? penyerahanPenghargaan
                      : null,
                  id_liga_kreasi: _insertLigaKreasi.value.id,
                }),
                updateManuskripKarya({
                  manuskrip_karya: _uploadManuskripKarya.value.data
                    ? _uploadManuskripKarya.value.data
                    : typeof manuSkripKarya === "string"
                      ? manuSkripKarya
                      : null,
                  id_liga_kreasi: _insertLigaKreasi.value.id,
                }),
              ]);

              if (
                _updateSertifikatPialaMedali.value.success &&
                _updateFotoPenyerahanPenghargaan.value.success &&
                _updateManuskripKarya.value.success
              ) {
                swal("Sukses!", "Data berhasil terkirim.", "success").then(() => {
                  if(props.actionType === "Edit" && props.success) {
                    props.success({
                      id_liga_kreasi: _insertLigaKreasi.value.id,
                      akhir_pelaksanaan: tanggalAkhirPelaksanaan,
                      awal_pelaksanaan: tanggalAwalPelaksanaan,
                      bidang_perlombaan: bidangPerlombaan
                        ? bidangPerlombaan.value 
                        : null,
                      capaian_prestasi: capaianPrestasi
                        ? capaianPrestasi.value
                        : null,
                      foto_penyerahan_penghargaan: _uploadFotoPenyerahanPenghargaan.value.data 
                        ? _uploadFotoPenyerahanPenghargaan.value.data
                        : typeof penyerahanPenghargaan === "string"
                          ? penyerahanPenghargaan
                          : null,
                      id_pengguna: userContext.userActive.id_pengguna,
                      is_creator: true,
                      jenis_perlombaan: jenisPerlombaan
                        ? jenisPerlombaan.value 
                        : null,
                      manuskrip_karya: _uploadManuskripKarya.value.data 
                        ? _uploadManuskripKarya.value.data 
                        : typeof manuSkripKarya === "string"
                          ? manuSkripKarya 
                          : null,
                      nama_perlombaan: namaPerlombaan,
                      penyelenggara_perlombaan: penyelenggaraPerlombaan
                        ? penyelenggaraPerlombaan.value
                        : null,
                      range_negara_terlibat: rangeNegaraTerlibat
                        ? rangeNegaraTerlibat.value
                        : null,
                      range_universitas_terlibat: rangeUniversitasTerlibat
                        ? rangeUniversitasTerlibat.value
                        : null,
                      sertifikat_piala_medali: _uploadSertifikatPialaMedali.value.data 
                        ? _uploadSertifikatPialaMedali.value.data
                        : typeof sertifPialaMedali === "string"
                          ? sertifPialaMedali
                          : null,
                      skor: 0,
                      status: "Pending",
                      tanggal_pengajuan: new Date(),
                      tanggal_verifikasi: null,
                      tingkat_perlombaan: tingkatPerlombaan
                        ? tingkatPerlombaan.value
                        : null,
                      url_penyelenggara: urlPenyelenggaraLomba
                    });
                  }
                });
              } else {
                swal("Oops!", "Internal Server Error", "warning");
              }
            } else {
              if (!_uploadSertifikatPialaMedali.value.success) {
                swal(
                  "Oops!",
                  "Sertifikat/Piala/Medali gagal diupload.",
                  "warning"
                );
              } else if (!_uploadFotoPenyerahanPenghargaan.value.success) {
                swal(
                  "Oops!",
                  "Foto Penyerahan Penghargaan gagal diupload.",
                  "warning"
                );
              } else if (!_uploadManuskripKarya.value.success) {
                swal("Oops!", "Manuskrip Karya gagal diupload.", "warning");
              } else if (!_insertLigaKreasi.value.success) {
                swal("Oops!", "Data gagal dikirim.", "warning");
              }
            }
          } else {
            const _insertReimburse = await insertReimburse({
              nama_lomba: r_namaLomba,
              cabang_lomba: r_cabangLomba,
              penyelenggara_lomba: r_penyelenggaraLomba,
              deskripsi_lomba: r_deskripsiLomba,
              lokasi_lomba: r_lokasiLomba,
              awal_pelaksanaan_lomba: dateFormat(new Date(r_awalPelaksanaanLomba), "yyyy-MM-dd"),
              akhir_pelaksanaan_lomba: dateFormat(new Date(r_akhirPelaksanaanLomba), "yyyy-MM-dd"),
              tautan_resmi_lomba: r_tautanResmiLomba,
              penanggung_jawab_seleksi: r_penanggungJawabSeleksi,
              fasilitas_seleksi: r_fasilitasSeleksi,
              nama_pembimbing: r_namaPembimbing,
              institusi_pembimbing: r_institusiPembimbing,
              kontak_pembimbing: r_kontakPembimbing,
              bank_tujuan: r_bankTujuan,
              no_rekening_tujuan: r_nomorRekeningTujuan,
              nama_pemilik_rekening_tujuan: r_pemilikRekeningTujuan,
              id_penanggung_jawab_delegasi: userContext.userActive.id_pengguna,
              no_hp_penanggung_jawab_delegasi: r_nomorHPPenanggungJawab,
              email_penanggung_jawab_delegasi: r_emailPenanggungJawab,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd")
            });

            if(_insertReimburse.success) {
              const [_insertReimburseDelegasi, _insertReimburseDanaSeleksi, _insertReimburseDanaLomba, _generateReimbursePDF] = await Promise.all([
                insertReimburseDelegasi({
                  id_reimburse: _insertReimburse.id,
                  delegasi: r_nama,
                }),
                insertReimburseDanaSeleksi({
                  id_reimburse: _insertReimburse.id,
                  dana_seleksi: r_rincianDanaSeleksi
                }),
                insertReimburseDanaLomba({
                  id_reimburse: _insertReimburse.id,
                  dana_lomba: r_rincianDanaLomba
                }),
                generateReimbursePDF()
              ]);

              if(_insertReimburseDelegasi.success && _insertReimburseDanaSeleksi.success && _insertReimburseDanaLomba.success) {
                swal("Sukses!", "Proposal Reimburse berhasil dibuat.", "success");
              }
            }
          }

          setIsButtonActionLoading(false);
          handleResetData();
        }
      }, 0);
    } else if(r_isTambahRincianDanaSeleksiClicked) {
      r_setIsTambahRincianDanaSeleksiClicked(false);

      if(errorList.length === 0) {
        if(r_rincianDanaSeleksi.find(item => item.jenis_pengeluaran === r_jenisPengeluaranDanaSeleksi)) {
          swal("Oops!", "Jenis Pengeluaran tersebut sudah ada dalam daftar.", "warning");
        } else {
          r_setRincianDanaSeleksi([
            ...r_rincianDanaSeleksi,
            {
              jenis_pengeluaran: r_jenisPengeluaranDanaSeleksi,
              total_dana: r_totalDanaSeleksi
            }
          ]);

          r_setJenisPengeluaranDanaSeleksi("");
          r_setTotalDanaSeleksi("");
        }
      }
    } else if(r_isTambahRincianDanaLombaClicked) {
      r_setIsTambahRincianDanaLombaClicked(false);

      if(errorList.length === 0) {
        if(r_rincianDanaLomba.find(item => item.jenis_pengeluaran === r_jenisPengeluaranDanaLomba)) {
          swal("Oops!", "Jenis Pengeluaran tersebut sudah ada dalam daftar.", "warning");
        } else {
          r_setRincianDanaLomba([
            ...r_rincianDanaLomba,
            {
              jenis_pengeluaran: r_jenisPengeluaranDanaLomba,
              total_dana: r_totalDanaLomba
            }
          ]);

          r_setJenisPengeluaranDanaLomba("");
          r_setTotalDanaLomba("");
        }
      }
    }
  }, [errorList]);

  useEffect(() => {
    if (isFormSubmitted) {
      setErrorList(
        formActive === "Liga Kreasi"
          ? getErrorList(
              [
                "jenisPerlombaan",
                "bidangPerlombaan",
                "penyelenggaraPerlombaan",
                "namaPerlombaan",
                "tingkatPerlombaan",
              ]
                .concat(
                  tingkatPerlombaan &&
                    tingkatPerlombaan.value === "Internasional"
                    ? ["rangeNegaraTerlibat"]
                    : tingkatPerlombaan &&
                      (tingkatPerlombaan.value === "Nasional" ||
                        tingkatPerlombaan.value === "Provinsi/Wilayah")
                    ? ["rangeUniversitasTerlibat"]
                    : []
                )
                .concat([
                  "capaianPrestasi",
                  "tanggalAwalPelaksanaanLomba",
                  "tanggalAkhirPelaksanaanLomba",
                  "urlLamanPenyelenggaraLomba",
                  "sertifikatPialaMedali",
                  "fotoPenyerahanPenghargaan",
                ])
            )
          : getErrorList([
              "r_nama",
              "r_namaLomba",
              "r_penyelenggaraLomba",
              "r_deskripsiLomba",
              "r_lokasiLomba",
              "r_awalPelaksanaanLomba",
              "r_akhirPelaksanaanLomba",
              "r_tautanResmiLomba",
              "r_isSeleksiButuhDana"
            ].concat(
              r_isSeleksiButuhDana && r_isSeleksiButuhDana.value ? [
                "r_rincianDanaSeleksi"
              ] : []
            ).concat([
              "r_isLombaButuhDana"
            ]).concat(
              r_isLombaButuhDana && r_isLombaButuhDana.value ? [
                "r_rincianDanaLomba"
              ] : []
            ).concat([
              "r_bankTujuan",
              "r_nomorRekeningTujuan",
              "r_pemilikRekeningTujuan",
              "r_nomorHPPenanggungJawab",
              "r_emailPenanggungJawab"
            ])
          )
      );
    }
  }, [isFormSubmitted]);

  return (
    <div className="user-page-liga-kreasi">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/2.svg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => {
            if(props.onClose) props.onClose();
            else userContext.dispatchMenuActive("SelamatDatang");
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">{formActive}</span>
        <span className="sub-title-text">
          {formActive === "Liga Kreasi"
            ? "Bidang Minat Bakat"
            : "Pengajuan Pengembalian Dana Delegasi IKM FKUI"}
        </span>
      </div>
      <div className="body-area">
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          {formActive === "Liga Kreasi" ? (
            <React.Fragment>
              <div className="form-group required">
                <Autocomplete
                  id="liga-kreasi-jenis"
                  options={optionsJenisPerlombaan}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setJenisPerlombaan)
                  }
                  value={jenisPerlombaan || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Jenis Perlombaan"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {jenisPerlombaan && jenisPerlombaan.value === "Kelompok" && (
                <div className="form-group required">
                  <Autocomplete
                    id="liga-kreasi-anggota-kelompok"
                    multiple
                    options={optionsPengguna}
                    filterOptions={filterOptions}
                    noOptionsText="Tidak Ada Opsi"
                    clearOnBlur
                    clearText="Bersihkan"
                    openText="Pilih"
                    getOptionLabel={getOptionLabel}
                    onChange={(event, selected) =>
                      handleChangeSelectItem(selected, setAnggotaKelompok)
                    }
                    value={anggotaKelompok || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Anggota Kelompok"
                        variant="outlined"
                      />
                    )}
                    PopperComponent={PopperCustom}
                  />
                </div>
              )}
              <div className="form-group required">
                <Autocomplete
                  id="liga-kreasi-bidang-perlombaan"
                  options={optionsBidangPerlombaan}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setBidangPerlombaan)
                  }
                  value={bidangPerlombaan || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bidang Perlombaan"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              <div className="form-group required">
                <Autocomplete
                  id="liga-kreasi-penyelenggara-lomba"
                  options={optionsPenyelenggaraLomba}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setPenyelenggaraPerlombaan)
                  }
                  value={penyelenggaraPerlombaan || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Penyelenggara Perlombaan"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="liga-kreasi-nama-perlombaan"
                  label="Nama Perlombaan"
                  variant="outlined"
                  helperText="*Beserta cabang yang diikuti bila ada"
                  value={namaPerlombaan || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setNamaPerlombaan)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      namaPerlombaan,
                      setIsNamaPerlombaanClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsNamaPerlombaanClearActive)
                  }
                  InputProps={{
                    endAdornment: isNamaPerlombaanClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setNamaPerlombaan,
                              setIsNamaPerlombaanClearActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <Autocomplete
                  id="liga-kreasi-tingkat-lomba"
                  options={optionsTingkatLomba}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setTingkatPerlombaan)
                  }
                  value={tingkatPerlombaan || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tingkat Perlombaan"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              {tingkatPerlombaan &&
                tingkatPerlombaan.value === "Internasional" && (
                  <div className="form-group">
                    <Autocomplete
                      id="liga-kreasi-range-negara-terlibat"
                      options={optionsRangeNegaraTerlibat}
                      noOptionsText="Tidak Ada Opsi"
                      clearOnBlur
                      clearText="Bersihkan"
                      openText="Pilih"
                      getOptionLabel={getOptionLabel}
                      onChange={(event, selected) =>
                        handleChangeSelectItem(selected, setRangeNegaraTerlibat)
                      }
                      value={rangeNegaraTerlibat || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Range Negara Yang Terlibat"
                          variant="outlined"
                        />
                      )}
                      PopperComponent={PopperCustom}
                    />
                  </div>
                )}
              {tingkatPerlombaan &&
                (tingkatPerlombaan.value === "Nasional" ||
                  tingkatPerlombaan.value === "Provinsi/Wilayah") && (
                  <div className="form-group">
                    <Autocomplete
                      id="liga-kreasi-range-univ-terlibat"
                      options={optionsRangeUnivTerlibat}
                      noOptionsText="Tidak Ada Opsi"
                      clearOnBlur
                      clearText="Bersihkan"
                      openText="Pilih"
                      getOptionLabel={getOptionLabel}
                      onChange={(event, selected) =>
                        handleChangeSelectItem(
                          selected,
                          setRangeUniversitasTerlibat
                        )
                      }
                      value={rangeUniversitasTerlibat || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Range Universitas Yang Terlibat"
                          variant="outlined"
                        />
                      )}
                      PopperComponent={PopperCustom}
                    />
                  </div>
                )}
              <div className="form-group required">
                <Autocomplete
                  id="liga-kreasi-capaian-prestasi"
                  options={optionsCapaianPrestasi}
                  noOptionsText="Tidak Ada Opsi"
                  clearOnBlur
                  clearText="Bersihkan"
                  openText="Pilih"
                  getOptionLabel={getOptionLabel}
                  onChange={(event, selected) =>
                    handleChangeSelectItem(selected, setCapaianPrestasi)
                  }
                  value={capaianPrestasi || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Capaian Prestasi"
                      variant="outlined"
                    />
                  )}
                  PopperComponent={PopperCustom}
                />
              </div>
              <div className="form-group required">
                <DatePicker
                  format="dd-MM-yyyy"
                  label="Tanggal Awal Pelaksanaan Lomba"
                  maxDate={
                    tanggalAkhirPelaksanaan
                      ? tanggalAkhirPelaksanaan
                      : new Date()
                  }
                  openTo="year"
                  views={["year", "month", "date"]}
                  value={tanggalAwalPelaksanaan || null}
                  inputVariant="outlined"
                  onChange={(selected) =>
                    handleChangeSelectItem(selected, setTanggalAwalPelaksanaan)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      tanggalAwalPelaksanaan,
                      setIsTanggalAwalPelaksanaanClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(
                      setIsTanggalAwalPelaksanaanClearActive
                    )
                  }
                  open={isTanggalAwalPelaksanaanDialogActive}
                  onOpen={() =>
                    handleToggleDialogActive(
                      isTanggalAwalPelaksanaanDialogActive,
                      setIsTanggalAwalPelaksanaanDialogActive
                    )
                  }
                  onClose={() =>
                    handleToggleDialogActive(
                      isTanggalAwalPelaksanaanDialogActive,
                      setIsTanggalAwalPelaksanaanDialogActive
                    )
                  }
                  InputProps={{
                    endAdornment: isTanggalAwalPelaksanaanClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={() =>
                            handleMouseDownClearTextFieldDate(
                              setTanggalAwalPelaksanaan,
                              setIsTanggalAwalPelaksanaanClearActive,
                              setIsTanggalAwalPelaksanaanDialogActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <DatePicker
                  format="dd-MM-yyyy"
                  label="Tanggal Akhir Pelaksanaan Lomba"
                  minDate={
                    tanggalAwalPelaksanaan
                      ? tanggalAwalPelaksanaan
                      : new Date("1900-01-01")
                  }
                  maxDate={new Date()}
                  openTo="year"
                  views={["year", "month", "date"]}
                  value={tanggalAkhirPelaksanaan || null}
                  inputVariant="outlined"
                  onChange={(selected) =>
                    handleChangeSelectItem(selected, setTanggalAkhirPelaksanaan)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      tanggalAkhirPelaksanaan,
                      setIsTanggalAkhirPelaksanaanClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(
                      setIsTanggalAkhirPelaksanaanClearActive
                    )
                  }
                  open={isTanggalAkhirPelaksanaanDialogActive}
                  onOpen={() =>
                    handleToggleDialogActive(
                      isTanggalAkhirPelaksanaanDialogActive,
                      setIsTanggalAkhirPelaksanaanDialogActive
                    )
                  }
                  onClose={() =>
                    handleToggleDialogActive(
                      isTanggalAkhirPelaksanaanDialogActive,
                      setIsTanggalAkhirPelaksanaanDialogActive
                    )
                  }
                  InputProps={{
                    endAdornment: isTanggalAkhirPelaksanaanClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={() =>
                            handleMouseDownClearTextFieldDate(
                              setTanggalAkhirPelaksanaan,
                              setIsTanggalAkhirPelaksanaanClearActive,
                              setIsTanggalAkhirPelaksanaanDialogActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  id="liga-kreasi-url-laman-penyelenggara"
                  label="URL Laman Penyelenggara Lomba"
                  helperText="*Dapat berupa website resmi dan akun media sosial dari penyelenggara lomba, atau berita yang memuat perlombaan. Contoh: https://ligamedika.com"
                  variant="outlined"
                  value={urlPenyelenggaraLomba || ""}
                  onChange={(event) =>
                    handleChangeTextField(event, setUrlPenyelenggaraLomba)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      urlPenyelenggaraLomba,
                      setIsUrlPenyelenggaraLombaClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(
                      setIsUrlPenyelenggaraLombaClearActive
                    )
                  }
                  InputProps={{
                    endAdornment: isUrlPenyelenggaraLombaClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setUrlPenyelenggaraLomba,
                              setIsUrlPenyelenggaraLombaClearActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                className={`form-group required upload-area${
                  isSertifikatPialaMedaliActive ? " pointer-active" : ""
                }`}
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    sertifPialaMedali,
                    setIsSertifikatPialaMedaliActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(setIsSertifikatPialaMedaliActive)
                }
              >
                <TextField
                  id="liga-kreasi-sertif-piala-medali"
                  label="Sertifikat/Piala/Medali"
                  variant="outlined"
                  helperText="*Format: .pdf (Untuk perlombaan yang tidak melampirkan sertifikat, dapat mengunggah foto piala/medali dari minimal 3 sisi dan ditata dalam satu dokumen)"
                  value={
                    typeof sertifPialaMedali === "object"
                      ? (sertifPialaMedali?.name || "")
                      : sertifPialaMedali
                  }
                  ref={sertifPialaMedaliRef}
                  readOnly
                />
                {isSertifikatPialaMedaliActive && (
                  <div className="clear-icon">
                    <ClearIcon
                      onClick={() =>
                        handleClickClearTextField(
                          setSertifPialaMedali,
                          setIsSertifikatPialaMedaliActive
                        )
                      }
                    />
                  </div>
                )}
                <div
                  className="upload-button"
                  onClick={() =>
                    handleOpenDialog(setIsSertifikatPialaMedaliDialogActive)
                  }
                >
                  <span className="upload-text">Upload</span>
                </div>
                <DropzoneDialog
                  acceptedFiles={[".pdf"]}
                  cancelButtonText={"Cancel"}
                  submitButtonText={"Upload"}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={isSertifikatPialaMedaliDialogActive}
                  onClose={() =>
                    handleCloseDialog(setIsSertifikatPialaMedaliDialogActive)
                  }
                  onSave={(files) =>
                    handleDialogSaveFile(
                      files,
                      sertifPialaMedaliRef,
                      setSertifPialaMedali,
                      setIsSertifikatPialaMedaliDialogActive
                    )
                  }
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
              <div
                className={`form-group required upload-area${
                  isPenyerahanPenghargaanActive ? " pointer-active" : ""
                }`}
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    penyerahanPenghargaan,
                    setIsPenyerahanPenghargaanActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(setIsPenyerahanPenghargaanActive)
                }
              >
                <TextField
                  id="liga-kreasi-foto-penyerahan-penghargaan"
                  label="Foto Penyerahan Penghargaan"
                  variant="outlined"
                  helperText="*Foto apapun yang memuat tanda bukti memperoleh juara"
                  value={
                    typeof penyerahanPenghargaan === "object" 
                      ? (penyerahanPenghargaan?.name || "")
                      : penyerahanPenghargaan
                  }
                  ref={penyerahanPenghargaanRef}
                  readOnly
                />
                {isPenyerahanPenghargaanActive && (
                  <div className="clear-icon">
                    <ClearIcon
                      onClick={() =>
                        handleClickClearTextField(
                          setPenyerahanPenghargaan,
                          setIsPenyerahanPenghargaanActive
                        )
                      }
                    />
                  </div>
                )}
                <div
                  className="upload-button"
                  onClick={() =>
                    handleOpenDialog(setIsPenyerahanPenghargaanDialogActive)
                  }
                >
                  <span className="upload-text">Upload</span>
                </div>
                <DropzoneDialog
                  acceptedFiles={["image/*"]}
                  cancelButtonText={"Cancel"}
                  submitButtonText={"Upload"}
                  maxFileSize={5000000}
                  filesLimit={1}
                  open={isPenyerahanPenghargaanDialogActive}
                  onClose={() =>
                    handleCloseDialog(setIsPenyerahanPenghargaanDialogActive)
                  }
                  onSave={(files) =>
                    handleDialogSaveFile(
                      files,
                      penyerahanPenghargaanRef,
                      setPenyerahanPenghargaan,
                      setIsPenyerahanPenghargaanDialogActive
                    )
                  }
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </div>
              {bidangPerlombaan && bidangPerlombaan.value === "Penalaran" && (
                <div
                  className={`form-group upload-area${
                    isManuSkripKaryaActive ? " pointer-active" : ""
                  }`}
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      manuSkripKarya,
                      setIsManuSkripKaryaActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsManuSkripKaryaActive)
                  }
                >
                  <TextField
                    id="liga-kreasi-manu-skrip-karya"
                    label="Manuskrip Karya"
                    variant="outlined"
                    helperText="*Format: .pdf (Karya akan dimasukkan ke Website BEM IKM FKUI sebagai referensi)."
                    value={
                      typeof manuSkripKarya === "object"
                        ? (manuSkripKarya?.name || "")
                        : manuSkripKarya
                    }
                    ref={manuSkripKaryaRef}
                    readOnly
                  />
                  {isManuSkripKaryaActive && (
                    <div className="clear-icon">
                      <ClearIcon
                        onClick={() =>
                          handleClickClearTextField(
                            setManuSkripKarya,
                            setIsManuSkripKaryaActive
                          )
                        }
                      />
                    </div>
                  )}
                  <div
                    className="upload-button"
                    onClick={() =>
                      handleOpenDialog(setIsManuSkripKaryaDialogActive)
                    }
                  >
                    <span className="upload-text">Upload</span>
                  </div>
                  <DropzoneDialog
                    acceptedFiles={[".pdf"]}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Upload"}
                    maxFileSize={8000000}
                    filesLimit={1}
                    open={isManuSkripKaryaDialogActive}
                    onClose={() =>
                      handleCloseDialog(setIsManuSkripKaryaDialogActive)
                    }
                    onSave={(files) =>
                      handleDialogSaveFile(
                        files,
                        manuSkripKaryaRef,
                        setManuSkripKarya,
                        setIsManuSkripKaryaDialogActive
                      )
                    }
                    showPreviews={true}
                    showFileNamesInPreview={true}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="sub-form-group-wrapper">
                <span className="sub-title-form">A. Informasi Delegasi</span>
                <div className="form-group required">
                  <Autocomplete
                    id="reimburse-nama"
                    multiple
                    options={optionsPengguna}
                    filterOptions={filterOptions}
                    noOptionsText="Tidak Ada Opsi"
                    clearOnBlur
                    clearText="Bersihkan"
                    openText="Pilih"
                    getOptionLabel={getOptionLabel}
                    onChange={(event, selected) =>
                      handleChangeSelectItem(selected, r_setNama)
                    }
                    value={r_nama || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Mahasiswa Terlibat"
                        variant="outlined"
                      />
                    )}
                    PopperComponent={PopperCustom}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <span className="sub-title-form">B. Informasi Perlombaan</span>
                <div className="form-group required">
                  <TextField
                    id="reimburse-nama-lomba"
                    label="Nama Lomba"
                    variant="outlined"
                    value={r_namaLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setNamaLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_namaLomba,
                        r_setIsNamaLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsNamaLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isNamaLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setNamaLomba,
                                r_setIsNamaLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    id="reimburse-cabang-lomba"
                    label="Cabang (jika ada)"
                    variant="outlined"
                    value={r_cabangLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setCabangLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_cabangLomba,
                        r_setIsCabangLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsCabangLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isCabangLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setCabangLomba,
                                r_setIsCabangLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-penyelenggara-lomba"
                    label="Penyelenggara"
                    variant="outlined"
                    value={r_penyelenggaraLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setPenyelenggaraLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_penyelenggaraLomba,
                        r_setIsPenyelenggaraLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsPenyelenggaraLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isPenyelenggaraLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setPenyelenggaraLomba,
                                r_setIsPenyelenggaraLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-deskripsi-lomba"
                    label="Deskripsi"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={r_deskripsiLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setDeskripsiLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_deskripsiLomba,
                        r_setIsDeskripsiLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsDeskripsiLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isDeskripsiLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setDeskripsiLomba,
                                r_setIsDeskripsiLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-lokasi-lomba"
                    label="Lokasi"
                    variant="outlined"
                    value={r_lokasiLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setLokasiLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_lokasiLomba,
                        r_setIsLokasiLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsLokasiLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isLokasiLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setLokasiLomba,
                                r_setIsLokasiLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <DatePicker
                    format="dd-MM-yyyy"
                    label="Awal Pelaksanaan Lomba"
                    maxDate={
                      r_akhirPelaksanaanLomba
                        ? r_akhirPelaksanaanLomba
                        : new Date()
                    }
                    openTo="year"
                    views={["year", "month", "date"]}
                    value={r_awalPelaksanaanLomba || null}
                    inputVariant="outlined"
                    onChange={(selected) =>
                      handleChangeSelectItem(selected, r_setAwalPelaksanaanLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_awalPelaksanaanLomba,
                        r_setIsAwalPelaksanaanLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(
                        r_setIsAwalPelaksanaanLombaClearActive
                      )
                    }
                    open={r_isAwalPelaksanaanLombaDialogActive}
                    onOpen={() =>
                      handleToggleDialogActive(
                        r_isAwalPelaksanaanLombaDialogActive,
                        r_setIsAwalPelaksanaanLombaDialogActive
                      )
                    }
                    onClose={() =>
                      handleToggleDialogActive(
                        r_isAwalPelaksanaanLombaDialogActive,
                        r_setIsAwalPelaksanaanLombaDialogActive
                      )
                    }
                    InputProps={{
                      endAdornment: r_isAwalPelaksanaanLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onMouseDown={() =>
                              handleMouseDownClearTextFieldDate(
                                r_setAwalPelaksanaanLomba,
                                r_setIsAwalPelaksanaanLombaClearActive,
                                r_setIsAwalPelaksanaanLombaDialogActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <DatePicker
                    format="dd-MM-yyyy"
                    label="Akhir Pelaksanaan Lomba"
                    minDate={
                      r_awalPelaksanaanLomba
                        ? r_awalPelaksanaanLomba
                        : new Date("01-01-1900")
                    }
                    maxDate={new Date()}
                    openTo="year"
                    views={["year", "month", "date"]}
                    value={r_akhirPelaksanaanLomba || null}
                    inputVariant="outlined"
                    onChange={(selected) =>
                      handleChangeSelectItem(selected, r_setAkhirPelaksanaanLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_akhirPelaksanaanLomba,
                        r_setIsAkhirPelaksanaanLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(
                        r_setIsAkhirPelaksanaanLombaClearActive
                      )
                    }
                    open={r_isAkhirPelaksanaanLombaDialogActive}
                    onOpen={() =>
                      handleToggleDialogActive(
                        r_isAkhirPelaksanaanLombaDialogActive,
                        r_setIsAkhirPelaksanaanLombaDialogActive
                      )
                    }
                    onClose={() =>
                      handleToggleDialogActive(
                        r_isAkhirPelaksanaanLombaDialogActive,
                        r_setIsAkhirPelaksanaanLombaDialogActive
                      )
                    }
                    InputProps={{
                      endAdornment: r_isAkhirPelaksanaanLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onMouseDown={() =>
                              handleMouseDownClearTextFieldDate(
                                r_setAkhirPelaksanaanLomba,
                                r_setIsAkhirPelaksanaanLombaClearActive,
                                r_setIsAkhirPelaksanaanLombaDialogActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    id="reimburse-tautan-resmi-lomba"
                    label="Tautan Resmi"
                    variant="outlined"
                    value={r_tautanResmiLomba || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setTautanResmiLomba)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_tautanResmiLomba,
                        r_setIsTautanResmiLombaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsTautanResmiLombaClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isTautanResmiLombaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setTautanResmiLomba,
                                r_setIsTautanResmiLombaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <span className="sub-title-form">C. Informasi Seleksi</span>
                <div className="form-group">
                  <TextField
                    id="reimburse-penanggung-jawab-seleksi"
                    label="Penanggung Jawab"
                    variant="outlined"
                    value={r_penanggungJawabSeleksi || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setPenanggungJawabSeleksi)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_penanggungJawabSeleksi,
                        r_setIsPenanggungJawabSeleksiClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsPenanggungJawabSeleksiClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isPenanggungJawabSeleksiClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setPenanggungJawabSeleksi,
                                r_setIsPenanggungJawabSeleksiClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <Autocomplete
                    id="reimburse-butuh-tidaknya-dana-seleksi"
                    options={r_optionsIsSeleksiButuhDana}
                    noOptionsText="Tidak Ada Opsi"
                    clearOnBlur
                    clearText="Bersihkan"
                    openText="Pilih"
                    getOptionLabel={getOptionLabel}
                    onChange={(event, selected) =>
                      handleChangeSelectItem(selected, r_setIsSeleksiButuhDana)
                    }
                    value={r_isSeleksiButuhDana || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Apakah seleksinya membutuhkan dana?"
                        variant="outlined"
                      />
                    )}
                    PopperComponent={PopperCustom}
                  />
                </div>
                {r_isSeleksiButuhDana && r_isSeleksiButuhDana.value && (
                  <React.Fragment>
                    <div className="form-group required">
                      <div className="inner-form-group-wrapper">
                        <div className="inner-form-group rincian-dana-dibutuhkan">
                          <div className="inner-half-form">
                            <TextField
                              id="reimburse-jenis-pengeluaran-dana-seleksi"
                              label="Jenis Pengeluaran"
                              variant="outlined"
                              value={r_jenisPengeluaranDanaSeleksi || ""}
                              onChange={(event) =>
                                handleChangeTextField(event, r_setJenisPengeluaranDanaSeleksi)
                              }
                              onMouseEnter={() =>
                                handleMouseEnterTextField(
                                  r_jenisPengeluaranDanaSeleksi,
                                  r_setIsJenisPengeluaranDanaSeleksiClearActive
                                )
                              }
                              onMouseLeave={() =>
                                handleMouseLeaveTextField(r_setIsJenisPengeluaranDanaSeleksiClearActive)
                              }
                              InputProps={{
                                endAdornment: r_isJenisPengeluaranDanaSeleksiClearActive && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleClickClearTextField(
                                          r_setJenisPengeluaranDanaSeleksi,
                                          r_setIsJenisPengeluaranDanaSeleksiClearActive
                                        )
                                      }
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="inner-half-form">
                          <CurrencyTextField
                            label="Total Dana"
                            variant="outlined"
                            value={r_totalDanaSeleksi || ""}
                            textAlign="left"
                            currencySymbol="Rp"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            onChange={(event, value) =>
                              handleChangeCurrencyTextField(value, r_setTotalDanaSeleksi)
                            }
                            InputProps={{
                              endAdornment: r_isTotalDanaSeleksiClearActive && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickClearTextField(
                                        r_setTotalDanaSeleksi,
                                        r_setIsTotalDanaSeleksiClearActive
                                      )
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          </div>
                        </div>
                        <div className="rincian-dana-dibutuhkan-button-form-group">
                          <div className="add-button" onClick={handleClickTambahRincianDanaSeleksi}>
                            <span className="button-text">Tambah Rincian</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {r_rincianDanaSeleksi.length > 0 && (
                      <div className="form-group required">
                        <div className="text-area-list-rincian-dana-seleksi">
                          {r_rincianDanaSeleksi.map((item, index) => (
                            <div className="single-list-little-box" key={index}>
                              <span className="list-text">
                                {item.jenis_pengeluaran} - ({rupiahCurrencyFormat(item.total_dana)})
                              </span>
                              <span
                                className="delete-rincian-dana-seleksi-icon-area"
                                onClick={() => handleClickDeleteRincianDanaSeleksi(item.jenis_pengeluaran)}
                              >
                                <span className="delete-icon">
                                  <FontAwesomeIcon icon={faTimes} />
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                <div className="form-group">
                  <TextField
                    id="reimburse-fasilitas-seleksi"
                    label="Fasilitas Seleksi"
                    variant="outlined"
                    value={r_fasilitasSeleksi || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setFasilitasSeleksi)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_fasilitasSeleksi,
                        r_setIsFasilitasSeleksiClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsFasilitasSeleksiClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isFasilitasSeleksiClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setFasilitasSeleksi,
                                r_setIsFasilitasSeleksiClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <span className="sub-title-form">D. Informasi Pembimbing</span>
                <div className="form-group">
                  <TextField
                    id="reimburse-nama-pembimbing"
                    label="Nama"
                    variant="outlined"
                    value={r_namaPembimbing || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setNamaPembimbing)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_namaPembimbing,
                        r_setIsNamaPembimbingClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsNamaPembimbingClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isNamaPembimbingClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setNamaPembimbing,
                                r_setIsNamaPembimbingClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    id="reimburse-institusi-pembimbing"
                    label="Institusi"
                    variant="outlined"
                    value={r_institusiPembimbing || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setInstitusiPembimbing)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_institusiPembimbing,
                        r_setIsInstitusiPembimbingClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsInstitusiPembimbingClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isInstitusiPembimbingClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setInstitusiPembimbing,
                                r_setIsInstitusiPembimbingClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    id="reimburse-kontak-pembimbing"
                    label="Kontak"
                    variant="outlined"
                    value={r_kontakPembimbing || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setKontakPembimbing)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_kontakPembimbing,
                        r_setIsKontakPembimbingClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsKontakPembimbingClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isKontakPembimbingClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setKontakPembimbing,
                                r_setIsKontakPembimbingClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <span className="sub-title-form">E. Informasi Dana Lomba</span>
                <div className="form-group required">
                  <Autocomplete
                    id="reimburse-butuh-tidaknya-dana-lomba"
                    options={r_optionsIsLombaButuhDana}
                    noOptionsText="Tidak Ada Opsi"
                    clearOnBlur
                    clearText="Bersihkan"
                    openText="Pilih"
                    getOptionLabel={getOptionLabel}
                    onChange={(event, selected) =>
                      handleChangeSelectItem(selected, r_setIsLombaButuhDana)
                    }
                    value={r_isLombaButuhDana || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Apakah lombanya membutuhkan dana?"
                        variant="outlined"
                      />
                    )}
                    PopperComponent={PopperCustom}
                  />
                </div>
                {r_isLombaButuhDana && r_isLombaButuhDana.value && (
                  <React.Fragment>
                    <div className="form-group required">
                      <div className="inner-form-group-wrapper">
                        <div className="inner-form-group rincian-dana-dibutuhkan">
                          <div className="inner-half-form">
                            <TextField
                              id="reimburse-jenis-pengeluaran-dana-lomba"
                              label="Jenis Pengeluaran"
                              variant="outlined"
                              value={r_jenisPengeluaranDanaLomba || ""}
                              onChange={(event) =>
                                handleChangeTextField(event, r_setJenisPengeluaranDanaLomba)
                              }
                              onMouseEnter={() =>
                                handleMouseEnterTextField(
                                  r_jenisPengeluaranDanaLomba,
                                  r_setIsJenisPengeluaranDanaLombaClearActive
                                )
                              }
                              onMouseLeave={() =>
                                handleMouseLeaveTextField(r_setIsJenisPengeluaranDanaLombaClearActive)
                              }
                              InputProps={{
                                endAdornment: r_isJenisPengeluaranDanaLombaClearActive && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleClickClearTextField(
                                          r_setJenisPengeluaranDanaLomba,
                                          r_setIsJenisPengeluaranDanaLombaClearActive
                                        )
                                      }
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="inner-half-form">
                          <CurrencyTextField
                            label="Total Dana"
                            variant="outlined"
                            value={r_totalDanaLomba || ""}
                            textAlign="left"
                            currencySymbol="Rp"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            onChange={(event, value) =>
                              handleChangeCurrencyTextField(value, r_setTotalDanaLomba)
                            }
                            InputProps={{
                              endAdornment: r_isTotalDanaLombaClearActive && (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickClearTextField(
                                        r_setTotalDanaLomba,
                                        r_setIsTotalDanaLombaClearActive
                                      )
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          </div>
                        </div>
                        <div className="rincian-dana-dibutuhkan-button-form-group">
                          <div className="add-button" onClick={handleClickTambahRincianDanaLomba}>
                            <span className="button-text">Tambah Rincian</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {r_rincianDanaLomba.length > 0 && (
                      <div className="form-group required">
                        <div className="text-area-list-rincian-dana-lomba">
                          {r_rincianDanaLomba.map((item, index) => (
                            <div className="single-list-little-box" key={index}>
                              <span className="list-text">
                                {item.jenis_pengeluaran} - ({rupiahCurrencyFormat(item.total_dana)})
                              </span>
                              <span
                                className="delete-rincian-dana-lomba-icon-area"
                                onClick={() => handleClickDeleteRincianDanaLomba(item.jenis_pengeluaran)}
                              >
                                <span className="delete-icon">
                                  <FontAwesomeIcon icon={faTimes} />
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                <div className="form-group required">
                  <TextField
                    id="reimburse-bank-tujuan"
                    label="Bank Tujuan"
                    variant="outlined"
                    value={r_bankTujuan || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setBankTujuan)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_bankTujuan,
                        r_setIsBankTujuanClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsBankTujuanClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isBankTujuanClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setBankTujuan,
                                r_setIsBankTujuanClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-no-rekening-tujuan"
                    label="Nomor Rekening"
                    variant="outlined"
                    value={r_nomorRekeningTujuan || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setNomorRekeningTujuan)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_nomorRekeningTujuan,
                        r_setIsNomorRekeningTujuanClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsNomorRekeningTujuanClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isNomorRekeningTujuanClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setNomorRekeningTujuan,
                                r_setIsNomorRekeningTujuanClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-nama-pemilik-rekening-tujuan"
                    label="Nama Pemilik Rekening"
                    variant="outlined"
                    value={r_pemilikRekeningTujuan || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setPemilikRekeningTujuan)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_pemilikRekeningTujuan,
                        r_setIsPemilikRekeningTujuanClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsPemilikRekeningTujuanClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isPemilikRekeningTujuanClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setPemilikRekeningTujuan,
                                r_setIsPemilikRekeningTujuanClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <div className="sub-title-form">F. Penanggung Jawab Delegasi</div>
                <div className="form-group required disabled">
                  <TextField
                    id="reimburse-nama-penanggung-jawab"
                    label="Nama"
                    variant="outlined"
                    value={r_namaPenanggungJawab || ""}
                  />
                </div>
                <div className="form-group required disabled">
                  <TextField
                    id="reimburse-angkatan-penanggung-jawab"
                    label="Angkatan"
                    variant="outlined"
                    value={r_angkatanPenanggungJawab || ""}
                  />
                </div>
                <div className="form-group required disabled">
                  <TextField
                    id="reimburse-npm-penanggung-jawab"
                    label="NPM"
                    variant="outlined"
                    value={r_npmPenanggungJawab || ""}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-nomor-hp-penanggung-jawab"
                    label="Nomor HP"
                    variant="outlined"
                    value={r_nomorHPPenanggungJawab || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setNomorHPPenanggungJawab)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_nomorHPPenanggungJawab,
                        r_setIsNomorHPPenanggungJawabClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsNomorHPPenanggungJawabClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isNomorHPPenanggungJawabClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setNomorHPPenanggungJawab,
                                r_setIsNomorHPPenanggungJawabClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form-group required">
                  <TextField
                    id="reimburse-email-penanggung-jawab"
                    label="Email"
                    variant="outlined"
                    value={r_emailPenanggungJawab || ""}
                    onChange={(event) =>
                      handleChangeTextField(event, r_setEmailPenanggungJawab)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        r_emailPenanggungJawab,
                        r_setIsEmailPenanggungJawabClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(r_setIsEmailPenanggungJawabClearActive)
                    }
                    InputProps={{
                      endAdornment: r_isEmailPenanggungJawabClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                r_setEmailPenanggungJawab,
                                r_setIsEmailPenanggungJawabClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="sub-form-group-wrapper">
                <div className="form-helper">
                  <p>Setelah mendapatkan form reimburse, upload form di <a href="http://e-ikm.fk.ui.ac.id/application/surat-pengajuan-dana-ppkm" target="_blank">http://e-ikm.fk.ui.ac.id/application/surat-pengajuan-dana-ppkm</a> beserta bukti-bukti di bawah ini.</p>
                  <ul>
                    <li>1. Bukti pendaftaran</li>
                    <li>2. Bukti pembayaran registrasi lomba</li>
                    <li className="mandatory">3. Tiket</li>
                    <li className="mandatory">4. Boarding pass</li>
                    <li className="mandatory">5. Bukti pembayaran tiket</li>
                    <li className="mandatory">6. Bukti pembayaran transportasi lainnya</li>
                    <li>7. Bukti pembayaran akomodasi</li>
                    <li>8. Dokumentasi kegiatan</li>
                  </ul>
                  <p>Bukti-bukti tersebut kemudian digabungkan dalam format <b>.zip</b> dan diunggah di slot <b>"Unggah Surat Izin Kegiatan"</b>.</p>
                  <p>Nomor surat dapat diminta ke Bidang Minat dan Bakat BEM IKM FKUI.</p>
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="form-action-area">
            <div className="helper-wrapper">
              {formActive === "Liga Kreasi" ? 
                "reimburse" in props
                  ? props.reimburse 
                    ? (
                      <span
                        className="helper-text"
                        onClick={handleClickOpenFormReimburse}
                      >
                        Atau isi Form Reimburse
                      </span>
                    )
                    : null
                  : (
                    <span
                      className="helper-text"
                      onClick={handleClickOpenFormReimburse}
                    >
                      Atau isi Form Reimburse
                    </span>
                  )
               : (
                <span
                  className="helper-text"
                  onClick={handleClickOpenFormLigaKreasi}
                >
                  Kembali ke Form Liga Kreasi
                </span>
              )}
            </div>
            <div className="button-wrapper">
              <button
                type="submit"
                className="submit-button"
                onClick={(event) =>
                  handleFormSubmitAction(event, setIsFormSubmitted)
                }
              >
                {isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  <span className="button-text">
                    {props.actionType && props.actionType === "Edit" 
                      ? "Update" 
                      : "Submit"
                    }
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseErrorSnackbar(setIsErrorSnackbarActive)}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </div>
  );
}

export default LigaKreasi;
