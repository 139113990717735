import React from "react";
import "./style.css";
import Navbar from "../../../../../../../../../Components/Navbar";

function DetailInfo() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="body-content-wrapper-detail-info">
        <div className="body-content-detail-info">
          <div className="box-wrapper">
            <div className="title-area">
              <span className="title-text">Digital Talent Scholarship</span>
            </div>
            <div className="date-area">
              <span className="date-text">20-11-2020</span>
            </div>
            <div className="content-area">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DetailInfo;
