import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { dateFormat } from "../../../../../../../Services/Format/date";
import { handleCloseDialog } from "../../../../../../../Services/FormHandler";
import { getKKMBuktiKeikutsertaan } from "../../../../../../../Services/API/file";
import {
  getKKM,
  getTotalKKM,
  updateKKMStatus,
  getSKP,
  getTotalSKP,
  updateSKPStatus,
} from "../../../../../../../Services/API/kaderisasi_berkelanjutan";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "../../../../../../../Services/Format/blob";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import ShowDataToggle from "../ShowDataToggle";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";

function KaderisasiBerkelanjutan() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Kaderisasi Berkelanjutan -------------------------------------------------------------------------

  // @ShowData Section
  // Kaderisasi Berkelanjutan

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "KKM", label: "KKM" },
    { value: "SKP", label: "SKP" },
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Kaderisasi Berkelanjutan

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Kaderisasi Berkelanjutan - By KKM

  const [dataByKKM, setDataByKKM] = useState([]);

  const dataFieldsByKKM = [
    {
      primary: true,
      checkbox: true,
      value: "id_kkm",
      type: "text",
      label: "ID KKM",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tingkat",
      type: "text",
      label: "Tingkat",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "indikator_pencapaian",
      type: "multiple-textbox",
      separator: "*; ",
      label: "Indikator Pencapaian",
      size: "xxl",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_kegiatan",
      type: "text",
      size: "default",
      label: "Tanggal Kegiatan",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "penyelenggara",
      type: "text",
      label: "Penyelenggara",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "bukti_keikutsertaan",
      type: "file",
      label: "Bukti Keikutsertaan",
      size: "default",
      contentGetFile: getKKMBuktiKeikutsertaan,
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByKKM, setSortItemByKKM] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputByKKM, setSearchInputByKKM] = useState("");

  const optionsSearchFilterByKKM = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "tingkat", label: "Tingkat" },
    { value: "indikator_pencapaian", label: "Indikator Pencapaian" },
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "tanggal_kegiatan", label: "Tanggal Kegiatan" },
    { value: "penyelenggara", label: "Penyelenggara" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ];

  const [searchFilterByKKM, setSearchFilterByKKM] = useState([
    optionsSearchFilterByKKM[1],
  ]);

  // @Table Section
  // Kaderisasi Berkelanjutan - By SKP

  const [dataBySKP, setDataBySKP] = useState([]);

  const dataFieldsBySKP = [
    {
      primary: true,
      checkbox: true,
      value: "id_skp",
      type: "text",
      label: "ID SKP",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "unsur_kegiatan",
      type: "text",
      label: "Unsur Kegiatan",
      size: "l",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tingkat",
      type: "text",
      label: "Tingkat",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "posisi",
      type: "text",
      label: "Posisi",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "bobot",
      type: "text",
      label: "Bobot",
      size: "xs",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemBySKP, setSortItemBySKP] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputBySKP, setSearchInputBySKP] = useState("");

  const optionsSearchFilterBySKP = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "unsur_kegiatan", label: "Unsur Kegiatan" },
    { value: "tingkat", label: "Tingkat" },
    { value: "posisi", label: "Posisi" },
    { value: "bobot", label: "Bobot" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ];

  const [searchFilterBySKP, setSearchFilterBySKP] = useState([
    optionsSearchFilterBySKP[1],
  ]);

  // @Pagination Section
  // Kaderisasi Berkelanjutan - By KKM

  const optionsNumberPerPageByKKM = [10, 20, 30, 40, 50];

  const [numberPerPageByKKM, setNumberPerPageByKKM] = useState(
    optionsNumberPerPageByKKM[0]
  );

  const [numberPerPageFromByKKM, setNumberPerPageFromByKKM] = useState(0);

  const [numberPerPageToByKKM, setNumberPerPageToByKKM] = useState(0);

  const [totalDataByKKM, setTotalDataByKKM] = useState(0);

  const [totalPageByKKM, setTotalPageByKKM] = useState(0);

  const [currentPageByKKM, setCurrentPageByKKM] = useState(0);

  // @Pagination Section
  // Kaderisasi Berkelanjutan - By SKP

  const optionsNumberPerPageBySKP = [10, 20, 30, 40, 50];

  const [numberPerPageBySKP, setNumberPerPageBySKP] = useState(
    optionsNumberPerPageBySKP[0]
  );

  const [numberPerPageFromBySKP, setNumberPerPageFromBySKP] = useState(0);

  const [numberPerPageToBySKP, setNumberPerPageToBySKP] = useState(0);

  const [totalDataBySKP, setTotalDataBySKP] = useState(0);

  const [totalPageBySKP, setTotalPageBySKP] = useState(0);

  const [currentPageBySKP, setCurrentPageBySKP] = useState(0);

  // @Dialog Section
  // Kaderisasi Berkelanjutan

  const dialogFullScreenClassName = "kaderisasi-berkelanjutan-full-screen-bar";

  const [isViewFileDialogActive, setIsViewFileDialogActive] = useState(false);

  const [objectMime, setObjectMime] = useState(null);

  const [objectURL, setObjectURL] = useState(null);

  const [objectFilename, setObjectFilename] = useState(null);

  const handleUpdateDataByKKM = (id, status, tanggal_verifikasi) => {
    setDataByKKM(
      dataByKKM.map((item, index) => {
        return item.id_kkm === id
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleUpdateDataBySKP = (id, status, tanggal_verifikasi) => {
    setDataBySKP(
      dataBySKP.map((item, index) => {
        return item.id_skp === id
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleClickSingleBodyContentByKKM = ({ id_kkm }) => {
    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      if (value) {
        const status = value === "accept" ? "Accepted" : "Rejected";
        const tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");

        const update = await updateKKMStatus({
          id_kkm,
          status,
          tanggal_verifikasi,
        });

        if (update.success) {
          handleUpdateDataByKKM(id_kkm, status, tanggal_verifikasi);
        } else {
          swal("Oops!", "Verifikasi gagal.", "warning");
        }
      }
    });
  };

  const handleClickSingleBodyContentBySKP = ({ id_skp }) => {
    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      if (value) {
        const status = value === "accept" ? "Accepted" : "Rejected";
        const tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");

        const update = await updateSKPStatus({
          id_skp,
          status,
          tanggal_verifikasi,
        });

        if (update.success) {
          handleUpdateDataBySKP(id_skp, status, tanggal_verifikasi);
        } else {
          swal("Oops!", "Verifikasi gagal.", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [byKKM, totalByKKM, bySKP, totalBySKP] = await Promise.all([
      getKKM({
        order: sortItemByKKM,
        limit: numberPerPageByKKM,
        offset: 0,
      }),
      getTotalKKM({}),
      getSKP({
        order: sortItemBySKP,
        limit: numberPerPageBySKP,
        offset: 0,
      }),
      getTotalSKP({}),
    ]);

    if (
      byKKM.success &&
      totalByKKM.success &&
      bySKP.success &&
      totalBySKP.success
    ) {
      // Set Kaderisasi Berkelanjutan - By KKM
      setDataByKKM(byKKM.data);
      setTotalDataByKKM(totalByKKM.data.total);
      setTotalPageByKKM(
        Math.ceil(totalByKKM.data.total / numberPerPageByKKM)
      );
      setCurrentPageByKKM(totalByKKM.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByKKM(totalByKKM.data.total > 0 ? 1 : 0);
      setNumberPerPageToByKKM(
        totalByKKM.data.total > 0 ? byKKM.data.length : 0
      );

      // Set Kaderisasi Berkelanjutan - By SKP
      setDataBySKP(bySKP.data);
      setTotalDataBySKP(totalBySKP.data.total);
      setTotalPageBySKP(
        Math.ceil(totalBySKP.data.total / numberPerPageBySKP)
      );
      setCurrentPageBySKP(totalBySKP.data.total > 0 ? 1 : 0);
      setNumberPerPageFromBySKP(totalBySKP.data.total > 0 ? 1 : 0);
      setNumberPerPageToBySKP(
        totalBySKP.data.total > 0 ? bySKP.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper kaderisasi-berkelanjutan-content-panel">
          <div className="header-panel">
            <h1 className="topic-panel">Kaderisasi Berkelanjutan</h1>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      {showDataActive.value === "KKM" ? (
                        <Search
                          setData={setDataByKKM}
                          getData={getKKM}
                          dataFields={dataFieldsByKKM}
                          sortItem={sortItemByKKM}
                          searchInput={searchInputByKKM}
                          setSearchInput={setSearchInputByKKM}
                          optionsSearchFilterBy={optionsSearchFilterByKKM}
                          searchFilterBy={searchFilterByKKM}
                          setSearchFilterBy={setSearchFilterByKKM}
                          numberPerPage={numberPerPageByKKM}
                          setNumberPerPageFrom={setNumberPerPageFromByKKM}
                          setNumberPerPageTo={setNumberPerPageToByKKM}
                          getTotalData={getTotalKKM}
                          setTotalData={setTotalDataByKKM}
                          setTotalPage={setTotalPageByKKM}
                          setCurrentPage={setCurrentPageByKKM}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      ) : (
                        <Search
                          setData={setDataBySKP}
                          getData={getSKP}
                          dataFields={dataFieldsBySKP}
                          sortItem={sortItemBySKP}
                          searchInput={searchInputBySKP}
                          setSearchInput={setSearchInputBySKP}
                          optionsSearchFilterBy={optionsSearchFilterBySKP}
                          searchFilterBy={searchFilterBySKP}
                          setSearchFilterBy={setSearchFilterBySKP}
                          numberPerPage={numberPerPageBySKP}
                          setNumberPerPageFrom={setNumberPerPageFromBySKP}
                          setNumberPerPageTo={setNumberPerPageToBySKP}
                          getTotalData={getTotalSKP}
                          setTotalData={setTotalDataBySKP}
                          setTotalPage={setTotalPageBySKP}
                          setCurrentPage={setCurrentPageBySKP}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      )}
                      <ShowDataToggle
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      {showDataActive.value === "KKM" ? (
                        <Pagination
                          setData={setDataByKKM}
                          getData={getKKM}
                          dataFields={dataFieldsByKKM}
                          sortItem={sortItemByKKM}
                          searchInput={searchInputByKKM}
                          searchFilterBy={searchFilterByKKM}
                          optionsNumberPerPage={optionsNumberPerPageByKKM}
                          numberPerPage={numberPerPageByKKM}
                          setNumberPerPage={setNumberPerPageByKKM}
                          numberPerPageFrom={numberPerPageFromByKKM}
                          setNumberPerPageFrom={setNumberPerPageFromByKKM}
                          numberPerPageTo={numberPerPageToByKKM}
                          setNumberPerPageTo={setNumberPerPageToByKKM}
                          totalData={totalDataByKKM}
                          getTotalData={getTotalKKM}
                          setTotalData={setTotalDataByKKM}
                          totalPage={totalPageByKKM}
                          setTotalPage={setTotalPageByKKM}
                          currentPage={currentPageByKKM}
                          setCurrentPage={setCurrentPageByKKM}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      ) : (
                        <Pagination
                          setData={setDataBySKP}
                          getData={getSKP}
                          dataFields={dataFieldsBySKP}
                          sortItem={sortItemBySKP}
                          searchInput={searchInputBySKP}
                          searchFilterBy={searchFilterBySKP}
                          optionsNumberPerPage={optionsNumberPerPageBySKP}
                          numberPerPage={numberPerPageBySKP}
                          setNumberPerPage={setNumberPerPageBySKP}
                          numberPerPageFrom={numberPerPageFromBySKP}
                          setNumberPerPageFrom={setNumberPerPageFromBySKP}
                          numberPerPageTo={numberPerPageToBySKP}
                          setNumberPerPageTo={setNumberPerPageToBySKP}
                          totalData={totalDataBySKP}
                          getTotalData={getTotalSKP}
                          setTotalData={setTotalDataBySKP}
                          totalPage={totalPageBySKP}
                          setTotalPage={setTotalPageBySKP}
                          currentPage={currentPageBySKP}
                          setCurrentPage={setCurrentPageBySKP}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      )}
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                {showDataActive.value === "KKM" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByKKM}
                    setData={setDataByKKM}
                    getData={getKKM}
                    dataFields={dataFieldsByKKM}
                    sortItem={sortItemByKKM}
                    setSortItem={setSortItemByKKM}
                    searchInput={searchInputByKKM}
                    searchFilterBy={searchFilterByKKM}
                    numberPerPage={numberPerPageByKKM}
                    setNumberPerPageFrom={setNumberPerPageFromByKKM}
                    setNumberPerPageTo={setNumberPerPageToByKKM}
                    totalData={totalDataByKKM}
                    getTotalData={getTotalKKM}
                    setTotalData={setTotalDataByKKM}
                    setTotalPage={setTotalPageByKKM}
                    setCurrentPage={setCurrentPageByKKM}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentByKKM
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : (
                  <Table
                    ref={tableWrapperRef}
                    data={dataBySKP}
                    setData={setDataBySKP}
                    getData={getSKP}
                    dataFields={dataFieldsBySKP}
                    sortItem={sortItemBySKP}
                    setSortItem={setSortItemBySKP}
                    searchInput={searchInputBySKP}
                    searchFilterBy={searchFilterBySKP}
                    numberPerPage={numberPerPageBySKP}
                    setNumberPerPageFrom={setNumberPerPageFromBySKP}
                    setNumberPerPageTo={setNumberPerPageToBySKP}
                    totalData={totalDataBySKP}
                    getTotalData={getTotalSKP}
                    setTotalData={setTotalDataBySKP}
                    setTotalPage={setTotalPageBySKP}
                    setCurrentPage={setCurrentPageBySKP}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentBySKP
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <Dialog
        fullScreen
        open={isViewFileDialogActive}
        onClose={() => handleCloseDialog(setIsViewFileDialogActive)}
        className={`full-screen-app-bar${
          dialogFullScreenClassName ? " " + dialogFullScreenClassName : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsViewFileDialogActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              File Viewer
            </Typography>
            {objectURL &&
              objectMime &&
              objectFilename &&
              objectMime.split("/")[0] === "image" && (
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => downloadBlob(objectURL, objectFilename)}
                  className="single-button-right-top-custom"
                >
                  Download
                </Button>
              )}
          </Toolbar>
        </AppBar>
        {objectURL && objectMime && objectMime === "application/pdf" && (
          <div className="fullscreen-pdf-file-viewer">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div className="pdf-file-viewer">
                <Viewer fileUrl={objectURL} />
              </div>
            </Worker>
          </div>
        )}
        {objectURL && objectMime && objectMime.split("/")[0] === "image" && (
          <div className="fullscreen-image-file-viewer">
            <img className="image-file" src={objectURL} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default KaderisasiBerkelanjutan;
