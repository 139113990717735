import React from "react";
import "./style.css";
import { css, jsx } from "@emotion/core";
import { BallClipRotateMultiple } from "react-pure-loaders";

function BallClipRotateMultipleCustom(props) {
  return (
    <div
      className={`loader-full-screen-wrapper${
        props.isLoading ? " active" : ""
      }`}
      style={props.styleCustom ? props.styleCustom : null}
    >
      <BallClipRotateMultiple color={"#1890BF"} loading={props.isLoading} />
    </div>
  );
}

export default BallClipRotateMultipleCustom;
