import React, { useContext } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import { ProfileContext } from "../../../Profil";
import { dateFormat } from "../../../../../Services/Format/date";

function Publikasi() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  return (
    <div className="publikasi-box box-wrapper">
      <div className="box-header-area">
        <span className="box-title-text">Publikasi</span>
        <div className="box-action-area">
          {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
        </div>
      </div>
      <div className="box-body-area">
        <div className="box-body-top">
          <div className="inner-box-body-top-left">
            <div className="single-info total">
              <span className="info-label">Total :</span>
              <span className="info-value">
                {profileContext.publikasi.length}
              </span>
            </div>
          </div>
          <div className="inner-box-body-top-right">
            {/* <div className="single-action pagination">
              <div className="prev-button-area">
                <div className="single-button prev-button">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
              </div>
              <div className="page-info-area">
                <span className="page-info">Page 1 of 2</span>
              </div>
              <div className="next-button-area">
                <div className="single-button next-button active">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          {profileContext.publikasi.map((item, index) => (
            <div className="single-box col-lg-4 col-md-6 col-12" key={index}>
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">{item.judul_publikasi}</span>
                </div>
                <div className="date-area">
                  <span className="date-text">
                    <span className="value-text">
                      {dateFormat(
                        new Date(item.tanggal_publikasi),
                        "dd-MM-yyyy"
                      )}
                    </span>
                  </span>
                </div>
                <div className="link-area">
                  <span className="link-text">
                    <span className="value-text">
                      <a href={item.link_publikasi} target="_blank">
                        {item.link_publikasi}
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Publikasi;
