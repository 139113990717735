import React, { useEffect, useState, useContext, useRef } from "react";
import "./style.css";
import Navbar from "../../../Components/Navbar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getRankingLigaKreasi } from "../../../Services/API/liga_kreasi";
import { getPengguna } from "../../../Services/API/pengguna";
import { getBisnis } from "../../../Services/API/bisnis";
import { UserContext } from "../../User";
import { closestIndexTo } from "date-fns";
import searchData from "../../../Services/Search";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";
import useDidMountEffect from "../../../Components/useDidMountEffect";
import {
  getFotoProfilPengguna,
  getLogoBisnis,
} from "../../../Services/API/file";

function RankingLigaKreasi() {
  const userContext = useContext(UserContext);

  const [rankingLigaKreasiBySemua, setRankingLigaKreasiBySemua] = useState([]);

  const [
    rankingLigaKreasiByAngkatan,
    setRankingLigaKreasiByAngkatan,
  ] = useState([]);

  const [rankingActive, setRankingActive] = useState("Semua");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const [
        _rankingLigaKreasiBySemua,
        _rankingLigaKreasiByAngkatan,
      ] = await Promise.all([
        getRankingLigaKreasi({
          searchPengguna: null,
          searchRank: {
            groupOp: "",
            rules: [
              {
                field: "rank",
                op: "<=",
                data: 50,
              },
            ],
          },
          order: {
            by: "rank",
            mode: "ASC",
          },
        }),
        getRankingLigaKreasi({
          searchPengguna: {
            groupOp: "",
            rules: [
              {
                field: "angkatan",
                op: "=",
                data: userContext.userActive.angkatan || 2023,
              },
            ],
          },
          searchRank: {
            groupOp: "",
            rules: [
              {
                field: "rank",
                op: "<=",
                data: 50,
              },
            ],
          },
          order: {
            by: "rank",
            mode: "ASC",
          },
        }),
      ]);

      if (
        _rankingLigaKreasiBySemua.success &&
        _rankingLigaKreasiByAngkatan.success
      ) {
        await Promise.all([
          setRankingLigaKreasiBySemua(_rankingLigaKreasiBySemua.data),
          setRankingLigaKreasiByAngkatan(_rankingLigaKreasiByAngkatan.data),
        ]);

        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="ranking-liga-kreasi-area">
      <div className="header-area">
        <div className="title-area">
          <span className="title-text">Ranking Liga Kreasi</span>
        </div>
        <div className="menu-area">
          <div
            className={`single-menu${
              rankingActive === "Semua" ? " active" : ""
            }`}
            onClick={() => setRankingActive("Semua")}
          >
            <span className="menu-text">Semua</span>
          </div>
          <div
            className={`single-menu${
              rankingActive === "Angkatan" ? " active" : ""
            }`}
            onClick={() => setRankingActive("Angkatan")}
          >
            <span className="menu-text">Angkatan</span>
          </div>
        </div>
        <div className="description-area">
          <span className="description-text">Top 50 teratas ({`01/01/${new Date().getFullYear()}`} - {`31/12/${new Date().getFullYear()}`})</span>
        </div>
        <div className="head-table">
          <div className="single-head-table urutan">
            <span className="head-text">#</span>
          </div>
          <div className="single-head-table mahasiswa">
            <span className="head-text">Mahasiswa</span>
          </div>
          <div className="single-head-table skor">
            <span className="head-text">Skor</span>
          </div>
        </div>
      </div>
      <BallClipRotateMultipleCustom
        isLoading={isLoading}
        styleCustom={{
          position: "absolute",
          top: "140px",
          height: "calc(100% - 140px)",
        }}
      />
      {!isLoading && (
        <div className="body-area">
          {rankingActive === "Semua"
            ? rankingLigaKreasiBySemua.length > 0 ? rankingLigaKreasiBySemua.map((item, index) => (
                <div className="single-body-table" key={index}>
                  <div className="inner-body-content urutan">
                    <span className="body-text">{item.rank}</span>
                  </div>
                  <div className="inner-body-content mahasiswa">
                    <div className="nama-lengkap-area">
                      <span className="body-text">
                        <Link to={`/telusuri/mahasiswa?id=${item.id_pengguna}`}>
                          {item.nama_lengkap.toLowerCase()}
                        </Link>
                      </span>
                    </div>
                    <div className="angkatan-area">
                      <span className="body-text">{item.angkatan}</span>
                    </div>
                  </div>
                  <div className="inner-body-content skor">
                    <span className="body-text">{item.total_skor}</span>
                  </div>
                </div>
              )) : (
                <div className="" style={{ marginTop: "10px" }}>Tidak ada data</div>
              )
            : rankingLigaKreasiByAngkatan.length > 0 ? rankingLigaKreasiByAngkatan.map((item, index) => (
                <div className="single-body-table" key={index}>
                  <div className="inner-body-content urutan">
                    <span className="body-text">{item.rank}</span>
                  </div>
                  <div className="inner-body-content mahasiswa">
                    <div className="nama-lengkap-area">
                      <span className="body-text">{item.nama_lengkap}</span>
                    </div>
                    <div className="angkatan-area">
                      <span className="body-text">{item.angkatan}</span>
                    </div>
                  </div>
                  <div className="inner-body-content skor">
                    <span className="body-text">{item.total_skor}</span>
                  </div>
                </div>
              )) : (
                <div className="" style={{ marginTop: "10px" }}>Tidak ada data</div>
              )}
        </div>
      )}
    </div>
  );
}

function Pencarian() {
  const userContext = useContext(UserContext);

  const loadMoreMahasiswaRef = useRef(null);

  const loadMoreBisnisRef = useRef(null);

  const limitMahasiswaPerPage = 24;

  const limitBisnisPerPage = 24;

  const [mahasiswa, setMahasiswa] = useState([]);

  const [bisnis, setBisnis] = useState([]);

  const [searchInputMahasiswa, setSearchInputMahasiswa] = useState("");

  const [searchInputBisnis, setSearchInputBisnis] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [
    isIntersectionMahasiswaLoading,
    setIsIntersectionMahasiswaLoading,
  ] = useState(false);

  const [
    isIntersectionBisnisLoading,
    setIsIntersectionBisnisLoading,
  ] = useState(false);

  const [offsetMahasiswaPerPage, setOffsetMahasiswaPerPage] = useState(0);

  const [offsetBisnisPerPage, setOffsetBisnisPerPage] = useState(0);

  const [pencarianActive, setPencarianActive] = useState("Mahasiswa");

  const searchBisnis = async () => {
    setIsLoading(true);

    setOffsetBisnisPerPage(0);

    const _bisnis = await getBisnis({
      fields: ["id_bisnis", "nama_bisnis", "jenis_bisnis", "logo_bisnis"],
      search: {
        groupOp: "",
        rules: [
          {
            field: "nama_bisnis",
            op: "LIKE",
            data: searchInputBisnis,
          },
        ],
      },
      order: {
        by: "nama_bisnis",
        mode: "ASC",
      },
      limit: limitBisnisPerPage,
      offset: 0,
    });

    if (_bisnis.success) {
      const _previewLogoBisnis = await Promise.allSettled(
        _bisnis.data.map((item) => getLogoBisnis(item.logo_bisnis))
      );

      setBisnis(
        _bisnis.data.map((item, index) => {
          return {
            ...item,
            preview_logo_bisnis:
              _previewLogoBisnis[index].value.success &&
              _previewLogoBisnis[index].value.data
                ? _previewLogoBisnis[index].value.data
                : null,
          };
        })
      );

      setIsLoading(false);

      setIsIntersectionBisnisLoading(
        _bisnis.data.length === limitBisnisPerPage
      );
    }
  };

  const searchMahasiswa = async () => {
    setIsLoading(true);

    setOffsetMahasiswaPerPage(0);

    const _mahasiswa = await getPengguna({
      fields: ["id_pengguna", "nama_lengkap", "angkatan", "foto_pengguna"],
      search: {
        groupOp: "",
        rules: [
          {
            field: "nama_lengkap",
            op: "LIKE",
            data: searchInputMahasiswa,
          },
        ],
      },
      order: {
        by: "nama_lengkap",
        mode: "ASC",
      },
      limit: limitMahasiswaPerPage,
      offset: 0,
    });

    if (_mahasiswa.success) {
      const _previewFotoPengguna = await Promise.allSettled(
        _mahasiswa.data.map((item) => getFotoProfilPengguna(item.foto_pengguna))
      );

      setMahasiswa(
        _mahasiswa.data.map((item, index) => {
          return {
            ...item,
            preview_foto_pengguna:
              _previewFotoPengguna[index].value.success &&
              _previewFotoPengguna[index].value.data
                ? _previewFotoPengguna[index].value.data
                : null,
          };
        })
      );

      setIsLoading(false);

      setIsIntersectionMahasiswaLoading(
        _mahasiswa.data.length === limitMahasiswaPerPage
      );
    }
  };

  const handleKeyUpSearchInputBisnis = (event) => {
    if (event.keyCode === 13) {
      searchBisnis();
    }
  };

  const handleKeyUpSearchInputMahasiswa = (event) => {
    if (event.keyCode === 13) {
      searchMahasiswa();
    }
  };

  const fetchBisnis = async () => {
    const _bisnis = await getBisnis({
      fields: ["id_bisnis", "nama_bisnis", "jenis_bisnis", "logo_bisnis"],
      search: searchInputBisnis
        ? {
            groupOp: "",
            rules: [
              {
                field: "nama_bisnis",
                op: "LIKE",
                data: searchInputBisnis,
              },
            ],
          }
        : null,
      order: {
        by: "nama_bisnis",
        mode: "ASC",
      },
      limit: limitBisnisPerPage,
      offset: offsetBisnisPerPage,
    });

    if (_bisnis.success) {
      const _previewLogoBisnis = await Promise.allSettled(
        _bisnis.data.map((item) => getLogoBisnis(item.logo_bisnis))
      );

      setBisnis((prevBisnis) => [
        ...prevBisnis,
        ..._bisnis.data.map((item, index) => {
          return {
            ...item,
            preview_logo_bisnis:
              _previewLogoBisnis[index].value.success &&
              _previewLogoBisnis[index].value.data
                ? _previewLogoBisnis[index].value.data
                : null,
          };
        }),
      ]);

      setIsIntersectionBisnisLoading(
        _bisnis.data.length === limitBisnisPerPage
      );
    }
  };

  const fetchMahasiswa = async () => {
    const _mahasiswa = await getPengguna({
      fields: ["id_pengguna", "nama_lengkap", "angkatan", "foto_pengguna"],
      search: searchInputMahasiswa
        ? {
            groupOp: "",
            rules: [
              {
                field: "nama_lengkap",
                op: "LIKE",
                data: searchInputMahasiswa,
              },
            ],
          }
        : null,
      order: {
        by: "nama_lengkap",
        mode: "ASC",
      },
      limit: limitMahasiswaPerPage,
      offset: offsetMahasiswaPerPage,
    });

    if (_mahasiswa.success) {
      const _previewFotoPengguna = await Promise.allSettled(
        _mahasiswa.data.map((item) => getFotoProfilPengguna(item.foto_pengguna))
      );

      setMahasiswa((prevMahasiswa) => [
        ...prevMahasiswa,
        ..._mahasiswa.data.map((item, index) => {
          return {
            ...item,
            preview_foto_pengguna:
              _previewFotoPengguna[index].value.success &&
              _previewFotoPengguna[index].value.data
                ? _previewFotoPengguna[index].value.data
                : null,
          };
        }),
      ]);

      setIsIntersectionMahasiswaLoading(
        _mahasiswa.data.length === limitMahasiswaPerPage
      );
    }
  };

  useDidMountEffect(() => {
    if (offsetBisnisPerPage > 0) {
      fetchBisnis();
    }
  }, [offsetBisnisPerPage]);

  useDidMountEffect(() => {
    if (offsetMahasiswaPerPage > 0) {
      fetchMahasiswa();
    }
  }, [offsetMahasiswaPerPage]);

  useDidMountEffect(() => {
    if (isIntersectionBisnisLoading) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreBisnis();
          }
        },
        { threshold: 1 }
      );

      observer.observe(loadMoreBisnisRef.current);
    }
  }, [isIntersectionBisnisLoading]);

  useDidMountEffect(() => {
    if (isIntersectionMahasiswaLoading) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreMahasiswa();
          }
        },
        { threshold: 1 }
      );

      observer.observe(loadMoreMahasiswaRef.current);
    }
  }, [isIntersectionMahasiswaLoading]);

  const loadMoreMahasiswa = () => {
    setOffsetMahasiswaPerPage(
      (prevOffsetPerPage) => prevOffsetPerPage + limitMahasiswaPerPage
    );
  };

  const loadMoreBisnis = () => {
    setOffsetBisnisPerPage(
      (prevOffsetPerPage) => prevOffsetPerPage + limitBisnisPerPage
    );
  };

  useEffect(() => {
    (async () => {
      const [_mahasiswa, _bisnis] = await Promise.all([
        getPengguna({
          fields: ["id_pengguna", "nama_lengkap", "angkatan", "foto_pengguna"],
          search: null,
          order: {
            by: "nama_lengkap",
            mode: "ASC",
          },
          limit: limitMahasiswaPerPage,
          offset: offsetMahasiswaPerPage,
        }),
        getBisnis({
          fields: ["id_bisnis", "nama_bisnis", "jenis_bisnis", "logo_bisnis"],
          search: null,
          order: {
            by: "nama_bisnis",
            mode: "ASC",
          },
          limit: limitBisnisPerPage,
          offset: offsetBisnisPerPage,
        }),
      ]);

      if (_mahasiswa.success && _bisnis.success) {
        const [_previewFotoPengguna, _previewLogoBisnis] = await Promise.all([
          Promise.allSettled(
            _mahasiswa.data.map((item) =>
              getFotoProfilPengguna(item.foto_pengguna)
            )
          ),
          Promise.allSettled(
            _bisnis.data.map((item) => getLogoBisnis(item.logo_bisnis))
          ),
        ]);

        setMahasiswa(
          _mahasiswa.data.map((item, index) => {
            return {
              ...item,
              preview_foto_pengguna:
                _previewFotoPengguna[index].value.success &&
                _previewFotoPengguna[index].value.data
                  ? _previewFotoPengguna[index].value.data
                  : null,
            };
          })
        );

        setBisnis(
          _bisnis.data.map((item, index) => {
            return {
              ...item,
              preview_logo_bisnis:
                _previewLogoBisnis[index].value.success &&
                _previewLogoBisnis[index].value.data
                  ? _previewLogoBisnis[index].value.data
                  : null,
            };
          })
        );

        setIsLoading(false);

        if (pencarianActive === "Mahasiswa") {
          setIsIntersectionMahasiswaLoading(
            _mahasiswa.data.length === limitMahasiswaPerPage
          );
        } else {
          setIsIntersectionBisnisLoading(
            _bisnis.data.length === limitBisnisPerPage
          );
        }
      }
    })();
  }, []);

  return (
    <div className="pencarian-area">
      <div className="header-area">
        <div className="navigation-area">
          <div className="menu-area">
            <div
              className={`single-menu${
                pencarianActive === "Mahasiswa" ? " active" : ""
              }`}
              onClick={() => setPencarianActive("Mahasiswa")}
            >
              <span className="menu-text">Mahasiswa</span>
            </div>
            <div
              className={`single-menu${
                pencarianActive === "Bisnis" ? " active" : ""
              }`}
              onClick={() => setPencarianActive("Bisnis")}
            >
              <span className="menu-text">Bisnis</span>
            </div>
          </div>
          {pencarianActive === "Mahasiswa" ? (
            <div className="search-area">
              <input
                type="text"
                id="telusuri-input"
                placeholder="Cari Mahasiswa"
                onKeyUp={handleKeyUpSearchInputMahasiswa}
                onChange={(event) =>
                  setSearchInputMahasiswa(event.target.value)
                }
                value={searchInputMahasiswa || ""}
              />
              <div className="search-icon" onClick={searchMahasiswa}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          ) : (
            <div className="search-area">
              <input
                type="text"
                id="telusuri-input"
                placeholder="Cari Bisnis"
                onKeyUp={handleKeyUpSearchInputBisnis}
                onChange={(event) => setSearchInputBisnis(event.target.value)}
                value={searchInputBisnis || ""}
              />
              <div className="search-icon" onClick={searchBisnis}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          )}
        </div>
      </div>
      <BallClipRotateMultipleCustom
        isLoading={isLoading}
        styleCustom={{
          position: "absolute",
          top: "70px",
          left: "initial",
          height: "calc(100% - 70px)",
        }}
      />
      {!isLoading && (
        <div className="body-area">
          <div className="row">
            {pencarianActive === "Mahasiswa"
              ? mahasiswa.map((item, index) => (
                  <div className="single-body-content col-xl-4" key={index}>
                    <div
                      className={`inner-body-content mahasiswa${
                        item.preview_foto_pengguna ? " photo-visible" : ""
                      }`}
                    >
                      <div className="left-content">
                        <div
                          className="picture"
                          style={{
                            backgroundImage: `${
                              item.preview_foto_pengguna
                                ? `url(${item.preview_foto_pengguna})`
                                : `url(${require("../../../Assets/Miscellaneous/no_image_profile.png")})`
                            }`,
                          }}
                        ></div>
                      </div>
                      <div className="right-content">
                        <div className="single-right-content">
                          <span className="content-text nama-lengkap-area">
                            <Link
                              to={`/telusuri/mahasiswa?id=${item.id_pengguna}`}
                            >
                              {item.nama_lengkap.toLowerCase()}
                            </Link>
                          </span>
                        </div>
                        <div className="single-right-content">
                          <span className="content-text">{item.angkatan}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : bisnis.map((item, index) => (
                  <div className="single-body-content col-xl-4" key={index}>
                    <div className="inner-body-content bisnis">
                      <div className="left-content">
                        <img
                          className="picture"
                          src={
                            item.preview_logo_bisnis
                              ? item.preview_logo_bisnis
                              : `${require("../../../Assets/Miscellaneous/no_image_bisnis.png")}`
                          }
                        />
                      </div>
                      <div className="right-content">
                        <div className="single-right-content">
                          <span className="content-text">
                            {item.nama_bisnis}
                          </span>
                        </div>
                        <div className="single-right-content">
                          <span className="content-text">
                            {item.jenis_bisnis}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {pencarianActive === "Mahasiswa"
            ? isIntersectionMahasiswaLoading && (
                <div className="load-more-area" ref={loadMoreMahasiswaRef}>
                  <BallClipRotateMultipleCustom
                    isLoading={true}
                    styleCustom={{
                      position: "relative",
                      height: "40px",
                    }}
                  />
                </div>
              )
            : isIntersectionBisnisLoading && (
                <div className="load-more-area" ref={loadMoreBisnisRef}>
                  <BallClipRotateMultipleCustom
                    isLoading={true}
                    styleCustom={{
                      position: "relative",
                      height: "40px",
                    }}
                  />
                </div>
              )}
        </div>
      )}
    </div>
  );
}

function Telusuri() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="body-content-wrapper-telusuri">
        <div className="telusuri-container">
          <RankingLigaKreasi />
          <Pencarian />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Telusuri;
