import React from "react";
import "../../style.css";
import "./style.css";

function Admin() {
  return (
    <div className="content-panel-wrapper admin-content-panel">
      <div className="header-panel">
        <h1 className="topic-panel">Admin</h1>
      </div>
      <div className="body-panel"></div>
    </div>
  );
}

export default Admin;
