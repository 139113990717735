import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { dateFormat } from "../../../../../../../Services/Format/date";
import { handleCloseDialog } from "../../../../../../../Services/FormHandler";
import { getLogoBisnis } from "../../../../../../../Services/API/file";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getAllBisnisByBisnis,
  getTotalBisnisByBisnis,
  deleteBisnisByBisnis,
  getAllBisnisByPengguna,
  getTotalBisnisByPengguna,
  deleteBisnisByPengguna,
  getAllBisnisByPengajuan,
  getTotalBisnisByPengajuan,
  deleteBisnisByPengajuan,
  verifikasiBisnis,
} from "../../../../../../../Services/API/bisnis";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "../../../../../../../Services/Format/blob";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import ShowDataToggle from "../ShowDataToggle";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";

function Bisnis() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Bisnis ----------------------------------------------------------------

  // @ShowData Section
  // Bisnis

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "Pengajuan", label: "Pengajuan" },
    { value: "Per Bisnis", label: "Per Bisnis" },
    { value: "Per Pengguna", label: "Per Pengguna" },
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Bisnis

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Bisnis - ByPengajuan

  const [dataByPengajuan, setDataByPengajuan] = useState([]);

  const dataFieldsByPengajuan = [
    {
      primary: true,
      checkbox: true,
      value: "id_detail_bisnis",
      type: "text",
      label: "ID Detail Bisnis",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "id_bisnis",
      type: "text",
      label: "ID Bisnis",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "keterangan",
      type: "text",
      label: "Keterangan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "nama_bisnis",
      type: "text",
      label: "Nama Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "jenis_bisnis",
      type: "text",
      label: "Jenis Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "logo_bisnis",
      type: "file",
      label: "Logo Bisnis",
      contentGetFile: getLogoBisnis,
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "sejak_kapan",
      type: "text",
      label: "Bisnisnya Sejak Kapan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "contact_person_bisnis",
      type: "text",
      label: "Contact Person Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "facebook_bisnis",
      type: "text",
      label: "ID Facebook Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "instagram_bisnis",
      type: "text",
      label: "Username Instagram Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "twitter_bisnis",
      type: "text",
      label: "Username Twitter Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "linkedin_bisnis",
      type: "textlink",
      label: "URL Profil Linkedin Bisnis",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "status_in_group",
      type: "text",
      label: "Diinvite Dalam Grup IKM FKUI",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByPengajuan, setSortItemByPengajuan] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputByPengajuan, setSearchInputByPengajuan] = useState("");

  const optionsSearchFilterByPengajuan = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "keterangan", label: "Keterangan" },
    { value: "nama_bisnis", label: "Nama Bisnis" },
    { value: "jenis_bisnis", label: "Jenis Bisnis" },
    { value: "sejak_kapan", label: "Bisnisnya Sejak Kapan" },
    { value: "contact_person_bisnis", label: "Contact Person Bisnis" },
    { value: "facebook_bisnis", label: "ID Facebook Bisnis" },
    { value: "instagram_bisnis", label: "Username Instagram Bisnis" },
    { value: "twitter_bisnis", label: "Username Twitter Bisnis" },
    { value: "linkedin_bisnis", label: "URL Profil Linkedin Bisnis" },
    { value: "status_in_group", label: "Diinvite Dalam Grup IKM FKUI" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Verifikasi" },
  ];

  const [searchFilterByPengajuan, setSearchFilterByPengajuan] = useState([
    optionsSearchFilterByPengajuan[1],
  ]);

  // @Table Section
  // Bisnis - ByBisnis

  const [dataByBisnis, setDataByBisnis] = useState([]);

  const dataFieldsByBisnis = [
    {
      primary: true,
      checkbox: true,
      value: "id_bisnis",
      type: "text",
      label: "ID Bisnis",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "nama_bisnis",
      type: "text",
      label: "Nama Bisnis",
      size: "default",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "jenis_bisnis",
      type: "text",
      label: "Jenis Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "logo_bisnis",
      type: "file",
      label: "Logo Bisnis",
      contentGetFile: getLogoBisnis,
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "sejak_kapan",
      type: "text",
      label: "Bisnisnya Sejak Kapan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "anggota_bisnis",
      type: "multiple-textbox",
      label: "Anggota Bisnis",
      contentFontType: "capitalize",
      size: "xl",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "jumlah_anggota",
      type: "text",
      label: "Jumlah Anggota",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "contact_person_bisnis",
      type: "text",
      label: "Contact Person Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "facebook_bisnis",
      type: "text",
      label: "ID Facebook Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "instagram_bisnis",
      type: "text",
      label: "Username Instagram Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "twitter_bisnis",
      type: "text",
      label: "Username Twitter Bisnis",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "linkedin_bisnis",
      type: "text",
      label: "URL Profil Linkedin Bisnis",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "status_in_group",
      type: "text",
      label: "Diinvite Dalam Grup IKM FKUI",
      size: "m",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByBisnis, setSortItemByBisnis] = useState({
    by: "nama_bisnis",
    mode: "ASC",
  });

  const [searchInputByBisnis, setSearchInputByBisnis] = useState("");

  const optionsSearchFilterByBisnis = [
    { value: "nama_bisnis", label: "Nama Bisnis" },
    { value: "jenis_bisnis", label: "Jenis Bisnis" },
    { value: "sejak_kapan", label: "Sejak Kapan Bisnisnya" },
    { value: "anggota_bisnis", label: "Anggota Bisnis" },
    { value: "jumlah_anggota", label: "Jumlah Anggota" },
    { value: "contact_person_bisnis", label: "Contact Person Bisnis" },
    { value: "facebook_bisnis", label: "IDFacebook Bisnis" },
    { value: "instagram_bisnis", label: "Username Instagram Bisnis" },
    { value: "twitter_bisnis", label: "Username Twitter Bisnis" },
    { value: "linkedin_bisnis", label: "URL Profil Linkedin Bisnis" },
    { value: "status_in_group", label: "Diinvite Dalam Grup IKM FKUI" },
  ];

  const [searchFilterByBisnis, setSearchFilterByBisnis] = useState([
    optionsSearchFilterByBisnis[0],
  ]);

  // @Table Section
  // Bisnis - ByPengguna

  const [dataByPengguna, setDataByPengguna] = useState([]);

  const dataFieldsByPengguna = [
    {
      primary: true,
      checkbox: true,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "bisnis",
      type: "multiple-textbox",
      label: "Bisnis",
      size: "xl",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "jumlah_bisnis",
      type: "text",
      label: "Jumlah Bisnis",
      size: "s",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByPengguna, setSortItemByPengguna] = useState({
    by: "nama_lengkap",
    mode: "ASC",
  });

  const [searchInputByPengguna, setSearchInputByPengguna] = useState("");

  const optionsSearchFilterByPengguna = [
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "bisnis", label: "Bisnis" },
    { value: "jumlah_bisnis", label: "Jumlah Bisnis" },
  ];

  const [searchFilterByPengguna, setSearchFilterByPengguna] = useState([
    optionsSearchFilterByPengguna[0],
  ]);

  // @Pagination Section
  // Bisnis - By Pengajuan

  const optionsNumberPerPageByPengajuan = [10, 20, 30, 40, 50];

  const [numberPerPageByPengajuan, setNumberPerPageByPengajuan] = useState(
    optionsNumberPerPageByPengajuan[0]
  );

  const [
    numberPerPageFromByPengajuan,
    setNumberPerPageFromByPengajuan,
  ] = useState(0);

  const [numberPerPageToByPengajuan, setNumberPerPageToByPengajuan] = useState(
    0
  );

  const [totalDataByPengajuan, setTotalDataByPengajuan] = useState(0);

  const [totalPageByPengajuan, setTotalPageByPengajuan] = useState(0);

  const [currentPageByPengajuan, setCurrentPageByPengajuan] = useState(0);

  // @Pagination Section
  // Bisnis - ByBisnis

  const optionsNumberPerPageByBisnis = [10, 20, 30, 40, 50];

  const [numberPerPageByBisnis, setNumberPerPageByBisnis] = useState(
    optionsNumberPerPageByBisnis[0]
  );

  const [numberPerPageFromByBisnis, setNumberPerPageFromByBisnis] = useState(0);

  const [numberPerPageToByBisnis, setNumberPerPageToByBisnis] = useState(0);

  const [totalDataByBisnis, setTotalDataByBisnis] = useState(0);

  const [totalPageByBisnis, setTotalPageByBisnis] = useState(0);

  const [currentPageByBisnis, setCurrentPageByBisnis] = useState(0);

  // @Pagination Section
  // Bisnis - ByPengguna

  const optionsNumberPerPageByPengguna = [10, 20, 30, 40, 50];

  const [numberPerPageByPengguna, setNumberPerPageByPengguna] = useState(
    optionsNumberPerPageByPengguna[0]
  );

  const [
    numberPerPageFromByPengguna,
    setNumberPerPageFromByPengguna,
  ] = useState(0);

  const [numberPerPageToByPengguna, setNumberPerPageToByPengguna] = useState(0);

  const [totalDataByPengguna, setTotalDataByPengguna] = useState(0);

  const [totalPageByPengguna, setTotalPageByPengguna] = useState(0);

  const [currentPageByPengguna, setCurrentPageByPengguna] = useState(0);

  // @Dialog Section
  // Bisnis

  const dialogFullScreenClassName = "bisnis-full-screen-bar";

  const [isViewFileDialogActive, setIsViewFileDialogActive] = useState(false);

  const [objectMime, setObjectMime] = useState(null);

  const [objectURL, setObjectURL] = useState(null);

  const [objectFilename, setObjectFilename] = useState(null);

  const handleUpdateDataByPengajuan = async (
    id_detail_bisnis,
    id_bisnis,
    keterangan,
    status,
    tanggal_verifikasi
  ) => {
    setDataByPengajuan(
      dataByPengajuan.map((item, index) => {
        return item.id_detail_bisnis === id_detail_bisnis
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );

    const [_byBisnis, _byPengguna] = await Promise.all([
      getAllBisnisByBisnis({
        search: {
          groupOp: searchFilterByBisnis.length > 1 ? "OR" : "",
          rules: searchFilterByBisnis.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputByBisnis,
            };
          }),
        },
        order: sortItemByBisnis,
        limit: numberPerPageByBisnis,
        offset: 0,
      }),
      getAllBisnisByPengguna({
        search: {
          groupOp: searchFilterByPengguna.length > 1 ? "OR" : "",
          rules: searchFilterByPengguna.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputByPengguna,
            };
          }),
        },
        order: sortItemByPengguna,
        limit: numberPerPageByPengguna,
        offset: 0,
      }),
    ]);

    if (_byBisnis.success && _byPengguna.success) {
      setDataByBisnis(_byBisnis.data);
      setDataByPengguna(_byPengguna.data);
    }
  };

  const handleClickSingleBodyContentByPengajuan = async ({
    id_detail_bisnis,
    id_bisnis,
    keterangan,
  }) => {
    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      if (!value) return;

      const status = value === "accept" ? "Accepted" : "Rejected";
      const tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");

      const update = await verifikasiBisnis({
        id_detail_bisnis,
        id_bisnis,
        keterangan,
        tanggal_verifikasi,
        status,
      });

      if (update.success) {
        handleUpdateDataByPengajuan(
          id_detail_bisnis,
          id_bisnis,
          keterangan,
          status,
          tanggal_verifikasi
        );
      } else {
        swal("Oops!", "Verifikasi gagal.", "warning");
      }
    });
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        switch(showDataActive.value) {
          case "Pengajuan":
            _delete = await deleteBisnisByPengajuan(dataSelected);
          break;
          case "Per Bisnis":
            _delete = await deleteBisnisByBisnis(dataSelected);
          break;
          case "Per Pengguna":
            _delete = await deleteBisnisByPengguna(dataSelected);
          break;
        }

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [
      byPengajuan,
      totalByPengajuan,
      byBisnis,
      totalByBisnis,
      byPengguna,
      totalByPengguna,
    ] = await Promise.all([
      getAllBisnisByPengajuan({
        order: sortItemByPengajuan,
        limit: numberPerPageByPengajuan,
        offset: 0,
      }),
      getTotalBisnisByPengajuan({}),
      getAllBisnisByBisnis({
        order: sortItemByBisnis,
        limit: numberPerPageByBisnis,
        offset: 0,
      }),
      getTotalBisnisByBisnis({}),
      getAllBisnisByPengguna({
        order: sortItemByPengguna,
        limit: numberPerPageByPengguna,
        offset: 0,
      }),
      getTotalBisnisByPengguna({}),
    ]);

    if (
      byPengajuan.success &&
      totalByPengajuan.success &&
      byBisnis.success &&
      totalByBisnis.success &&
      byPengguna.success &&
      totalByPengguna.success
    ) {
      // Set Bisnis - ByPengajuan
      setDataByPengajuan(byPengajuan.data);
      setTotalDataByPengajuan(totalByPengajuan.data.total);
      setTotalPageByPengajuan(
        Math.ceil(totalByPengajuan.data.total / numberPerPageByPengajuan)
      );
      setCurrentPageByPengajuan(totalByPengajuan.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByPengajuan(
        totalByPengajuan.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByPengajuan(
        totalByPengajuan.data.total > 0 ? byPengajuan.data.length : 0
      );

      // Set Bisnis - ByBisnis
      setDataByBisnis(byBisnis.data);
      setTotalDataByBisnis(totalByBisnis.data.total);
      setTotalPageByBisnis(
        Math.ceil(totalByBisnis.data.total / numberPerPageByBisnis)
      );
      setCurrentPageByBisnis(totalByBisnis.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByBisnis(totalByBisnis.data.total > 0 ? 1 : 0);
      setNumberPerPageToByBisnis(
        totalByBisnis.data.total > 0 ? byBisnis.data.length : 0
      );

      // Set Bisnis - ByPengguna
      setDataByPengguna(byPengguna.data);
      setTotalDataByPengguna(totalByPengguna.data.total);
      setTotalPageByPengguna(
        Math.ceil(totalByPengguna.data.total / numberPerPageByPengguna)
      );
      setCurrentPageByPengguna(totalByPengguna.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByPengguna(totalByPengguna.data.total > 0 ? 1 : 0);
      setNumberPerPageToByPengguna(
        totalByPengguna.data.total > 0 ? byPengguna.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper bisnis-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Bisnis</h1>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      {showDataActive.value === "Pengajuan" ? (
                        <Search
                          setData={setDataByPengajuan}
                          getData={getAllBisnisByPengajuan}
                          dataFields={dataFieldsByPengajuan}
                          sortItem={sortItemByPengajuan}
                          searchInput={searchInputByPengajuan}
                          setSearchInput={setSearchInputByPengajuan}
                          optionsSearchFilterBy={optionsSearchFilterByPengajuan}
                          searchFilterBy={searchFilterByPengajuan}
                          setSearchFilterBy={setSearchFilterByPengajuan}
                          numberPerPage={numberPerPageByPengajuan}
                          setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                          setNumberPerPageTo={setNumberPerPageToByPengajuan}
                          getTotalData={getTotalBisnisByPengajuan}
                          setTotalData={setTotalDataByPengajuan}
                          setTotalPage={setTotalPageByPengajuan}
                          setCurrentPage={setCurrentPageByPengajuan}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Per Bisnis" ? (
                        <Search
                          setData={setDataByBisnis}
                          getData={getAllBisnisByBisnis}
                          dataFields={dataFieldsByBisnis}
                          sortItem={sortItemByBisnis}
                          searchInput={searchInputByBisnis}
                          setSearchInput={setSearchInputByBisnis}
                          optionsSearchFilterBy={optionsSearchFilterByBisnis}
                          searchFilterBy={searchFilterByBisnis}
                          setSearchFilterBy={setSearchFilterByBisnis}
                          numberPerPage={numberPerPageByBisnis}
                          setNumberPerPageFrom={setNumberPerPageFromByBisnis}
                          setNumberPerPageTo={setNumberPerPageToByBisnis}
                          getTotalData={getTotalBisnisByBisnis}
                          setTotalData={setTotalDataByBisnis}
                          setTotalPage={setTotalPageByBisnis}
                          setCurrentPage={setCurrentPageByBisnis}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : (
                        <Search
                          setData={setDataByPengguna}
                          getData={getAllBisnisByPengguna}
                          dataFields={dataFieldsByPengguna}
                          sortItem={sortItemByPengguna}
                          searchInput={searchInputByPengguna}
                          setSearchInput={setSearchInputByPengguna}
                          optionsSearchFilterBy={optionsSearchFilterByPengguna}
                          searchFilterBy={searchFilterByPengguna}
                          setSearchFilterBy={setSearchFilterByPengguna}
                          numberPerPage={numberPerPageByPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromByPengguna}
                          setNumberPerPageTo={setNumberPerPageToByPengguna}
                          getTotalData={getTotalBisnisByPengguna}
                          setTotalData={setTotalDataByPengguna}
                          setTotalPage={setTotalPageByPengguna}
                          setCurrentPage={setCurrentPageByPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      )}
                      <ShowDataToggle
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      {showDataActive.value === "Pengajuan" ? (
                        <Pagination
                          setData={setDataByPengajuan}
                          getData={getAllBisnisByPengajuan}
                          dataFields={dataFieldsByPengajuan}
                          sortItem={sortItemByPengajuan}
                          searchInput={searchInputByPengajuan}
                          searchFilterBy={searchFilterByPengajuan}
                          optionsNumberPerPage={optionsNumberPerPageByPengajuan}
                          numberPerPage={numberPerPageByPengajuan}
                          setNumberPerPage={setNumberPerPageByPengajuan}
                          numberPerPageFrom={numberPerPageFromByPengajuan}
                          setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                          numberPerPageTo={numberPerPageToByPengajuan}
                          setNumberPerPageTo={setNumberPerPageToByPengajuan}
                          totalData={totalDataByPengajuan}
                          getTotalData={getTotalBisnisByPengajuan}
                          setTotalData={setTotalDataByPengajuan}
                          totalPage={totalPageByPengajuan}
                          setTotalPage={setTotalPageByPengajuan}
                          currentPage={currentPageByPengajuan}
                          setCurrentPage={setCurrentPageByPengajuan}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Per Bisnis" ? (
                        <Pagination
                          setData={setDataByBisnis}
                          getData={getAllBisnisByBisnis}
                          dataFields={dataFieldsByBisnis}
                          sortItem={sortItemByBisnis}
                          searchInput={searchInputByBisnis}
                          searchFilterBy={searchFilterByBisnis}
                          optionsNumberPerPage={optionsNumberPerPageByBisnis}
                          numberPerPage={numberPerPageByBisnis}
                          setNumberPerPage={setNumberPerPageByBisnis}
                          numberPerPageFrom={numberPerPageFromByBisnis}
                          setNumberPerPageFrom={setNumberPerPageFromByBisnis}
                          numberPerPageTo={numberPerPageToByBisnis}
                          setNumberPerPageTo={setNumberPerPageToByBisnis}
                          totalData={totalDataByBisnis}
                          getTotalData={getTotalBisnisByBisnis}
                          setTotalData={setTotalDataByBisnis}
                          totalPage={totalPageByBisnis}
                          setTotalPage={setTotalPageByBisnis}
                          currentPage={currentPageByBisnis}
                          setCurrentPage={setCurrentPageByBisnis}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : (
                        <Pagination
                          setData={setDataByPengguna}
                          getData={getAllBisnisByPengguna}
                          dataFields={dataFieldsByPengguna}
                          sortItem={sortItemByPengguna}
                          searchInput={searchInputByPengguna}
                          searchFilterBy={searchFilterByPengguna}
                          optionsNumberPerPage={optionsNumberPerPageByPengguna}
                          numberPerPage={numberPerPageByPengguna}
                          setNumberPerPage={setNumberPerPageByPengguna}
                          numberPerPageFrom={numberPerPageFromByPengguna}
                          setNumberPerPageFrom={setNumberPerPageFromByPengguna}
                          numberPerPageTo={numberPerPageToByPengguna}
                          setNumberPerPageTo={setNumberPerPageToByPengguna}
                          totalData={totalDataByPengguna}
                          getTotalData={getTotalBisnisByPengguna}
                          setTotalData={setTotalDataByPengguna}
                          totalPage={totalPageByPengguna}
                          setTotalPage={setTotalPageByPengguna}
                          currentPage={currentPageByPengguna}
                          setCurrentPage={setCurrentPageByPengguna}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      )}
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                {showDataActive.value === "Pengajuan" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByPengajuan}
                    setData={setDataByPengajuan}
                    getData={getAllBisnisByPengajuan}
                    dataFields={dataFieldsByPengajuan}
                    sortItem={sortItemByPengajuan}
                    setSortItem={setSortItemByPengajuan}
                    searchInput={searchInputByPengajuan}
                    searchFilterBy={searchFilterByPengajuan}
                    numberPerPage={numberPerPageByPengajuan}
                    setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                    setNumberPerPageTo={setNumberPerPageToByPengajuan}
                    totalData={totalDataByPengajuan}
                    getTotalData={getTotalBisnisByPengajuan}
                    setTotalData={setTotalDataByPengajuan}
                    setTotalPage={setTotalPageByPengajuan}
                    setCurrentPage={setCurrentPageByPengajuan}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentByPengajuan
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Per Bisnis" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByBisnis}
                    setData={setDataByBisnis}
                    getData={getAllBisnisByBisnis}
                    dataFields={dataFieldsByBisnis}
                    sortItem={sortItemByBisnis}
                    setSortItem={setSortItemByBisnis}
                    searchInput={searchInputByBisnis}
                    searchFilterBy={searchFilterByBisnis}
                    numberPerPage={numberPerPageByBisnis}
                    setNumberPerPageFrom={setNumberPerPageFromByBisnis}
                    setNumberPerPageTo={setNumberPerPageToByBisnis}
                    totalData={totalDataByBisnis}
                    getTotalData={getTotalBisnisByBisnis}
                    setTotalData={setTotalDataByBisnis}
                    setTotalPage={setTotalPageByBisnis}
                    setCurrentPage={setCurrentPageByBisnis}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByPengguna}
                    setData={setDataByPengguna}
                    getData={getAllBisnisByPengguna}
                    dataFields={dataFieldsByPengguna}
                    sortItem={sortItemByPengguna}
                    setSortItem={setSortItemByPengguna}
                    searchInput={searchInputByPengguna}
                    searchFilterBy={searchFilterByPengguna}
                    numberPerPage={numberPerPageByPengguna}
                    setNumberPerPageFrom={setNumberPerPageFromByPengguna}
                    setNumberPerPageTo={setNumberPerPageToByPengguna}
                    totalData={totalDataByPengguna}
                    getTotalData={getTotalBisnisByPengguna}
                    setTotalData={setTotalDataByPengguna}
                    setTotalPage={setTotalPageByPengguna}
                    setCurrentPage={setCurrentPageByPengguna}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <Dialog
        fullScreen
        open={isViewFileDialogActive}
        onClose={() => handleCloseDialog(setIsViewFileDialogActive)}
        className={`full-screen-app-bar${
          dialogFullScreenClassName ? " " + dialogFullScreenClassName : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsViewFileDialogActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              File Viewer
            </Typography>
            {objectURL &&
              objectMime &&
              objectFilename &&
              objectMime.split("/")[0] === "image" && (
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => downloadBlob(objectURL, objectFilename)}
                  className="single-button-right-top-custom"
                >
                  Download
                </Button>
              )}
          </Toolbar>
        </AppBar>
        {objectURL && objectMime && objectMime === "application/pdf" && (
          <div className="fullscreen-pdf-file-viewer">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div className="pdf-file-viewer">
                <Viewer fileUrl={objectURL} />
              </div>
            </Worker>
          </div>
        )}
        {objectURL && objectMime && objectMime.split("/")[0] === "image" && (
          <div className="fullscreen-image-file-viewer">
            <img className="image-file" src={objectURL} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default Bisnis;
