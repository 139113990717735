import React, { useState, useEffect, useRef, useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import { DropzoneDialog } from "material-ui-dropzone";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { DatePicker } from "@material-ui/pickers";
import { UserContext } from "../../../../../../User";
import { dateFormat } from "../../../../../../../Services/Format/date";
import {
  getBisnis,
  getAllJenisBisnis,
  insertBisnis,
  insertDetailBisnis,
  updateLogoBisnis,
} from "../../../../../../../Services/API/bisnis";
import swal from "sweetalert";
import { uploadLogoBisnis } from "../../../../../../../Services/API/file";
import {
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  filterOptions,
} from "../../../../../../../Services/FormHandler";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import { toCapitalize } from "../../../../../../../Services/Format/text_transform";

function Bisnis() {
  const userContext = useContext(UserContext);

  const uploadLogoBisnisRef = useRef(null);

  const [pilihanBisnis, setPilihanBisnis] = useState(null);
  const [namaBisnisLain, setNamaBisnisLain] = useState("");
  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);
  const [
    isClearNamaBisnisLainActive,
    setIsClearNamaBisnisLainActive,
  ] = useState(false);
  const [sejakKapan, setSejakKapan] = useState(null);
  const [isClearSejakKapanActive, setIsClearSejakKapanActive] = useState(false);
  const [isSejakKapanDialogActive, setIsSejakKapanDialogActive] = useState(
    false
  );
  const [jenisBisnis, setJenisBisnis] = useState(null);
  const [jenisBisnisLain, setJenisBisnisLain] = useState("");
  const [
    isClearJenisBisnisLainActive,
    setIsClearJenisBisnisLainActive,
  ] = useState(false);
  const [contactPersonBisnis, setContactPersonBisnis] = useState("");
  const [
    isClearContactPersonBisnisActive,
    setIsClearContactPersonBisnisActive,
  ] = useState(false);
  const [socialMediaBisnis, setSocialMediaBisnis] = useState([]);
  const [idFacebookBisnis, setIdFacebookBisnis] = useState("");
  const [
    isClearIdFacebookBisnisActive,
    setIsClearIdFacebookBisnisActive,
  ] = useState(false);
  const [usernameTwitterBisnis, setUsernameTwitterBisnis] = useState("");
  const [
    isClearUsernameTwitterBisnisActive,
    setIsClearUsernameTwitterBisnisActive,
  ] = useState(false);
  const [usernameInstagramBisnis, setUsernameInstagramBisnis] = useState("");
  const [
    isClearUsernameInstagramBisnisActive,
    setIsClearUsernameInstagramBisnisActive,
  ] = useState(false);
  const [urlProfilLinkedinBisnis, setURLProfilLinkedinBisnis] = useState("");
  const [
    isClearIdLinkedinBisnisActive,
    setIsClearIdLinkedinBisnisActive,
  ] = useState(false);
  const [logoBisnis, setLogoBisnis] = useState(null);
  const [openUploadLogoBisnisDialog, setOpenUploadLogoBisnisDialog] = useState(
    false
  );
  const [isClearLogoBisnisActive, setIsClearLogoBisnisActive] = useState(false);
  const [isStatusInGroupActive, setIsStatusInGroupActive] = useState(false);
  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [optionsPilihanBisnis, setOptionsPilihanBisnis] = useState([]);

  const [optionsJenisBisnis, setOptionsJenisBisnis] = useState([]);

  const [optionsSocialMediaBisnis, setOptionsSocialMediaBisnis] = useState([
    { value: "Facebook", label: "Facebook" },
    { value: "Twitter", label: "Twitter" },
    { value: "Instagram", label: "Instagram" },
    { value: "Linkedin", label: "Linkedin" },
  ]);

  const getOptionLabel = (option) => {
    return option.label;
  };

  const handleChangePilihanBisnis = (event, selected) => {
    setPilihanBisnis(selected);
  };

  const handleChangeNamaBisnisLain = (event) => {
    setNamaBisnisLain(event.target.value);
  };

  const handleMouseEnterNamaBisnisLain = () => {
    setIsClearNamaBisnisLainActive(namaBisnisLain ? true : false);
  };

  const handleMouseLeaveNamaBisnisLain = () => {
    setIsClearNamaBisnisLainActive(false);
  };

  const handleClickClearNamaBisnisLain = () => {
    setNamaBisnisLain("");
    setIsClearNamaBisnisLainActive(false);
  };

  const handleChangeSejakKapan = (selected) => {
    setSejakKapan(selected);
  };

  const handleMouseEnterSejakKapan = () => {
    setIsClearSejakKapanActive(sejakKapan ? true : false);
  };

  const handleMouseLeaveSejakKapan = () => {
    setIsClearSejakKapanActive(false);
  };

  const handleMouseDownClearSejakKapan = () => {
    setSejakKapan(null);
    setIsClearSejakKapanActive(false);
    setIsSejakKapanDialogActive(false);
  };

  const handleToggleSejakKapanDialogActive = () => {
    setIsSejakKapanDialogActive(!isSejakKapanDialogActive);
  };

  const handleChangeJenisBisnis = (event, selected) => {
    setJenisBisnis(selected);
  };

  const handleChangeContactPersonBisnis = (event) => {
    setContactPersonBisnis(event.target.value);
  };

  const handleMouseEnterContactPersonBisnis = () => {
    setIsClearContactPersonBisnisActive(contactPersonBisnis ? true : false);
  };

  const handleMouseLeaveContactPersonBisnis = () => {
    setIsClearContactPersonBisnisActive(false);
  };

  const handleClickClearContactPersonBisnis = () => {
    setContactPersonBisnis("");
    setIsClearContactPersonBisnisActive(false);
  };

  const handleChangeSocialMediaBisnis = (event, selected) => {
    setSocialMediaBisnis(selected);
  };

  const handleChangeIdFacebookBisnis = (event) => {
    setIdFacebookBisnis(event.target.value);
  };

  const handleMouseEnterIdFacebookBisnis = () => {
    setIsClearIdFacebookBisnisActive(idFacebookBisnis ? true : false);
  };

  const handleMouseLeaveIdFacebookBisnis = () => {
    setIsClearIdFacebookBisnisActive(false);
  };

  const handleClickClearIdFacebookBisnis = () => {
    setIdFacebookBisnis("");
    setIsClearIdFacebookBisnisActive(false);
  };

  const handleChangeUsernameTwitterBisnis = (event) => {
    setUsernameTwitterBisnis(event.target.value);
  };

  const handleMouseEnterUsernameTwitterBisnis = () => {
    setIsClearUsernameTwitterBisnisActive(usernameTwitterBisnis ? true : false);
  };

  const handleMouseLeaveUsernameTwitterBisnis = () => {
    setIsClearUsernameTwitterBisnisActive(false);
  };

  const handleClickClearUsernameTwitterBisnis = () => {
    setUsernameTwitterBisnis("");
    setIsClearUsernameTwitterBisnisActive(false);
  };

  const handleChangeUsernameInstagramBisnis = (event) => {
    setUsernameInstagramBisnis(event.target.value);
  };

  const handleMouseEnterUsernameInstagramBisnis = () => {
    setIsClearUsernameInstagramBisnisActive(
      usernameInstagramBisnis ? true : false
    );
  };

  const handleMouseLeaveUsernameInstagramBisnis = () => {
    setIsClearUsernameInstagramBisnisActive(false);
  };

  const handleClickClearUsernameInstagramBisnis = () => {
    setUsernameInstagramBisnis("");
    setIsClearUsernameInstagramBisnisActive(false);
  };

  const handleChangeIdLinkedinBisnis = (event) => {
    setURLProfilLinkedinBisnis(event.target.value);
  };

  const handleMouseEnterIdLinkedinBisnis = () => {
    setIsClearIdLinkedinBisnisActive(urlProfilLinkedinBisnis ? true : false);
  };

  const handleMouseLeaveIdLinkedinBisnis = () => {
    setIsClearIdLinkedinBisnisActive(false);
  };

  const handleClickClearIdLinkedinBisnis = () => {
    setURLProfilLinkedinBisnis("");
    setIsClearIdLinkedinBisnisActive(false);
  };

  const handleClickUploadLogoBisnis = () => {
    setOpenUploadLogoBisnisDialog(true);
  };

  const handleCloseUploadLogoBisnis = () => {
    setOpenUploadLogoBisnisDialog(false);
  };

  const handleSaveUploadLogoBisnis = (files) => {
    setLogoBisnis(files[0]);
    uploadLogoBisnisRef.current.focus();
    setOpenUploadLogoBisnisDialog(false);
  };

  const handleMouseEnterClearLogoBisnis = () => {
    setIsClearLogoBisnisActive(logoBisnis ? true : false);
  };

  const handleMouseLeaveClearLogoBisnis = () => {
    setIsClearLogoBisnisActive(false);
  };

  const handleClickClearLogoBisnis = () => {
    setLogoBisnis(null);
    setIsClearLogoBisnisActive(false);
  };

  const handleChangeStatusInGroup = () => {
    setIsStatusInGroupActive(!isStatusInGroupActive);
  };

  const handleFormSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleCloseErrorSnackbar = () => {
    setIsErrorSnackbarActive(false);
  };

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("pilihanBisnis")) {
      if (!pilihanBisnis) {
        _errorList.push({
          type: "required",
          message: "Pilihan Bisnis wajib dipilih.",
        });
      }
    }

    if (target.includes("namaBisnisLain")) {
      if (!namaBisnisLain) {
        _errorList.push({
          type: "required",
          message: "Nama Bisnis Lainnya wajib diisi.",
        });
      }
    }

    if (target.includes("sejakKapan")) {
      if (!sejakKapan) {
        _errorList.push({
          type: "required",
          message: "Sejak Kapan wajib diisi.",
        });
      }
    }

    if (target.includes("jenisBisnis")) {
      if (!jenisBisnis) {
        _errorList.push({
          type: "required",
          message: "Jenis Bisnis wajib dipilih.",
        });
      }
    }

    if (target.includes("jenisBisnisLain")) {
      if (!jenisBisnisLain) {
        _errorList.push({
          type: "required",
          message: "Jenis Bisnis Lainnya wajib diisi.",
        });
      }
    }

    if (target.includes("contactPersonBisnis")) {
      if (!contactPersonBisnis) {
        _errorList.push({
          type: "required",
          message: "Contact Person Bisnis wajib diisi.",
        });
      } else {
        const regExp = /^[0-9]*$/;
        if (!regExp.test(contactPersonBisnis)) {
          _errorList.push({
            type: "pattern",
            message: "Format Contact Person Bisnis wajib berupa angka.",
          });
        }
      }
    }

    if (target.includes("socialMediaBisnis")) {
      if (socialMediaBisnis.length === 0) {
        _errorList.push({
          type: "required",
          message: "Social Media Bisnis wajib dipilih.",
        });
      }
    }

    if (target.includes("idFacebookBisnis")) {
      if (!idFacebookBisnis) {
        _errorList.push({
          type: "required",
          message: "ID Facebook Bisnis wajib diisi.",
        });
      }
    }

    if (target.includes("usernameTwitterBisnis")) {
      if (!usernameTwitterBisnis) {
        _errorList.push({
          type: "required",
          message: "Username Twitter Bisnis wajib diisi.",
        });
      }
    }

    if (target.includes("usernameInstagramBisnis")) {
      if (!usernameInstagramBisnis) {
        _errorList.push({
          type: "required",
          message: "Username Instagram Bisnis wajib diisi.",
        });
      }
    }

    if (target.includes("urlProfilLinkedinBisnis")) {
      if (!urlProfilLinkedinBisnis) {
        _errorList.push({
          type: "required",
          message: "ID Linkedin Bisnis wajib diisi.",
        });
      }
    }

    if (target.includes("logoBisnis")) {
      if (!logoBisnis) {
        _errorList.push({
          type: "required",
          message: "Logo Bisnis wajib diupload.",
        });
      }
    }

    return _errorList;
  };

  const handleResetData = () => {
    setPilihanBisnis(null);
    setNamaBisnisLain("");
    setSejakKapan(null);
    setJenisBisnis(null);
    setJenisBisnisLain("");
    setContactPersonBisnis("");
    setSocialMediaBisnis([]);
    setIdFacebookBisnis("");
    setUsernameTwitterBisnis("");
    setUsernameInstagramBisnis("");
    setURLProfilLinkedinBisnis("");
    setLogoBisnis(null);
    setIsStatusInGroupActive(false);
  };

  useDidMountEffect(() => {
    setIsClearJenisBisnisLainActive(jenisBisnisLain ? true : false);
  }, [jenisBisnisLain]);

  useDidMountEffect(() => {
    setIsClearNamaBisnisLainActive(namaBisnisLain ? true : false);
  }, [namaBisnisLain]);

  useDidMountEffect(() => {
    setIsClearContactPersonBisnisActive(contactPersonBisnis ? true : false);
  }, [contactPersonBisnis]);

  useDidMountEffect(() => {
    setIsClearIdFacebookBisnisActive(idFacebookBisnis ? true : false);
  }, [idFacebookBisnis]);

  useDidMountEffect(() => {
    setIsClearUsernameTwitterBisnisActive(usernameTwitterBisnis ? true : false);
  }, [usernameTwitterBisnis]);

  useDidMountEffect(() => {
    setIsClearUsernameInstagramBisnisActive(
      usernameInstagramBisnis ? true : false
    );
  }, [usernameInstagramBisnis]);

  useDidMountEffect(() => {
    setIsClearIdLinkedinBisnisActive(urlProfilLinkedinBisnis ? true : false);
  }, [urlProfilLinkedinBisnis]);

  useDidMountEffect(() => {
    if (isFormSubmitted) {
      setIsErrorSnackbarActive(errorList ? true : false);
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsButtonActionLoading(true);

          if (pilihanBisnis.value !== "Bisnis Lainnya") {
            const insertDetail = await insertDetailBisnis({
              id_bisnis: pilihanBisnis.value,
              id_pengguna: userContext.userActive.id_pengguna,
              keterangan: "Bergabung",
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
            });

            if (insertDetail.success) {
              swal("Sukses!", insertDetail.message, "success");
            } else {
              swal(
                "Oops!",
                insertDetail.message
                  ? insertDetail.message
                  : "Internal Server Error",
                "warning"
              );
            }
          } else {
            const dataBisnis = {
              keterangan: "Bisnis baru",
              nama_bisnis: namaBisnisLain,
              jenis_bisnis:
                jenisBisnis.value === "Jenis Bisnis Lainnya"
                  ? toCapitalize(jenisBisnisLain)
                  : jenisBisnis.value,
              sejak_kapan: dateFormat(new Date(sejakKapan), "yyyy-MM-dd"),
              contact_person_bisnis: contactPersonBisnis,
              facebook_bisnis: idFacebookBisnis,
              twitter_bisnis: usernameTwitterBisnis,
              instagram_bisnis: usernameInstagramBisnis,
              linkedin_bisnis: urlProfilLinkedinBisnis,
              status_in_group: isStatusInGroupActive ? 1 : 0,
              id_pengguna: userContext.userActive.id_pengguna,
              tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
            };

            const logoBisnisFormData = new FormData();

            if (logoBisnis) {
              logoBisnisFormData.append("single_file", logoBisnis);
            }

            const [
              _uploadLogoBisnis,
              _insertBisnis,
            ] = await Promise.allSettled([
              uploadLogoBisnis(logoBisnisFormData),
              insertBisnis(dataBisnis),
            ]);

            if (
              _uploadLogoBisnis.value.success &&
              _insertBisnis.value.success
            ) {
              const _updateLogoBisnis = await updateLogoBisnis({
                logo_bisnis: _uploadLogoBisnis.value.data
                  ? _uploadLogoBisnis.value.data
                  : null,
                id_bisnis: _insertBisnis.value.id,
              });

              if (_updateLogoBisnis.success) {
                swal("Sukses!", "Data berhasil terkirim.", "success");
              } else {
                swal("Oops!", "Internal Server Error", "warning");
              }
            } else {
              if (!_uploadLogoBisnis.value.success) {
                swal("Oops!", "Logo Bisnis gagal diupload.", "warning");
              } else if (!_insertBisnis.value.success) {
                swal("Oops!", "Data gagal terkirim.", "warning");
              }
            }
          }

          setIsButtonActionLoading(false);
          setTimeout(handleResetData, 0);
        }
      }, 0);
    }
  }, [errorList]);

  useDidMountEffect(() => {
    if (isFormSubmitted) {
      let _socialMediaBisnisActive = [];

      if (socialMediaBisnis.some((element) => element.value === "Facebook")) {
        _socialMediaBisnisActive.push("idFacebookBisnis");
      }

      if (socialMediaBisnis.some((element) => element.value === "Twitter")) {
        _socialMediaBisnisActive.push("usernameTwitterBisnis");
      }

      if (socialMediaBisnis.some((element) => element.value === "Instagram")) {
        _socialMediaBisnisActive.push("usernameInstagramBisnis");
      }

      if (socialMediaBisnis.some((element) => element.value === "Linkedin")) {
        _socialMediaBisnisActive.push("urlProfilLinkedinBisnis");
      }

      setErrorList(
        getErrorList(
          pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
            ? []
            : [
                pilihanBisnis && pilihanBisnis.value === "Bisnis Lainnya"
                  ? "namaBisnisLain"
                  : "pilihanBisnis",
              ]
                .concat(["sejakKapan", "jenisBisnis"])
                .concat(
                  jenisBisnis && jenisBisnis.value === "Jenis Bisnis Lainnya"
                    ? ["jenisBisnisLain"]
                    : []
                )
                .concat(["contactPersonBisnis", "socialMediaBisnis"])
                .concat(_socialMediaBisnisActive)
                .concat(["logoBisnis"])
        )
      );
    }
  }, [isFormSubmitted]);

  useEffect(() => {
    (async () => {
      const [bisnisAvailable, jenisBisnisAvailable] = await Promise.all([
        getBisnis({
          fields: [
            "id_bisnis",
            "jenis_bisnis",
            "nama_bisnis",
            "sejak_kapan",
            "contact_person_bisnis",
            "logo_bisnis",
            "facebook_bisnis",
            "instagram_bisnis",
            "twitter_bisnis",
            "linkedin_bisnis",
            "status_in_group",
          ],
          search: {
            groupOp: "",
            rules: [
              {
                field: "status_accepted",
                op: "=",
                data: 1,
              },
            ],
          },
          order: {
            by: "nama_bisnis",
            mode: "ASC",
          },
          offset: null,
          limit: null,
        }),
        getAllJenisBisnis(),
      ]);

      if (bisnisAvailable.success && jenisBisnisAvailable.success) {
        setOptionsPilihanBisnis(
          [{ value: "Bisnis Lainnya", label: "Bisnis Lainnya" }].concat(
            bisnisAvailable.data.map((item) => {
              return {
                value: item.id_bisnis,
                label: item.nama_bisnis,
                sejak_kapan: item.sejak_kapan,
                jenis_bisnis: {
                  value: item.jenis_bisnis,
                  label: item.jenis_bisnis,
                },
                social_media_bisnis: [
                  item.facebook_bisnis
                    ? {
                        value: "Facebook",
                        label: "Facebook",
                      }
                    : [],
                ]
                  .concat([
                    item.instagram_bisnis
                      ? {
                          value: "Instagram",
                          label: "Instagram",
                        }
                      : [],
                  ])
                  .concat([
                    item.twitter_bisnis
                      ? {
                          value: "Twitter",
                          label: "Twitter",
                        }
                      : [],
                  ])
                  .concat([
                    item.linkedin_bisnis
                      ? {
                          value: "Linkedin",
                          label: "Linkedin",
                        }
                      : [],
                  ]),
                contact_person_bisnis: item.contact_person_bisnis,
                facebook_bisnis: item.facebook_bisnis,
                instagram_bisnis: item.instagram_bisnis,
                twitter_bisnis: item.twitter_bisnis,
                linkedin_bisnis: item.linkedin_bisnis,
                logo_bisnis: item.logo_bisnis,
                status_in_group: item.status_in_group,
              };
            })
          )
        );
        setOptionsJenisBisnis(
          [
            {
              value: "Jenis Bisnis Lainnya",
              label: "Jenis Bisnis Lainnya",
            },
          ].concat(
            jenisBisnisAvailable.data.map((item) => {
              return { value: item.jenis_bisnis, label: item.jenis_bisnis };
            })
          )
        );
      }
    })();
  }, []);

  return (
    <div className="user-page-bisnis">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/3.svg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => userContext.dispatchMenuActive("SelamatDatang")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">Bisnis</span>
        <span className="sub-title-text">Pendataan Bisnis</span>
      </div>
      <div className="body-area">
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <div className="form-group required">
            <Autocomplete
              id="bisnis-pilihan-bisnis"
              options={optionsPilihanBisnis}
              filterOptions={filterOptions}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={handleChangePilihanBisnis}
              value={pilihanBisnis || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pilih Bisnis yang tersedia"
                  variant="outlined"
                  helperText='*Pilih "Bisnis Lainnya" jika bisnis yang dimaksud belum terdaftar.'
                />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          {pilihanBisnis && pilihanBisnis.value === "Bisnis Lainnya" && (
            <div className="form-group required">
              <TextField
                id="bisnis-nama-bisnis-lainnya"
                label="Nama Bisnis Lainnya"
                variant="outlined"
                value={namaBisnisLain || ""}
                onChange={handleChangeNamaBisnisLain}
                onMouseEnter={handleMouseEnterNamaBisnisLain}
                onMouseLeave={handleMouseLeaveNamaBisnisLain}
                InputProps={{
                  endAdornment: isClearNamaBisnisLainActive && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickClearNamaBisnisLain}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <div
            className={`form-group required${
              pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                ? " disabled"
                : ""
            }`}
          >
            <DatePicker
              format="dd-MM-yyyy"
              label="Sejak Kapan"
              maxDate={new Date()}
              openTo="year"
              views={["year", "month", "date"]}
              value={
                (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? pilihanBisnis.sejak_kapan
                  : sejakKapan) || null
              }
              inputVariant="outlined"
              onChange={handleChangeSejakKapan}
              onMouseEnter={handleMouseEnterSejakKapan}
              onMouseLeave={handleMouseLeaveSejakKapan}
              open={isSejakKapanDialogActive}
              onOpen={handleToggleSejakKapanDialogActive}
              onClose={handleToggleSejakKapanDialogActive}
              InputProps={{
                endAdornment: isClearSejakKapanActive && (
                  <InputAdornment position="end">
                    <IconButton onMouseDown={handleMouseDownClearSejakKapan}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            className={`form-group required${
              pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                ? " disabled"
                : ""
            }`}
          >
            <Autocomplete
              id="bisnis-jenis-bisnis"
              options={optionsJenisBisnis}
              filterOptions={filterOptions}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={handleChangeJenisBisnis}
              value={
                (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? pilihanBisnis.jenis_bisnis
                  : jenisBisnis) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Jenis Bisnis"
                  variant="outlined"
                  helperText='*Pilih "Jenis Bisnis Lainnya" jika jenis bisnis yang dimaksud belum terdaftar.'
                />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          {jenisBisnis && jenisBisnis.value === "Jenis Bisnis Lainnya" && (
            <div
              className={`form-group required${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
            >
              <TextField
                id="bisnis-jenis-bisnis-lain"
                label="Nama Jenis Bisnis Lainnya"
                variant="outlined"
                value={jenisBisnisLain || ""}
                onChange={(event) =>
                  handleChangeTextField(event, setJenisBisnisLain)
                }
                onMouseEnter={() =>
                  handleMouseEnterTextField(
                    jenisBisnisLain,
                    setIsClearJenisBisnisLainActive
                  )
                }
                onMouseLeave={() =>
                  handleMouseLeaveTextField(setIsClearJenisBisnisLainActive)
                }
                InputProps={{
                  endAdornment: isClearJenisBisnisLainActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleClickClearTextField(
                            setJenisBisnisLain,
                            setIsClearJenisBisnisLainActive
                          )
                        }
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <div
            className={`form-group required${
              pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                ? " disabled"
                : ""
            }`}
          >
            <TextField
              id="bisnis-contact-person-bisnis"
              label="Contact Person Bisnis"
              variant="outlined"
              value={
                (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? pilihanBisnis.contact_person_bisnis
                  : contactPersonBisnis) || ""
              }
              onChange={handleChangeContactPersonBisnis}
              onMouseEnter={handleMouseEnterContactPersonBisnis}
              onMouseLeave={handleMouseLeaveContactPersonBisnis}
              InputProps={{
                endAdornment: isClearContactPersonBisnisActive && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickClearContactPersonBisnis}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            className={`form-group required${
              pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                ? " disabled"
                : ""
            }`}
          >
            <Autocomplete
              id="bisnis-social-media-bisnis"
              multiple
              options={optionsSocialMediaBisnis}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={handleChangeSocialMediaBisnis}
              value={
                (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? pilihanBisnis.social_media_bisnis
                  : socialMediaBisnis) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Social Media Bisnis"
                  variant="outlined"
                />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          {(pilihanBisnis &&
            pilihanBisnis.value !== "Bisnis Lainnya" &&
            pilihanBisnis.social_media_bisnis.some(
              (element) => element.value === "Facebook"
            )) ||
          socialMediaBisnis.some((element) => element.value === "Facebook") ? (
            <div
              className={`form-group required${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
            >
              <TextField
                id="bisnis-id-facebook-bisnis"
                label="ID Facebook Bisnis"
                variant="outlined"
                value={
                  (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                    ? pilihanBisnis.facebook_bisnis
                    : idFacebookBisnis) || ""
                }
                onChange={handleChangeIdFacebookBisnis}
                onMouseEnter={handleMouseEnterIdFacebookBisnis}
                onMouseLeave={handleMouseLeaveIdFacebookBisnis}
                InputProps={{
                  endAdornment: isClearIdFacebookBisnisActive && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickClearIdFacebookBisnis}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : null}
          {(pilihanBisnis &&
            pilihanBisnis.value !== "Bisnis Lainnya" &&
            pilihanBisnis.social_media_bisnis.some(
              (element) => element.value === "Twitter"
            )) ||
          socialMediaBisnis.some((element) => element.value === "Twitter") ? (
            <div
              className={`form-group required${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
            >
              <TextField
                id="bisnis-username-twitter-bisnis"
                label="Username Twitter Bisnis"
                variant="outlined"
                value={
                  (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                    ? pilihanBisnis.twitter_bisnis
                    : usernameTwitterBisnis) || ""
                }
                onChange={handleChangeUsernameTwitterBisnis}
                onMouseEnter={handleMouseEnterUsernameTwitterBisnis}
                onMouseLeave={handleMouseLeaveUsernameTwitterBisnis}
                InputProps={{
                  endAdornment: isClearUsernameTwitterBisnisActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickClearUsernameTwitterBisnis}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : null}
          {(pilihanBisnis &&
            pilihanBisnis.value !== "Bisnis Lainnya" &&
            pilihanBisnis.social_media_bisnis.some(
              (element) => element.value === "Instagram"
            )) ||
          socialMediaBisnis.some((element) => element.value === "Instagram") ? (
            <div
              className={`form-group required${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
            >
              <TextField
                id="bisnis-username-instagram-bisnis"
                label="Username Instagram Bisnis"
                variant="outlined"
                value={
                  (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                    ? pilihanBisnis.instagram_bisnis
                    : usernameInstagramBisnis) || ""
                }
                onChange={handleChangeUsernameInstagramBisnis}
                onMouseEnter={handleMouseEnterUsernameInstagramBisnis}
                onMouseLeave={handleMouseLeaveUsernameInstagramBisnis}
                InputProps={{
                  endAdornment: isClearUsernameInstagramBisnisActive && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickClearUsernameInstagramBisnis}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : null}
          {(pilihanBisnis &&
            pilihanBisnis.value !== "Bisnis Lainnya" &&
            pilihanBisnis.social_media_bisnis.some(
              (element) => element.value === "Linkedin"
            )) ||
          socialMediaBisnis.some((element) => element.value === "Linkedin") ? (
            <div
              className={`form-group required${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
            >
              <TextField
                id="bisnis-id-linkedin-bisnis"
                label="URL Profil Linkedin Bisnis"
                variant="outlined"
                value={
                  (pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                    ? pilihanBisnis.linkedin_bisnis
                    : urlProfilLinkedinBisnis) || ""
                }
                onChange={handleChangeIdLinkedinBisnis}
                onMouseEnter={handleMouseEnterIdLinkedinBisnis}
                onMouseLeave={handleMouseLeaveIdLinkedinBisnis}
                InputProps={{
                  endAdornment: isClearIdLinkedinBisnisActive && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickClearIdLinkedinBisnis}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          ) : null}
          <div
            className={`form-group required upload-area${
              pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                ? " disabled"
                : isClearLogoBisnisActive
                ? " pointer-active"
                : ""
            }`}
            onMouseEnter={handleMouseEnterClearLogoBisnis}
            onMouseLeave={handleMouseLeaveClearLogoBisnis}
          >
            <TextField
              id="bisnis-upload-logo-bisnis"
              label="Logo Bisnis"
              variant="outlined"
              value={
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? pilihanBisnis.logo_bisnis
                  : logoBisnis
                  ? logoBisnis.name
                  : ""
              }
              ref={uploadLogoBisnisRef}
              readOnly
            />
            {isClearLogoBisnisActive && (
              <div className="clear-icon">
                <ClearIcon onClick={handleClickClearLogoBisnis} />
              </div>
            )}
            <div
              className={`upload-button${
                pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya"
                  ? " disabled"
                  : ""
              }`}
              onClick={handleClickUploadLogoBisnis}
            >
              <span className="upload-text">Upload</span>
            </div>
            <DropzoneDialog
              acceptedFiles={["image/*"]}
              cancelButtonText={"Cancel"}
              submitButtonText={"Upload"}
              maxFileSize={5000000}
              filesLimit={1}
              open={openUploadLogoBisnisDialog}
              onClose={handleCloseUploadLogoBisnis}
              onSave={handleSaveUploadLogoBisnis}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
          </div>
          {pilihanBisnis && pilihanBisnis.value !== "Bisnis Lainnya" ? null : (
            <div className="form-group status-in-group">
              <p className="paragraph-text">
                Apakah mau diinvite ke grup Bisnis IKM FKUI?
              </p>
              <div className="agreement-area">
                <span
                  className={`box-wrapper${
                    isStatusInGroupActive ? " active" : ""
                  }`}
                  onClick={handleChangeStatusInGroup}
                >
                  {isStatusInGroupActive && <FontAwesomeIcon icon={faCheck} />}
                </span>
                <span className="agreement-text">Ya, Mau</span>
              </div>
            </div>
          )}
          <div className="form-action-area">
            <div className="helper-wrapper"></div>
            <div className="button-wrapper">
              <button
                type="submit"
                className="submit-button"
                onClick={handleFormSubmitAction}
              >
                {isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  <span className="button-text">Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={handleCloseErrorSnackbar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </div>
  );
}

export default Bisnis;
