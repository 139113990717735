import React, { useEffect, useState } from "react";
import "./style.css";
import config from "../../../Config";
import { useCookies } from "react-cookie";
import useRouter from "../../../Hooks/useRouter";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";
import { loadUserByToken } from "../../../Services/API/auth";

function Landing() {
  const [cookies] = useCookies(["onedatamakara"]);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    if(cookies && cookies.onedatamakara) {
      try {
        const userByToken = await loadUserByToken(cookies["onedatamakara"]);

        if(userByToken.success) {
          if(userByToken?.data?.status === "verified") {
            router.push("/");
          } else {
            setIsLoading(false);
            return;
          }
        } else {
          setIsLoading(false);
          return;
        }
      } catch (e) {
        setIsLoading(false);
        return;
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <div className="landing-page-wrapper">
          <div className="landing-page">
            <div className="box-wrapper">
              <div className="brand">
                <img src="/odm-logo-title.png" />
              </div>
              <div className="description">
                <p className="single-description">
                  A frictionless information gathering
                </p>
                <p className="single-description">system for IKM FKUI</p>
              </div>
              <div className="button-wrapper">
                <div className="single-button login-button">
                  <a className="button-text" href={`${config.SERVER_URI}/login`}>Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Landing;
