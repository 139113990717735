import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./style.css";
import BoxPanel from "./Components/BoxPanel";
import Pengabdian from "./Components/Pengabdian";
import Bisnis from "./Components/Bisnis";
import Beasiswa from "./Components/Beasiswa";
import LigaKreasi from "./Components/LigaKreasi";
import Reimburse from "./Components/Reimburse";
import KaderisasiBerkelanjutan from "./Components/KaderisasiBerkelanjutan";
import KeteranganKinerja from "./Components/KeteranganKinerja";
import Publikasi from "./Components/Publikasi";
import Organisasi from "./Components/Organisasi";
import Pengumuman from "./Components/Pengumuman";
import Admin from "./Components/Admin";
import Pengguna from "./Components/Pengguna";
import VerifikasiPengguna from "./Components/VerifikasiPengguna";

function BodyContent() {
  return (
    <Router>
      <Switch>
        <Route exact path="/adminpanel">
          <BoxPanel />
        </Route>
        <Route path="/adminpanel/pengabdian">
          <Pengabdian />
        </Route>
        <Route path="/adminpanel/bisnis">
          <Bisnis />
        </Route>
        <Route path="/adminpanel/beasiswa">
          <Beasiswa />
        </Route>
        <Route path="/adminpanel/liga-kreasi">
          <LigaKreasi />
        </Route>
        <Route path="/adminpanel/reimburse">
          <Reimburse />
        </Route>
        <Route path="/adminpanel/kaderisasi-berkelanjutan">
          <KaderisasiBerkelanjutan />
        </Route>
        <Route path="/adminpanel/keterangan-kinerja">
          <KeteranganKinerja />
        </Route>
        <Route path="/adminpanel/publikasi">
          <Publikasi />
        </Route>
        <Route path="/adminpanel/organisasi">
          <Organisasi />
        </Route>
        <Route path="/adminpanel/pengumuman">
          <Pengumuman />
        </Route>
        <Route path="/adminpanel/admin">
          <Admin />
        </Route>
        <Route path="/adminpanel/pengguna">
          <Pengguna />
        </Route>
        <Route path="/adminpanel/verifikasi-pengguna">
          <VerifikasiPengguna />
        </Route>
        <Redirect to="/adminpanel" />
      </Switch>
    </Router>
  );
}

export default BodyContent;
