import "./style.scss";
import React, { useEffect, useState } from "react";
import config from "../../../Config";
import useRouter from "../../../Hooks/useRouter";
import { useCookies } from "react-cookie";
import { loadUserByToken } from "../../../Services/API/auth";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";

function UnsupportedEmail() {
  const [cookies] = useCookies(["onedatamakara"]);
  const router = useRouter();
  const [message, setMessage] = useState(null);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const emailChecking = () => {
    let _message;

    if(router && router?.query?.email) {
      _message = (
        <span className="c-UnsupportedEmail__message">
          Halo! Email <span className="c-UnsupportedEmail__emphasizedText">{router.query.email}</span> tidak disupport oleh sistem. Harap kembali login menggunakan Email Student.
        </span>
      );
    } else {
      _message = (
        <span className="c-UnsupportedEmail__message">
          Halo! Email kamu belum diketahui sistem. Harap kembali login menggunakan Email Student.
        </span>
      );
    }

    setMessage(_message);
    setShowLoginButton(true);
    setIsLoading(false);
  }

  const init = async () => {
    if(cookies && cookies.onedatamakara) {
      try {
        const userByToken = await loadUserByToken(cookies["onedatamakara"]);

        if(userByToken.success) {
          if(userByToken?.data?.status === "verified") {
            router.push("/");
          } else {
            emailChecking();
          }
        } else {
          emailChecking();
        }
      } catch (e) {
        emailChecking();
      }
    } else {
      emailChecking();
    }
  };

  useEffect(() => {
    init();
  }, [router]);

  return (
    <>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <div className="c-UnsupportedEmail">
          {message && (
            <div className="c-UnsupportedEmail__messageWrapper">
              {message}
            </div>
          )}
          {showLoginButton && (
            <div className="c-UnsupportedEmail__actionArea">
              <a 
                href={`${config.SERVER_URI}/login`} 
                className="c-UnsupportedEmail__loginButton"
              >
                Login dengan Email Student
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UnsupportedEmail;