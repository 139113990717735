import React, { useContext } from "react";
import "./style.css";
import SelamatDatang from "./Components/SelamatDatang";
import LigaKreasi from "./Components/LigaKreasi";
import { UserContext } from "../../../../User";
import Bisnis from "./Components/Bisnis";
import Beasiswa from "./Components/Beasiswa";
import KaderisasiBerkelanjutan from "./Components/KaderisasiBerkelanjutan";
import Publikasi from "./Components/Publikasi";
import Pengumuman from "./Components/Pengumuman";

function BodyContent() {
  const userContext = useContext(UserContext);

  return (
    <div className="user-page-body">
      <div className="body-box">
        {userContext.menuActive === "SelamatDatang" ? (
          <SelamatDatang />
        ) : userContext.menuActive === "Pengumuman" ? (
          <Pengumuman />
        ) : userContext.menuActive === "LigaKreasi" ? (
          <LigaKreasi />
        ) : userContext.menuActive === "Bisnis" ? (
          <Bisnis />
        ) : userContext.menuActive === "Beasiswa" ? (
          <Beasiswa />
        ) : userContext.menuActive === "KaderisasiBerkelanjutan" ? (
          <KaderisasiBerkelanjutan />
        ) : userContext.menuActive === "Publikasi" ? (
          <Publikasi />
        ) : null}
      </div>
    </div>
  );
}

export default BodyContent;
