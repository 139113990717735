import React, { useState, useEffect, useRef } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { handleCloseDialog } from "../../../../../../../Services/FormHandler";
import {
  getLigaKreasi,
  getTotalLigaKreasi,
  resetLigaKreasi,
  deleteLigaKreasi,
  verifikasiLigaKreasi,
} from "../../../../../../../Services/API/liga_kreasi";
import {
  getSertifikatPialaMedali,
  getFotoPenyerahanPenghargaan,
  getManuskripKarya,
} from "../../../../../../../Services/API/file";
import { dateFormat } from "../../../../../../../Services/Format/date";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "../../../../../../../Services/Format/blob";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import useOnClickOutside from "../../../../../../../Components/useOnClickOutside";
import { generateExcel } from "../../../../../../../Services/File/excel";

function LigaKreasi() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isResetLoading, setIsResetLoading] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const exportToExcelOptionRef = useRef(null);

  const [
    isExportToExcelOptionActive,
    setIsExportToExcelOptionActive,
  ] = useState(false);

  const [exportToExcelList, setExportToExcelList] = useState([
    {
      value: "Semua",
      isLoading: false,
    },
    {
      value: "Semua Sesuai Filter",
      isLoading: false,
    },
    {
      value: "Hanya Halaman Ini",
      isLoading: false,
    },
  ]);

  // Liga Kreasi ------------------------------------------------------------

  // @Table Section
  // Liga Kreasi

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Liga Kreasi - By Pengajuan

  const [dataByPengajuan, setDataByPengajuan] = useState([]);

  const dataFieldsByPengajuan = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text",
      },
    },
    {
      primary: true,
      checkbox: true,
      value: "id_liga_kreasi",
      type: "text",
      label: "ID Liga Kreasi",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
      exportToExcel: {
        visible: true,
        bgColor: [
          { value: "Accepted", argb: "FF8DF792" },
          { value: "Rejected", argb: "FFF9ADAD" },
          { value: "Pending", argb: "FFF7D881" },
        ],
        cellWidth: 18,
        type: "text",
      }
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "nama_perlombaan",
      type: "text",
      label: "Nama Perlombaan",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "jenis_perlombaan",
      type: "text",
      label: "Jenis Perlombaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "anggota_kelompok",
      type: "multiple-textbox",
      label: "Anggota Kelompok",
      contentFontType: "capitalize",
      size: "xl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 50,
        type: "text"
      }
    },
    {
      primary: false,
      value: "bidang_perlombaan",
      type: "text",
      label: "Bidang Perlombaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_perlombaan",
      type: "text",
      label: "Penyelenggara Perlombaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "tingkat_perlombaan",
      type: "text",
      label: "Tingkat Perlombaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "range_negara_terlibat",
      type: "text",
      label: "Range Negara Terlibat",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text"
      }
    },
    {
      primary: false,
      value: "range_universitas_terlibat",
      type: "text",
      label: "Range Universitas Terlibat",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text"
      }
    },
    {
      primary: false,
      value: "capaian_prestasi",
      type: "text",
      label: "Capaian Prestasi",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "awal_pelaksanaan",
      type: "text",
      label: "Awal Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "akhir_pelaksanaan",
      type: "text",
      label: "Akhir Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "url_penyelenggara",
      type: "textlink",
      label: "URL Penyelenggara",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sertifikat_piala_medali",
      type: "file",
      label: "Sertifikat/Piala/Medali",
      contentGetFile: getSertifikatPialaMedali,
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "textlink",
        prefixLink:
          "https://onedatamakara.s3-ap-southeast-1.amazonaws.com/liga_kreasi/sertifikat_piala_medali/",
      },
    },
    {
      primary: false,
      value: "foto_penyerahan_penghargaan",
      type: "file",
      label: "Foto Penyerahan Penghargaan",
      contentGetFile: getFotoPenyerahanPenghargaan,
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "textlink",
        prefixLink:
          "https://onedatamakara.s3-ap-southeast-1.amazonaws.com/liga_kreasi/foto_penyerahan_penghargaan/",
      },
    },
    {
      primary: false,
      value: "manuskrip_karya",
      type: "file",
      label: "Manuskrip Karya",
      contentGetFile: getManuskripKarya,
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "textlink",
        prefixLink:
          "https://onedatamakara.s3-ap-southeast-1.amazonaws.com/liga_kreasi/manuskrip_karya/",
      },
    },
    {
      primary: false,
      value: "skor",
      type: "text",
      label: "Skor",
      size: "xs",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
  ];

  const [sortItemByPengajuan, setSortItemByPengajuan] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputByPengajuan, setSearchInputByPengajuan] = useState("");

  const optionsSearchFilterByPengajuan = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "nama_perlombaan", label: "Nama Perlombaan" },
    { value: "jenis_perlombaan", label: "Jenis Perlombaan" },
    { value: "anggota_kelompok", label: "Anggota Kelompok" },
    { value: "bidang_perlombaan", label: "Bidang Perlombaan" },
    { value: "penyelenggara_perlombaan", label: "Penyelenggara Perlombaan" },
    { value: "tingkat_perlombaan", label: "Tingkat Perlombaan" },
    { value: "range_negara_terlibat", label: "Range Negara Terlibat" },
    {
      value: "range_universitas_terlibat",
      label: "Range Universitas Terlibat",
    },
    { value: "capaian_prestasi", label: "Capaian Prestasi" },
    { value: "awal_pelaksanaan", label: "Awal Pelaksanaan" },
    { value: "akhir_pelaksanaan", label: "Akhir Pelaksanaan" },
    { value: "url_penyelenggara", label: "URL Penyelenggara" },
    { value: "skor", label: "Skor" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ];

  const [searchFilterByPengajuan, setSearchFilterByPengajuan] = useState([
    optionsSearchFilterByPengajuan[1],
  ]);

  // @Pagination Section
  // Liga Kreasi - By Pengajuan

  const optionsNumberPerPageByPengajuan = [10, 20, 30, 40, 50];

  const [numberPerPageByPengajuan, setNumberPerPageByPengajuan] = useState(
    optionsNumberPerPageByPengajuan[0]
  );

  const [
    numberPerPageFromByPengajuan,
    setNumberPerPageFromByPengajuan,
  ] = useState(0);

  const [numberPerPageToByPengajuan, setNumberPerPageToByPengajuan] = useState(
    0
  );

  const [totalDataByPengajuan, setTotalDataByPengajuan] = useState(0);

  const [totalPageByPengajuan, setTotalPageByPengajuan] = useState(0);

  const [currentPageByPengajuan, setCurrentPageByPengajuan] = useState(0);

  // @Dialog Section
  // Liga Kreasi

  const dialogFullScreenClassName = "liga-kreasi-full-screen-bar";

  const [isViewFileDialogActive, setIsViewFileDialogActive] = useState(false);

  const [objectMime, setObjectMime] = useState(null);

  const [objectURL, setObjectURL] = useState(null);

  const [objectFilename, setObjectFilename] = useState(null);

  // @Export Section
  // Liga Kreasi

  const handleClickSingleListExport = async (exportTarget) => {
    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: true,
            }
          : item;
      })
    );

    const data = await (async () => {
      let _data = {};

      _data.bgHeader = "FFB9CDF7";

      _data.title = "Liga Kreasi";

      _data.fileName = `Liga Kreasi.xlsx`;

      _data.target = dataFieldsByPengajuan.filter((item) =>
        item.exportToExcel.visible ? true : false
      );

      _data.header = dataFieldsByPengajuan
        .filter((item) => (item.exportToExcel.visible ? true : false))
        .map((x) => x.label);

      const byPengajuan = await getLigaKreasi({
        search:
          exportTarget === "Semua"
            ? null
            : {
                groupOp:
                  searchFilterByPengajuan.length > 1 ? "OR" : "",
                rules: searchFilterByPengajuan.map((item) => {
                  return {
                    field: item.value,
                    op: "LIKE",
                    data: searchInputByPengajuan,
                  };
                }),
              },
        order: sortItemByPengajuan,
        limit:
          exportTarget === "Hanya Halaman Ini"
            ? numberPerPageByPengajuan
            : null,
        offset:
          exportTarget === "Hanya Halaman Ini"
            ? (currentPageByPengajuan - 1 < 0
                ? 0
                : currentPageByPengajuan - 1) * numberPerPageByPengajuan
            : null,
      });

      if(byPengajuan.success) {
        let no = 1;

        _data.value = byPengajuan.data.map((x) => {
          let item = [no++];

          _data.target.slice(1, _data.target.length).forEach((t) => {
            const val = x[t.value];

            item.push(
              val
                ? t.exportToExcel.type === "textlink"
                  ? t.exportToExcel.prefixLink + val
                  : val
                : "-"
            );
          });

          return item;
        });

        _data.total = byPengajuan.data.length;
      }

      return _data;
    })();

    generateExcel({
      title: data.title,
      subTitle: `Total Data: ${data.total}`,
      header: data.header,
      bgHeader: data.bgHeader,
      fileName: data.fileName,
      data: data.value,
      cell: data.target,
    });

    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: false,
            }
          : item;
      })
    );
  };

  const handleUpdateDataByPengajuan = (
    id_liga_kreasi,
    status,
    skor,
    tanggal_verifikasi
  ) => {
    setDataByPengajuan(
      dataByPengajuan.map((item, index) => {
        return item.id_liga_kreasi === id_liga_kreasi
          ? {
              ...item,
              status,
              skor,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleClickSingleBodyContentByPengajuan = async ({
    id_liga_kreasi,
    jenis_perlombaan,
    tingkat_perlombaan,
    capaian_prestasi,
  }) => {
    let status;
    let tanggal_verifikasi;
    let skor = 0;

    skor +=
      jenis_perlombaan === "Individu"
        ? 5
        : jenis_perlombaan === "Kelompok"
        ? 3
        : 0;

    skor +=
      tingkat_perlombaan === "Fakultas/Universitas"
        ? 3
        : tingkat_perlombaan === "Provinsi/Wilayah"
        ? 5
        : tingkat_perlombaan === "Nasional"
        ? 8
        : tingkat_perlombaan === "Internasional"
        ? 10
        : 0;

    skor +=
      capaian_prestasi === "Finalis Lomba"
        ? 2
        : capaian_prestasi === "Pengakuan/Apresiasi (Best Category)"
        ? 3
        : capaian_prestasi === "Juara Harapan I atau Runner Up III"
        ? 3
        : capaian_prestasi === "Juara III atau Runner Up II"
        ? 5
        : capaian_prestasi === "Juara II atau Runner Up I"
        ? 7
        : capaian_prestasi === "Juara I atau Winner" ||
          capaian_prestasi === "Juara Umum"
        ? 10
        : 0;

    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      let update;

      switch (value) {
        case "accept":
          status = "Accepted";
          tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
          update = await verifikasiLigaKreasi({
            id_liga_kreasi,
            status,
            skor,
            tanggal_verifikasi,
          });
          break;
        case "reject":
          status = "Rejected";
          skor = 0;
          tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
          update = await verifikasiLigaKreasi({
            id_liga_kreasi,
            status,
            skor,
            tanggal_verifikasi,
          });
          break;
      }

      if (update) {
        if (update.success) {
          handleUpdateDataByPengajuan(
            id_liga_kreasi,
            status,
            skor,
            tanggal_verifikasi
          );
        } else {
          swal("Oops!", "Verifikasi gagal.", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [byPengajuan, totalByPengajuan] = await Promise.all([
      getLigaKreasi({
        order: sortItemByPengajuan,
        limit: numberPerPageByPengajuan,
        offset: 0,
      }),
      getTotalLigaKreasi({}),
    ]);

    if (byPengajuan.success && totalByPengajuan.success) {
      // Set Liga Kreasi - ByPengajuan
      setDataByPengajuan(byPengajuan.data);
      setTotalDataByPengajuan(totalByPengajuan.data.total);
      setTotalPageByPengajuan(
        Math.ceil(totalByPengajuan.data.total / numberPerPageByPengajuan)
      );
      setCurrentPageByPengajuan(totalByPengajuan.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByPengajuan(
        totalByPengajuan.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByPengajuan(
        totalByPengajuan.data.total > 0 ? byPengajuan.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  const resetLigaKreasiButtonOnClick = () => {
    swal({
      title: "Yakin ingin reset liga kreasi?",
      text: "Data liga kreasi tidak akan hilang, hanya direset.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Reset",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if (value === "accept") {
        setIsResetLoading(true);

        const _reset = await resetLigaKreasi();

        setIsResetLoading(false);

        if (_reset.success) {
          swal("Sukses!", _reset.message, "success");
        } else {
          swal("Oops!", _reset.message ? _reset.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        _delete = await deleteLigaKreasi(dataSelected);

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  useOnClickOutside(exportToExcelOptionRef, () => {
    if (isExportToExcelOptionActive) {
      setIsExportToExcelOptionActive(false);
    }
  });

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper liga-kreasi-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Liga Kreasi</h1>
            <div className="header-right-button-area">
              <div className="single-button reset-liga-kreasi-button" onClick={resetLigaKreasiButtonOnClick}>
                {isResetLoading ? <CircularProgress size={17} className="loading-circle" /> : "Reset Liga Kreasi"}
              </div>
              <div className="single-button export-to-excel-button" onMouseDown={() => setIsExportToExcelOptionActive(!isExportToExcelOptionActive) }
              >
                <span className="button-text">Export to Excel</span>
                <span
                  className={`caret-down-icon${
                    isExportToExcelOptionActive ? " rotate" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
              <div className={`export-to-excel-option${isExportToExcelOptionActive ? " active" : ""}`} ref={exportToExcelOptionRef}>
                <ul className="list-export-wrapper">
                  {exportToExcelList.map((item, index) => (
                    <li
                      className="single-list"
                      onClick={() => handleClickSingleListExport(item.value)}
                      key={index}
                    >
                      {item.isLoading ? (
                        <CircularProgress
                          size={13}
                          className="loading-circle"
                        />
                      ) : (
                        item.value
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setDataByPengajuan}
                        getData={getLigaKreasi}
                        dataFields={dataFieldsByPengajuan}
                        sortItem={sortItemByPengajuan}
                        searchInput={searchInputByPengajuan}
                        setSearchInput={setSearchInputByPengajuan}
                        optionsSearchFilterBy={optionsSearchFilterByPengajuan}
                        searchFilterBy={searchFilterByPengajuan}
                        setSearchFilterBy={setSearchFilterByPengajuan}
                        numberPerPage={numberPerPageByPengajuan}
                        setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                        setNumberPerPageTo={setNumberPerPageToByPengajuan}
                        getTotalData={getTotalLigaKreasi}
                        setTotalData={setTotalDataByPengajuan}
                        setTotalPage={setTotalPageByPengajuan}
                        setCurrentPage={setCurrentPageByPengajuan}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setDataByPengajuan}
                        getData={getLigaKreasi}
                        dataFields={dataFieldsByPengajuan}
                        sortItem={sortItemByPengajuan}
                        searchInput={searchInputByPengajuan}
                        searchFilterBy={searchFilterByPengajuan}
                        optionsNumberPerPage={optionsNumberPerPageByPengajuan}
                        numberPerPage={numberPerPageByPengajuan}
                        setNumberPerPage={setNumberPerPageByPengajuan}
                        numberPerPageFrom={numberPerPageFromByPengajuan}
                        setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                        numberPerPageTo={numberPerPageToByPengajuan}
                        setNumberPerPageTo={setNumberPerPageToByPengajuan}
                        totalData={totalDataByPengajuan}
                        getTotalData={getTotalLigaKreasi}
                        setTotalData={setTotalDataByPengajuan}
                        totalPage={totalPageByPengajuan}
                        setTotalPage={setTotalPageByPengajuan}
                        currentPage={currentPageByPengajuan}
                        setCurrentPage={setCurrentPageByPengajuan}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={dataByPengajuan}
                  setData={setDataByPengajuan}
                  getData={getLigaKreasi}
                  dataFields={dataFieldsByPengajuan}
                  sortItem={sortItemByPengajuan}
                  setSortItem={setSortItemByPengajuan}
                  searchInput={searchInputByPengajuan}
                  searchFilterBy={searchFilterByPengajuan}
                  numberPerPage={numberPerPageByPengajuan}
                  setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                  setNumberPerPageTo={setNumberPerPageToByPengajuan}
                  totalData={totalDataByPengajuan}
                  getTotalData={getTotalLigaKreasi}
                  setTotalData={setTotalDataByPengajuan}
                  setTotalPage={setTotalPageByPengajuan}
                  setCurrentPage={setCurrentPageByPengajuan}
                  objectMime={objectMime}
                  setObjectMime={setObjectMime}
                  objectURL={objectURL}
                  setObjectURL={setObjectURL}
                  objectFilename={objectFilename}
                  setObjectFilename={setObjectFilename}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  setIsFullScreenLoading={setIsFullScreenLoading}
                  isViewFileDialogActive={isViewFileDialogActive}
                  setIsViewFileDialogActive={setIsViewFileDialogActive}
                  handleClickSingleBodyContent={
                    handleClickSingleBodyContentByPengajuan
                  }
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <Dialog
        fullScreen
        open={isViewFileDialogActive}
        onClose={() => handleCloseDialog(setIsViewFileDialogActive)}
        className={`full-screen-app-bar${
          dialogFullScreenClassName ? " " + dialogFullScreenClassName : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsViewFileDialogActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              File Viewer
            </Typography>
            {objectURL &&
              objectMime &&
              objectFilename &&
              objectMime.split("/")[0] === "image" && (
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => downloadBlob(objectURL, objectFilename)}
                  className="single-button-right-top-custom"
                >
                  Download
                </Button>
              )}
          </Toolbar>
        </AppBar>
        {objectURL && objectMime && objectMime === "application/pdf" && (
          <div className="fullscreen-pdf-file-viewer">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div className="pdf-file-viewer">
                <Viewer fileUrl={objectURL} />
              </div>
            </Worker>
          </div>
        )}
        {objectURL && objectMime && objectMime.split("/")[0] === "image" && (
          <div className="fullscreen-image-file-viewer">
            <img className="image-file" src={objectURL} />
          </div>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default LigaKreasi;
