import React from "react";
import { Bar } from "react-chartjs-2";

// bem1 345
// bem2 638
// golputbem 17

// bpm 1 794
// bpm 2 187
// golput 19

function Chart({ data }) {
  return (
    <div className="chart-pemira__page">
      <div>
        <Bar
          data={data}
          width={40}
          height={250}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    </div>
  );
}

export default Chart;
