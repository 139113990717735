import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { dateFormat } from "../../../../../../../Services/Format/date";
import {
  getPublikasi,
  getTotalPublikasi,
  verifikasiPublikasi,
} from "../../../../../../../Services/API/publikasi";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";

function Publikasi() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Publikasi -------------------------------------------------------------

  // @Table Section
  // Publikasi

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Publikasi - By Pengajuan

  const [dataByPengajuan, setDataByPengajuan] = useState([]);

  const dataFieldsByPengajuan = [
    {
      primary: true,
      checkbox: true,
      value: "id_publikasi",
      type: "text",
      label: "ID Publikasi",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "judul_publikasi",
      type: "text",
      label: "Judul Publikasi",
      size: "l",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_publikasi",
      type: "text",
      label: "Tanggal Publikasi",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "link_publikasi",
      type: "textlink",
      label: "Link Publikasi",
      size: "m",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByPengajuan, setSortItemByPengajuan] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [searchInputByPengajuan, setSearchInputByPengajuan] = useState("");

  const [
    optionsSearchFilterByPengajuan,
    setOptionsSearchFilterByPengajuan,
  ] = useState([
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "judul_publikasi", label: "Judul Publikasi" },
    { value: "tanggal_publikasi", label: "Tanggal Publikasi" },
    { value: "link_publikasi", label: "Link Publikasi" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ]);

  const [searchFilterByPengajuan, setSearchFilterByPengajuan] = useState([
    optionsSearchFilterByPengajuan[1],
  ]);

  // @Pagination Section
  // Publikasi - By Pengajuan

  const optionsNumberPerPageByPengajuan = [10, 20, 30, 40, 50];

  const [numberPerPageByPengajuan, setNumberPerPageByPengajuan] = useState(
    optionsNumberPerPageByPengajuan[0]
  );

  const [
    numberPerPageFromByPengajuan,
    setNumberPerPageFromByPengajuan,
  ] = useState(0);

  const [numberPerPageToByPengajuan, setNumberPerPageToByPengajuan] = useState(
    0
  );

  const [totalDataByPengajuan, setTotalDataByPengajuan] = useState(0);

  const [totalPageByPengajuan, setTotalPageByPengajuan] = useState(0);

  const [currentPageByPengajuan, setCurrentPageByPengajuan] = useState(0);

  const handleUpdateDataByPengajuan = async (
    id_publikasi,
    status,
    tanggal_verifikasi
  ) => {
    setDataByPengajuan(
      dataByPengajuan.map((item, index) => {
        return item.id_publikasi === id_publikasi
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleClickSingleBodyContentByPengajuan = ({ id_publikasi }) => {
    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      if (!value) return;

      const status = value === "accept" ? "Accepted" : "Rejected";
      const tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");

      const update = await verifikasiPublikasi({
        status,
        tanggal_verifikasi,
        id_publikasi,
      });

      if (update.success) {
        handleUpdateDataByPengajuan(id_publikasi, status, tanggal_verifikasi);
      } else {
        swal("Oops!", "Verifikasi gagal.", "warning");
      }
    });
  };

  const handleLoadData = async () => {
    const [byPengajuan, totalByPengajuan] = await Promise.all([
      getPublikasi({
        order: sortItemByPengajuan,
        limit: numberPerPageByPengajuan,
        offset: 0,
      }),
      getTotalPublikasi({}),
    ]);

    if (byPengajuan.success && totalByPengajuan.success) {
      // Set Publikasi - ByPengajuan
      setDataByPengajuan(byPengajuan.data);
      setTotalDataByPengajuan(totalByPengajuan.data.total);
      setTotalPageByPengajuan(
        Math.ceil(totalByPengajuan.data.total / numberPerPageByPengajuan)
      );
      setCurrentPageByPengajuan(totalByPengajuan.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByPengajuan(
        totalByPengajuan.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByPengajuan(
        totalByPengajuan.data.total > 0 ? byPengajuan.data.length : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper publikasi-content-panel">
          <div className="header-panel">
            <h1 className="topic-panel">Publikasi</h1>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setDataByPengajuan}
                        getData={getPublikasi}
                        dataFields={dataFieldsByPengajuan}
                        sortItem={sortItemByPengajuan}
                        searchInput={searchInputByPengajuan}
                        setSearchInput={setSearchInputByPengajuan}
                        optionsSearchFilterBy={optionsSearchFilterByPengajuan}
                        searchFilterBy={searchFilterByPengajuan}
                        setSearchFilterBy={setSearchFilterByPengajuan}
                        numberPerPage={numberPerPageByPengajuan}
                        setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                        setNumberPerPageTo={setNumberPerPageToByPengajuan}
                        getTotalData={getTotalPublikasi}
                        setTotalData={setTotalDataByPengajuan}
                        setTotalPage={setTotalPageByPengajuan}
                        setCurrentPage={setCurrentPageByPengajuan}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setDataByPengajuan}
                        getData={getPublikasi}
                        dataFields={dataFieldsByPengajuan}
                        sortItem={sortItemByPengajuan}
                        searchInput={searchInputByPengajuan}
                        searchFilterBy={searchFilterByPengajuan}
                        optionsNumberPerPage={optionsNumberPerPageByPengajuan}
                        numberPerPage={numberPerPageByPengajuan}
                        setNumberPerPage={setNumberPerPageByPengajuan}
                        numberPerPageFrom={numberPerPageFromByPengajuan}
                        setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                        numberPerPageTo={numberPerPageToByPengajuan}
                        setNumberPerPageTo={setNumberPerPageToByPengajuan}
                        totalData={totalDataByPengajuan}
                        getTotalData={getTotalPublikasi}
                        setTotalData={setTotalDataByPengajuan}
                        totalPage={totalPageByPengajuan}
                        setTotalPage={setTotalPageByPengajuan}
                        currentPage={currentPageByPengajuan}
                        setCurrentPage={setCurrentPageByPengajuan}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={dataByPengajuan}
                  setData={setDataByPengajuan}
                  getData={getPublikasi}
                  dataFields={dataFieldsByPengajuan}
                  sortItem={sortItemByPengajuan}
                  setSortItem={setSortItemByPengajuan}
                  searchInput={searchInputByPengajuan}
                  searchFilterBy={searchFilterByPengajuan}
                  numberPerPage={numberPerPageByPengajuan}
                  setNumberPerPageFrom={setNumberPerPageFromByPengajuan}
                  setNumberPerPageTo={setNumberPerPageToByPengajuan}
                  totalData={totalDataByPengajuan}
                  getTotalData={getTotalPublikasi}
                  setTotalData={setTotalDataByPengajuan}
                  setTotalPage={setTotalPageByPengajuan}
                  setCurrentPage={setCurrentPageByPengajuan}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  setIsFullScreenLoading={setIsFullScreenLoading}
                  handleClickSingleBodyContent={
                    handleClickSingleBodyContentByPengajuan
                  }
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
    </React.Fragment>
  );
}

export default Publikasi;
