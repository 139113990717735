import React, {useContext, useState, useEffect} from "react";
import "./style.css";
import Navbar from "../../../../../../../../../Components/Navbar";
import { getSurveyBySlug } from "../../../../../../../../../Services/API/pengumuman";
import BallClipRotateMultipleCustom from "../../../../../../../../../Components/BallClipRotateMultipleCustom";
import useDidMountEffect from "../../../../../../../../../Components/useDidMountEffect";
import { useParams, useHistory } from "react-router-dom";
import { UserContext } from "../../../../../../../../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function DetailSurvey() {
  const userContext = useContext(UserContext);

  const { slug } = useParams();

  const history = useHistory();

  const [dataSurvey, setDataSurvey] = useState({});

  const [isInitDataSurvey, setIsInitDataSurvey] = useState(false);

  const [isInitLoading, setIsInitLoading] = useState(true);

  const fetchSurveyBySlug = async () => {
    const _survey = await getSurveyBySlug(slug);

    if(_survey.success) {
      setDataSurvey(_survey.data);
      setIsInitDataSurvey(true);
      setIsInitLoading(false);
      return;
    } 

    throw new Error("Fetch survey data based on the survey slug");
  };

  useDidMountEffect(() => {
    if(dataSurvey && dataSurvey.constructor === Object && Object.keys(dataSurvey).length > 0) {

      const indexOfViewForm = 
        dataSurvey.embed_html.lastIndexOf("viewform?");
          
      const embedHTMLManipulated = 
        `${dataSurvey.embed_html.slice(0, indexOfViewForm + 9)}embedded=true&${dataSurvey.embed_html.slice(indexOfViewForm + 9)}`
          .replace(/NAMA/gi, userContext.userActive.nama_lengkap)
          .replace(/NPM/gi, userContext.userActive.npm)
          .replace(/ANGKATAN/gi, userContext.userActive.angkatan)
          .replace(/PROGRAM_BELAJAR/gi, userContext.userActive.program_belajar)
          .replace(/PROGRAM_PENDIDIKAN/gi, userContext.userActive.program_pendidikan)
          .replace(/EMAIL/gi, userContext.userActive.email)
          .replace(/NO_HP/gi, userContext.userActive.no_hp)
          .replace(/ID_LINE/gi, userContext.userActive.id_line)
          .replace(/USERNAME_INSTAGRAM/gi, userContext.userActive.username_instagram);

      const embedHTMLNoScrolling = 
        `${embedHTMLManipulated.slice(0, 8)}scrolling=no ${embedHTMLManipulated.slice(8)}`;

      setDataSurvey({
        ...dataSurvey,
        embed_html: embedHTMLNoScrolling
      });
    }
  }, [isInitDataSurvey]);

  useEffect(() => {
    fetchSurveyBySlug();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isInitLoading} />
      {!isInitLoading && (
        <React.Fragment>
          <Navbar />
          <div className="_body-content-wrapper-beranda">
            <div className="_user-page-menu">
              <span className="_back-button" onClick={() => history.goBack()}>
                <span className="_button-icon">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                <span className="_button-text">Kembali</span>
              </span>
            </div>
            <div className="_user-page-body">
              <div className="_body-box">
                <div className="_survey-wrapper">
                  <div className="_single-survey">
                    <div className="_title-area">
                      <span className="_title-text">{dataSurvey.judul}</span>
                    </div>
                    <div className="_sub-head-area">
                      <span className="_date-text">{dataSurvey.tanggal_publikasi}</span>
                      <span className={`_status-box${dataSurvey.status === "Open" ? " _textbox-green-smooth" : dataSurvey.status === "Closed" ? " _textbox-red-smooth" : ""}`}>
                        <span className="_status-text">{dataSurvey.status}</span>
                      </span>
                    </div>
                    <div className="_description-area">
                      <div className="_description-text _preformatted-text">{dataSurvey.deskripsi}</div>
                    </div>
                    <div 
                      dangerouslySetInnerHTML={{
                        __html: dataSurvey.embed_html
                      }} 
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="_user-page-profile-card"></div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default DetailSurvey;
