import axios from "axios";
import config from "../../Config";

const getPublikasiByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/publikasi/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getPublikasi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/publikasi`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPublikasi = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/publikasi/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const insertPublikasi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/publikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const verifikasiPublikasi = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/publikasi/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getPublikasiByPengguna,
  getPublikasi,
  getTotalPublikasi,
  insertPublikasi,
  verifikasiPublikasi,
};
