import axios from "axios";
import config from "../../Config";

const insertUnverifiedPengguna = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/unverified_pengguna`,
    data
  });

  const response = request.data;
  return response;
};

const deleteUnverifiedPengguna = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/unverified_pengguna`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const getAllUnverifiedPengguna = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/unverified_pengguna`,
    params: data
  });

  const response = request.data;
  return response;
};

const getTotalAllUnverifiedPengguna = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/unverified_pengguna/total`,
    params: data
  });

  const response = request.data;
  return response;
};

export {
  insertUnverifiedPengguna,
  deleteUnverifiedPengguna,
  getAllUnverifiedPengguna,
  getTotalAllUnverifiedPengguna
};