import React, { useContext } from "react";
import "./style.css";
import { UserContext } from "../../../../User";

function ProfileCard() {
  const userContext = useContext(UserContext);

  return (
    <div className="user-page-profile-card">
      <div className="profile-box">
        <div className="body-profile">
          <div className="profile-picture-area">
            <div
              className="profile-picture"
              style={{
                backgroundImage: `${
                  userContext.userActive.preview_foto_pengguna
                    ? userContext.userActive.preview_foto_pengguna
                    : `url(${require("../../../../../Assets/Miscellaneous/no_image_profile.png")})`
                }`,
              }}
            ></div>
          </div>
          <div className="user-name-area">
            <span className="user-name">
              {userContext.userActive.nama_lengkap.toLowerCase()}
            </span>
          </div>
          <div className="graduation-area">
            <span className="graduation">
              FKUI {userContext.userActive.angkatan}
            </span>
          </div>
          <div className="study-program-area">
            <span className="study-program">
              {userContext.userActive.program_belajar}
            </span>
          </div>
          <div className="ranking-area">
            <div className="ranking-title">Ranking Liga Kreasi</div>
            <div className="ranking-score-area">
              <div className="score-area">
                <span className="main-score">
                  {userContext.userActive.rank}
                </span>
                <span className="total-player">
                  / {userContext.userActive.jumlah_seangkatan}
                </span>
              </div>
              <span className="helper-text">Per Angkatan</span>
            </div>
          </div>
        </div>
        {/* <div className="footer-profile">
          <div className="button-wrapper">
            <div className="single-button">
              <span className="button-text">Download Profil</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ProfileCard;
