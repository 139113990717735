import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import jwt_decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import useDidMountEffect from "../../../Components/useDidMountEffect";
import { loginAdmin, authAdmin } from "../../../Services/API/admin";
import swal from "sweetalert";
import { setToken, getToken } from "../../../Services/Storage/token";

function Login() {
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [username, setUsername] = useState("");
  const [isClearUsernameActive, setIsClearUsernameActive] = useState(false);
  const [password, setPassword] = useState("");
  const [isClearPasswordActive, setIsClearPasswordActive] = useState(false);
  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [
    isSubmitButtonActionLoading,
    setIsSubmitButtonActionLoading,
  ] = useState(false);

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleMouseEnterUsername = () => {
    setIsClearUsernameActive(username ? true : false);
  };

  const handleMouseLeaveUsername = () => {
    setIsClearUsernameActive(false);
  };

  const handleClickClearUsername = () => {
    setUsername("");
    setIsClearUsernameActive(false);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleMouseEnterPassword = () => {
    setIsClearPasswordActive(password ? true : false);
  };

  const handleMouseLeavePassword = () => {
    setIsClearPasswordActive(false);
  };

  const handleClickClearPassword = () => {
    setPassword("");
    setIsClearPasswordActive(false);
  };

  const handleFormSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleCloseErrorSnackbar = () => {
    setIsErrorSnackbarActive(false);
  };

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("username")) {
      if (!username) {
        _errorList.push({
          type: "required",
          message: "Username wajib diisi.",
        });
      }
    }

    if (target.includes("password")) {
      if (!password) {
        _errorList.push({
          type: "required",
          message: "Password wajib diisi.",
        });
      }
    }

    return _errorList;
  };

  useEffect(() => {
    setIsClearUsernameActive(username ? true : false);
  }, [username]);

  useEffect(() => {
    setIsClearPasswordActive(password ? true : false);
  }, [password]);

  useEffect(() => {
    if (isFormSubmitted) {
      setIsErrorSnackbarActive(errorList ? true : false);
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsSubmitButtonActionLoading(true);

          try {
            const login = await loginAdmin({
              username,
              password,
            });
  
            setIsSubmitButtonActionLoading(false);
  
            if (login.success) {
              setToken("onedatamakara_adm", login.token);
              setRedirect("/adminpanel");
            } else {
              swal(
                "Oops!",
                login.message ? login.message : "Internal Server Error",
                "warning"
              );
            }
          } catch (e) {
            swal(
              "Oops!",
              "Internal Server Error",
              "warning"
            );
          }
        }
      }, 0);
    }
  }, [errorList]);

  useEffect(() => {
    if (isFormSubmitted) {
      setErrorList(getErrorList(["username", "password"]));
    }
  }, [isFormSubmitted]);

  useEffect(() => {
    (async () => {
      const token = getToken("onedatamakara_adm");

      if (token) {
        const { username, level } = jwt_decode(token);

        if (username && level) {
          const auth = await authAdmin({ token });

          if (auth.success) {
            setRedirect("/adminpanel");
          }
        }
      }

      setIsLoading(false);
    })();
  }, []);

  return (
    !isLoading &&
    (redirect ? (
      <Redirect to={redirect} />
    ) : (
      <div className="login-admin-panel">
        <div className="box-panel">
          <div className="header-panel">
            <h1 className="brand">OneDataMakara</h1>
            <p className="message">
              Welcome to the Admin Panel. Please login to continue.
            </p>
          </div>
          <div className="body-panel">
            <form
              autoComplete="off"
              onSubmit={(event) => event.preventDefault()}
            >
              <div className="form-group required">
                <TextField
                  id="login-admin-username"
                  label="Username"
                  variant="outlined"
                  value={username || ""}
                  onChange={handleChangeUsername}
                  onMouseEnter={handleMouseEnterUsername}
                  onMouseLeave={handleMouseLeaveUsername}
                  InputProps={{
                    endAdornment: isClearUsernameActive && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickClearUsername}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group required">
                <TextField
                  id="login-admin-password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password || ""}
                  onChange={handleChangePassword}
                  onMouseEnter={handleMouseEnterPassword}
                  onMouseLeave={handleMouseLeavePassword}
                  InputProps={{
                    endAdornment: isClearPasswordActive && (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickClearPassword}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-action-area">
                <div className="button-wrapper">
                  <button
                    type="submit"
                    className="submit-button"
                    onClick={handleFormSubmitAction}
                  >
                    {isSubmitButtonActionLoading ? (
                      <IconButton>
                        <CircularProgress
                          size={20}
                          className="loading-circle"
                        />
                      </IconButton>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <Portal>
            <Snackbar
              open={isErrorSnackbarActive && errorList.length > 0}
              onClose={handleCloseErrorSnackbar}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <MuiAlert variant="filled" severity="error">
                <ul className="error-list">
                  {errorList.map((element, index) => (
                    <li className="single-list" key={index}>
                      {element.message}
                    </li>
                  ))}
                </ul>
              </MuiAlert>
            </Snackbar>
          </Portal>
        </div>
      </div>
    ))
  );
}

export default Login;
