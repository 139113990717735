import React, { useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../../../User";
import Menu from "./Component/Menu";
import Content from "./Component/Content";

function Pengumuman() {
  const userContext = useContext(UserContext);
  return (
    <div className="user-page-pengumuman">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/1.svg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => userContext.dispatchMenuActive("SelamatDatang")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">Pengumuman</span>
        <span className="sub-title-text">Info, Poll, Survey, Recruitment</span>
      </div>
      <div className="body-area">
        <Menu />
        <Content />
      </div>
    </div>
  );
}

export default Pengumuman;
