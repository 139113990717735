import axios from "axios";
import config from "../../Config";

const getReimburse = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/reimburse`,
    params: data
  });

  const response = request.data;
  return response;
};

const getTotalReimburse = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/reimburse/total`,
    params: data
  });

  const response = request.data;
  return response;
};

const insertReimburse = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/reimburse`,
    data
  });

  const response = request.data;
  return response;
};

const getDelegasiByIdReimburse = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/reimburse/delegasi/${id}`
  });

  const response = request.data;
  return response;
};

const insertReimburseDelegasi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/reimburse/delegasi`,
    data
  });

  const response = request.data;
  return response;
};

const getDanaSeleksiByIdReimburse = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/reimburse/dana_seleksi/${id}`
  });

  const response = request.data;
  return response;
};

const insertReimburseDanaSeleksi = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/reimburse/dana_seleksi`,
    data
  });

  const response = request.data;
  return response;
};

const getDanaLombaByIdReimburse = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/reimburse/dana_lomba/${id}`
  });

  const response = request.data;
  return response;
};

const insertReimburseDanaLomba = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/reimburse/dana_lomba`,
    data
  });

  const response = request.data;
  return response;
};

const deleteReimburse = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/reimburse`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};  

export { 
  getReimburse,
  getTotalReimburse,
  insertReimburse,
  getDelegasiByIdReimburse,
  insertReimburseDelegasi,
  getDanaSeleksiByIdReimburse,
  insertReimburseDanaSeleksi,
  getDanaLombaByIdReimburse,
  insertReimburseDanaLomba,
  deleteReimburse
};