import React, { useRef, useContext, useState } from "react";
import "./style.css";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faBell,
  faEllipsisH,
  faTrash,
  faBookmark as fasBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark as farBookmark } from "@fortawesome/free-regular-svg-icons";
import useOnClickOutside from "../../../../../Components/useOnClickOutside";
import { AdminContext } from "../../../Panel";
import { logoutAdmin } from "../../../../../Services/API/admin";

function Navbar() {
  const dummyBodyNotification = [
    {
      id: 1,
      text: "Lorem ipsum test 1",
      dateTime: "2 menit yang lalu",
      isRead: false,
    },
    {
      id: 2,
      text: "Lorem ipsum test 2",
      dateTime: "5 jam yang lalu",
      isRead: true,
    },
    {
      id: 3,
      text: "Lorem ipsum test 3",
      dateTime: "7 jam yang lalu",
      isRead: true,
    },
    {
      id: 4,
      text: "Lorem ipsum test 4",
      dateTime: "1 hari yang lalu",
      isRead: false,
    },
    {
      id: 5,
      text: "Lorem ipsum test 5",
      dateTime: "4 minggu yang lalu",
      isRead: true,
    },
    {
      id: 6,
      text: "Lorem ipsum test 6",
      dateTime: "6 bulan yang lalu",
      isRead: true,
    },
  ];

  const adminContext = useContext(AdminContext);

  const [redirect, setRedirect] = useState(null);

  const headerNotifActionContentRef = useRef(null);
  const notificationContentRef = useRef(null);
  const userThumbnailActionContentRef = useRef(null);

  useOnClickOutside(headerNotifActionContentRef, () => {
    if (adminContext.isHeaderNotifActionActive)
      adminContext.dispatchIsHeaderNotifActionActive({
        type: false,
      });
  });

  useOnClickOutside(notificationContentRef, () => {
    if (adminContext.isNotifContentActive) {
      adminContext.dispatchIsNotifContentActive({
        type: false,
      });
    }
  });

  useOnClickOutside(userThumbnailActionContentRef, () => {
    if (adminContext.isUserThumbnailActionActive) {
      adminContext.dispatchIsUserThumbnailActionActive({
        type: false,
      });
    }
  });

  const handleClickLogout = () => {
    logoutAdmin(() => {
      setRedirect("/adminpanel/login");
    });
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <div className="navbar-admin-panel">
      <div
        className="logo-section"
        onClick={() => window.location.replace("/adminpanel")}
      >
        <a href="/adminpanel">
          <img
            className="logo"
            src={require("../../../../../Assets/Logos/odm-logo-title.png")}
          />
        </a>
      </div>
      <div className="main-topic-section">
        <span
          className="topic-text"
          onClick={() => window.location.replace("/adminpanel")}
        >
          Admin Panel
        </span>
      </div>
      <div className="user-section">
        {/* <div
          className="notification-area"
          onMouseDown={() => {
            adminContext.dispatchIsNotifContentActive({
              type: !adminContext.isNotifContentActive,
            });
          }}
        >
          <div className="bell-icon">
            <FontAwesomeIcon icon={faBell} />
          </div>
          <div className="new-notification">
            <span className="count">2</span>
          </div>
        </div>
        <div
          className={`notification-content${
            adminContext.isNotifContentActive ? " active" : ""
          }`}
          ref={notificationContentRef}
        >
          <div className="header-notification">
            <span className="notification-title">Notifikasi</span>
            <div className="notification-action">
              <div
                className="ellipsis-horizontal-icon"
                onMouseDown={() =>
                  adminContext.dispatchIsHeaderNotifActionActive({
                    type: !adminContext.isHeaderNotifActionActive,
                  })
                }
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
              <div
                className={`header-action-content${
                  adminContext.isHeaderNotifActionActive ? " active" : ""
                }`}
                ref={headerNotifActionContentRef}
              >
                <ul className="header-action-content-list">
                  <li className="single-list">
                    <span className="icon-area">
                      <FontAwesomeIcon icon={fasBookmark} />
                    </span>
                    <span className="text-area">
                      Tandai semua sebagai telah dibaca
                    </span>
                  </li>
                  <li className="single-list">
                    <span className="icon-area">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                    <span className="text-area">Hapus semua notifikasi</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="body-notification">
            <ul className="notification-list">
              {dummyBodyNotification.map((element, index) => (
                <li
                  className={`single-list${element.isRead ? " read" : ""}`}
                  key={element.id}
                >
                  <span className="notification-text">{element.text}</span>
                  <span className="date-time">{element.dateTime}</span>
                  <div className="bookmark-icon">
                    <FontAwesomeIcon
                      icon={element.isRead ? fasBookmark : farBookmark}
                    />
                  </div>
                  <div className="trash-icon">
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-notification">
            <div className="view-more-notification">
              <span className="view-more-text">Lihat Lebih Banyak</span>
            </div>
          </div>
        </div> */}
        <div
          className="thumbnail"
          onMouseDown={() =>
            adminContext.dispatchIsUserThumbnailActionActive({
              type: !adminContext.isUserThumbnailActionActive,
            })
          }
        >
          <img src={require("../../../../../Assets/Photos/no-picture.png")} />
          <div className="online-symbol"></div>
        </div>
        <div
          className={`user-action${
            adminContext.isUserThumbnailActionActive ? " active" : ""
          }`}
          ref={userThumbnailActionContentRef}
        >
          <ul className="user-action-list">
            <li className="single-list">
              <span className="action-icon">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <span className="action-text">Profil Saya</span>
            </li>
          </ul>
          <div className="horizontal-line-separator"></div>
          <ul className="user-action-list">
            <a onClick={handleClickLogout}>
              <li className="single-list logout-action">
                <span className="action-icon">
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                <span className="action-text">Keluar</span>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
