import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import useRouter from "../../../Hooks/useRouter";
import BallClipRotateMultipleCustom from "../../../Components/BallClipRotateMultipleCustom";
import config from "../../../Config";
import { loadUserByToken } from "../../../Services/API/auth";
import TextField from "@material-ui/core/TextField";
import swal from "sweetalert";
import { v4 as uuidv4 } from 'uuid';
import { insertUnverifiedPengguna } from "../../../Services/API/unverified_pengguna";
import { dateFormat } from "../../../Services/Format/date";
import { handleChangeTextField } from "../../../Services/FormHandler";
import { useCookies } from "react-cookie";

function UnverifiedUser() {
  const router = useRouter();
  const [cookies] = useCookies(["onedatamakara"]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [userByToken, setUserByToken] = useState(null);

  const [npm, setNPM] = useState("");

  const sendButtonOnClick = async (data) => {
    if(npm === "") {
      swal("Oops!", "NPM wajib diisi.", "warning");
      return;
    }

    const now = new Date();

    try {
      const _insertUnverifiedPengguna = await insertUnverifiedPengguna({
        uuid: uuidv4(),
        npm,
        nama_lengkap: data.nama_lengkap,
        email: data.email,
        tanggal_login: dateFormat(
          new Date(),
          "yyyy-MM-dd"
        )
      });

      swal("Berhasil Terkirim!", "Kamu akan diemail setelah diverifikasi Admin", "success").then(() => {
        router.push("/landing");
      });
    } catch (e) {
      swal("Oops!", "Terjadi kesalahan. Silahkan login kembali beberapa saat lagi.", "warning").then(() => {
        router.push("/landing");
      });
    }
  };

  const validateUserToken = async () => {
    if(router && router?.query?.token) {
      try {
        const _userByToken = await loadUserByToken(router.query.token);
        setUserByToken(_userByToken);

        if(_userByToken.success) {
          const status = _userByToken.data.status;

          if(status === "pending") {
            setShowLoginButton(false);
          } else if(status === "new") {
            setShowLoginButton(false);
          } else if(status === "verified") {
            setShowLoginButton(false);
            router.push(`/auth/logged-in?token=${router.query.token}`);
          } else {
            const _message = "Oops! Internal Server Error. Silahkan kembali login beberapa saat lagi.";
            setMessage(_message);
            setShowLoginButton(true);
          }
        } else {
          const _message = userByToken.message;
          setMessage(_message);
          setShowLoginButton(true);
        } 
      } catch (e) {
        const _message = e?.response?.message;
        setMessage(_message);
        setShowLoginButton(true);
      }
    } else {
      const _message = "Halo! Token kamu belum diketahui sistem. Harap kembali login menggunakan Email Student.";
      setMessage(_message);
      setShowLoginButton(true);
    }

    setIsLoading(false);
  };

  const init = async () => {
    if(cookies && cookies.onedatamakara) {
      try {
        const userByToken = await loadUserByToken(cookies["onedatamakara"]);

        if(userByToken.success) {
          if(userByToken?.data?.status === "verified") {
            router.push("/");
          } else {
            validateUserToken();
          }
        } else {
          validateUserToken();
        }
      } catch (e) {
        validateUserToken();
      }
    } else {
      validateUserToken();
    }
  };
  
  useEffect(() => {
    init();
  }, [router]);
  
  return (
    <>
      <BallClipRotateMultipleCustom isLoading={isLoading} />
      {!isLoading && (
        <div className="c-UnverifiedUser">
          {message && (
            <div className="c-UnverifiedUser__messageWrapper">
              <span className="c-UnverifiedUser__message">
                {message}
              </span>
            </div>
          )}
          {userByToken && (
            userByToken?.data?.status === "pending" ? (
              <>
                <span className="c-UnverifiedUser__message">
                  Halo! <span className="c-UnverifiedUser__emphasizedText">{userByToken.data.nama_lengkap.split(" ")[0]}</span>, akunmu dengan Email <span className="c-UnverifiedUser__emphasizedText">{userByToken.data.email}</span> masih dalam antrian verifikasi.
                </span>
                <span className="c-UnverifiedUser__message">
                  Kamu akan diemail setelah diverifikasi Admin.  
                </span>
              </>
            ) : userByToken?.data?.status === "new" ? (
              <>
                <span className="c-UnverifiedUser__message" align="left">
                  Halo! <span className="c-UnverifiedUser__emphasizedText">{userByToken.data.nama_lengkap.split(" ")[0]}</span>, akunmu akan diverifikasi Admin terlebih dahulu sebelum kamu bisa login.
                </span>
                <div className="c-UnverifiedUser__disabledInputArea" align="left">
                  <TextField 
                    className="c-UnverifiedUser__textField"
                    label="Nama Lengkap"
                    variant="outlined"
                    defaultValue={userByToken.data.nama_lengkap}
                    disabled
                  />
                  <TextField 
                    className="c-UnverifiedUser__textField"
                    label="Email"
                    variant="outlined"
                    defaultValue={userByToken.data.email}
                    disabled
                  />
                </div>
                <span className="c-UnverifiedUser__message" align="left">
                  Silahkan masukkan NPM kamu di bawah ini untuk mempermudah proses verifikasi.
                </span>
                <div className="c-UnverifiedUser__inputArea" align="left">
                  <TextField 
                    className="c-UnverifiedUser__textField"
                    label="NPM"
                    variant="outlined"
                    onChange={(event) => handleChangeTextField(event, setNPM)}
                    onKeyUp={(event) => {
                      const key = event.keyCode || event.which;
                      if(key === 13) sendButtonOnClick(userByToken.data);
                    }}
                  />
                  <span 
                    className="c-UnverifiedUser__sendButton"
                    onClick={() => sendButtonOnClick(userByToken.data)}
                  >
                    Kirim
                  </span>
                </div>
              </>
            ) : null
          )}
          {showLoginButton && (
            <div className="c-UnverifiedUser__actionArea">
              <a 
                href={`${config.SERVER_URI}/login`}
                className="c-UnverifiedUser__loginButton"
              >
                Login with Email Student
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UnverifiedUser;