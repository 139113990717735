import React, { useState, useEffect } from "react";
import "./style.css";
import Navbar from "../../../../../../../../../Components/Navbar";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { DropzoneDialog } from "material-ui-dropzone";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import swal from "sweetalert";
import useDidMountEffect from "../../../../../../../../../Components/useDidMountEffect";
import {
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleOpenDialog,
  handleCloseDialog,
  handleDialogSaveFile,
  handleFormSubmitAction,
} from "../../../../../../../../../Services/FormHandler";

function DetailRecruitment() {
  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);

  const [posisiDiinginkan, setPosisiDiinginkan] = useState("");
  const [
    isPosisiDiinginkanClearActive,
    setIsPosisiDiinginkanClearActive,
  ] = useState(false);

  const [alasanInginBergabung, setAlasanInginBergabung] = useState("");
  const [
    isAlasanInginBergabungClearActive,
    setIsAlasanInginBergabungClearActive,
  ] = useState(false);

  const [filePendukung, setFilePendukung] = useState(null);
  const [isFilePendukungClearActive, setIsFilePendukungClearActive] = useState(
    false
  );
  const [
    isFilePendukungDialogActive,
    setIsFilePendukungDialogActive,
  ] = useState(false);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useDidMountEffect(() => {
    setIsPosisiDiinginkanClearActive(posisiDiinginkan ? true : false);
  }, [posisiDiinginkan]);

  useDidMountEffect(() => {
    setIsAlasanInginBergabungClearActive(alasanInginBergabung ? true : false);
  }, [alasanInginBergabung]);

  return (
    <React.Fragment>
      <Navbar />
      <div className="body-content-wrapper-detail-recruitment">
        <div className="body-content-detail-recruitment">
          <div className="box-wrapper">
            <div className="description-area">
              <div className="title-area">
                <span className="title-text">
                  Kepanitiaan Lomba Makan Kerupuk
                </span>
              </div>
              <div className="date-area">
                <span className="date-text">20-11-2020</span>
              </div>
              <div className="content-area">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip
              </div>
            </div>
            <div className="form-area">
              <div className="form-title-area">
                <span className="form-title-text">Form Registrasi</span>
              </div>
              <div className="form-body-area">
                <form
                  autoComplete="off"
                  onSubmit={(event) => event.preventDefault()}
                >
                  <div className="form-group required">
                    <TextField
                      id="detail-recruitment-posisi-diinginkan"
                      label="Posisi yang diinginkan"
                      variant="outlined"
                      value={posisiDiinginkan || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setPosisiDiinginkan)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          posisiDiinginkan,
                          setIsPosisiDiinginkanClearActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsPosisiDiinginkanClearActive
                        )
                      }
                      InputProps={{
                        endAdornment: isPosisiDiinginkanClearActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setPosisiDiinginkan,
                                  setIsPosisiDiinginkanClearActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="form-group required">
                    <TextField
                      id="detail-recruitment-alasan-bergabung"
                      label="Alasan ingin bergabung"
                      multiline
                      rows={3}
                      variant="outlined"
                      value={alasanInginBergabung || ""}
                      onChange={(event) =>
                        handleChangeTextField(event, setAlasanInginBergabung)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterTextField(
                          alasanInginBergabung,
                          setIsAlasanInginBergabungClearActive
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeaveTextField(
                          setIsAlasanInginBergabungClearActive
                        )
                      }
                      InputProps={{
                        endAdornment: isAlasanInginBergabungClearActive && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleClickClearTextField(
                                  setAlasanInginBergabung,
                                  setIsAlasanInginBergabungClearActive
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    className={`form-group required upload-area${
                      isFilePendukungClearActive ? " pointer-active" : ""
                    }`}
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        filePendukung,
                        setIsFilePendukungClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(setIsFilePendukungClearActive)
                    }
                  >
                    <TextField
                      id="detail-recruitment-file-pendukung"
                      label="File Pendukung"
                      variant="outlined"
                      value={filePendukung ? filePendukung.name : ""}
                      readOnly
                    />
                    {isFilePendukungClearActive && (
                      <div className="clear-icon">
                        <ClearIcon
                          onClick={() =>
                            handleClickClearTextField(
                              setFilePendukung,
                              setIsFilePendukungClearActive
                            )
                          }
                        />
                      </div>
                    )}
                    <div
                      className="upload-button"
                      onClick={() =>
                        handleOpenDialog(setIsFilePendukungDialogActive)
                      }
                    >
                      <span className="upload-text">Upload</span>
                    </div>
                    <DropzoneDialog
                      // acceptedFiles={["image/*", ".pdf"]}
                      cancelButtonText={"Cancel"}
                      submitButtonText={"Upload"}
                      maxFileSize={5000000}
                      filesLimit={1}
                      open={isFilePendukungDialogActive}
                      onClose={() =>
                        handleCloseDialog(setIsFilePendukungDialogActive)
                      }
                      onSave={(files) =>
                        handleDialogSaveFile(
                          files,
                          null,
                          setFilePendukung,
                          setIsFilePendukungDialogActive
                        )
                      }
                      showPreviews={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                  <div className="form-action-area">
                    <div className="helper-wrapper"></div>
                    <div className="button-wrapper">
                      <button
                        type="submit"
                        className="submit-button"
                        onClick={(event) =>
                          handleFormSubmitAction(event, setIsFormSubmitted)
                        }
                      >
                        {isButtonActionLoading ? (
                          <CircularProgress
                            size={18}
                            className="loading-circle"
                          />
                        ) : (
                          <span className="button-text">Submit</span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DetailRecruitment;
