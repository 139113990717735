import axios from "axios";
import config from "../../Config";

const getPendaftarBeasiswaByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getPenerimaBeasiswaByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getPenerimaBeasiswa = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPenerimaBeasiswa = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const deletePenerimaBeasiswa = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const getPendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalPendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getAllBeasiswaTersedia = async () => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/beasiswa_tersedia`,
  });

  const response = request.data;
  return response;
};

const updateBeasiswaTersedia = async (data) => {
  const request = await axios({
    method: "put",
    baseURL: `${config.SERVER_URI}/beasiswa_tersedia`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteBeasiswaTersedia = async (data) => {
  const request = await axios({
    method: "delete",
    baseURL: `${config.SERVER_URI}/beasiswa_tersedia`,
    data,
  });

  const response = request.data;
  return response;
};

const deleteAllBeasiswaTersedia = async () => {
  const request = await axios({
    method: "delete",
    baseURL: `${config.SERVER_URI}/beasiswa_tersedia/all`,
  });

  const response = request.data;
  return response;
};

const insertBeasiswaTersedia = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/beasiswa_tersedia`,
    data,
  });

  const response = request.data;
  return response;
};

const insertPenerimaBeasiswa = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa`,
    data,
  });

  const response = request.data;
  return response;
};

const insertPendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "post",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa`,
    data,
  });

  const response = request.data;
  return response;
};

const deletePendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const updatePendaftarBeasiswaStatus = async (data) => {
  const request = await axios({
    method: "put",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const updatePenerimaBeasiswaStatus = async (data) => {
  const request = await axios({
    method: "put",
    baseURL: `${config.SERVER_URI}/penerima_beasiswa/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const updateSSStatusAkademikPendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa/ss_status_akademik`,
    data,
  });

  const response = request.data;
  return response;
};

const updateSSIuranBOPPendaftarBeasiswa = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/pendaftar_beasiswa/ss_iuran_bop`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getPendaftarBeasiswaByPengguna,
  getPenerimaBeasiswaByPengguna,
  getPenerimaBeasiswa,
  getTotalPenerimaBeasiswa,
  deletePenerimaBeasiswa,
  getPendaftarBeasiswa,
  getTotalPendaftarBeasiswa,
  getAllBeasiswaTersedia,
  updateBeasiswaTersedia,
  deleteBeasiswaTersedia,
  deleteAllBeasiswaTersedia,
  insertBeasiswaTersedia,
  insertPenerimaBeasiswa,
  insertPendaftarBeasiswa,
  deletePendaftarBeasiswa,
  updatePendaftarBeasiswaStatus,
  updatePenerimaBeasiswaStatus,
  updateSSStatusAkademikPendaftarBeasiswa,
  updateSSIuranBOPPendaftarBeasiswa,
};
