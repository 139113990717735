import React from "react";
import "./style.css";
import Navbar from "../../../Components/Navbar";
import Menu from "./Components/Menu";
import BodyContent from "./Components/BodyContent";
import ProfileCard from "./Components/ProfileCard";

function Beranda() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="body-content-wrapper-beranda">
        <Menu />
        <BodyContent />
        <ProfileCard />
      </div>
    </React.Fragment>
  );
}

export default Beranda;
