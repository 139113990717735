import React, { useState } from "react";
import "../../style.css";
import "./style.css";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { 
  insertSurvey,
  updateSurvey
} from "../../../../../../../Services/API/pengumuman";
import { dateFormat } from "../../../../../../../Services/Format/date";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import {
  getOptionLabel,
  filterOptions,
  handleChangeSelectItem,
  handleClickClearTextField,
  handleCloseDialog,
  handleChangeTextField, 
  handleMouseEnterTextField, 
  handleMouseLeaveTextField,
} from "../../../../../../../Services/FormHandler";
import CircularProgress from "@material-ui/core/CircularProgress";
import swal from "sweetalert";

function SurveyDialog(props) {
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [idSurvey, setIdSurvey] = useState("");

  const [status, setStatus] = useState(null);

  const [optionsStatus, setOptionsStatus] = useState([
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" }
  ]);

  const [judul, setJudul] = useState("");

  const [isJudulClearActive, setIsJudulClearActive] = useState(false);

  const [deskripsi, setDeskripsi] = useState("");

  const [isDeskripsiClearActive, setIsDeskripsiClearActive] = useState(false);

  const [embedHTML, setEmbedHTML] = useState("");

  const [isEmbedHTMLClearActive, setIsEmbedHTMLClearActive] = useState(false);

  const [slug, setSlug] = useState("");

  const [isSlugClearActive, setIsSlugClearActive] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getErrorList = (target) => {
    let _errorList = [];

    if(target.includes("status")) {
      if(!status) {
        _errorList.push({
          type: "required",
          message: "Status wajib dipilih."
        });
      }
    }

    if(target.includes("judul")) {
      if(!judul) {
        _errorList.push({
          type: "required",
          message: "Judul wajib diisi."
        });
      }
    }

    if(target.includes("deskripsi")) {
      if(!deskripsi) {
        _errorList.push({
          type: "required",
          message: "Deskripsi wajib diisi."
        });
      }
    }

    if(target.includes("embedHTML")) {
      if(!embedHTML) {
        _errorList.push({
          type: "required",
          message: "Embed HTML wajib diisi."
        });
      }
    }

    if(target.includes("slug")) {
      if(!slug) {
        _errorList.push({
          type: "required",
          message: "Slug wajib diisi."
        });
      }
    }
    
    return _errorList;
  };

  const handleClickSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleResetData = () => {
    setIdSurvey("");
    setStatus(null);
    setJudul("");
    setDeskripsi("");
    setEmbedHTML("");
    setSlug("");

    props.setDialogActiveActionType("");
    props.setDialogTitleActive("");
    props.setDialogDataActive(null);
  };

  useDidMountEffect(() => {
    setIsJudulClearActive(judul ? true : false);
  }, [judul]);

  useDidMountEffect(() => {
    setIsDeskripsiClearActive(deskripsi ? true : false);
  }, [deskripsi]);

  useDidMountEffect(() => {
    setIsEmbedHTMLClearActive(embedHTML ? true : false);
  }, [embedHTML]);

  useDidMountEffect(() => {
    setIsSlugClearActive(slug ? true : false);
  }, [slug]);

  useDidMountEffect(() => {
    if(isFormSubmitted) {
      setErrorList(
        getErrorList([
          "status",
          "judul",
          "deskripsi",
          "embedHTML",
          "slug"
        ])
      );
    }
  }, [isFormSubmitted]);

  useDidMountEffect(async () => {
    setIsErrorSnackbarActive(errorList.length > 0);

    if(isFormSubmitted) {
      setIsFormSubmitted(false);

      if(errorList.length === 0) {
        setIsSubmitButtonLoading(true);

        if(props.dialogActiveActionType === "Tambah") {
          const _insert = await insertSurvey({
            status: status.value,
            judul,
            deskripsi,
            embed_html: embedHTML,
            slug,
            tanggal_publikasi: dateFormat(new Date(), "yyyy-MM-dd")
          });

          if(_insert.success) {
            swal("Sukses!", "Data Survey berhasil ditambahkan.", "success").then(() => {
              props.setIsDialogFullScreenActive(false);
              props.handleLoadSurvey("REFRESH");
            });
          } else {
            swal("Oops!", _insert.message ? _insert.message : "Internal Server Error", "warning");
          }
        } else if(props.dialogActiveActionType === "Update") {
          const _update = await updateSurvey({
            status: status.value,
            judul,
            deskripsi,
            embed_html: embedHTML,
            slug,
            id_survey: idSurvey
          });

          if(_update.success) {
            swal("Sukses!", "Data Survey berhasil diupdate.", "success").then(() => {
              props.setIsDialogFullScreenActive(false);
              props.handleLoadSurvey("REFRESH");
            });
          } else {
            swal("Oops!", _update.message ? _update.message : "Internal Server Error", "warning");
          }
        }
 
        setIsSubmitButtonLoading(false);
      }
    }
  }, [errorList]);

  useDidMountEffect(() => {
    setIdSurvey(
      props.dialogDataActive ? props.dialogDataActive.idSurvey : ""
    );

    setStatus(
      props.dialogDataActive 
        ? props.dialogDataActive.status === optionsStatus[0].value
            ? optionsStatus[0]
            : props.dialogDataActive.status === optionsStatus[1].value
                ? optionsStatus[1]
                : null
        : null
    );

    setJudul(
      props.dialogDataActive ? props.dialogDataActive.judul : ""
    ); 

    setDeskripsi(
      props.dialogDataActive ? props.dialogDataActive.deskripsi : ""
    );

    setEmbedHTML(
      props.dialogDataActive ? props.dialogDataActive.embedHTML : ""
    );

    setSlug(
      props.dialogDataActive ? props.dialogDataActive.slug : ""
    );

    setTimeout(() => {
      setIsJudulClearActive(false);
      setIsDeskripsiClearActive(false);
      setIsEmbedHTMLClearActive(false);
      setIsSlugClearActive(false);
    }, 0);
  }, [props.dialogDataActive]);

  useDidMountEffect(() => {
    if(!props.isDialogFullScreenActive) {
      handleResetData();
    }
  }, [props.isDialogFullScreenActive]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isDialogFullScreenActive}
        onClose={() => handleCloseDialog(props.setIsDialogFullScreenActive)}
        className={`full-screen-app-bar${
          props.dialogFullScreenClassName
            ? " " + props.dialogFullScreenClassName
            : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(props.setIsDialogFullScreenActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              {props.dialogTitleActive}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClickSubmitAction}
              className="single-button-right-top-custom"
            >
              {isSubmitButtonLoading ? (
                <CircularProgress size={18} className="loading-circle" />
              ) : props.dialogActiveActionType === "Tambah" ? (
                "Submit"
              ) : (
                "Update"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <form className="fullscreen-modal-form-body" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <div className="form-group required">
            <Autocomplete 
              id="survey-status"
              options={optionsStatus}
              filterOptions={filterOptions}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={(event, selected) => handleChangeSelectItem(selected, setStatus)}
              value={status || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="outlined"
                />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="survey-judul"
              label="Judul"
              variant="outlined"
              value={judul || ""}
              onChange={(event) => handleChangeTextField(event, setJudul)}
              onMouseEnter={() => handleMouseEnterTextField(judul, setIsJudulClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsJudulClearActive)}
              InputProps={{
                endAdornment: isJudulClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setJudul,
                          setIsJudulClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="survey-deskripsi"
              label="Deskripsi"
              variant="outlined"
              multiline
              rows={7}
              value={deskripsi || ""}
              onChange={(event) => handleChangeTextField(event, setDeskripsi)}
              onMouseEnter={() => handleMouseEnterTextField(deskripsi, setIsDeskripsiClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsDeskripsiClearActive)}
              InputProps={{
                endAdornment: isDeskripsiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setDeskripsi,
                          setIsDeskripsiClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="survey-embed-html"
              label="Embed HTML"
              variant="outlined"
              multiline
              rows={4}
              value={embedHTML || ""}
              onChange={(event) => handleChangeTextField(event, setEmbedHTML)}
              onMouseEnter={() => handleMouseEnterTextField(embedHTML, setIsEmbedHTMLClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsEmbedHTMLClearActive)}
              InputProps={{
                endAdornment: isEmbedHTMLClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setEmbedHTML,
                          setIsEmbedHTMLClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="form-group required">
            <TextField 
              id="slug"
              label="Slug"
              variant="outlined"
              value={slug || ""}
              onChange={(event) => handleChangeTextField(event, setSlug)}
              onMouseEnter={() => handleMouseEnterTextField(slug, setIsSlugClearActive)}
              onMouseLeave={() => handleMouseLeaveTextField(setIsSlugClearActive)}
              InputProps={{
                endAdornment: isSlugClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setSlug,
                          setIsSlugClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
        </form>
      </Dialog>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseDialog(setIsErrorSnackbarActive)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </React.Fragment>
  );
}

export default SurveyDialog;