import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { dateFormat } from "../../../../../../../Services/Format/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import {
  getPenerimaBeasiswa,
  getTotalPenerimaBeasiswa,
  getPendaftarBeasiswa,
  getTotalPendaftarBeasiswa,
  updatePendaftarBeasiswaStatus,
  deletePendaftarBeasiswa,
  updatePenerimaBeasiswaStatus,
  deletePenerimaBeasiswa,
  getAllBeasiswaTersedia,
  deleteAllBeasiswaTersedia,
  insertBeasiswaTersedia
} from "../../../../../../../Services/API/beasiswa";
import {
  handleOpenDialog,
  handleCloseDialog,
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
} from "../../../../../../../Services/FormHandler";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import {
  getScreenshotIuranBOP,
  getScreenshotStatusAkademik,
} from "../../../../../../../Services/API/file";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "../../../../../../../Services/Format/blob";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import ShowDataToggle from "../ShowDataToggle";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import useOnClickOutside from "../../../../../../../Components/useOnClickOutside";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { generateExcel } from "../../../../../../../Services/File/excel";

function BeasiswaTersedia(props) {
  let namaBeasiswaRef = useRef(null);

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  const [namaBeasiswa, setNamaBeasiswa] = useState("");

  const [isNamaBeasiswaClearActive, setIsNamaBeasiswaClearActive] = useState(
    false
  );

  const [beasiswaTersedia, setBeasiswaTersedia] = useState([]);

  const [
    isTambahBeasiswaTersediaClicked,
    setIsTambahBeasiswaTersediaClicked,
  ] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleClickTambahBeasiswaTersedia = () => {
    setIsTambahBeasiswaTersediaClicked(true);
  };

  const handleClickDeleteBeasiswaTersedia = (idx) => {
    setBeasiswaTersedia(
      beasiswaTersedia
        .filter((_item, _index) => _item.id_beasiswa_tersedia !== idx)
        .map((item, index) => {
          return {
            id_beasiswa_tersedia: index + 1,
            nama_beasiswa: item.nama_beasiswa,
          };
        })
    );
  };

  const handleClickUpdateBeasiswaTersedia = () => {
    setIsFormSubmitted(true);
  };

  const handleKeyUpNamaBeasiswa = (event) => {
    if (event.keyCode === 13) {
      setIsTambahBeasiswaTersediaClicked(true);
    }
  };

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("namaBeasiswa")) {
      if (!namaBeasiswa) {
        _errorList.push({
          type: "required",
          message: "Nama Beasiswa wajib diisi.",
        });
      }
    }

    return _errorList;
  };

  useDidMountEffect(async () => {
    setIsErrorSnackbarActive(errorList.length > 0 ? true : false);

    if (errorList.length === 0) {
      if (isFormSubmitted) {
        setIsFormSubmitted(false);
        setIsSubmitButtonLoading(true);

        const _deleteAllBeasiswaTersedia = await deleteAllBeasiswaTersedia();

        if (!_deleteAllBeasiswaTersedia.success) {
          setIsSubmitButtonLoading(false);
          swal("Oops!", "Beasiswa Tersedia gagal diupdate", "warning");

          return;
        }

        if (beasiswaTersedia.length > 0) {
          await Promise.allSettled(
            beasiswaTersedia.map((item) => insertBeasiswaTersedia(item))
          );
        }

        setIsSubmitButtonLoading(false);
        swal("Sukses!", "Beasiswa Tersedia berhasil diupdate", "success");
      } else if (isTambahBeasiswaTersediaClicked) {
        setIsTambahBeasiswaTersediaClicked(false);

        if (
          beasiswaTersedia.find((item) => item.nama_beasiswa === namaBeasiswa)
        ) {
          swal("Oops!", "Beasiswa tersebut telah terdaftar", "warning");
        } else {
          setBeasiswaTersedia([
            ...beasiswaTersedia.map((item, index) => {
              return {
                id_beasiswa_tersedia: index + 1,
                nama_beasiswa: item.nama_beasiswa,
              };
            }),
            {
              id_beasiswa_tersedia: beasiswaTersedia.length + 1,
              nama_beasiswa: namaBeasiswa,
            },
          ]);

          setNamaBeasiswa("");
          namaBeasiswaRef.focus();
        }
      }
    }
  }, [errorList]);

  useDidMountEffect(() => {
    setErrorList(getErrorList([]));
  }, [isFormSubmitted]);

  useDidMountEffect(() => {
    setIsNamaBeasiswaClearActive(namaBeasiswa ? true : false);
  }, [namaBeasiswa]);

  useDidMountEffect(() => {
    if (isTambahBeasiswaTersediaClicked) {
      setErrorList(getErrorList(["namaBeasiswa"]));
    }
  }, [isTambahBeasiswaTersediaClicked]);

  useDidMountEffect(() => {
    if (!props.isBeasiswaTersediaDialogActive) {
      setNamaBeasiswa("");
    }
  }, [props.isBeasiswaTersediaDialogActive]);

  useEffect(() => {
    (async () => {
      const _beasiswaTersedia = await getAllBeasiswaTersedia();

      if (_beasiswaTersedia.success) {
        setBeasiswaTersedia(_beasiswaTersedia.data);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isBeasiswaTersediaDialogActive}
        onClose={() =>
          handleCloseDialog(props.setIsBeasiswaTersediaDialogActive)
        }
        className={`full-screen-app-bar${
          props.dialogFullScreenClassName
            ? " " + props.dialogFullScreenClassName
            : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(props.setIsBeasiswaTersediaDialogActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              Beasiswa Tersedia
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClickUpdateBeasiswaTersedia}
              className="single-button-right-top-custom"
            >
              {isSubmitButtonLoading ? (
                <CircularProgress size={18} className="loading-circle" />
              ) : (
                "Update"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <form
          className="fullscreen-modal-form-body"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-group required">
            <div className="inner-form-group-wrapper">
              <div className="inner-form-group-nama-beasiswa">
                <TextField
                  id="beasiswa-tersedia-nama"
                  label="Nama Beasiswa"
                  variant="outlined"
                  value={namaBeasiswa || ""}
                  inputRef={(ref) => {
                    namaBeasiswaRef = ref;
                  }}
                  onChange={(event) =>
                    handleChangeTextField(event, setNamaBeasiswa)
                  }
                  onMouseEnter={() =>
                    handleMouseEnterTextField(
                      namaBeasiswa,
                      setIsNamaBeasiswaClearActive
                    )
                  }
                  onMouseLeave={() =>
                    handleMouseLeaveTextField(setIsNamaBeasiswaClearActive)
                  }
                  onKeyUp={handleKeyUpNamaBeasiswa}
                  InputProps={{
                    endAdornment: isNamaBeasiswaClearActive && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleClickClearTextField(
                              setNamaBeasiswa,
                              setIsNamaBeasiswaClearActive
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="tambah-beasiswa-button-form-group">
                <div
                  className="tambah-beasiswa-button"
                  onClick={handleClickTambahBeasiswaTersedia}
                >
                  <span className="button-text">Tambah Beasiswa</span>
                </div>
              </div>
            </div>
          </div>
          {beasiswaTersedia.length > 0 && (
            <div className="form-group required">
              <div className="text-area-list-beasiswa-tersedia">
                {beasiswaTersedia.map((item, index) => (
                  <div className="single-list-little-box" key={index + 1}>
                    <span className="list-text">{item.nama_beasiswa}</span>
                    <span
                      className="delete-beasiswa-tersedia-icon-area"
                      onClick={() =>
                        handleClickDeleteBeasiswaTersedia(index + 1)
                      }
                    >
                      <span className="delete-icon">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </form>
      </Dialog>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseDialog(setIsErrorSnackbarActive)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </React.Fragment>
  );
}

function Beasiswa() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const exportToExcelOptionRef = useRef(null);

  const [
    isExportToExcelOptionActive,
    setIsExportToExcelOptionActive,
  ] = useState(false);

  const [exportToExcelList, setExportToExcelList] = useState([
    {
      value: "Semua",
      isLoading: false,
    },
    {
      value: "Semua Sesuai Filter",
      isLoading: false,
    },
    {
      value: "Hanya Halaman Ini",
      isLoading: false,
    },
  ]);

  // Beasiswa --------------------------------------------------------------------------------

  // @ShowData Section
  // Beasiswa

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "Pendaftar Beasiswa", label: "Pendaftar Beasiswa" },
    { value: "Penerima Beasiswa", label: "Penerima Beasiswa" },
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Beasiswa

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Beasiswa - By Pendaftar Beasiswa

  const [dataByPendaftarBeasiswa, setDataByPendaftarBeasiswa] = useState([]);

  const dataFieldsByPendaftarBeasiswa = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text",
      },
    },
    {
      primary: true,
      checkbox: true,
      value: "id_pendaftar_beasiswa",
      type: "text",
      label: "ID Pendaftar Beasiswa",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false,
      },
    },
    {
      primary: false,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false,
      },
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
      exportToExcel: {
        visible: true,
        bgColor: [
          { value: "Accepted", argb: "FF8DF792" },
          { value: "Rejected", argb: "FFF9ADAD" },
          { value: "Pending", argb: "FFF7D881" },
        ],
        cellWidth: 18,
        type: "text",
      },
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "nama_beasiswa",
      type: "text",
      label: "Nama Beasiswa",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "ss_status_akademik",
      type: "file",
      label: "Screenshot Status Akademik",
      size: "default",
      contentGetFile: getScreenshotStatusAkademik,
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "textlink",
        prefixLink:
          "https://onedatamakara.s3-ap-southeast-1.amazonaws.com/beasiswa/screenshot_status_akademik/",
      },
    },
    {
      primary: false,
      value: "ss_iuran_bop",
      type: "file",
      label: "Screenshot Iuran BOP",
      size: "default",
      contentGetFile: getScreenshotIuranBOP,
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "textlink",
        prefixLink:
          "https://onedatamakara.s3-ap-southeast-1.amazonaws.com/beasiswa/screenshot_iuran_bop/",
      },
    },
    {
      primary: false,
      value: "id_line",
      type: "text",
      label: "ID Line",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "no_hp",
      type: "text",
      label: "No. HP",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
  ];

  const [
    sortItemByPendaftarBeasiswa,
    setSortItemByPendaftarBeasiswa,
  ] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [
    searchInputByPendaftarBeasiswa,
    setSearchInputByPendaftarBeasiswa,
  ] = useState("");

  const optionsSearchFilterByPendaftarBeasiswa = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "nama_beasiswa", label: "Nama Beasiswa" },
    { value: "id_line", label: "ID Line" },
    { value: "no_hp", label: "No. HP" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ];

  const [
    searchFilterByPendaftarBeasiswa,
    setSearchFilterByPendaftarBeasiswa,
  ] = useState([optionsSearchFilterByPendaftarBeasiswa[1]]);

  // @Table Section
  // Beasiswa - By Penerima Beasiswa

  const [dataByPenerimaBeasiswa, setDataByPenerimaBeasiswa] = useState([]);

  const dataFieldsByPenerimaBeasiswa = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text",
      },
    },
    {
      primary: true,
      checkbox: true,
      value: "id_penerima_beasiswa",
      type: "text",
      label: "ID Penerima Beasiswa",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false,
      },
    },
    {
      primary: false,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false,
      },
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Accepted", name: "textbox-green-smooth" },
        { value: "Rejected", name: "textbox-red-smooth" },
        { value: "Pending", name: "textbox-yellow-smooth" },
      ],
      exportToExcel: {
        visible: true,
        bgColor: [
          { value: "Accepted", argb: "FF8DF792" },
          { value: "Rejected", argb: "FFF9ADAD" },
          { value: "Pending", argb: "FFF7D881" },
        ],
        cellWidth: 18,
        type: "text",
      },
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text",
      },
    },
    {
      primary: false,
      value: "nama_beasiswa",
      type: "text",
      label: "Nama Beasiswa",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text",
      },
    },
    {
      primary: false,
      value: "bentuk_beasiswa",
      type: "text",
      label: "Bentuk Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "bentuk_beasiswa_lain",
      type: "text",
      label: "Bentuk Beasiswa Lain",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "besar_beasiswa",
      type: "text",
      label: "Besar Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "asal_beasiswa",
      type: "text",
      label: "Asal Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "asal_beasiswa_lain",
      type: "text",
      label: "Asal Beasiswa Lainnya",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "pemberi_beasiswa",
      type: "text",
      label: "Pemberi Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "periode_awal_beasiswa",
      type: "text",
      label: "Periode Awal Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "periode_akhir_beasiswa",
      type: "text",
      label: "Periode Akhir Beasiswa",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "id_line",
      type: "text",
      label: "ID Line",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "no_hp",
      type: "text",
      label: "No. HP",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 30,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_pengajuan",
      type: "text",
      label: "Tanggal Pengajuan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
    {
      primary: false,
      value: "tanggal_verifikasi",
      type: "text",
      label: "Tanggal Diverifikasi",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
  ];

  const [sortItemByPenerimaBeasiswa, setSortItemByPenerimaBeasiswa] = useState({
    by: "tanggal_pengajuan",
    mode: "DESC",
  });

  const [
    searchInputByPenerimaBeasiswa,
    setSearchInputByPenerimaBeasiswa,
  ] = useState("");

  const optionsSearchFilterByPenerimaBeasiswa = [
    { value: "status", label: "Status" },
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    { value: "nama_beasiswa", label: "Nama Beasiswa" },
    { value: "bentuk_beasiswa", label: "Bentuk Beasiswa" },
    { value: "bentuk_beasiswa_lain", label: "Bentuk Beasiswa Lainnya" },
    { value: "besar_beasiswa", label: "Besar Beasiswa" },
    { value: "asal_beasiswa", label: "Asal Beasiswa" },
    { value: "asal_beasiswa_lain", label: "Asal Beasiswa Lainnya" },
    { value: "pemberi_beasiswa", label: "Pemberi Beasiswa" },
    { value: "periode_awal_beasiswa", label: "Periode Awal Beasiswa" },
    { value: "periode_akhir_beasiswa", label: "Periode Akhir Beasiswa" },
    { value: "id_line", label: "ID LIne" },
    { value: "no_hp", label: "No. HP" },
    { value: "tanggal_pengajuan", label: "Tanggal Pengajuan" },
    { value: "tanggal_verifikasi", label: "Tanggal Diverifikasi" },
  ];

  const [
    searchFilterByPenerimaBeasiswa,
    setSearchFilterByPenerimaBeasiswa,
  ] = useState([optionsSearchFilterByPenerimaBeasiswa[1]]);

  // @Pagination Section
  // Beasiswa - By Pendaftar Beasiswa

  const optionsNumberPerPageByPendaftarBeasiswa = [10, 20, 30, 40, 50];

  const [
    numberPerPageByPendaftarBeasiswa,
    setNumberPerPageByPendaftarBeasiswa,
  ] = useState(optionsNumberPerPageByPendaftarBeasiswa[0]);

  const [
    numberPerPageFromByPendaftarBeasiswa,
    setNumberPerPageFromByPendaftarBeasiswa,
  ] = useState(0);

  const [
    numberPerPageToByPendaftarBeasiswa,
    setNumberPerPageToByPendaftarBeasiswa,
  ] = useState(0);

  const [
    totalDataByPendaftarBeasiswa,
    setTotalDataByPendaftarBeasiswa,
  ] = useState(0);

  const [
    totalPageByPendaftarBeasiswa,
    setTotalPageByPendaftarBeasiswa,
  ] = useState(0);

  const [
    currentPageByPendaftarBeasiswa,
    setCurrentPageByPendaftarBeasiswa,
  ] = useState(0);

  // @Pagination Section
  // Beasiswa - By Penerima Beasiswa

  const optionsNumberPerPageByPenerimaBeasiswa = [10, 20, 30, 40, 50];

  const [
    numberPerPageByPenerimaBeasiswa,
    setNumberPerPageByPenerimaBeasiswa,
  ] = useState(optionsNumberPerPageByPenerimaBeasiswa[0]);

  const [
    numberPerPageFromByPenerimaBeasiswa,
    setNumberPerPageFromByPenerimaBeasiswa,
  ] = useState(0);

  const [
    numberPerPageToByPenerimaBeasiswa,
    setNumberPerPageToByPenerimaBeasiswa,
  ] = useState(0);

  const [
    totalDataByPenerimaBeasiswa,
    setTotalDataByPenerimaBeasiswa,
  ] = useState(0);

  const [
    totalPageByPenerimaBeasiswa,
    setTotalPageByPenerimaBeasiswa,
  ] = useState(0);

  const [
    currentPageByPenerimaBeasiswa,
    setCurrentPageByPenerimaBeasiswa,
  ] = useState(0);


  // @Dialog Section
  // Beasiswa

  const [
    isBeasiswaTersediaDialogActive,
    setIsBeasiswaTersediaDialogActive,
  ] = useState(false);

  const dialogFullScreenClassName = "beasiswa-full-screen-bar";

  const [isViewFileDialogActive, setIsViewFileDialogActive] = useState(false);

  const [objectMime, setObjectMime] = useState(null);

  const [objectURL, setObjectURL] = useState(null);

  const [objectFilename, setObjectFilename] = useState(null);

  
  // @Export Section
  // Beasiswa

  const handleClickSingleListExport = async (exportTarget) => {
    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: true,
            }
          : item;
      })
    );

    const data = await (async () => {
      let _data = {};

      _data.bgHeader = "FFB9CDF7";

      _data.title = showDataActive.value;

      _data.fileName = `${showDataActive.value}.xlsx`;

      switch (showDataActive.value) {
        case "Pendaftar Beasiswa":
          _data.target = dataFieldsByPendaftarBeasiswa.filter((item) =>
            item.exportToExcel.visible ? true : false
          );

          _data.header = dataFieldsByPendaftarBeasiswa
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byPendaftarBeasiswa = await getPendaftarBeasiswa({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp:
                      searchFilterByPendaftarBeasiswa.length > 1 ? "OR" : "",
                    rules: searchFilterByPendaftarBeasiswa.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputByPendaftarBeasiswa,
                      };
                    }),
                  },
            order: sortItemByPendaftarBeasiswa,
            limit:
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageByPendaftarBeasiswa
                : null,
            offset:
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageByPendaftarBeasiswa - 1 < 0
                    ? 0
                    : currentPageByPendaftarBeasiswa - 1) *
                  numberPerPageByPendaftarBeasiswa
                : null,
          });

          if (byPendaftarBeasiswa.success) {
            let no = 1;

            _data.value = byPendaftarBeasiswa.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byPendaftarBeasiswa.data.length;
          }
          break;
        case "Penerima Beasiswa":
          _data.target = dataFieldsByPenerimaBeasiswa.filter((item) =>
            item.exportToExcel.visible ? true : false
          );

          _data.header = dataFieldsByPenerimaBeasiswa
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byPenerimaBeasiswa = await getPenerimaBeasiswa({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp:
                      searchFilterByPenerimaBeasiswa.length > 1 ? "OR" : "",
                    rules: searchFilterByPenerimaBeasiswa.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputByPenerimaBeasiswa,
                      };
                    }),
                  },
            order: sortItemByPenerimaBeasiswa,
            limit:
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageByPenerimaBeasiswa
                : null,
            offset:
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageByPenerimaBeasiswa - 1 < 0
                    ? 0
                    : currentPageByPenerimaBeasiswa - 1) *
                  numberPerPageByPenerimaBeasiswa
                : null,
          });

          if (byPenerimaBeasiswa.success) {
            let no = 1;

            _data.value = byPenerimaBeasiswa.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                item.push(x[t.value] ? x[t.value] : "-");
              });

              return item;
            });

            _data.total = byPenerimaBeasiswa.data.length;
          }

          break;
      }

      return _data;
    })();

    generateExcel({
      title: data.title,
      subTitle: `Total Data: ${data.total}`,
      header: data.header,
      bgHeader: data.bgHeader,
      fileName: data.fileName,
      data: data.value,
      cell: data.target,
    });

    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: false,
            }
          : item;
      })
    );
  };


  const handleUpdateDataByPendaftarBeasiswa = (
    id,
    status,
    tanggal_verifikasi
  ) => {
    setDataByPendaftarBeasiswa(
      dataByPendaftarBeasiswa.map((item, index) => {
        return item.id_pendaftar_beasiswa === id
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleUpdateDataByPenerimaBeasiswa = (
    id,
    status,
    tanggal_verifikasi
  ) => {
    setDataByPenerimaBeasiswa(
      dataByPenerimaBeasiswa.map((item, index) => {
        return item.id_penerima_beasiswa === id
          ? {
              ...item,
              status,
              tanggal_verifikasi: dateFormat(
                new Date(tanggal_verifikasi),
                "dd-MM-yyyy"
              ),
            }
          : item;
      })
    );
  };

  const handleClickSingleBodyContentByPendaftarBeasiswa = ({
    id_pendaftar_beasiswa,
  }) => {
    let status;
    let tanggal_verifikasi;

    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      let update;

      if (value) {
        switch (value) {
          case "accept":
            status = "Accepted";
            tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
            update = await updatePendaftarBeasiswaStatus({
              id_pendaftar_beasiswa,
              status,
              tanggal_verifikasi,
            });
            break;
          case "reject":
            status = "Rejected";
            tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
            update = await updatePendaftarBeasiswaStatus({
              id_pendaftar_beasiswa,
              status,
              tanggal_verifikasi,
            });
            break;
        }

        if (update) {
          if (update.success) {
            handleUpdateDataByPendaftarBeasiswa(
              id_pendaftar_beasiswa,
              status,
              tanggal_verifikasi
            );
          } else {
            swal("Oops!", "Verifikasi gagal.", "warning");
          }
        }
      }
    });
  };

  const handleClickSingleBodyContentByPenerimaBeasiswa = ({
    id_penerima_beasiswa,
  }) => {
    let status;
    let tanggal_verifikasi;

    swal({
      title: "Tahapan Verifikasi",
      text: "Silahkan memilih antara Terima atau Tolak.",
      buttons: {
        catch: {
          text: "Tolak",
          value: "reject",
          className: "warning-color-custom",
        },
        defeat: {
          text: "Terima",
          value: "accept",
          className: "success-color-custom",
        },
      },
    }).then(async (value) => {
      let update;

      if (value) {
        switch (value) {
          case "accept":
            status = "Accepted";
            tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
            update = await updatePenerimaBeasiswaStatus({
              id_penerima_beasiswa,
              status,
              tanggal_verifikasi,
            });
            break;
          case "reject":
            status = "Rejected";
            tanggal_verifikasi = dateFormat(new Date(), "yyyy-MM-dd");
            update = await updatePenerimaBeasiswaStatus({
              id_penerima_beasiswa,
              status,
              tanggal_verifikasi,
            });
            break;
        }

        if (update) {
          if (update.success) {
            handleUpdateDataByPenerimaBeasiswa(
              id_penerima_beasiswa,
              status,
              tanggal_verifikasi
            );
          } else {
            swal("Oops!", "Verifikasi gagal.", "warning");
          }
        }
      }
    });
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        switch(showDataActive.value) {
          case "Pendaftar Beasiswa":
            _delete = await deletePendaftarBeasiswa(dataSelected);
          break;
          case "Penerima Beasiswa":
            _delete = await deletePenerimaBeasiswa(dataSelected);
          break;
        }

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [
      byPendaftarBeasiswa,
      totalByPendaftarBeasiswa,
      byPenerimaBeasiswa,
      totalByPenerimaBeasiswa,
    ] = await Promise.all([
      getPendaftarBeasiswa({
        order: sortItemByPendaftarBeasiswa,
        limit: numberPerPageByPendaftarBeasiswa,
        offset: 0,
      }),
      getTotalPendaftarBeasiswa({}),
      getPenerimaBeasiswa({
        order: sortItemByPenerimaBeasiswa,
        limit: numberPerPageByPenerimaBeasiswa,
        offset: 0,
      }),
      getTotalPenerimaBeasiswa({}),
    ]);

    if (
      byPendaftarBeasiswa.success &&
      totalByPendaftarBeasiswa.success &&
      byPenerimaBeasiswa.success &&
      totalByPenerimaBeasiswa.success
    ) {
      // Set Beasiswa - By Pendaftar Beasiswa
      setDataByPendaftarBeasiswa(byPendaftarBeasiswa.data);
      setTotalDataByPendaftarBeasiswa(totalByPendaftarBeasiswa.data.total);
      setTotalPageByPendaftarBeasiswa(
        Math.ceil(
          totalByPendaftarBeasiswa.data.total /
            numberPerPageByPendaftarBeasiswa
        )
      );
      setCurrentPageByPendaftarBeasiswa(
        totalByPendaftarBeasiswa.data.total > 0 ? 1 : 0
      );
      setNumberPerPageFromByPendaftarBeasiswa(
        totalByPendaftarBeasiswa.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByPendaftarBeasiswa(
        totalByPendaftarBeasiswa.data.total > 0
          ? byPendaftarBeasiswa.data.length
          : 0
      );

      // Set Beasiswa - By Penerima Beasiswa
      setDataByPenerimaBeasiswa(byPenerimaBeasiswa.data);
      setTotalDataByPenerimaBeasiswa(totalByPenerimaBeasiswa.data.total);
      setTotalPageByPenerimaBeasiswa(
        Math.ceil(
          totalByPenerimaBeasiswa.data.total / numberPerPageByPenerimaBeasiswa
        )
      );
      setCurrentPageByPenerimaBeasiswa(
        totalByPenerimaBeasiswa.data.total > 0 ? 1 : 0
      );
      setNumberPerPageFromByPenerimaBeasiswa(
        totalByPenerimaBeasiswa.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByPenerimaBeasiswa(
        totalByPenerimaBeasiswa.data.total > 0
          ? byPenerimaBeasiswa.data.length
          : 0
      );
    }

    setIsBodyPanelLoading(false);
  };

  useOnClickOutside(exportToExcelOptionRef, () => {
    if (isExportToExcelOptionActive) {
      setIsExportToExcelOptionActive(false);
    }
  });

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper beasiswa-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Beasiswa</h1>
            <div className="header-right-button-area">
              <div
                className="single-button export-to-excel-button"
                onMouseDown={() =>
                  setIsExportToExcelOptionActive(!isExportToExcelOptionActive)
                }
              >
                <span className="button-text">Export to Excel</span>
                <span
                  className={`caret-down-icon${
                    isExportToExcelOptionActive ? " rotate" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
              <div
                className={`export-to-excel-option${
                  isExportToExcelOptionActive ? " active" : ""
                }`}
                ref={exportToExcelOptionRef}
              >
                <ul className="list-export-wrapper">
                  {exportToExcelList.map((item, index) => (
                    <li
                      className="single-list"
                      onClick={() => handleClickSingleListExport(item.value)}
                      key={index}
                    >
                      {item.isLoading ? (
                        <CircularProgress
                          size={13}
                          className="loading-circle"
                        />
                      ) : (
                        item.value
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="single-button"
                onClick={() =>
                  handleOpenDialog(setIsBeasiswaTersediaDialogActive)
                }
              >
                <span className="button-text">Beasiswa Tersedia</span>
              </div>
            </div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      {showDataActive.value === "Pendaftar Beasiswa" ? (
                        <Search
                          setData={setDataByPendaftarBeasiswa}
                          getData={getPendaftarBeasiswa}
                          dataFields={dataFieldsByPendaftarBeasiswa}
                          sortItem={sortItemByPendaftarBeasiswa}
                          searchInput={searchInputByPendaftarBeasiswa}
                          setSearchInput={setSearchInputByPendaftarBeasiswa}
                          optionsSearchFilterBy={
                            optionsSearchFilterByPendaftarBeasiswa
                          }
                          searchFilterBy={searchFilterByPendaftarBeasiswa}
                          setSearchFilterBy={setSearchFilterByPendaftarBeasiswa}
                          numberPerPage={numberPerPageByPendaftarBeasiswa}
                          setNumberPerPageFrom={
                            setNumberPerPageFromByPendaftarBeasiswa
                          }
                          setNumberPerPageTo={
                            setNumberPerPageToByPendaftarBeasiswa
                          }
                          getTotalData={getTotalPendaftarBeasiswa}
                          setTotalData={setTotalDataByPendaftarBeasiswa}
                          setTotalPage={setTotalPageByPendaftarBeasiswa}
                          setCurrentPage={setCurrentPageByPendaftarBeasiswa}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : (
                        <Search
                          setData={setDataByPenerimaBeasiswa}
                          getData={getPenerimaBeasiswa}
                          dataFields={dataFieldsByPenerimaBeasiswa}
                          sortItem={sortItemByPenerimaBeasiswa}
                          searchInput={searchInputByPenerimaBeasiswa}
                          setSearchInput={setSearchInputByPenerimaBeasiswa}
                          optionsSearchFilterBy={
                            optionsSearchFilterByPenerimaBeasiswa
                          }
                          searchFilterBy={searchFilterByPenerimaBeasiswa}
                          setSearchFilterBy={setSearchFilterByPenerimaBeasiswa}
                          numberPerPage={numberPerPageByPenerimaBeasiswa}
                          setNumberPerPageFrom={
                            setNumberPerPageFromByPenerimaBeasiswa
                          }
                          setNumberPerPageTo={
                            setNumberPerPageToByPenerimaBeasiswa
                          }
                          getTotalData={getTotalPenerimaBeasiswa}
                          setTotalData={setTotalDataByPenerimaBeasiswa}
                          setTotalPage={setTotalPageByPenerimaBeasiswa}
                          setCurrentPage={setCurrentPageByPenerimaBeasiswa}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      )}
                      <ShowDataToggle
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      {showDataActive.value === "Pendaftar Beasiswa" ? (
                        <Pagination
                          setData={setDataByPendaftarBeasiswa}
                          getData={getPendaftarBeasiswa}
                          dataFields={dataFieldsByPendaftarBeasiswa}
                          sortItem={sortItemByPendaftarBeasiswa}
                          searchInput={searchInputByPendaftarBeasiswa}
                          searchFilterBy={searchFilterByPendaftarBeasiswa}
                          optionsNumberPerPage={
                            optionsNumberPerPageByPendaftarBeasiswa
                          }
                          numberPerPage={numberPerPageByPendaftarBeasiswa}
                          setNumberPerPage={setNumberPerPageByPendaftarBeasiswa}
                          numberPerPageFrom={
                            numberPerPageFromByPendaftarBeasiswa
                          }
                          setNumberPerPageFrom={
                            setNumberPerPageFromByPendaftarBeasiswa
                          }
                          numberPerPageTo={numberPerPageToByPendaftarBeasiswa}
                          setNumberPerPageTo={
                            setNumberPerPageToByPendaftarBeasiswa
                          }
                          totalData={totalDataByPendaftarBeasiswa}
                          getTotalData={getTotalPendaftarBeasiswa}
                          setTotalData={setTotalDataByPendaftarBeasiswa}
                          totalPage={totalPageByPendaftarBeasiswa}
                          setTotalPage={setTotalPageByPendaftarBeasiswa}
                          currentPage={currentPageByPendaftarBeasiswa}
                          setCurrentPage={setCurrentPageByPendaftarBeasiswa}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : (
                        <Pagination
                          setData={setDataByPenerimaBeasiswa}
                          getData={getPenerimaBeasiswa}
                          dataFields={dataFieldsByPenerimaBeasiswa}
                          sortItem={sortItemByPenerimaBeasiswa}
                          searchInput={searchInputByPenerimaBeasiswa}
                          searchFilterBy={searchFilterByPenerimaBeasiswa}
                          optionsNumberPerPage={
                            optionsNumberPerPageByPenerimaBeasiswa
                          }
                          numberPerPage={numberPerPageByPenerimaBeasiswa}
                          setNumberPerPage={setNumberPerPageByPenerimaBeasiswa}
                          numberPerPageFrom={
                            numberPerPageFromByPenerimaBeasiswa
                          }
                          setNumberPerPageFrom={
                            setNumberPerPageFromByPenerimaBeasiswa
                          }
                          numberPerPageTo={numberPerPageToByPenerimaBeasiswa}
                          setNumberPerPageTo={
                            setNumberPerPageToByPenerimaBeasiswa
                          }
                          totalData={totalDataByPenerimaBeasiswa}
                          getTotalData={getTotalPenerimaBeasiswa}
                          setTotalData={setTotalDataByPenerimaBeasiswa}
                          totalPage={totalPageByPenerimaBeasiswa}
                          setTotalPage={setTotalPageByPenerimaBeasiswa}
                          currentPage={currentPageByPenerimaBeasiswa}
                          setCurrentPage={setCurrentPageByPenerimaBeasiswa}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      )}
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                {showDataActive.value === "Pendaftar Beasiswa" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByPendaftarBeasiswa}
                    setData={setDataByPendaftarBeasiswa}
                    getData={getPendaftarBeasiswa}
                    dataFields={dataFieldsByPendaftarBeasiswa}
                    sortItem={sortItemByPendaftarBeasiswa}
                    setSortItem={setSortItemByPendaftarBeasiswa}
                    searchInput={searchInputByPendaftarBeasiswa}
                    searchFilterBy={searchFilterByPendaftarBeasiswa}
                    numberPerPage={numberPerPageByPendaftarBeasiswa}
                    setNumberPerPageFrom={
                      setNumberPerPageFromByPendaftarBeasiswa
                    }
                    setNumberPerPageTo={setNumberPerPageToByPendaftarBeasiswa}
                    totalData={totalDataByPendaftarBeasiswa}
                    getTotalData={getTotalPendaftarBeasiswa}
                    setTotalData={setTotalDataByPendaftarBeasiswa}
                    setTotalPage={setTotalPageByPendaftarBeasiswa}
                    setCurrentPage={setCurrentPageByPendaftarBeasiswa}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentByPendaftarBeasiswa
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : (
                  <Table
                    ref={tableWrapperRef}
                    data={dataByPenerimaBeasiswa}
                    setData={setDataByPenerimaBeasiswa}
                    getData={getPenerimaBeasiswa}
                    dataFields={dataFieldsByPenerimaBeasiswa}
                    sortItem={sortItemByPenerimaBeasiswa}
                    setSortItem={setSortItemByPenerimaBeasiswa}
                    searchInput={searchInputByPenerimaBeasiswa}
                    searchFilterBy={searchFilterByPenerimaBeasiswa}
                    numberPerPage={numberPerPageByPenerimaBeasiswa}
                    setNumberPerPageFrom={
                      setNumberPerPageFromByPenerimaBeasiswa
                    }
                    setNumberPerPageTo={setNumberPerPageToByPenerimaBeasiswa}
                    totalData={totalDataByPenerimaBeasiswa}
                    getTotalData={getTotalPenerimaBeasiswa}
                    setTotalData={setTotalDataByPenerimaBeasiswa}
                    setTotalPage={setTotalPageByPenerimaBeasiswa}
                    setCurrentPage={setCurrentPageByPenerimaBeasiswa}
                    objectMime={objectMime}
                    setObjectMime={setObjectMime}
                    objectURL={objectURL}
                    setObjectURL={setObjectURL}
                    objectFilename={objectFilename}
                    setObjectFilename={setObjectFilename}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    isViewFileDialogActive={isViewFileDialogActive}
                    setIsViewFileDialogActive={setIsViewFileDialogActive}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentByPenerimaBeasiswa
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <Dialog
        fullScreen
        open={isViewFileDialogActive}
        onClose={() => handleCloseDialog(setIsViewFileDialogActive)}
        className={`full-screen-app-bar${
          dialogFullScreenClassName ? " " + dialogFullScreenClassName : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsViewFileDialogActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              File Viewer
            </Typography>
            {objectURL &&
              objectMime &&
              objectFilename &&
              objectMime.split("/")[0] === "image" && (
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => downloadBlob(objectURL, objectFilename)}
                  className="single-button-right-top-custom"
                >
                  Download
                </Button>
              )}
          </Toolbar>
        </AppBar>
        {objectURL && objectMime && objectMime === "application/pdf" && (
          <div className="fullscreen-pdf-file-viewer">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
              <div className="pdf-file-viewer">
                <Viewer fileUrl={objectURL} />
              </div>
            </Worker>
          </div>
        )}
        {objectURL && objectMime && objectMime.split("/")[0] === "image" && (
          <div className="fullscreen-image-file-viewer">
            <img className="image-file" src={objectURL} />
          </div>
        )}
      </Dialog>
      <BeasiswaTersedia
        isBeasiswaTersediaDialogActive={isBeasiswaTersediaDialogActive}
        setIsBeasiswaTersediaDialogActive={setIsBeasiswaTersediaDialogActive}
        dialogFullScreenClassName={dialogFullScreenClassName}
      />
    </React.Fragment>
  );
}

export default Beasiswa;
