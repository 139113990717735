import axios from "axios";
import config from "../../Config";

const getKKMByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm/${id}`,
  });

  const response = request.data;
  return response;
};

const insertKKM = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm`,
    data,
  });

  const response = request.data;
  return response;
};

const updateKKMBuktiKeikutsertaan = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm/bukti_keikutsertaan`,
    data,
  });

  const response = request.data;
  return response;
};

const insertSKP = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/skp`,
    data,
  });

  const response = request.data;
  return response;
};

const getKKM = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalKKM = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getSKPByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/skp/${id}`,
  });

  const response = request.data;
  return response;
};

const getSKP = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/skp`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalSKP = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/skp/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const updateKKMStatus = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/kkm/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const updateSKPStatus = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/kaderisasi_berkelanjutan/skp/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getSKPByPengguna,
  getKKMByPengguna,
  insertKKM,
  updateKKMBuktiKeikutsertaan,
  insertSKP,
  updateKKMStatus,
  updateSKPStatus,
  getKKM,
  getTotalKKM,
  getSKP,
  getTotalSKP,
};
