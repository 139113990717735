import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="not-found-wrapper">
      <div className="inner-not-found">
        <h1 className="main-title-msg">Oops!</h1>
        <p className="sub-title-msg">Sesuatu yang Anda cari tidak ditemukan.</p>
        <p className="optional-info-msg">Kembali ke <Link to={"/"}>Homepage</Link></p>
      </div>
    </div>
  )
}

export default NotFound;