import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import { UserContext } from "../../../../../../User";
import { DatePicker } from "@material-ui/pickers";
import { dateFormat } from "../../../../../../../Services/Format/date";
import swal from "sweetalert";
import {
  handleChangeTextField,
  handleMouseEnterTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleMouseDownClearTextFieldDate,
  handleToggleDialogActive,
  handleChangeSelectItem,
  handleCloseErrorSnackbar,
  handleFormSubmitAction,
} from "../../../../../../../Services/FormHandler";
import { urlRegExp } from "../../../../../../../Services/RegExp";
import { insertPublikasi } from "../../../../../../../Services/API/publikasi";

function Publikasi() {
  const userContext = useContext(UserContext);

  const [isButtonActionLoading, setIsButtonActionLoading] = useState(false);
  const [judulPublikasi, setJudulPublikasi] = useState("");
  const [
    isJudulPublikasiClearActive,
    setIsJudulPublikasiClearActive,
  ] = useState(false);
  const [tanggalPublikasi, setTanggalPublikasi] = useState(null);
  const [
    isTanggalPublikasiClearActive,
    setIsTanggalPublikasiClearActive,
  ] = useState(false);
  const [
    isTanggalPublikasiDialogActive,
    setIsTanggalPublikasiDialogActive,
  ] = useState(false);
  const [linkPublikasi, setLinkPublikasi] = useState("");
  const [isLinkPublikasiClearActive, setIsLinkPublikasiClearActive] = useState(
    false
  );
  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleResetData = () => {
    setJudulPublikasi("");
    setTanggalPublikasi(null);
    setLinkPublikasi("");
  };

  const getErrorList = (target) => {
    const _errorList = [];

    if (target.includes("judulPublikasi")) {
      if (!judulPublikasi) {
        _errorList.push({
          type: "required",
          message: "Judul Publikasi wajib diisi.",
        });
      }
    }

    if (target.includes("tanggalPublikasi")) {
      if (!tanggalPublikasi) {
        _errorList.push({
          type: "required",
          message: "Tanggal Publikasi wajib diisi.",
        });
      }
    }

    if (target.includes("linkPublikasi")) {
      if (!linkPublikasi) {
        _errorList.push({
          type: "required",
          message: "Link Publikasi wajib diisi.",
        });
      } else {
        if (!urlRegExp.test(linkPublikasi)) {
          _errorList.push({
            type: "pattern",
            message: "Format Link Publikasi tidak valid.",
          });
        }
      }
    }

    return _errorList;
  };

  useEffect(() => {
    setIsJudulPublikasiClearActive(judulPublikasi ? true : false);
  }, [judulPublikasi]);

  useEffect(() => {
    setIsLinkPublikasiClearActive(linkPublikasi ? true : false);
  }, [linkPublikasi]);

  useEffect(() => {
    if (isFormSubmitted) {
      setIsErrorSnackbarActive(errorList ? true : false);
      setIsFormSubmitted(false);

      setTimeout(async () => {
        if (errorList.length === 0) {
          setIsButtonActionLoading(true);

          const insert = await insertPublikasi({
            id_pengguna: userContext.userActive.id_pengguna,
            judul_publikasi: judulPublikasi,
            tanggal_publikasi: dateFormat(
              new Date(tanggalPublikasi),
              "yyyy-MM-dd"
            ),
            link_publikasi: linkPublikasi,
            tanggal_pengajuan: dateFormat(new Date(), "yyyy-MM-dd"),
          });

          if (insert.success) {
            swal("Sukses!", insert.message, "success");
          } else {
            swal(
              "Oops!",
              insert.message ? insert.message : "Internal Server Error",
              "warning"
            );
          }

          setIsButtonActionLoading(false);
          setTimeout(handleResetData, 0);
        }
      }, 0);
    }
  }, [errorList]);

  useEffect(() => {
    setErrorList(
      getErrorList(["judulPublikasi", "tanggalPublikasi", "linkPublikasi"])
    );
  }, [isFormSubmitted]);

  return (
    <div className="user-page-publikasi">
      <div className="illustration-area">
        <img
          src={require("../../../../../../../Assets/Illustrations/6.jpg")}
          className="illustration"
        />
      </div>
      <div className="header-area">
        <span
          className="close-form-icon"
          onClick={() => userContext.dispatchMenuActive("SelamatDatang")}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
        <span className="title-text">Publikasi</span>
        <span className="sub-title-text">Pendataan Publikasi</span>
      </div>
      <div className="body-area">
        <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
          <div className="form-group required">
            <TextField
              id="publikasi-judul-publikasi"
              label="Judul Publikasi"
              variant="outlined"
              value={judulPublikasi || ""}
              onChange={(event) =>
                handleChangeTextField(event, setJudulPublikasi)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  judulPublikasi,
                  setIsJudulPublikasiClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsJudulPublikasiClearActive)
              }
              InputProps={{
                endAdornment: isJudulPublikasiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setJudulPublikasi,
                          setIsJudulPublikasiClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group required">
            <DatePicker
              format="dd-MM-yyyy"
              label="Tanggal Publikasi"
              maxDate={new Date()}
              openTo="year"
              views={["year", "month", "date"]}
              value={tanggalPublikasi || null}
              inputVariant="outlined"
              onChange={(selected) =>
                handleChangeSelectItem(selected, setTanggalPublikasi)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  tanggalPublikasi,
                  setIsTanggalPublikasiClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsTanggalPublikasiClearActive)
              }
              open={isTanggalPublikasiDialogActive}
              onOpen={() =>
                handleToggleDialogActive(
                  isTanggalPublikasiDialogActive,
                  setIsTanggalPublikasiDialogActive
                )
              }
              onClose={() =>
                handleToggleDialogActive(
                  isTanggalPublikasiDialogActive,
                  setIsTanggalPublikasiDialogActive
                )
              }
              InputProps={{
                endAdornment: isTanggalPublikasiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() =>
                        handleMouseDownClearTextFieldDate(
                          setTanggalPublikasi,
                          setIsTanggalPublikasiClearActive,
                          setIsTanggalPublikasiDialogActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group required">
            <TextField
              id="publikasi-link-publikasi"
              label="Link Publikasi"
              variant="outlined"
              value={linkPublikasi || ""}
              onChange={(event) =>
                handleChangeTextField(event, setLinkPublikasi)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  linkPublikasi,
                  setIsLinkPublikasiClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsLinkPublikasiClearActive)
              }
              InputProps={{
                endAdornment: isLinkPublikasiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setLinkPublikasi,
                          setIsLinkPublikasiClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-action-area">
            <div className="helper-wrapper"></div>
            <div className="button-wrapper">
              <button
                type="submit"
                className="submit-button"
                onClick={(event) =>
                  handleFormSubmitAction(event, setIsFormSubmitted)
                }
              >
                {isButtonActionLoading ? (
                  <CircularProgress size={18} className="loading-circle" />
                ) : (
                  <span className="button-text">Submit</span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseErrorSnackbar(setIsErrorSnackbarActive)}
          autoHideDuration={8000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </div>
  );
}

export default Publikasi;
