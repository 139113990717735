import React, { useContext } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { AdminContext } from "../../../../../Panel";

function BoxPanel() {
  const adminContext = useContext(AdminContext);

  return (
    <div className="box-panel-wrapper">
      <div className="row">
        {(adminContext.level === "super" ||
          adminContext.level === "pengabdian") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/pengabdian">
              <div className="inner-box-panel pengabdian-box-panel">
                <span className="box-panel-text">Pengabdian</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "bisnis") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/bisnis">
              <div className="inner-box-panel bisnis-box-panel">
                <span className="box-panel-text">Bisnis</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "beasiswa") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/beasiswa">
              <div className="inner-box-panel beasiswa-box-panel">
                <span className="box-panel-text">Beasiswa</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "liga kreasi") && (
          <React.Fragment>
            <div className="single-box-panel col-3">
              <Link to="/adminpanel/liga-kreasi">
                <div className="inner-box-panel liga-kreasi-box-panel">
                  <span className="box-panel-text">Liga Kreasi</span>
                </div>
              </Link>
            </div>
            <div className="single-box-panel col-3">
              <Link to="/adminpanel/reimburse">
                <div className="inner-box-panel reimburse-box-panel">
                  <span className="box-panel-text">Reimburse</span>
                </div>
              </Link>
            </div>
          </React.Fragment>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "kaderisasi berkelanjutan") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/kaderisasi-berkelanjutan">
              <div className="inner-box-panel kaderisasi-berkelanjutan-box-panel">
                <span className="box-panel-text">Kaderisasi Berkelanjutan</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "keterangan kinerja") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/keterangan-kinerja">
              <div className="inner-box-panel keterangan-kinerja-box-panel">
                <span className="box-panel-text">Keterangan Kinerja</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "publikasi") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/publikasi">
              <div className="inner-box-panel publikasi-box-panel">
                <span className="box-panel-text">Publikasi</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "organisasi") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/organisasi">
              <div className="inner-box-panel organisasi-box-panel">
                <span className="box-panel-text">Organisasi / Kepanitiaan</span>
              </div>
            </Link>
          </div>
        )}
        {(adminContext.level === "super" ||
          adminContext.level === "pengumuman") && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/pengumuman">
              <div className="inner-box-panel pengumuman-box-panel">
                <span className="box-panel-text">Pengumuman</span>
              </div>
            </Link>
          </div>
        )}
        {adminContext.level === "super" && (
          <div className="single-box-panel col-3">
            <Link to="/adminpanel/admin">
              <div className="inner-box-panel admin-box-panel">
                <span className="box-panel-text">Admin</span>
              </div>
            </Link>
          </div>
        )}
        {adminContext.level === "super" || adminContext.level === "pengguna" && (
          <>
            <div className="single-box-panel col-3">
              <Link to="/adminpanel/pengguna">
                <div className="inner-box-panel pengguna-box-panel">
                  <span className="box-panel-text">Pengguna</span>
                </div>
              </Link>
            </div>
            <div className="single-box-panel col-3">
              <Link to="/adminpanel/verifikasi-pengguna">
                <div className="inner-box-panel verifikasi-pengguna-box-panel">
                  <span className="box-panel-text">Verifikasi Pengguna</span>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BoxPanel;
