import React, { useContext } from "react";
import "./style.css";
import { UserContext } from "../../../../../../../../User";
import Info from "../Content/Info";
import Poll from "../Content/Poll";
import Survey from "../Content/Survey";
import Recruitment from "../Content/Recruitment";
import ComingSoon from "./ComingSoon";

function Content() {
  const userContext = useContext(UserContext);

  return (
    <div className="content-area">
      {userContext.pengumumanContentActive === "Info" ? (
        <ComingSoon />
      ) : userContext.pengumumanContentActive === "Poll" ? (
        <ComingSoon />
      ) : userContext.pengumumanContentActive === "Survey" ? (
        <Survey />
      ) : userContext.pengumumanContentActive === "Recruitment" ? (
        <ComingSoon />
      ) : null}
    </div>
  );
}

export default Content;
