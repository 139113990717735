import React, { useContext, useState } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
  faEye,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getImgFromUrl } from "../../../../../Services/Image";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import {
  handleOpenDialog,
  handleCloseDialog,
} from "../../../../../Services/FormHandler";
import { dateFormat } from "../../../../../Services/Format/date";
import { ProfileContext } from "../../../Profil";

function KaderisasiBerkelanjutan() {
  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  const [isSKPModalActive, setIsSKPModalActive] = useState(false);

  const pdf_dataHeadTable = [
    [
      "Nama Kegiatan",
      "Tingkat",
      "Indikator Pencapaian",
      "Status Terverifikasi",
    ],
  ];

  const pdf_dataBodyTable = profileContext.kkm.map((item) => {
    return [
      item.nama_kegiatan,
      item.tingkat,
      item.indikator_pencapaian.split("*; ").map(e => {
        return e.padStart(e.length + 2, "- ");
      }).join("\n"),
      {
        content: item.status,
        styles: {
          textColor:
            item.status === "Accepted"
              ? [0, 201, 114]
              : item.status === "Rejected"
              ? [255, 0, 0]
              : [255, 146, 30],
        },
      },
    ];
  });

  const generatePDF = async () => {
    const doc = new jsPDF("l", "pt", "a4");
    const pageWidth = doc.internal.pageSize.width;
    // let profilePicture;

    // if (userContext.userActive.foto_pengguna) {
    //   profilePicture = await getImgFromUrl(
    //     require("../../../../../Assets/Miscellaneous/no_image.png")
    //   );
    // }

    // if (profilePicture) {
    //   doc.addImage(profilePicture, "png", pageWidth / 2 - 60, 10, 120, 120);
    // }

    doc.setFontSize(13);
    doc.text(
      `Nama            : ${userContext.userActive.nama_lengkap}`,
      40,
      50,
      null,
      null,
      "left"
    );

    doc.text(
      `Angkatan      : FKUI ${userContext.userActive.angkatan}`,
      40,
      70,
      null,
      null,
      "left"
    );

    doc.text(
      `NPM             : ${userContext.userActive.npm}`,
      40,
      90,
      null,
      null,
      "left"
    );

    doc.setFontSize(20);
    doc.text("KKM", pageWidth - 40, 65, null, null, "right");

    doc.setFontSize(16);
    doc.text(
      "Kaderisasi Berkelanjutan",
      pageWidth - 40,
      90,
      null,
      null,
      "right"
    );

    doc.autoTable({
      theme: "striped",
      styles: { minCellWidth: 150 },
      head: pdf_dataHeadTable,
      headStyles: { fillColor: [50, 105, 65] },
      body: pdf_dataBodyTable,
      startY: 120,
    });

    doc.save(
      `KKM_${userContext.userActive.nama_lengkap}_${userContext.userActive.npm}.pdf`
    );
  };

  return (
    <React.Fragment>
      <div className="kaderisasi-berkelanjutan-box box-wrapper">
        <div className="box-header-area">
          <span className="box-title-text">Kaderisasi Berkelanjutan</span>
          <div className="box-action-area">
            {/* <div className="single-button download-button">
            <span className="button-text-area">
              <span className="button-icon">
                <FontAwesomeIcon icon={faFilePdf} />
              </span>
              <span className="button-text">Download as PDF</span>
            </span>
          </div> */}
          </div>
        </div>
        <div className="box-body-area">
          <div className="row">
            <div className="single-box col-lg-4 col-md-6 col-12">
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">KKM</span>
                </div>
                <div className="button-wrapper-area">
                  <div className="center-button">
                    <span className="icon-button">
                      <FontAwesomeIcon icon={faFilePdf} />
                    </span>
                    <span className="text-button" onClick={generatePDF}>
                      Download as PDF
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-box col-lg-4 col-md-6 col-12">
              <div className="inner-box">
                <div className="title-area">
                  <span className="title-text">SKP</span>
                </div>
                <div className="button-wrapper-area">
                  <div className="center-button">
                    <span className="icon-button">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                    <span
                      className="text-button"
                      onClick={() => handleOpenDialog(setIsSKPModalActive)}
                    >
                      Lihat Nilai
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={isSKPModalActive}
        onClose={() => handleCloseDialog(setIsSKPModalActive)}
        aria-label="close"
      >
        <AppBar className="kaderisasi-berkelanjutan-full-screen-bar full-screen-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog(setIsSKPModalActive)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              SKP
            </Typography>
          </Toolbar>
        </AppBar>
        <form className="fullscreen-modal-form-body" autoComplete="off">
          {profileContext.skp.map((item, index) => (
            <div className="single-form-group-wrapper" key={index}>
              <div className="form-group disabled">
                <TextField
                  id="skp-unsur-kegiatan"
                  label="Unsur Kegiatan"
                  variant="outlined"
                  value={item.unsur_kegiatan}
                  readOnly
                />
              </div>
              {item.tingkat && (
                <div className="form-group disabled">
                  <TextField
                    id="skp-tingkat"
                    label="Tingkat"
                    variant="outlined"
                    value={item.tingkat}
                    readOnly
                  />
                </div>
              )}
              {item.posisi && (
                <div className="form-group disabled">
                  <TextField
                    id="skp-posisi"
                    label="Posisi"
                    variant="outlined"
                    value={item.posisi}
                    readOnly
                  />
                </div>
              )}
              <div className="form-group disabled">
                <div className="inner-form-group-wrapper">
                  <div className="inner-half-form">
                    <TextField
                      id="skp-bobot"
                      label="Bobot"
                      variant="outlined"
                      value={item.bobot}
                      readOnly
                    />
                  </div>
                  <div
                    className={`inner-half-form${
                      item.status === "Pending"
                        ? " pending"
                        : item.status === "Rejected"
                        ? " rejected"
                        : item.status === "Accepted"
                        ? " accepted"
                        : ""
                    }`}
                  >
                    <TextField
                      id="skp-status"
                      label="Status"
                      variant="outlined"
                      value={
                        item.status === "Pending"
                          ? "Belum Diverifikasi"
                          : item.status === "Rejected"
                          ? "Ditolak"
                          : item.status === "Accepted"
                          ? "Telah Diverifikasi"
                          : ""
                      }
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default KaderisasiBerkelanjutan;
