import React, { useContext, useState } from "react";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import NavbarPemira from "./Components/NavbarPemira";
import "./styles.css";
import { __RouterContext } from "react-router";
import Counter from "./Components/Counter";
import Settings from "./Components/Settings";
import KotakSuara from "./Components/KotakSuara";

function PemiraAdmin() {
  const routerContext = useContext(__RouterContext);
  const showCounter = routerContext.location.search === "?show=counter";
  const showKotakSuara = routerContext.location.search === "?show=kotaksuara";
  const showSettings = routerContext.location.search === "?show=settings";
  const [loggedIn, setLoggedIn] = useState(false);
  const [darkMode, setDarkmode] = useState(false);
  return (
    <div
      className={
        darkMode
          ? "pemira-admin__container dark-mode"
          : "pemira-admin__container"
      }
    >
      {loggedIn ? (
        <>
          <NavbarPemira setDarkmode={setDarkmode} darkMode={darkMode} />
          {showCounter ? (
            <Counter />
          ) : showSettings ? (
            <Settings />
          ) : showKotakSuara ? (
            <KotakSuara/>
          ) : (
            <Dashboard />
          )}
        </>
      ) : (
        <>
          <Login setLoggedIn={setLoggedIn} />
        </>
      )}
    </div>
  );
}

export default PemiraAdmin;
