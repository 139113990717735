import React, { useContext, useState, useEffect, useRef } from "react";
import "../../style.css";
import "./style.css";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../../../User";
import { dateFormat } from "../../../../../Services/Format/date";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getImgFromUrl } from "../../../../../Services/Image";
import { ProfileContext } from "../../../Profil";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import swal from "sweetalert";
import { deleteLigaKreasi, deleteKelompokLigaKreasi } from "../../../../../Services/API/liga_kreasi";
import LigaKreasi from "../../../Beranda/Components/BodyContent/Components/LigaKreasi";
import useDidMountEffect from "../../../../../Components/useDidMountEffect";
import classNames from "classnames";

const browser = typeof window !== 'undefined';

function MyLigaKreasi() {
  const location = useLocation();

  const userContext = useContext(UserContext);
  const profileContext = useContext(ProfileContext);

  const [ligaKreasiDataActive, setLigaKreasiDataActive] = useState(null);

  const ligaKreasiBoxOverlayRef = useRef(null);

  const [isLigaKreasiOverlayActive, setIsLigaKreasiOverlayActive] = useState(false);

  const [totalSkor, setTotalSkor] = useState(profileContext.ligaKreasi.reduce(
    (accumulator, currentValue) => accumulator + currentValue.skor,
    0
  ) || 0);

  const pdf_dataHeadTable = [
    [
      "Jenis Perlombaan",
      "Bidang Lomba",
      "Nama Lomba (Cabang)",
      "Tingkat",
      "Capaian",
      "Tahun",
    ],
  ];

  const pdf_dataBodyTable = profileContext.ligaKreasi.map((item, index) => {
    return [
      item.jenis_perlombaan,
      item.bidang_perlombaan,
      item.nama_perlombaan,
      item.tingkat_perlombaan,
      item.capaian_prestasi,
      dateFormat(new Date(item.awal_pelaksanaan), "yyyy"),
    ];
  });

  const generatePDF = async () => {
    const doc = new jsPDF("l", "pt", "a4");
    const pageWidth = doc.internal.pageSize.width;

    doc.setFontSize(13);
    doc.text(
      `Nama            : ${userContext.userActive.nama_lengkap}`,
      40,
      50,
      null,
      null,
      "left"
    );

    doc.text(
      `Angkatan      : FKUI ${userContext.userActive.angkatan}`,
      40,
      70,
      null,
      null,
      "left"
    );

    doc.text(
      `NPM             : ${userContext.userActive.npm}`,
      40,
      90,
      null,
      null,
      "left"
    );

    doc.setFontSize(18);
    doc.text("Rapor Liga Kreasi", pageWidth - 40, 70, null, null, "right");

    doc.autoTable({
      head: pdf_dataHeadTable,
      headStyles: { fillColor: [120, 120, 120] },
      body: pdf_dataBodyTable,
      startY: 110,
    });

    doc.save(
      `LigaKreasi_${userContext.userActive.nama_lengkap}_${userContext.userActive.npm}.pdf`
    );
  };

  const updateTotalSkor = () => {

    const _totalSkor = profileContext.ligaKreasi.reduce((acc, curr) => {

      if (!curr.score_expired_at){
        // kalau current score_expired_at nya null, maka skornya dihitung
        return (acc + curr.skor)
      } 

      // kalau current score_expired_at nya tidak null, maka udah expired, skor tidak dihitung
      // const expiredAt = new Date(curr.score_expired_at); // score_expired_at ini dalam bentuk timestamp
      return acc
    }, 0);
    setTotalSkor(_totalSkor || 0);
  };

  const _updateLigaKreasiOnSuccess = (dataLigaKreasi) => {
    setIsLigaKreasiOverlayActive(false);
    setLigaKreasiDataActive(null);
    
    profileContext.setLigaKreasi((prev) => {
      return prev.map((item) => {
        return item.id_liga_kreasi === dataLigaKreasi.id_liga_kreasi 
          ? dataLigaKreasi
          : item
      });
    });
  };

  const _deleteLigaKreasi = async (id) => {
    const isCreator = profileContext.ligaKreasi.find((item) => {
      return item.is_creator
    });
    let _delete;

    if(isCreator) {
      _delete = await deleteLigaKreasi([ id ]);
    } else {
      _delete = await deleteKelompokLigaKreasi({
        id_liga_kreasi: id,
        id_pengguna: userContext.userActive.id_pengguna
      });
    }

    if(_delete.success) {
      profileContext.setLigaKreasi((prev) => {
        return prev.filter((item) => {
          return item.id_liga_kreasi !== id
        });
      });

      swal(
        "Sukses!", 
        _delete.message || (
          isCreator 
            ? "Data Liga Kreasi berhasil dihapus"
            : "Kamu berhasil dihapus dari kelompok Liga Kreasi"
        ),
        "success"
      );
    } else {
      swal(
        "Oops!", 
        _delete.message || (
          isCreator 
            ? "Data Liga Kreasi gagal dihapus"
            : "Kamu gagal dihapus dari kelompok Liga Kreasi"
        ),
        "warning"
      );
    }
  };

  const editBtnLigaKreasiOnClick = (id) => {
    document.body.style.overflow = "hidden";
    setIsLigaKreasiOverlayActive(true);
    setLigaKreasiDataActive(profileContext.ligaKreasi.find((item) => item.id_liga_kreasi === id));
  };

  const deleteBtnLigaKreasiOnClick = (id) => {
    swal({
      title: "Hapus Liga Kreasi",
      text: "Apakah Kamu yakin ingin menghapusnya?",
      buttons: {
        catch: {
          text: "Batal",
          value: "cancel",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "delete",
          className: "warning-color-custom"
        }
      }
    }).then((value) => {
      switch(value) {
        case "cancel":
          swal.close();
        break;
        case "delete":
          _deleteLigaKreasi(id);
        break;
      }
    });
  };
  const ligaKreasiOverlayOnClose = () => {
    setIsLigaKreasiOverlayActive(false);
  };

  const windowOnKeyUp = (e) => {
    if(e.keyCode === 27) {
      setIsLigaKreasiOverlayActive(false);
    }
  };

  useDidMountEffect(() => {
    updateTotalSkor();
  }, [profileContext.ligaKreasi]);

  useEffect(() => {
    if(!isLigaKreasiOverlayActive) {
      document.body.style.overflow = "initial";
    }
  }, [isLigaKreasiOverlayActive]);

  useEffect(() => {
    if(browser) {
      window.addEventListener("keyup", windowOnKeyUp);
      return () => window.removeEventListener("keyup", windowOnKeyUp);
    }
  }, []);

  return (
    <>
      <div className="liga-kreasi-box box-wrapper">
        <div className="box-header-area">
          <span className="box-title-text">Liga Kreasi</span>
          <div className="box-action-area">
            {location.pathname === "/profil" && (
              <div className="single-button download-button">
                <span className="button-text-area">
                  <span className="button-icon">
                    <FontAwesomeIcon icon={faFilePdf} />
                  </span>
                  <span className="button-text" onClick={generatePDF}>
                    Download as PDF
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="box-body-area">
          <div className="box-body-top">
            <div className="inner-box-body-top-left">
              <div className="single-info total">
                <span className="info-label">Total :</span>
                <span className="info-value">
                  {profileContext.ligaKreasi.length}
                </span>
              </div>
              <div className="single-info skor">
                <span className="info-label">Skor :</span>
                <span className="info-value">{totalSkor}</span>
              </div>
            </div>
            <div className="inner-box-body-top-right">
              {/* <div className="single-action pagination">
                <div className="prev-button-area">
                  <div className="single-button prev-button">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                </div>
                <div className="page-info-area">
                  <span className="page-info">Page 1 of 2</span>
                </div>
                <div className="next-button-area">
                  <div className="single-button next-button active">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="row">
            {profileContext.ligaKreasi.map((item) => (
              <div
                className="single-box col-lg-4 col-md-6 col-12"
                key={item.id_liga_kreasi}
              >
                <div className="inner-box">
                  <div className="title-area">
                    <span className="title-text">{item.nama_perlombaan}</span>
                  </div>
                  <div className="achievement-area">
                    <span className="achievement-value">
                      {item.capaian_prestasi}
                    </span>
                  </div>
                  <div className="period-area">
                    <span className="period-value">
                      {dateFormat(new Date(item.awal_pelaksanaan), "yyyy")}
                    </span>
                  </div>
                  <div className="level-area">
                    <div className="float-box-level">
                      <span className="level-value">
                        {item.tingkat_perlombaan}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          {profileContext.ligaKreasi.length > 0 && (
            <div className="table-wrapper">
              <table id="liga-kreasi-table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Bidang Lomba</th>
                    <th>Nama Lomba (Cabang)</th>
                    <th>Tingkat</th>
                    <th>Capaian</th>
                    <th>Tahun</th>
                    <th>Skor</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {profileContext.ligaKreasi.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span className={
                          classNames({
                            "textbox-green-smooth": item.status === "Accepted"
                          },
                          {
                            "textbox-red-smooth": item.status === "Rejected"
                          }, 
                          {
                            "textbox-yellow-smooth": item.status === "Pending"
                          })
                        }>
                          {item.status}
                        </span>
                      </td>
                      <td>{item.bidang_perlombaan}</td>
                      <td>{item.nama_perlombaan}</td>
                      <td>{item.tingkat_perlombaan}</td>
                      <td>{item.capaian_prestasi}</td>
                      <td>
                        {dateFormat(new Date(item.awal_pelaksanaan), "yyyy")}
                      </td>
                      <td>{item.skor || 0}</td>
                      <td>
                        <span 
                          className="liga-kreasi-table__btn"
                          title="Edit" 
                          aria-label="Edit"
                          onClick={(event) => editBtnLigaKreasiOnClick(item.id_liga_kreasi)}
                        >
                          <EditIcon />
                        </span>
                        <span 
                          className="liga-kreasi-table__btn"
                          title="Hapus" 
                          aria-label="Hapus"
                          onClick={(event) => deleteBtnLigaKreasiOnClick(item.id_liga_kreasi)}
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div 
        className={classNames(
          "overlay",
          {
            "active": isLigaKreasiOverlayActive
          }
        )}
      >
        <LigaKreasi 
          onClose={ligaKreasiOverlayOnClose} 
          dataActive={ligaKreasiDataActive}
          reimburse={false}
          actionType="Edit"
          success={_updateLigaKreasiOnSuccess}
        />
      </div>
    </>
  );
}

export default MyLigaKreasi;
