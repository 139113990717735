import React, { useEffect, useState } from "react";
import "./kotaksuara.css";
import "./counter.styles.css";
import Chart from "./Chart";
import Axios from "axios";

const data = {
  labels: [
    "Paslon 1 BEM (34,5%)",
    "Paslon 2 BEM (63,8%)",
    "Golongan Putih (1,7%)",
  ],
  datasets: [
    {
      label: "Pemilih Paslon BEM",
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(54, 162, 235, 0.5)",
      hoverBorderColor: "rgba(54, 162, 235, 1)",
      data: [345, 638, 17],
    },
  ],
};

const dataBpm = {
  labels: [
    "Anggota Independen 1 (79,4%)",
    "Surat Suara Kosong (18,7%)",
    "Golongan Putih (1,9%)",
  ],
  datasets: [
    {
      label: "Pemilih Anggota Independen BPM",
      backgroundColor: "rgba(255, 159, 64, 0.2)",
      borderColor: "rgba(255, 159, 64, 1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255, 159, 64, 0.4)",
      hoverBorderColor: "rgba(255, 159, 64, 1)",
      data: [794, 187, 19],
    },
  ],
};
function KotakSuara() {
  const [correctTime, setCorrectTime] = useState(false);

  useEffect(() => {
    // generate timestamp deadline: 1608022800000 4 o clock gmt7 in miliseconds
    const currentDate = new Date();
    const currTimestamp = currentDate.getTime();
    const timeToEnd = 1608022800000 - currTimestamp;
    if (timeToEnd < 1) {
      setCorrectTime(true);
    }
  }, []);
  const [exec0, setExec0] = useState(0);
  const [exec2, setExec2] = useState(0);
  const [exec1, setExec1] = useState(0);
  const [leg0, setLeg0] = useState(0);
  const [leg2, setLeg2] = useState(0);
  const [leg1, setLeg1] = useState(0);
  const [dataLength, setDataLength] = useState(0);

  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const correctPass = password === "selamatkanIKM";
    if (correctTime && correctPass) {
      setIsLoggedIn(true);
    } else {
      alert("Hasil belum dapat dibuka");
    }
  };

  const [suratSuara, setSuratSuara] = useState([]);
  useEffect(() => {
    Axios.get("/api/pemira/getAllChoices").then((res) => {

      if (res.data.results.length < 700) {
        alert("PROGRAM IS STILL USING DUMMY DATA!");
      }
      setSuratSuara(res.data.results);
      setDataLength(res.data.results.length);
    });
  }, []);

  const [showCard, setShowCard] = useState({});


  const handleReveal = ({ index, value }) => {
    setShowCard({
      ...showCard,
      [index]: {
        key: index,
        show: true,
      },
    });
    if (value.executiveChoice == 1) {
      setExec1((prev) => prev + 1);
    } else if (value.executiveChoice == 2) {
      setExec2((prev) => prev + 1);
    } else if (value.executiveChoice == 0) {
      setExec0((prev) => prev + 1);
    }

    if (value.legislativeChoice == 1) {
      setLeg1((prev) => prev + 1);
    } else if (value.executiveChoice == 2) {
      setLeg2((prev) => prev + 1);
    } else if (value.executiveChoice == 0) {
      setLeg0((prev) => prev + 1);
    }
  };

  const data = {
    labels: [
      "Paslon 1 BEM",
      "Paslon 2 BEM ",
      "Golongan Putih (BEM)",
      "Anggota Independen 1",
      "Surat Suara Kosong",
      "Golongan Putih (BPM)",
    ],
    datasets: [
      {
        label: "Surat Suara BEM & BPM",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.5)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: [exec1, exec2, exec0, leg1, leg2, leg0],
      },
    ],
  };

  return (
    <div className="counter-pemira__page">
      <div className="counter-pemira__container">
        <div className="header">
          <h1>Kotak Suara</h1>
          <p>
            Suara Paslon 1 BEM: {exec1}, Suara Paslon 2 BEM: {exec2}, Suara
            Golput (BEM): {exec0}
          </p>
          <p>
            Suara Anggota Independen 1 BPM: {leg1}, Suara Kosong BPM: {leg2},
            Suara Golput (BPM): {leg0}
          </p>
          <Chart data={data} />
        </div>
        {!isLoggedIn ? (
          <form onSubmit={handleSubmitPassword} className="login__container">
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
            <button onClick={handleSubmitPassword}>
              Buka Kotak Suara
            </button>
          </form>
        ) : (
          <>
            <section className="table-component__container">
              <div className="tbl-header">
                <table>
                  <thead>
                    <tr>
                      <th>Surat Suara</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="tbl-content">
                <table>
                  <tbody>
                    {suratSuara &&
                      suratSuara.map((value, index) => {
                        let reveal = false;
                        if (showCard[index]) {
                          reveal = showCard[index];
                        } else {
                          reveal = false;
                        }
                        return (
                          <tr key={index}>
                            {reveal ? (
                              <>
                                <td>
                                  Paslon BEM {value.executiveChoice}, Anggota
                                  Independen {value.legislativeChoice}
                                </td>
                              </>
                            ) : (
                              <>
                                <td
                                  className="tombol-buka-surat-suara"
                                  onClick={() => handleReveal({ index, value })}
                                >
                                  Buka Surat Suara
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </section>
          </>
        )}
      </div>
      {/* <button onClick={handleOpenAll}>Buka Semua Kartu</button> */}
    </div>
  );
}

export default KotakSuara;
