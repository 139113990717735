import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { getSurvey } from "../../../../../../../../../../Services/API/pengumuman";
import useDidMountEffect from "../../../../../../../../../../Components/useDidMountEffect";
import BallClipRotateMultipleCustom from "../../../../../../../../../../Components/BallClipRotateMultipleCustom";
import { Link } from "react-router-dom";

function Survey() {
  const [dataSurvey, setDataSurvey] = useState([]);

  const [isInitLoading, setIsInitLoading] = useState(true);

  const loadMoreSurveyRef = useRef(null);

  const limitSurveyPerPage = 10;

  const [isIntersectionSurveyLoading, setIsIntersectionSurveyLoading] = useState(false);

  const [offsetSurvey, setOffsetSurvey] = useState(0);

  const fetchSurvey = async (options = { isInit: true }) => {
    const _survey = await getSurvey({
      search: null,
      order: {
        by: "tanggal_publikasi",
        mode: "DESC"
      },
      limit: limitSurveyPerPage,
      offset: offsetSurvey
    });

    if(_survey.success) {
      setDataSurvey((prevSurvey) => [...prevSurvey, ..._survey.data]);

      if(options.isInit) setIsInitLoading(false);

      setIsIntersectionSurveyLoading(
        _survey.data.length >= limitSurveyPerPage
      );
    }
  };

  const loadMoreSurvey = () => {
    setOffsetSurvey(
      (prevOffsetSurvey) => prevOffsetSurvey + limitSurveyPerPage
    );
  };

  useDidMountEffect(() => {
    if(offsetSurvey > 0) {
      fetchSurvey({ isInit: false });
    }
  }, [offsetSurvey]);

  useDidMountEffect(() => {
    if(isIntersectionSurveyLoading) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreSurvey();
          }
        },
        { threshold: 1 }
      );

      observer.observe(loadMoreSurveyRef.current);
    }
  }, [isIntersectionSurveyLoading]);

  useEffect(() => {
    fetchSurvey({ isInit: true });
  }, []);

  return (
    <div className="survey-wrapper">
      {dataSurvey.length > 0 ? (
        dataSurvey.map((item, index) => (
          <div className="single-survey" key={item.id_survey}>
            <div className="title-area">
              <span className="title-text">
                {item.judul}
              </span>
            </div>
            <div className="sub-head-area">
              <span className="date-text">{item.tanggal_publikasi}</span>
              <span className={`status-box${item.status === "Open" ? " textbox-green-smooth" : item.status === "Closed" ? " textbox-red-smooth" : ""}`}>
                <span className="status-text">{item.status}</span>
              </span>
            </div>
            <div className="description-area">
              <div className="description-text preformatted-text">{item.deskripsi}</div>
            </div>
            <div className="button-area">
              <div className={`toggle-button${item.status === "Open" ? "" : item.status === "Closed" ? " disabled" : ""}`}>
                <Link to={`/survey/${item.slug}`}>
                  <span className="button-text-area">
                    <span className="button-text">Isi Form</span> 
                    <span className="button-text-icon">
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : !isInitLoading && dataSurvey.length < 1 && (
        <span className="not-available-message">Oops! Survey tidak tersedia.</span>
      )}
      {isIntersectionSurveyLoading && (
        <div className="load-more-area" ref={loadMoreSurveyRef}>
          <BallClipRotateMultipleCustom
            isLoading={true}
            styleCustom={{
              position: "relative",
              height: "40px",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Survey;
