import { Workbook } from "exceljs";
import * as fs from "file-saver";

const generateExcel = (props) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet(props.worksheet);

  // Add new row
  let titleRow = worksheet.addRow([props.title]);

  // Set font, size and style in title row.
  titleRow.font = {
    size: 16,
    bold: true,
  };

  // Blank Row
  worksheet.addRow([]);

  // Add row with sub title
  let subTitleRow = worksheet.addRow([props.subTitle]);

  subTitleRow.font = {
    size: 14,
  };

  // Blank Row
  worksheet.addRow([]);

  // Add Header Row
  let headerRow = worksheet.addRow(props.header);

  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: props.bgHeader },
    };

    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    cell.font = {
      size: 13,
    };

    cell.alignment = {
      horizontal: "left",
      vertical: "middle",
      wrapText: true,
    };
  });

  // Add Data and Conditional Formatting
  props.data.forEach((d) => {
    let row = worksheet.addRow(d);

    props.cell.forEach((item, index) => {
      let cell = row.getCell(index + 1);
      let bgColor = item.exportToExcel.bgColor;
      let type = item.exportToExcel.type;

      if (Array.isArray(bgColor)) {
        bgColor = bgColor.find((x) => x.value === cell.value).argb;
      }

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: bgColor },
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      if (type === "textlink") {
        const x = cell.value;

        cell.value = {
          formula: `HYPERLINK("${x}", "${x}")`,
        };

        cell.font = {
          color: {
            argb: "FF0000FF",
          },
          underline: "single",
        };
      }
    });
  });

  // Set each column width
  worksheet.columns.forEach(function (column, i) {
    column.width = props.cell[i].exportToExcel.cellWidth;
  });

  // Workbook is ready to export
  workbook.xlsx.writeBuffer().then((data = props.data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, props.fileName);
  });
};

export { generateExcel };
