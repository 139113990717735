import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import PopperCustom from "../../../../../../../Components/PopperCustom";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import { dateFormat } from "../../../../../../../Services/Format/date";
import {
  getOptionLabel,
  filterOptions,
  handleChangeSelectItem,
  handleOpenDialog,
  handleCloseDialog,
  handleMouseEnterTextField,
  handleChangeTextField,
  handleMouseLeaveTextField,
  handleClickClearTextField,
  handleToggleDialogActive,
  handleMouseDownClearTextFieldDate,
} from "../../../../../../../Services/FormHandler";
import { DatePicker } from "@material-ui/pickers";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import CloseIcon from "@material-ui/icons/Close";
import {
  getOrganisasi,
  getTotalOrganisasi,
  insertOrganisasi,
  insertDetailOrganisasi,
  deleteOrganisasi,
  deleteDetailOrganisasiByOrganisasi,
  updateOrganisasi,
} from "../../../../../../../Services/API/organisasi";
import { getPengguna } from "../../../../../../../Services/API/pengguna";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";

function OrganisasiDialog(props) {
  let anggotaRef = useRef(null);
  let jabatanAnggotaRef = useRef(null);

  const [
    isSubmitButtonActionLoading,
    setIsSubmitButtonActionLoading,
  ] = useState(false);

  const [idOrganisasi, setIdOrganisasi] = useState(null);

  const [jenis, setJenis] = useState(null);

  const [optionsJenis, setOptionsJenis] = useState([
    { value: "Organisasi", label: "Organisasi" },
    { value: "Kepanitiaan", label: "Kepanitiaan" },
  ]);

  const [namaOrganisasi, setNamaOrganisasi] = useState("");

  const [
    isNamaOrganisasiClearActive,
    setIsNamaOrganisasiClearActive,
  ] = useState(false);

  const [periodeAwal, setPeriodeAwal] = useState(null);

  const [isPeriodeAwalClearActive, setIsPeriodeAwalClearActive] = useState(
    false
  );

  const [isPeriodeAwalDialogActive, setIsPeriodeAwalDialogActive] = useState(
    false
  );

  const [periodeAkhir, setPeriodeAkhir] = useState(null);

  const [isPeriodeAkhirClearActive, setIsPeriodeAkhirClearActive] = useState(
    false
  );

  const [isPeriodeAkhirDialogActive, setIsPeriodeAkhirDialogActive] = useState(
    false
  );

  const [anggota, setAnggota] = useState(null);

  const [jabatanAnggota, setJabatanAnggota] = useState("");

  const [
    isJabatanAnggotaClearActive,
    setIsJabatanAnggotaClearActive,
  ] = useState(false);

  const [anggotaSelected, setAnggotaSelected] = useState([]);

  const [isAnggotaEnterClicked, setIsAnggotaEnterClicked] = useState(false);

  const [isTambahAnggotaClicked, setIsTambahAnggotaClicked] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [isErrorSnackbarActive, setIsErrorSnackbarActive] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getErrorList = (target) => {
    let _errorList = [];

    if (target.includes("jenis")) {
      if (!jenis) {
        _errorList.push({
          type: "required",
          message: "Jenis wajib dipilih.",
        });
      }
    }

    if (target.includes("namaOrganisasi")) {
      if (!namaOrganisasi) {
        _errorList.push({
          type: "required",
          message: "Nama Organisasi/Kepanitiaan wajib diisi.",
        });
      }
    }

    if (target.includes("periodeAwal")) {
      if (!periodeAwal) {
        _errorList.push({
          type: "required",
          message: "Periode Awal wajib diisi.",
        });
      }
    }

    if (target.includes("periodeAkhir")) {
      if (!periodeAkhir) {
        _errorList.push({
          type: "required",
          message: "Periode Akhir wajib diisi.",
        });
      }
    }

    if (target.includes("anggotaSelected")) {
      if (anggotaSelected.length === 0) {
        _errorList.push({
          type: "required",
          message: "Anggota wajib diisi.",
        });
      }
    }

    if (target.includes("anggota")) {
      if (!anggota) {
        _errorList.push({
          type: "required",
          message: "Anggota wajib dipilih.",
        });
      }
    }

    if (target.includes("jabatan")) {
      if (!jabatanAnggota) {
        _errorList.push({
          type: "required",
          message: "Jabatan Anggota wajib diisi.",
        });
      }
    }

    return _errorList;
  };

  const handleKeyUpAnggota = (event) => {
    if (event.keyCode === 13) {
      setIsAnggotaEnterClicked(true);
    }
  };

  const handleKeyUpJabatan = (event) => {
    if (event.keyCode === 13) {
      setIsTambahAnggotaClicked(true);
    }
  };

  const handleClickTambahAnggota = () => {
    setIsTambahAnggotaClicked(true);
  };

  const handleClickSubmitAction = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
  };

  const handleClickDeleteAnggota = (id) => {
    setAnggotaSelected(anggotaSelected.filter((item) => item.value !== id));
  };

  const handleResetData = () => {
    setJenis(null);
    setNamaOrganisasi("");
    setPeriodeAwal(null);
    setPeriodeAkhir(null);
    setAnggotaSelected([]);
    setAnggota(null);
    setJabatanAnggota("");
  };

  useDidMountEffect(() => {
    setIsNamaOrganisasiClearActive(namaOrganisasi ? true : false);
  }, [namaOrganisasi]);

  useDidMountEffect(() => {
    setIsJabatanAnggotaClearActive(jabatanAnggota ? true : false);
  }, [jabatanAnggota]);

  useDidMountEffect(() => {
    if (isAnggotaEnterClicked) {
      setErrorList(getErrorList(["anggota"]));
    }
  }, [isAnggotaEnterClicked]);

  useDidMountEffect(() => {
    if (isTambahAnggotaClicked) {
      setErrorList(getErrorList(["anggota", "jabatan"]));
    }
  }, [isTambahAnggotaClicked]);

  useDidMountEffect(() => {
    if (isFormSubmitted) {
      setErrorList(
        getErrorList([
          "jenis",
          "namaOrganisasi",
          "periodeAwal",
          "periodeAkhir",
          "anggotaSelected",
        ])
      );
    }
  }, [isFormSubmitted]);

  useDidMountEffect(() => {
    (async () => {
      setIsErrorSnackbarActive(errorList.length > 0 ? true : false);

      if (isFormSubmitted) {
        setIsFormSubmitted(false);

        if (errorList.length === 0) {
          setIsSubmitButtonActionLoading(true);

          if (props.dialogActiveType === "Tambah") {
            const insert = await insertOrganisasi({
              nama_organisasi: namaOrganisasi,
              jenis: jenis.value,
              periode_awal: dateFormat(new Date(periodeAwal), "yyyy-MM-dd"),
              periode_akhir: dateFormat(new Date(periodeAkhir), "yyyy-MM-dd"),
            });

            if (insert.success) {
              const insertDetail = await insertDetailOrganisasi({
                id_organisasi: insert.id,
                anggota: anggotaSelected,
              });

              if (insertDetail.success) {
                swal(
                  "Sukses!",
                  `Data ${
                    jenis ? jenis.value : "Organisasi/Kepanitiaan"
                  } berhasil ditambahkan.`,
                  "success"
                );

                handleResetData();
                props.setDataOrganisasi();
              } else {
                swal(
                  "Oops!",
                  insertDetail.message
                    ? insertDetail.message
                    : "Internal Server Error",
                  "warning"
                );
              }
            } else {
              swal(
                "Oops!",
                insert.message ? insert.message : "Internal Server Error",
                "warning"
              );
            }
          } else {
            const _delete = await deleteDetailOrganisasiByOrganisasi(
              idOrganisasi
            );

            const [_update, _insert] = await Promise.all([
              updateOrganisasi({
                nama_organisasi: namaOrganisasi,
                jenis: jenis.value,
                periode_awal: dateFormat(new Date(periodeAwal), "yyyy-MM-dd"),
                periode_akhir: dateFormat(new Date(periodeAkhir), "yyyy-MM-dd"),
                id_organisasi: idOrganisasi,
              }),
              insertDetailOrganisasi({
                anggota: anggotaSelected,
                id_organisasi: idOrganisasi,
              }),
            ]);

            if (_update.success && _delete.success && _insert.success) {
              swal("Sukses!", "Update Data berhasil", "success").then(() => {
                handleCloseDialog(props.setIsOrganisasiDialogActive);
              });
            } else {
              swal("Oops!", "Internal Server Error", "warning");
            }

            props.setDataOrganisasi();
            handleResetData();
          }

          setIsSubmitButtonActionLoading(false);
        }
      } else if (isTambahAnggotaClicked) {
        setIsTambahAnggotaClicked(false);

        if (errorList.length === 0) {
          if (anggotaSelected.find((item) => item.npm === anggota.npm)) {
            swal("Oops!", "Anggota tersebut telah dipilih.", "warning");
          } else {
            setAnggotaSelected([
              ...anggotaSelected,
              {
                value: anggota.value,
                nama_lengkap: anggota.nama_lengkap,
                npm: anggota.npm,
                jabatan: jabatanAnggota,
              },
            ]);

            setAnggota(null);
            setJabatanAnggota("");
            anggotaRef.focus();
          }
        }
      } else if (isAnggotaEnterClicked) {
        setIsAnggotaEnterClicked(false);

        if (errorList.length === 0) {
          jabatanAnggotaRef.focus();
        }
      }
    })();
  }, [errorList]);

  useDidMountEffect(() => {
    setIdOrganisasi(
      props.dataByOrganisasiActive
        ? props.dataByOrganisasiActive.idOrganisasi
        : null
    );
    setJenis(
      props.dataByOrganisasiActive ? props.dataByOrganisasiActive.jenis : null
    );
    setNamaOrganisasi(
      props.dataByOrganisasiActive
        ? props.dataByOrganisasiActive.namaOrganisasi
        : ""
    );
    setPeriodeAwal(
      props.dataByOrganisasiActive
        ? props.dataByOrganisasiActive.periodeAwal
        : null
    );
    setPeriodeAkhir(
      props.dataByOrganisasiActive
        ? props.dataByOrganisasiActive.periodeAkhir
        : null
    );
    setAnggotaSelected(
      props.dataByOrganisasiActive
        ? props.dataByOrganisasiActive.anggotaSelected
        : []
    );

    setTimeout(() => {
      setIsNamaOrganisasiClearActive(false);
    }, 0);
  }, [props.dataByOrganisasiActive]);

  useDidMountEffect(() => {
    if (!props.isOrganisasiDialogActive) {
      handleResetData();
    }
  }, [props.isOrganisasiDialogActive]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.isOrganisasiDialogActive}
        onClose={() => handleCloseDialog(props.setIsOrganisasiDialogActive)}
        className={`full-screen-app-bar${
          props.dialogFullScreenClassName
            ? " " + props.dialogFullScreenClassName
            : ""
        }`}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() =>
                handleCloseDialog(props.setIsOrganisasiDialogActive)
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="main-title-modal-form">
              {props.dialogActiveType} Data Organisasi / Kepanitiaan
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleClickSubmitAction}
              className="single-button-right-top-custom"
            >
              {isSubmitButtonActionLoading ? (
                <CircularProgress size={18} className="loading-circle" />
              ) : props.dialogActiveType === "Tambah" ? (
                "Submit"
              ) : (
                "Update"
              )}
            </Button>
          </Toolbar>
        </AppBar>
        <form
          className="fullscreen-modal-form-body"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-group required">
            <Autocomplete
              id="organisasi-jenis"
              options={optionsJenis}
              noOptionsText="Tidak Ada Opsi"
              clearOnBlur
              clearText="Bersihkan"
              openText="Pilih"
              getOptionLabel={getOptionLabel}
              onChange={(event, selected) =>
                handleChangeSelectItem(selected, setJenis)
              }
              value={jenis || null}
              renderInput={(params) => (
                <TextField {...params} label="Pilih Jenis" variant="outlined" />
              )}
              PopperComponent={PopperCustom}
            />
          </div>
          <div className="form-group required">
            <TextField
              id="organisasi-nama-organisasi"
              label={`Nama ${jenis ? jenis.value : "Organisasi/Kepanitiaan"}`}
              variant="outlined"
              value={namaOrganisasi || ""}
              onChange={(event) =>
                handleChangeTextField(event, setNamaOrganisasi)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  namaOrganisasi,
                  setIsNamaOrganisasiClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsNamaOrganisasiClearActive)
              }
              InputProps={{
                endAdornment: isNamaOrganisasiClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        handleClickClearTextField(
                          setNamaOrganisasi,
                          setIsNamaOrganisasiClearActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group required">
            <DatePicker
              label="Periode Awal"
              format="dd-MM-yyyy"
              openTo="year"
              views={["year", "month", "date"]}
              value={periodeAwal || null}
              inputVariant="outlined"
              onChange={(selected) =>
                handleChangeSelectItem(selected, setPeriodeAwal)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  periodeAwal,
                  setIsPeriodeAwalClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsPeriodeAwalClearActive)
              }
              open={isPeriodeAwalDialogActive}
              onOpen={() =>
                handleToggleDialogActive(
                  isPeriodeAwalDialogActive,
                  setIsPeriodeAwalDialogActive
                )
              }
              onClose={() =>
                handleToggleDialogActive(
                  isPeriodeAwalDialogActive,
                  setIsPeriodeAwalDialogActive
                )
              }
              InputProps={{
                endAdornment: isPeriodeAwalClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() =>
                        handleMouseDownClearTextFieldDate(
                          setPeriodeAwal,
                          setIsPeriodeAwalClearActive,
                          setIsPeriodeAkhirDialogActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group required">
            <DatePicker
              label="Periode Akhir"
              format="dd-MM-yyyy"
              openTo="year"
              views={["year", "month", "date"]}
              minDate={periodeAwal || new Date("01-01-1900")}
              value={periodeAkhir || null}
              inputVariant="outlined"
              onChange={(selected) =>
                handleChangeSelectItem(selected, setPeriodeAkhir)
              }
              onMouseEnter={() =>
                handleMouseEnterTextField(
                  periodeAkhir,
                  setIsPeriodeAkhirClearActive
                )
              }
              onMouseLeave={() =>
                handleMouseLeaveTextField(setIsPeriodeAkhirClearActive)
              }
              open={isPeriodeAkhirDialogActive}
              onOpen={() =>
                handleToggleDialogActive(
                  isPeriodeAkhirDialogActive,
                  setIsPeriodeAkhirDialogActive
                )
              }
              onClose={() =>
                handleToggleDialogActive(
                  isPeriodeAkhirDialogActive,
                  setIsPeriodeAkhirDialogActive
                )
              }
              InputProps={{
                endAdornment: isPeriodeAkhirClearActive && (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() =>
                        handleMouseDownClearTextFieldDate(
                          setPeriodeAkhir,
                          setIsPeriodeAkhirClearActive,
                          setIsPeriodeAkhirDialogActive
                        )
                      }
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group required">
            <div className="inner-form-group-wrapper">
              <div className="inner-form-group anggota-jabatan-form-group">
                <div className="inner-half-form">
                  <Autocomplete
                    id="organisasi-anggota"
                    options={props.optionsAnggota}
                    filterOptions={filterOptions}
                    noOptionsText="Tidak Ada Opsi"
                    clearOnBlur
                    clearText="Bersihkan"
                    openText="Pilih"
                    getOptionLabel={getOptionLabel}
                    onChange={(event, selected) =>
                      handleChangeSelectItem(selected, setAnggota)
                    }
                    onKeyUp={handleKeyUpAnggota}
                    value={anggota || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Anggota"
                        variant="outlined"
                        inputRef={(ref) => {
                          anggotaRef = ref;
                        }}
                      />
                    )}
                    PopperComponent={PopperCustom}
                  />
                </div>
                <div className="inner-half-form">
                  <TextField
                    id="organisasi-jabatan-organisasi"
                    label="Jabatan"
                    variant="outlined"
                    value={jabatanAnggota || ""}
                    inputRef={(ref) => {
                      jabatanAnggotaRef = ref;
                    }}
                    onChange={(event) =>
                      handleChangeTextField(event, setJabatanAnggota)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterTextField(
                        jabatanAnggota,
                        setIsJabatanAnggotaClearActive
                      )
                    }
                    onMouseLeave={() =>
                      handleMouseLeaveTextField(setIsJabatanAnggotaClearActive)
                    }
                    onKeyUp={handleKeyUpJabatan}
                    InputProps={{
                      endAdornment: isJabatanAnggotaClearActive && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickClearTextField(
                                setJabatanAnggota,
                                setIsJabatanAnggotaClearActive
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="anggota-jabatan-button-form-group">
                <div className="add-button" onClick={handleClickTambahAnggota}>
                  <span className="button-text">Tambah Anggota</span>
                </div>
              </div>
            </div>
          </div>
          {anggotaSelected.length > 0 && (
            <div className="form-group required">
              <div className="text-area-list-anggota">
                {anggotaSelected.map((item, index) => (
                  <div className="single-list-little-box" key={index}>
                    <span className="list-text">
                      {item.nama_lengkap} - {item.npm} ({item.jabatan})
                    </span>
                    <span
                      className="delete-anggota-icon-area"
                      onClick={() => handleClickDeleteAnggota(item.value)}
                    >
                      <span className="delete-icon">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </form>
      </Dialog>
      <Portal>
        <Snackbar
          open={isErrorSnackbarActive && errorList.length > 0}
          onClose={() => handleCloseDialog(setIsErrorSnackbarActive)}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert variant="filled" severity="error">
            <ul className="error-list">
              {errorList.map((element, index) => (
                <li className="single-list" key={index}>
                  {element.message}
                </li>
              ))}
            </ul>
          </MuiAlert>
        </Snackbar>
      </Portal>
    </React.Fragment>
  );
}

function Organisasi() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(true);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]); 

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);

  // Organisasi ----------------------------------------------------------------

  // @Table Section
  // Organisasi

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Organisasi - By Organisasi

  const [dataByOrganisasi, setDataByOrganisasi] = useState([]);

  const [dataByOrganisasiActive, setDataByOrganisasiActive] = useState(null);

  const dataFieldsByOrganisasi = [
    {
      primary: true,
      checkbox: true,
      value: "id_organisasi",
      type: "text",
      label: "ID Organisasi",
      size: "default",
      position: "left",
      status: 0,
    },
    {
      primary: false,
      value: "jenis",
      type: "single-textbox",
      label: "Jenis",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Organisasi", name: "textbox-blue-smooth" },
        { value: "Kepanitiaan", name: "textbox-brown-smooth" },
      ],
    },
    {
      primary: false,
      value: "nama_organisasi",
      type: "text",
      label: "Nama Organisasi",
      size: "default",
      position: "left",
      status: 1,
    },
    {
      primary: false,
      value: "periode_awal",
      type: "text",
      label: "Periode Awal",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "periode_akhir",
      type: "text",
      label: "Periode Akhir",
      size: "default",
      position: "right",
      status: 1,
    },
    {
      primary: false,
      value: "anggota",
      type: "multiple-textbox",
      label: "Anggota",
      size: "xxl",
      position: "right",
      status: 1,
      contentReplace: {
        from: /\d\d*##/gm,
        to: "",
      },
    },
    {
      primary: false,
      value: "jumlah_anggota",
      type: "text",
      label: "Jumlah Anggota",
      size: "s",
      position: "right",
      status: 1,
    },
  ];

  const [sortItemByOrganisasi, setSortItemByOrganisasi] = useState({
    by: "nama_organisasi",
    mode: "ASC",
  });

  const [searchInputByOrganisasi, setSearchInputByOrganisasi] = useState("");

  const optionsSearchFilterByOrganisasi = [
    { value: "jenis", label: "Jenis" },
    { value: "nama_organisasi", label: "Nama Organisasi" },
    { value: "periode_awal", label: "Periode Awal" },
    { value: "periode_akhir", label: "Periode Akhir" },
    { value: "anggota", label: "Anggota" },
    { value: "jumlah_anggota", label: "Jumlah Anggota" },
  ];

  const [searchFilterByOrganisasi, setSearchFilterByOrganisasi] = useState([
    optionsSearchFilterByOrganisasi[1],
  ]);

  // @Pagination Section
  // Organisasi - By Organisasi

  const optionsNumberPerPageByOrganisasi = [10, 20, 30, 40, 50];

  const [numberPerPageByOrganisasi, setNumberPerPageByOrganisasi] = useState(
    optionsNumberPerPageByOrganisasi[0]
  );

  const [
    numberPerPageFromByOrganisasi,
    setNumberPerPageFromByOrganisasi,
  ] = useState(0);

  const [
    numberPerPageToByOrganisasi,
    setNumberPerPageToByOrganisasi,
  ] = useState(0);

  const [totalDataByOrganisasi, setTotalDataByOrganisasi] = useState(0);

  const [totalPageByOrganisasi, setTotalPageByOrganisasi] = useState(0);

  const [currentPageByOrganisasi, setCurrentPageByOrganisasi] = useState(0);

  // @Dialog Section
  // Organisasi

  const dialogFullScreenClassName = "organisasi-full-screen-bar";

  const [isOrganisasiDialogActive, setIsOrganisasiDialogActive] = useState(
    false
  );

  const [dialogActiveType, setDialogActiveType] = useState("");

  const [optionsAnggota, setOptionsAnggota] = useState([]);

  const setDataOrganisasi = async () => {
    const [byOrganisasi, totalByOrganisasi] = await Promise.all([
      getOrganisasi({
        search: {
          groupOp: searchFilterByOrganisasi.length > 1 ? "OR" : "",
          rules: searchFilterByOrganisasi.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputByOrganisasi,
            };
          }),
        },
        order: sortItemByOrganisasi,
        limit: numberPerPageByOrganisasi,
        offset: currentPageByOrganisasi
          ? (currentPageByOrganisasi - 1) * numberPerPageByOrganisasi
          : 0,
      }),
      getTotalOrganisasi({}),
    ]);

    if (byOrganisasi.success && totalByOrganisasi.success) {
      // Set Organisasi - By Organisasi
      setDataByOrganisasi(byOrganisasi.data);
      setTotalDataByOrganisasi(totalByOrganisasi.data.total);
      setTotalPageByOrganisasi(
        Math.ceil(totalByOrganisasi.data.total / numberPerPageByOrganisasi)
      );
      setCurrentPageByOrganisasi(totalByOrganisasi.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByOrganisasi(
        totalByOrganisasi.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByOrganisasi(
        totalByOrganisasi.data.total > 0 ? byOrganisasi.data.length : 0
      );
    }
  };

  const handleClickSingleBodyContentByOrganisasi = async (item) => {
    setDataByOrganisasiActive({
      idOrganisasi: item.id_organisasi,
      jenis: {
        value: item.jenis,
        label: item.jenis,
      },
      namaOrganisasi: item.nama_organisasi,
      periodeAwal: new Date(item.periode_awal.split("-").reverse().join("-")),
      periodeAkhir: new Date(item.periode_akhir.split("-").reverse().join("-")),
      anggotaSelected: item.anggota.split(",").map((element) => {
        const infoPengguna = element.trim().split("##");
        const id_pengguna = infoPengguna[0];
        const nama_lengkap = infoPengguna[1].split("-")[0].trim();
        const npm = infoPengguna[1].split("-")[1].split("(")[0].trim();
        const jabatan = infoPengguna[1]
          .split("-")[1]
          .split("(")[1]
          .split(")")[0]
          .trim();

        return {
          value: id_pengguna,
          nama_lengkap,
          npm,
          jabatan,
        };
      }),
    });

    setDialogActiveType("Update");
    setIsOrganisasiDialogActive(true);
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        setIsDeleteLoading(true);

        const _delete = await deleteOrganisasi(dataSelected);

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData();
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = async () => {
    const [byOrganisasi, totalByOrganisasi, pengguna] = await Promise.all([
      getOrganisasi({
        order: sortItemByOrganisasi,
        limit: numberPerPageByOrganisasi,
        offset: 0,
      }),
      getTotalOrganisasi({}),
      getPengguna({
        fields: ["id_pengguna", "nama_lengkap", "npm"],
        search: null,
        order: {
          by: "nama_lengkap",
          mode: "ASC",
        },
        offset: null,
        limit: null,
      }),
    ]);

    if (
      byOrganisasi.success &&
      totalByOrganisasi.success &&
      pengguna.success
    ) {
      // Set Organisasi - By Organisasi
      setDataByOrganisasi(byOrganisasi.data);
      setTotalDataByOrganisasi(totalByOrganisasi.data.total);
      setTotalPageByOrganisasi(
        Math.ceil(totalByOrganisasi.data.total / numberPerPageByOrganisasi)
      );
      setCurrentPageByOrganisasi(totalByOrganisasi.data.total > 0 ? 1 : 0);
      setNumberPerPageFromByOrganisasi(
        totalByOrganisasi.data.total > 0 ? 1 : 0
      );
      setNumberPerPageToByOrganisasi(
        totalByOrganisasi.data.total > 0 ? byOrganisasi.data.length : 0
      );

      // Set Options Anggota
      setOptionsAnggota(
        pengguna.data.map((item) => {
          return {
            value: item.id_pengguna,
            label: `${item.nama_lengkap} - ${item.npm}`,
            npm: item.npm,
            nama_lengkap: item.nama_lengkap,
          };
        })
      );
    }

    setIsBodyPanelLoading(false);
  };

  useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper organisasi-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Organisasi</h1>
            <div className="header-right-button-area">
              <div
                className="single-button"
                onClick={() => {
                  setDialogActiveType("Tambah");
                  handleOpenDialog(setIsOrganisasiDialogActive);
                }}
              >
                <span className="button-text">Tambah Data</span>
              </div>
            </div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      <Search
                        setData={setDataByOrganisasi}
                        getData={getOrganisasi}
                        dataFields={dataFieldsByOrganisasi}
                        sortItem={sortItemByOrganisasi}
                        searchInput={searchInputByOrganisasi}
                        setSearchInput={setSearchInputByOrganisasi}
                        optionsSearchFilterBy={optionsSearchFilterByOrganisasi}
                        searchFilterBy={searchFilterByOrganisasi}
                        setSearchFilterBy={setSearchFilterByOrganisasi}
                        numberPerPage={numberPerPageByOrganisasi}
                        setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                        setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                        getTotalData={getTotalOrganisasi}
                        setTotalData={setTotalDataByOrganisasi}
                        setTotalPage={setTotalPageByOrganisasi}
                        setCurrentPage={setCurrentPageByOrganisasi}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      <Pagination
                        setData={setDataByOrganisasi}
                        getData={getOrganisasi}
                        dataFields={dataFieldsByOrganisasi}
                        sortItem={sortItemByOrganisasi}
                        searchInput={searchInputByOrganisasi}
                        searchFilterBy={searchFilterByOrganisasi}
                        optionsNumberPerPage={optionsNumberPerPageByOrganisasi}
                        numberPerPage={numberPerPageByOrganisasi}
                        setNumberPerPage={setNumberPerPageByOrganisasi}
                        numberPerPageFrom={numberPerPageFromByOrganisasi}
                        setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                        numberPerPageTo={numberPerPageToByOrganisasi}
                        setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                        totalData={totalDataByOrganisasi}
                        getTotalData={getTotalOrganisasi}
                        setTotalData={setTotalDataByOrganisasi}
                        totalPage={totalPageByOrganisasi}
                        setTotalPage={setTotalPageByOrganisasi}
                        currentPage={currentPageByOrganisasi}
                        setCurrentPage={setCurrentPageByOrganisasi}
                        isDataTableLoading={isDataTableLoading}
                        setIsDataTableLoading={setIsDataTableLoading}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                <Table
                  ref={tableWrapperRef}
                  data={dataByOrganisasi}
                  setData={setDataByOrganisasi}
                  getData={getOrganisasi}
                  dataFields={dataFieldsByOrganisasi}
                  sortItem={sortItemByOrganisasi}
                  setSortItem={setSortItemByOrganisasi}
                  searchInput={searchInputByOrganisasi}
                  searchFilterBy={searchFilterByOrganisasi}
                  numberPerPage={numberPerPageByOrganisasi}
                  setNumberPerPageFrom={setNumberPerPageFromByOrganisasi}
                  setNumberPerPageTo={setNumberPerPageToByOrganisasi}
                  totalData={totalDataByOrganisasi}
                  getTotalData={getTotalOrganisasi}
                  setTotalData={setTotalDataByOrganisasi}
                  setTotalPage={setTotalPageByOrganisasi}
                  setCurrentPage={setCurrentPageByOrganisasi}
                  isDataTableLoading={isDataTableLoading}
                  setIsDataTableLoading={setIsDataTableLoading}
                  setIsFullScreenLoading={setIsFullScreenLoading}
                  handleClickSingleBodyContent={
                    handleClickSingleBodyContentByOrganisasi
                  }
                  dataSelected={dataSelected}
                  setDataSelected={setDataSelected}
                />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      <OrganisasiDialog
        isOrganisasiDialogActive={isOrganisasiDialogActive}
        setIsOrganisasiDialogActive={setIsOrganisasiDialogActive}
        dialogFullScreenClassName={dialogFullScreenClassName}
        dialogActiveType={dialogActiveType}
        dataByOrganisasiActive={dataByOrganisasiActive}
        setDataOrganisasi={setDataOrganisasi}
        optionsAnggota={optionsAnggota}
      />
    </React.Fragment>
  );
}

export default Organisasi;
