const getImgFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      return resolve(img);
    };
  });
};

export { getImgFromUrl };
