import Axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./counter.styles.css";
import Chart from "./Chart";

function Counter() {
  const [correctTime, setCorrectTime] = useState(false);

  useEffect(() => {
    // generate timestamp deadline: 1608022800000 4 o clock gmt7 in miliseconds
    const currentDate = new Date();
    const currTimestamp = currentDate.getTime();
    const timeToEnd = 1608022800000 - currTimestamp;
    if (timeToEnd < 1){
      setCorrectTime(true)
    }
  }, []);

  const data = {
    labels: [
      "Paslon 1 BEM (34,5%)",
      "Paslon 2 BEM (63,8%)",
      "Golongan Putih (1,7%)",
    ],
    datasets: [
      {
        label: "Pemilih Paslon BEM",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.5)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: [345, 638, 17],
      },
    ],
  };

  const dataBpm = {
    labels: [
      "Anggota Independen 1 (79,4%)",
      "Surat Suara Kosong (18,7%)",
      "Golongan Putih (1,9%)",
    ],
    datasets: [
      {
        label: "Pemilih Anggota Independen BPM",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 159, 64, 0.4)",
        hoverBorderColor: "rgba(255, 159, 64, 1)",
        data: [794, 187, 19],
      },
    ],
  };

  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const correctPass = password === "selamatkanIKM";
    if (correctTime && correctPass) {
      setIsLoggedIn(true);
    } else {
      alert("Hasil belum dapat dibuka")
    }
  };

  return (
    <div className="counter-pemira__page">
      <div className="counter-pemira__container">
        <div className="header">
          <h1>Final Count</h1>
          <p>Pemilihan telah ditutup</p>
        </div>
        {isLoggedIn ? (
          <>
            <div className="counter">
              <h2>Pemilihan Ketua dan Wakil Ketua BEM IKM FKUI 2020 🗳</h2>
              <p>Pemilihan telah ditutup</p>
              <p>Kandidat terpilih: Nadhif Wiratara & M. Dhawinanda</p>
            </div>
            <div className="counter">
              <h2>Pemilihan Anggota Independen IKM FKUI 2020 🗳</h2>
              <p>Pemilihan telah ditutup</p>
              <p>Kandidat terpilih: Johannes Elia</p>
            </div>{" "}
            <Chart data={data} />
            <Chart data={dataBpm} />
          </>
        ) : (
          <form onSubmit={handleSubmitPassword} className="login__container">
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
            <button onClick={handleSubmitPassword}>
              Buka Hasil Penghitungan
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Counter;
