import axios from "axios";
import config from "../../Config";

const insertVerifiedPengguna = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/verified_pengguna`,
    data
  });

  const response = request.data;
  return response;
};

const deleteVerifiedPengguna = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/verified_pengguna`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const getAllVerifiedPengguna = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/verified_pengguna`,
    params: data
  });

  const response = request.data;
  return response;
};

const getTotalAllVerifiedPengguna = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/verified_pengguna/total`,
    params: data
  });

  const response = request.data;
  return response;
};

export {
  insertVerifiedPengguna,
  deleteVerifiedPengguna,
  getAllVerifiedPengguna,
  getTotalAllVerifiedPengguna
};