import React, { useState, useRef, useEffect } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import { getPengguna } from "../../../../../../../Services/API/pengguna";
import {
  getPenyuluhan,
  getDetailPenyuluhanByIdPenyuluhan,
  deletePenyuluhan,
  getTotalPenyuluhan,
  getKegiatanSosial,
  getTotalKegiatanSosial,
  getDetailKegiatanSosialByIdKegiatanSosial,
  deleteKegiatanSosial,
  getScreening,
  getDetailScreeningByIdScreening,
  getTotalScreening,
  deleteScreening,
  getPengobatanMassal,
  getDetailPengobatanMassalByIdPengobatanMassal,
  getTotalPengobatanMassal,
  deletePengobatanMassal,
  getSirkumsisi,
  getDetailSirkumsisiByIdSirkumsisi,
  getTotalSirkumsisi,
  deleteSirkumsisi,
  getKunjunganPasien,
  getDetailKunjunganPasienByIdKunjunganPasien,
  getTotalKunjunganPasien,
  deleteKunjunganPasien,
  getTimMedisFKUI,
  getDetailTimMedisFKUIByIdTimMedisFKUI,
  getTotalTimMedisFKUI,
  deleteTimMedisFKUI,
  getRekapIKM
} from "../../../../../../../Services/API/pengabdian";
import {
  getTotalPengguna
} from "../../../../../../../Services/API/pengguna";
import swal from "sweetalert";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import ShowDataToggle from "../ShowDataToggle";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";
import PenyuluhanDialog from "./PenyuluhanDialog";
import KegiatanSosialDialog from "./KegiatanSosialDialog";
import ScreeningDialog from "./ScreeningDialog";
import PengobatanMassalDialog from "./PengobatanMassalDialog";
import SirkumsisiDialog from "./SirkumsisiDialog";
import KunjunganPasienDialog from "./KunjunganPasienDialog";
import TimMedisFKUIDialog from "./TimMedisFKUIDialog";
import useOnClickOutside from "../../../../../../../Components/useOnClickOutside";
import { generateExcel } from "../../../../../../../Services/File/excel";

function Pengabdian() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const exportToExcelOptionRef = useRef(null);

  const [
    isExportToExcelOptionActive,
    setIsExportToExcelOptionActive,
  ] = useState(false);

  const [exportToExcelList, setExportToExcelList] = useState([
    {
      value: "Semua",
      isLoading: false,
    },
    {
      value: "Semua Sesuai Filter",
      isLoading: false,
    },
    {
      value: "Hanya Halaman Ini",
      isLoading: false,
    },
  ]);

  const [optionsMahasiswa, setOptionsMahassiwa] = useState([]);

  const [dataSelected, setDataSelected] = useState([]);

  // Pengabdian -------------------------------------------------------------------------------

  // @ShowData Section
  // Pengabdian

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "Penyuluhan", label: "Penyuluhan" },
    { value: "Kegiatan Sosial", label: "Kegiatan Sosial" },
    { value: "Screening", label: "Screening" },
    { value: "Pengobatan Massal", label: "Pengobatan Massal" },
    { value: "Sirkumsisi", label: "Sirkumsisi" },
    { value: "Kunjungan Pasien", label: "Kunjungan Pasien" },
    { value: "Tim Medis FKUI", label: "Tim Medis FKUI" },
    { value: "Rekap IKM", label: "Rekap IKM" }
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Pengabdian

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Pengabdian - Penyuluhan

  const [dataPenyuluhan, setDataPenyuluhan] = useState([]);

  const dataFieldsPenyuluhan = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_penyuluhan",
      type: "text",
      label: "ID Penyuluhan",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_masyarakat",
      type: "text",
      label: "Jumlah Masyarakat",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemPenyuluhan, setSortItemPenyuluhan] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputPenyuluhan, setSearchInputPenyuluhan] = useState("");

  const optionsSearchFilterPenyuluhan = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
    { value: "jumlah_masyarakat", label: "Jumlah Masyarakat" },
  ];

  const [searchFilterPenyuluhan, setSearchFilterPenyuluhan] = useState([
    optionsSearchFilterPenyuluhan[0],
  ]);

  // @Table Section
  // Pengabdian - Kegiatan Sosial

  const [dataKegiatanSosial, setDataKegiatanSosial] = useState([]);

  const dataFieldsKegiatanSosial = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_kegiatan_sosial",
      type: "text",
      label: "ID Kegiatan Sosial",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemKegiatanSosial, setSortItemKegiatanSosial] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputKegiatanSosial, setSearchInputKegiatanSosial] = useState(
    ""
  );

  const optionsSearchFilterKegiatanSosial = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
  ];

  const [searchFilterKegiatanSosial, setSearchFilterKegiatanSosial] = useState([
    optionsSearchFilterKegiatanSosial[0],
  ]);

  // @Table Section
  // Pengabdian - Screening

  const [dataScreening, setDataScreening] = useState([]);

  const dataFieldsScreening = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text",
      },
    },
    {
      primary: true,
      value: "id_screening",
      type: "text",
      label: "ID Screening",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      },
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_masyarakat",
      type: "text",
      label: "Jumlah Masyarakat",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemScreening, setSortItemScreening] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputScreening, setSearchInputScreening] = useState("");

  const optionsSearchFilterScreening = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
    { value: "jumlah_masyarakat", label: "Jumlah Masyarakat" },
  ];

  const [searchFilterScreening, setSearchFilterScreening] = useState([
    optionsSearchFilterScreening[0],
  ]);

  // @Table Section
  // Pengabdian - Pengobatan Massal

  const [dataPengobatanMassal, setDataPengobatanMassal] = useState([]);

  const dataFieldsPengobatanMassal = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_pengobatan_massal",
      type: "text",
      label: "ID Pengobatan Massal",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_masyarakat",
      type: "text",
      label: "Jumlah Masyarakat",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemPengobatanMassal, setSortItemPengobatanMassal] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [
    searchInputPengobatanMassal,
    setSearchInputPengobatanMassal,
  ] = useState("");

  const optionsSearchFilterPengobatanMassal = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
    { value: "jumlah_masyarakat", label: "Jumlah Masyarakat" },
  ];

  const [
    searchFilterPengobatanMassal,
    setSearchFilterPengobatanMassal,
  ] = useState([optionsSearchFilterPengobatanMassal[0]]);

  // @Table Section
  // Pengabdian - Sirkumsisi

  const [dataSirkumsisi, setDataSirkumsisi] = useState([]);

  const dataFieldsSirkumsisi = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_sirkumsisi",
      type: "text",
      label: "ID Sirkumsisi",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_masyarakat",
      type: "text",
      label: "Jumlah Masyarakat",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemSirkumsisi, setSortItemSirkumsisi] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputSirkumsisi, setSearchInputSirkumsisi] = useState("");

  const optionsSearchFilterSirkumsisi = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
    { value: "jumlah_masyarakat", label: "Jumlah Masyarakat" },
  ];

  const [searchFilterSirkumsisi, setSearchFilterSirkumsisi] = useState([
    optionsSearchFilterSirkumsisi[0],
  ]);

  // @Table Section
  // Pengabdian - Kunjungan Pasien

  const [dataKunjunganPasien, setDataKunjunganPasien] = useState([]);

  const dataFieldsKunjunganPasien = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_kunjungan_pasien",
      type: "text",
      label: "ID Kunjunga Pasien",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemKunjunganPasien, setSortItemKunjunganPasien] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputKunjunganPasien, setSearchInputKunjunganPasien] = useState(
    ""
  );

  const optionsSearchFilterKunjunganPasien = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
  ];

  const [
    searchFilterKunjunganPasien,
    setSearchFilterKunjunganPasien,
  ] = useState([optionsSearchFilterKunjunganPasien[0]]);

  // @Table Section
  // Pengabdian - Tim Medis FKUI

  const [dataTimMedisFKUI, setDataTimMedisFKUI] = useState([]);

  const dataFieldsTimMedisFKUI = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_tim_medis_fkui",
      type: "text",
      label: "ID Tim Medis FKUI",
      size: "default",
      position: "left",
      status: 0,
      checkbox: true,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_kegiatan",
      type: "text",
      label: "Nama Kegiatan",
      size: "default",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyelenggara_kegiatan",
      type: "text",
      label: "Penyelenggara Kegiatan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "lokasi",
      type: "text",
      label: "Lokasi",
      size: "m",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 40,
        type: "text"
      }
    },
    {
      primary: false,
      value: "waktu_pelaksanaan",
      type: "text",
      label: "Waktu Pelaksanaan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "mahasiswa",
      type: "multiple-textbox",
      label: "Mahasiswa Yang Terlibat",
      contentFontType: "capitalize",
      size: "xxl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 70,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_mahasiswa",
      type: "text",
      label: "Jumlah Mahasiswa",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
    {
      primary: false,
      value: "jumlah_masyarakat",
      type: "text",
      label: "Jumlah Masyarakat",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text"
      }
    },
  ];

  const [sortItemTimMedisFKUI, setSortItemTimMedisFKUI] = useState({
    by: "nama_kegiatan",
    mode: "ASC",
  });

  const [searchInputTimMedisFKUI, setSearchInputTimMedisFKUI] = useState("");

  const optionsSearchFilterTimMedisFKUI = [
    { value: "nama_kegiatan", label: "Nama Kegiatan" },
    { value: "penyelenggara_kegiatan", label: "Penyelenggara Kegiatan" },
    { value: "lokasi", label: "Lokasi" },
    { value: "waktu_pelaksanaan", label: "Waktu Pelaksanaan" },
    { value: "mahasiswa", label: "Mahasiswa Yang Terlibat" },
    { value: "jumlah_mahasiswa", label: "Jumlah Mahasiswa" },
    { value: "jumlah_masyarakat", label: "Jumlah Masyarakat" },
  ];

  const [searchFilterTimMedisFKUI, setSearchFilterTimMedisFKUI] = useState([
    optionsSearchFilterTimMedisFKUI[0],
  ]);

  // @Table Section
  // Pengabdian - Rekap IKM

  const [dataRekapIKM, setDataRekapIKM] = useState([]);

  const dataFieldsRekapIKM = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text"
      }
    },
    {
      primary: true,
      value: "id_pengguna",
      type: "text",
      label: "ID Pengguna",
      size: "default",
      position: "left",
      status: 0,
      checkbox: false,
      exportToExcel: {
        visible: false
      }
    },
    {
      primary: false,
      value: "nama_lengkap",
      type: "text",
      label: "Nama Lengkap",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 35,
        type: "text"
      }
    },
    {
      primary: false,
      value: "angkatan",
      type: "text",
      label: "Angkatan",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text"
      }
    },
    {
      primary: false,
      value: "npm",
      type: "text",
      label: "NPM",
      size: "s",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 15,
        type: "text"
      }
    },
    {
      primary: false,
      value: "penyuluhan",
      type: "text",
      label: "Penyuluhan",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "kegiatan_sosial",
      type: "text",
      label: "Kegiatan Sosial",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "screening_IVA",
      type: "text",
      label: "Screeening - IVA",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "screening_Anemia",
      type: "text",
      label: "Screening - Anemia",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "screening_IMS",
      type: "text",
      label: "Screening - IMS",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "screening_Medical_Check_Up",
      type: "text",
      label: "Screening - Medical Check Up",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "screening_Lainnya",
      type: "text",
      label: "Screening - Lainnya",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "pengobatan_massal",
      type: "text",
      label: "Pengobatan Massal",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sirkumsisi_Observer",
      type: "text",
      label: "Sirkumsisi - Observer",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sirkumsisi_Instrumen",
      type: "text",
      label: "Sirkumsisi - Instrumen",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sirkumsisi_Co_Operator",
      type: "text",
      label: "Sirkumsisi - Co Operator",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sirkumsisi_Operator",
      type: "text",
      label: "Sirkumsisi - Operator",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "sirkumsisi_Lainnya",
      type: "text",
      label: "Sirkumsisi - Lainnya",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "kunjungan_pasien",
      type: "text",
      label: "Kunjungan Pasien",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    },
    {
      primary: false,
      value: "tim_medis_fkui",
      type: "text",
      label: "Tim Medis FKUI",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 20,
        type: "text"
      }
    }
  ];

  const [sortItemRekapIKM, setSortItemRekapIKM] = useState({
    by: "nama_lengkap",
    mode: "ASC"
  });

  const [searchInputRekapIKM, setSearchInputRekapIKM] = useState("");

  const optionsSearchFilterRekapIKM = [
    { value: "nama_lengkap", label: "Nama Lengkap" },
    { value: "angkatan", label: "Angkatan" },
    { value: "npm", label: "NPM" },
    // { value: "penyuluhan", label: "Penyuluhan" },
    // { value: "kegiatan_sosial", label: "Kegiatan Sosial"},
    // { value: "screening_IVA", label: "Screening - IVA" },
    // { value: "screening_Anemia", label: "Screening - Anemia" },
    // { value: "screening_IMS", label: "Screening - IMS" },
    // { value: "screening_Medical_Check_Up", label: "Screening - Medical Check Up" },
    // { value: "pengobatan_massal", label: "Pengobatan Massal" },
    // { value: "sirkumsisi_Observer", label: "Sirkumsisi - Observer" },
    // { value: "sirkumsisi_Instrumen", label: "Sirkumsisi - Instrumen" },
    // { value: "sirkumsisi_Co_Operator", label: "Sirkumsisi - Co Operator" },
    // { value: "sirkumsisi_Operator", label: "Sirkumsisi - Operator" },
    // { value: "kunjungan_pasien", label: "Kunjungan Pasien" },
    // { value: "tim_medis_fkui", label: "Tim Medis FKUI" }
  ];

  const [searchFilterRekapIKM, setSearchFilterRekapIKM] = useState([
    optionsSearchFilterRekapIKM[0]
  ]);

  // @Pagination Section
  // Pengabdian - Penyuluhan

  const optionsNumberPerPagePenyuluhan = [10, 20, 30, 40, 50];

  const [numberPerPagePenyuluhan, setNumberPerPagePenyuluhan] = useState(
    optionsNumberPerPagePenyuluhan[0]
  );

  const [
    numberPerPageFromPenyuluhan,
    setNumberPerPageFromPenyuluhan,
  ] = useState(0);

  const [numberPerPageToPenyuluhan, setNumberPerPageToPenyuluhan] = useState(0);

  const [totalDataPenyuluhan, setTotalDataPenyuluhan] = useState(0);

  const [totalPagePenyuluhan, setTotalPagePenyuluhan] = useState(0);

  const [currentPagePenyuluhan, setCurrentPagePenyuluhan] = useState(0);

  // @Pagination Section
  // Pengabdian - Kegiatan Sosial

  const optionsNumberPerPageKegiatanSosial = [10, 20, 30, 40, 50];

  const [
    numberPerPageKegiatanSosial,
    setNumberPerPageKegiatanSosial,
  ] = useState(optionsNumberPerPageKegiatanSosial[0]);

  const [
    numberPerPageFromKegiatanSosial,
    setNumberPerPageFromKegiatanSosial,
  ] = useState(0);

  const [
    numberPerPageToKegiatanSosial,
    setNumberPerPageToKegiatanSosial,
  ] = useState(0);

  const [totalDataKegiatanSosial, setTotalDataKegiatanSosial] = useState(0);

  const [totalPageKegiatanSosial, setTotalPageKegiatanSosial] = useState(0);

  const [currentPageKegiatanSosial, setCurrentPageKegiatanSosial] = useState(0);

  // @Pagination Section
  // Pengabdian - Screening

  const optionsNumberPerPageScreening = [10, 20, 30, 40, 50];

  const [numberPerPageScreening, setNumberPerPageScreening] = useState(
    optionsNumberPerPageScreening[0]
  );

  const [numberPerPageFromScreening, setNumberPerPageFromScreening] = useState(
    0
  );

  const [numberPerPageToScreening, setNumberPerPageToScreening] = useState(0);

  const [totalDataScreening, setTotalDataScreening] = useState(0);

  const [totalPageScreening, setTotalPageScreening] = useState(0);

  const [currentPageScreening, setCurrentPageScreening] = useState(0);

  // @Pagination Section
  // Pengabdian - Pengobatan Massal

  const optionsNumberPerPagePengobatanMassal = [10, 20, 30, 40, 50];

  const [
    numberPerPagePengobatanMassal,
    setNumberPerPagePengobatanMassal,
  ] = useState(optionsNumberPerPagePengobatanMassal[0]);

  const [
    numberPerPageFromPengobatanMassal,
    setNumberPerPageFromPengobatanMassal,
  ] = useState(0);

  const [
    numberPerPageToPengobatanMassal,
    setNumberPerPageToPengobatanMassal,
  ] = useState(0);

  const [totalDataPengobatanMassal, setTotalDataPengobatanMassal] = useState(0);

  const [totalPagePengobatanMassal, setTotalPagePengobatanMassal] = useState(0);

  const [
    currentPagePengobatanMassal,
    setCurrentPagePengobatanMassal,
  ] = useState(0);

  // @Pagination Section
  // Pengabdian - Sirkumsisi

  const optionsNumberPerPageSirkumsisi = [10, 20, 30, 40, 50];

  const [numberPerPageSirkumsisi, setNumberPerPageSirkumsisi] = useState(
    optionsNumberPerPageSirkumsisi[0]
  );

  const [
    numberPerPageFromSirkumsisi,
    setNumberPerPageFromSirkumsisi,
  ] = useState(0);

  const [numberPerPageToSirkumsisi, setNumberPerPageToSirkumsisi] = useState(0);

  const [totalDataSirkumsisi, setTotalDataSirkumsisi] = useState(0);

  const [totalPageSirkumsisi, setTotalPageSirkumsisi] = useState(0);

  const [currentPageSirkumsisi, setCurrentPageSirkumsisi] = useState(0);

  // @Pagination Section
  // Pengabdian - Kunjungan Pasien

  const optionsNumberPerPageKunjunganPasien = [10, 20, 30, 40, 50];

  const [
    numberPerPageKunjunganPasien,
    setNumberPerPageKunjunganPasien,
  ] = useState(optionsNumberPerPageKunjunganPasien[0]);

  const [
    numberPerPageFromKunjunganPasien,
    setNumberPerPageFromKunjunganPasien,
  ] = useState(0);

  const [
    numberPerPageToKunjunganPasien,
    setNumberPerPageToKunjunganPasien,
  ] = useState(0);

  const [totalDataKunjunganPasien, setTotalDataKunjunganPasien] = useState(0);

  const [totalPageKunjunganPasien, setTotalPageKunjunganPasien] = useState(0);

  const [currentPageKunjunganPasien, setCurrentPageKunjunganPasien] = useState(
    0
  );

  // @Pagination Section
  // Pengabdian - Tim Medis FKUI

  const optionsNumberPerPageTimMedisFKUI = [10, 20, 30, 40, 50];

  const [numberPerPageTimMedisFKUI, setNumberPerPageTimMedisFKUI] = useState(
    optionsNumberPerPageTimMedisFKUI[0]
  );

  const [
    numberPerPageFromTimMedisFKUI,
    setNumberPerPageFromTimMedisFKUI,
  ] = useState(0);

  const [
    numberPerPageToTimMedisFKUI,
    setNumberPerPageToTimMedisFKUI,
  ] = useState(0);

  const [totalDataTimMedisFKUI, setTotalDataTimMedisFKUI] = useState(0);

  const [totalPageTimMedisFKUI, setTotalPageTimMedisFKUI] = useState(0);

  const [currentPageTimMedisFKUI, setCurrentPageTimMedisFKUI] = useState(0);

  // @Pagination Section
  // Pengabdian - Rekap IKM

  const optionsNumberPerPageRekapIKM = [10, 20, 30, 40, 50];

  const [numberPerPageRekapIKM, setNumberPerPageRekapIKM] = useState(
    optionsNumberPerPageRekapIKM[0]
  );

  const [
    numberPerPageFromRekapIKM,
    setNumberPerPageFromRekapIKM,
  ] = useState(0);

  const [
    numberPerPageToRekapIKM,
    setNumberPerPageToRekapIKM,
  ] = useState(0);

  const [totalDataRekapIKM, setTotalDataRekapIKM] = useState(0);

  const [totalPageRekapIKM, setTotalPageRekapIKM] = useState(0);

  const [currentPageRekapIKM, setCurrentPageRekapIKM] = useState(0);


  // @Dialog Section

  const dialogFullScreenClassName = "pengabdian-full-screen-bar";

  const [isDialogFullScreenActive, setIsDialogFullScreenActive] = useState(
    false
  );

  const [dialogDataActive, setDialogDataActive] = useState(null);

  const [dialogTitleActive, setDialogTitleActive] = useState("");

  const [dialogActiveActionType, setDialogActiveActionType] = useState("");


  // @Export Section

  const handleClickSingleListExport = async (exportTarget) => {
    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: true,
            }
          : item;
      })
    );

    const data = await (async () => {
      let _data = {};

      _data.bgHeader = "FFB9CDF7";

      _data.title = showDataActive.value;

      _data.fileName = `${showDataActive.value}.xlsx`;

      switch(showDataActive.value) {
        case "Penyuluhan":
          _data.target = dataFieldsPenyuluhan.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsPenyuluhan
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byPenyuluhan = await getPenyuluhan({
            search: 
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterPenyuluhan.length > 1 ? "OR" : "",
                    rules: searchFilterPenyuluhan.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputPenyuluhan
                      };
                    }),
                  },
            order: sortItemPenyuluhan,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPagePenyuluhan
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPagePenyuluhan - 1 < 0 
                    ? 0
                    : currentPagePenyuluhan - 1) * numberPerPagePenyuluhan
                : null,
          });

          if(byPenyuluhan.success) {
            let no = 1;

            _data.value = byPenyuluhan.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byPenyuluhan.data.length;
          }
        break;
        case "Kegiatan Sosial":
          _data.target = dataFieldsKegiatanSosial.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsKegiatanSosial
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byKegiatanSosial = await getKegiatanSosial({
            search: 
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterKegiatanSosial.length > 1 ? "OR" : "",
                    rules: searchFilterKegiatanSosial.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputKegiatanSosial
                      }
                    }),
                  },
            order: sortItemKegiatanSosial,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageKegiatanSosial
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageKegiatanSosial - 1 < 0 
                    ? 0
                    : currentPageKegiatanSosial - 1) * numberPerPageKegiatanSosial
                : null,
          });

          if(byKegiatanSosial.success) {
            let no = 1;

            _data.value = byKegiatanSosial.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byKegiatanSosial.data.length;
          }
        break;
        case "Screening":
          _data.target = dataFieldsScreening.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsScreening
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byScreening = await getScreening({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterScreening.length > 1 ? "OR" : "",
                    rules: searchFilterScreening.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputScreening
                      };
                    }),
                  },
            order: sortItemScreening,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageScreening
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageScreening - 1 < 0
                    ? 0
                    : currentPageScreening - 1) * numberPerPageScreening
                : null,
          });

          if(byScreening.success) {
            let no = 1;

            _data.value = byScreening.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byScreening.data.length;
          }
        break;
        case "Pengobatan Massal":
          _data.target = dataFieldsPengobatanMassal.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsPengobatanMassal
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byPengobatanMassal = await getPengobatanMassal({
            search: 
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterPengobatanMassal.length > 1 ? "OR" : "",
                    rules: searchFilterPengobatanMassal.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputPengobatanMassal
                      };
                    }),
                  },
            order: sortItemPengobatanMassal,
            limit:
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPagePengobatanMassal
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPagePengobatanMassal - 1 < 0
                    ? 0
                    : currentPagePengobatanMassal - 1) * numberPerPagePengobatanMassal
                : null,
          });

          if(byPengobatanMassal.success) {
            let no = 1;

            _data.value = byPengobatanMassal.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byPengobatanMassal.data.length;
          }
        break;
        case "Sirkumsisi":
          _data.target = dataFieldsSirkumsisi.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsSirkumsisi
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const bySirkumsisi = await getSirkumsisi({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterSirkumsisi.length > 1 ? "OR" : "",
                    rules: searchFilterSirkumsisi.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputSirkumsisi
                      };
                    }),
                  },
            order: sortItemSirkumsisi,
            limit:
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageSirkumsisi
                : null,
            offset:
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageSirkumsisi - 1 < 0 
                    ? 0
                    : currentPageSirkumsisi - 1) * numberPerPageSirkumsisi
                : null,
          });

          if(bySirkumsisi.success) {
            let no = 1;

            _data.value = bySirkumsisi.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = bySirkumsisi.data.length;
          }
        break;
        case "Kunjungan Pasien":
          _data.target = dataFieldsKunjunganPasien.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsKunjunganPasien
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byKunjunganPasien = await getKunjunganPasien({
            search: 
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterKunjunganPasien.length > 1 ? "OR" : "",
                    rules: searchFilterKunjunganPasien.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputKunjunganPasien
                      };
                    }),
                  },
            order: sortItemKunjunganPasien,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageKunjunganPasien
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageKunjunganPasien - 1 < 0
                    ? 0
                    : currentPageKunjunganPasien - 1) * numberPerPageKunjunganPasien
                : null,
          });

          if(byKunjunganPasien.success) {
            let no = 1;

            _data.value = byKunjunganPasien.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byKunjunganPasien.data.length;
          }
        break;
        case "Tim Medis FKUI":
          _data.target = dataFieldsTimMedisFKUI.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsTimMedisFKUI
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byTimMedisFKUI = await getTimMedisFKUI({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterTimMedisFKUI.length > 1 ? "OR" : "",
                    rules: searchFilterTimMedisFKUI.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputTimMedisFKUI
                      };
                    }),
                  },
            order: sortItemTimMedisFKUI,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageTimMedisFKUI
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageTimMedisFKUI - 1 < 0
                    ? 0
                    : currentPageTimMedisFKUI - 1) * numberPerPageTimMedisFKUI
                : null,
          });

          if(byTimMedisFKUI.success) {
            let no = 1;

            _data.value = byTimMedisFKUI.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byTimMedisFKUI.data.length;
          }
        break;
        case "Rekap IKM":
          _data.target = dataFieldsRekapIKM.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsRekapIKM
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const byRekapIKM = await getRekapIKM({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupOp: searchFilterRekapIKM.length > 1 ? "OR" : "",
                    rules: searchFilterRekapIKM.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputRekapIKM
                      }
                    }),
                  },
            order: sortItemRekapIKM,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageRekapIKM
                : null,
            offset:
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageRekapIKM - 1 < 0
                    ? 0
                    : currentPageRekapIKM - 1) * numberPerPageRekapIKM
                : null,
          });

          if(byRekapIKM.success) {
            let no = 1;

            _data.value = byRekapIKM.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = byRekapIKM.data.length;
          }
        break;
      }

      return _data;
    })();

    generateExcel({
      title: data.title,
      subTitle: `Total Data: ${data.total}`,
      header: data.header,
      bgHeader: data.bgHeader,
      fileName: data.fileName,
      data: data.value,
      cell: data.target,
    });

    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: false,
            }
          : item;
      })
    );
  };


  const handleClickSingleBodyContentPenyuluhan = async (item) => {
    setIsFullScreenLoading(true);
    
    const detailPenyuluhan = await getDetailPenyuluhanByIdPenyuluhan(item.id_penyuluhan);

    if(detailPenyuluhan.success) {
      setDialogDataActive({
        idPenyuluhan: item.id_penyuluhan,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        jumlahMasyarakat: item.jumlah_masyarakat,
        mahasiswaTerlibatSelected: detailPenyuluhan.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
          };
        }),
        jumlahMahasiswaTerlibat: detailPenyuluhan.data.length,
        listDetail: detailPenyuluhan.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentKegiatanSosial = async (item) => {
    setIsFullScreenLoading(true);
    
    const detailKegiatanSosial = await getDetailKegiatanSosialByIdKegiatanSosial(item.id_kegiatan_sosial);

    if(detailKegiatanSosial.success) {
      setDialogDataActive({
        idKegiatanSosial: item.id_kegiatan_sosial,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        mahasiswaTerlibatSelected: detailKegiatanSosial.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
          };
        }),
        jumlahMahasiswaTerlibat: detailKegiatanSosial.data.length,
        listDetail: detailKegiatanSosial.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentScreening = async (item) => {
    setIsFullScreenLoading(true);

    const detailScreening = await getDetailScreeningByIdScreening(item.id_screening);

    if(detailScreening.success) {
      setDialogDataActive({
        idScreening: item.id_screening,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        jumlahMasyarakat: item.jumlah_masyarakat,
        mahasiswaTerlibatSelected: detailScreening.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
            peran: x.peran
          };
        }),
        jumlahMahasiswaTerlibat: detailScreening.data.length,
        listDetail: detailScreening.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat,
            peran: x.peran
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentPengobatanMassal = async (item) => {
    setIsFullScreenLoading(true);

    const detailPengobatanMassal = await getDetailPengobatanMassalByIdPengobatanMassal(item.id_pengobatan_massal);

    if(detailPengobatanMassal.success) {
      setDialogDataActive({
        idPengobatanMassal: item.id_pengobatan_massal,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        jumlahMasyarakat: item.jumlah_masyarakat,
        mahasiswaTerlibatSelected: detailPengobatanMassal.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
          };
        }),
        jumlahMahasiswaTerlibat: detailPengobatanMassal.data.length,
        listDetail: detailPengobatanMassal.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentSirkumsisi = async (item) => {
    setIsFullScreenLoading(true);

    const detailSirkumsisi = await getDetailSirkumsisiByIdSirkumsisi(item.id_sirkumsisi);

    if(detailSirkumsisi.success) {
      setDialogDataActive({
        idSirkumsisi: item.id_sirkumsisi,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        jumlahMasyarakat: item.jumlah_masyarakat,
        mahasiswaTerlibatSelected: detailSirkumsisi.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
            peran: x.peran
          };
        }),
        jumlahMahasiswaTerlibat: detailSirkumsisi.data.length,
        listDetail: detailSirkumsisi.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat,
            peran: x.peran
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentKunjunganPasien = async (item) => {
    setIsFullScreenLoading(true);

    const detailKunjunganPasien = await getDetailKunjunganPasienByIdKunjunganPasien(item.id_kunjungan_pasien);

    if(detailKunjunganPasien.success) {
      setDialogDataActive({
        idKunjunganPasien: item.id_kunjungan_pasien,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        mahasiswaTerlibatSelected: detailKunjunganPasien.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
          };
        }),
        jumlahMahasiswaTerlibat: detailKunjunganPasien.data.length,
        listDetail: detailKunjunganPasien.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickSingleBodyContentTimMedisFKUI = async (item) => {
    setIsFullScreenLoading(true);
    
    const detailTimMedisFKUI = await getDetailTimMedisFKUIByIdTimMedisFKUI(item.id_tim_medis_fkui);

    if(detailTimMedisFKUI.success) {
      setDialogDataActive({
        idTimMedisFKUI: item.id_tim_medis_fkui,
        namaKegiatan: item.nama_kegiatan,
        penyelenggaraKegiatan: item.penyelenggara_kegiatan,
        lokasi: item.lokasi,
        waktuPelaksanaan: new Date(item.waktu_pelaksanaan.split("-").reverse().join("-")),
        jumlahMasyarakat: item.jumlah_masyarakat,
        mahasiswaTerlibatSelected: detailTimMedisFKUI.data.map(x => {
          return {
            value: x.id_pengguna,
            label: `${x.nama_lengkap} - ${x.npm}`,
            npm: x.npm,
            nama_lengkap: x.nama_lengkap,
          };
        }),
        jumlahMahasiswaTerlibat: detailTimMedisFKUI.data.length,
        listDetail: detailTimMedisFKUI.data.map(x => {
          return {
            id_pengguna: x.id_pengguna,
            isSertifikatClearActive: false,
            isSertifikatDialogActive: false,
            penggunaText: `${x.nama_lengkap} - ${x.npm}`,
            sertifikat: x.sertifikat
          };
        })
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickTambahDataButton = () => {
    setDialogActiveActionType("Tambah");
    setIsDialogFullScreenActive(true);
  };

  const handleLoadPenyuluhan = async (type) => {
    let penyuluhanObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        penyuluhanObject.search = {
          groupOp: searchFilterPenyuluhan.length > 1 ? "OR" : "",
          rules: searchFilterPenyuluhan.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputPenyuluhan
            };
          })
        };
        penyuluhanObject.order = sortItemPenyuluhan;
        penyuluhanObject.limit = numberPerPagePenyuluhan;
        penyuluhanObject.offset = 
          (currentPagePenyuluhan - 1 < 0
            ? 0
            : currentPagePenyuluhan - 1) *
          numberPerPagePenyuluhan;
      break;
      case "INIT":
      default: 
        penyuluhanObject.order = sortItemPenyuluhan;
        penyuluhanObject.limit = numberPerPagePenyuluhan;
        penyuluhanObject.offset = 0;
    }

    if((dataPenyuluhan.length === 0 && totalDataPenyuluhan === 0) || type === "REFRESH" || type === "INIT") {
      const [_penyuluhan, _totalPenyuluhan] = await Promise.all([
        getPenyuluhan(penyuluhanObject),
        getTotalPenyuluhan({})
      ]);

      if(_penyuluhan.success && _totalPenyuluhan.success) {
        setDataPenyuluhan(_penyuluhan.data);
        setTotalDataPenyuluhan(_totalPenyuluhan.data.total);
        setTotalPagePenyuluhan(
          Math.ceil(_totalPenyuluhan.data.total / numberPerPagePenyuluhan)
        );
        setCurrentPagePenyuluhan(_totalPenyuluhan.data.total > 0 ? 1 : 0);
        setNumberPerPageFromPenyuluhan(
          _totalPenyuluhan.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToPenyuluhan(
          _totalPenyuluhan.data.total > 0 ? _penyuluhan.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadKegiatanSosial = async (type) => {
    let kegiatanSosialObject = {};

    setIsBodyPanelLoading(true);
    
    switch(type) {
      case "REFRESH":
        kegiatanSosialObject.search = {
          groupOp: searchFilterKegiatanSosial.length > 1 ? "OR" : "",
          rules: searchFilterKegiatanSosial.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputKegiatanSosial
            };
          })
        };
        kegiatanSosialObject.order = sortItemKegiatanSosial;
        kegiatanSosialObject.limit = numberPerPageKegiatanSosial;
        kegiatanSosialObject.offset = 
          (currentPageKegiatanSosial - 1 < 0
            ? 0
            : currentPageKegiatanSosial - 1) *
          numberPerPageKegiatanSosial;
      break;
      case "INIT":
      default:
        kegiatanSosialObject.order = sortItemKegiatanSosial;
        kegiatanSosialObject.limit = numberPerPageKegiatanSosial;
        kegiatanSosialObject.offset = 0;
    }

    if((dataKegiatanSosial.length === 0 && totalDataKegiatanSosial === 0) || type === "REFRESH" || type === "INIT") {
      const [_kegiatanSosial, _totalKegiatanSosial] = await Promise.all([
        getKegiatanSosial(kegiatanSosialObject),
        getTotalKegiatanSosial({})
      ]);

      if(_kegiatanSosial.success && _totalKegiatanSosial.success) {
        setDataKegiatanSosial(_kegiatanSosial.data);
        setTotalDataKegiatanSosial(_totalKegiatanSosial.data.total);
        setTotalPageKegiatanSosial(
          Math.ceil(_totalKegiatanSosial.data.total / numberPerPageKegiatanSosial)
        );
        setCurrentPageKegiatanSosial(_totalKegiatanSosial.data.total > 0 ? 1 : 0);
        setNumberPerPageFromKegiatanSosial(
          _totalKegiatanSosial.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToKegiatanSosial(
          _totalKegiatanSosial.data.total > 0 ? _kegiatanSosial.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadScreening = async (type) => {
    let screeningObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        screeningObject.search = {
          groupOp: searchFilterScreening.length > 1 ? "OR" : "",
          rules: searchFilterScreening.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputScreening
            };
          })
        };
        screeningObject.order = sortItemScreening;
        screeningObject.limit = numberPerPageScreening;
        screeningObject.offset = 
          (currentPageScreening - 1 < 0
            ? 0
            : currentPageScreening - 1) *
          numberPerPageScreening;
      break;
      case "INIT":
      default: 
        screeningObject.order = sortItemScreening;
        screeningObject.limit = numberPerPageScreening;
        screeningObject.offset = 0;
    }

    if((dataScreening.length === 0 && totalDataScreening === 0) || type === "REFRESH" || type === "INIT") {
      const [_screening, _totalScreening] = await Promise.all([
        getScreening(screeningObject),
        getTotalScreening({})
      ]);

      if(_screening.success && _totalScreening.success) {
        setDataScreening(_screening.data);
        setTotalDataScreening(_totalScreening.data.total);
        setTotalPageScreening(
          Math.ceil(_totalScreening.data.total / numberPerPageScreening)
        );
        setCurrentPageScreening(_totalScreening.data.total > 0 ? 1 : 0);
        setNumberPerPageFromScreening(
          _totalScreening.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToScreening(
          _totalScreening.data.total > 0 ? _screening.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadPengobatanMassal = async (type) => {
    let pengobatanMassalObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        pengobatanMassalObject.search = {
          groupOp: searchFilterPengobatanMassal.length > 1 ? "OR" : "",
          rules: searchFilterPengobatanMassal.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputPengobatanMassal
            };
          })
        };
        pengobatanMassalObject.order = sortItemPengobatanMassal;
        pengobatanMassalObject.limit = numberPerPagePengobatanMassal;
        pengobatanMassalObject.offset = 
          (currentPagePengobatanMassal - 1 < 0
            ? 0
            : currentPagePengobatanMassal - 1) *
          numberPerPagePengobatanMassal;
      break;
      case "INIT":
      default: 
        pengobatanMassalObject.order = sortItemPengobatanMassal;
        pengobatanMassalObject.limit = numberPerPagePengobatanMassal;
        pengobatanMassalObject.offset = 0;
    }

    if((dataPengobatanMassal.length === 0 && totalDataPengobatanMassal === 0) || type === "REFRESH" || type === "INIT") {
      const [_pengobatanMassal, _totalPengobatanMassal] = await Promise.all([
        getPengobatanMassal(pengobatanMassalObject),
        getTotalPengobatanMassal({})
      ]);

      if(_pengobatanMassal.success && _totalPengobatanMassal.success) {
        setDataPengobatanMassal(_pengobatanMassal.data);
        setTotalDataPengobatanMassal(_totalPengobatanMassal.data.total);
        setTotalPagePengobatanMassal(
          Math.ceil(_totalPengobatanMassal.data.total / numberPerPagePengobatanMassal)
        );
        setCurrentPagePengobatanMassal(_totalPengobatanMassal.data.total > 0 ? 1 : 0);
        setNumberPerPageFromPengobatanMassal(
          _totalPengobatanMassal.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToPengobatanMassal(
          _totalPengobatanMassal.data.total > 0 ? _pengobatanMassal.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadSirkumsisi = async (type) => {
    let sirkumsisiObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        sirkumsisiObject.search = {
          groupOp: searchFilterSirkumsisi.length > 1 ? "OR" : "",
          rules: searchFilterSirkumsisi.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputSirkumsisi
            };
          })
        };
        sirkumsisiObject.order = sortItemSirkumsisi;
        sirkumsisiObject.limit = numberPerPageSirkumsisi;
        sirkumsisiObject.offset = 
          (currentPageSirkumsisi - 1 < 0
            ? 0
            : currentPageSirkumsisi - 1) *
          numberPerPageSirkumsisi;
      break;
      case "INIT":
      default: 
        sirkumsisiObject.order = sortItemSirkumsisi;
        sirkumsisiObject.limit = numberPerPageSirkumsisi;
        sirkumsisiObject.offset = 0;
    }

    if((dataSirkumsisi.length === 0 && totalDataSirkumsisi === 0) || type === "REFRESH" || type === "INIT") {
      const [_sirkumsisi, _totalSirkumsisi] = await Promise.all([
        getSirkumsisi(sirkumsisiObject),
        getTotalSirkumsisi({})
      ]);

      if(_sirkumsisi.success && _totalSirkumsisi.success) {
        setDataSirkumsisi(_sirkumsisi.data);
        setTotalDataSirkumsisi(_totalSirkumsisi.data.total);
        setTotalPageSirkumsisi(
          Math.ceil(_totalSirkumsisi.data.total / numberPerPageSirkumsisi)
        );
        setCurrentPageSirkumsisi(_totalSirkumsisi.data.total > 0 ? 1 : 0);
        setNumberPerPageFromSirkumsisi(
          _totalSirkumsisi.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToSirkumsisi(
          _totalSirkumsisi.data.total > 0 ? _sirkumsisi.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadKunjunganPasien = async (type) => {
    let kunjunganPasienObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        kunjunganPasienObject.search = {
          groupOp: searchFilterKunjunganPasien.length > 1 ? "OR" : "",
          rules: searchFilterKunjunganPasien.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputKunjunganPasien
            };
          })
        };
        kunjunganPasienObject.order = sortItemKunjunganPasien;
        kunjunganPasienObject.limit = numberPerPageKunjunganPasien;
        kunjunganPasienObject.offset = 
          (currentPageKunjunganPasien - 1 < 0
            ? 0
            : currentPageKunjunganPasien - 1) *
          numberPerPageKunjunganPasien;
      break;
      case "INIT":
      default: 
        kunjunganPasienObject.order = sortItemKunjunganPasien;
        kunjunganPasienObject.limit = numberPerPageKunjunganPasien;
        kunjunganPasienObject.offset = 0;
    }

    if((dataKunjunganPasien.length === 0 && totalDataKunjunganPasien === 0) || type === "REFRESH" || type === "INIT") {
      const [_kunjunganPasien, _totalKunjunganPasien] = await Promise.all([
        getKunjunganPasien(kunjunganPasienObject),
        getTotalKunjunganPasien({})
      ]);

      if(_kunjunganPasien.success && _totalKunjunganPasien.success) {
        setDataKunjunganPasien(_kunjunganPasien.data);
        setTotalDataKunjunganPasien(_totalKunjunganPasien.data.total);
        setTotalPageKunjunganPasien(
          Math.ceil(_totalKunjunganPasien.data.total / numberPerPageKunjunganPasien)
        );
        setCurrentPageKunjunganPasien(_totalKunjunganPasien.data.total > 0 ? 1 : 0);
        setNumberPerPageFromKunjunganPasien(
          _totalKunjunganPasien.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToKunjunganPasien(
          _totalKunjunganPasien.data.total > 0 ? _kunjunganPasien.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadTimMedisFKUI = async (type) => {
    let timMedisFKUIObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        timMedisFKUIObject.search = {
          groupOp: searchFilterTimMedisFKUI.length > 1 ? "OR" : "",
          rules: searchFilterTimMedisFKUI.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputTimMedisFKUI
            };
          })
        };
        timMedisFKUIObject.order = sortItemTimMedisFKUI;
        timMedisFKUIObject.limit = numberPerPageTimMedisFKUI;
        timMedisFKUIObject.offset = 
          (currentPageTimMedisFKUI - 1 < 0
            ? 0
            : currentPageTimMedisFKUI - 1) *
          numberPerPageTimMedisFKUI;
      break;
      case "INIT":
      default: 
        timMedisFKUIObject.order = sortItemTimMedisFKUI;
        timMedisFKUIObject.limit = numberPerPageTimMedisFKUI;
        timMedisFKUIObject.offset = 0;
    }

    if((dataTimMedisFKUI.length === 0 && totalDataTimMedisFKUI === 0) || type === "REFRESH" || type === "INIT") {
      const [_timMedisFKUI, _totalTimMedisFKUI] = await Promise.all([
        getTimMedisFKUI(timMedisFKUIObject),
        getTotalTimMedisFKUI({})
      ]);

      if(_timMedisFKUI.success && _totalTimMedisFKUI.success) {
        setDataTimMedisFKUI(_timMedisFKUI.data);
        setTotalDataTimMedisFKUI(_totalTimMedisFKUI.data.total);
        setTotalPageTimMedisFKUI(
          Math.ceil(_totalTimMedisFKUI.data.total / numberPerPageTimMedisFKUI)
        );
        setCurrentPageTimMedisFKUI(_totalTimMedisFKUI.data.total > 0 ? 1 : 0);
        setNumberPerPageFromTimMedisFKUI(
          _totalTimMedisFKUI.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToTimMedisFKUI(
          _totalTimMedisFKUI.data.total > 0 ? _timMedisFKUI.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const handleLoadRekapIKM = async (type) => {
    let rekapIKMObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        rekapIKMObject.search = {
          groupOp: searchFilterRekapIKM.length > 1 ? "OR" : "",
          rules: searchFilterRekapIKM.map(item => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputRekapIKM
            };
          })
        };
        rekapIKMObject.order = sortItemRekapIKM;
        rekapIKMObject.limit = numberPerPageRekapIKM;
        rekapIKMObject.offset = 
          (currentPageRekapIKM - 1 < 0
            ? 0
            : currentPageRekapIKM - 1) *
          numberPerPageRekapIKM;
      break;
      case "INIT":
      default: 
        rekapIKMObject.order = sortItemRekapIKM;
        rekapIKMObject.limit = numberPerPageRekapIKM;
        rekapIKMObject.offset = 0;
    }

    if((dataRekapIKM.length === 0 && totalDataRekapIKM === 0) || type === "REFRESH" || type === "INIT") {
      const [_rekapIKM, _totalRekapIKM] = await Promise.all([
        getRekapIKM(rekapIKMObject),
        getTotalPengguna({})
      ]);

      if(_rekapIKM.success && _totalRekapIKM.success) {
        setDataRekapIKM(_rekapIKM.data);
        setTotalDataRekapIKM(_totalRekapIKM.data.total);
        setTotalPageRekapIKM(
          Math.ceil(_totalRekapIKM.data.total / numberPerPageRekapIKM)
        );
        setCurrentPageRekapIKM(_totalRekapIKM.data.total > 0 ? 1 : 0);
        setNumberPerPageFromRekapIKM(
          _totalRekapIKM.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToRekapIKM(
          _totalRekapIKM.data.total > 0 ? _rekapIKM.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        switch(showDataActive.value) {
          case "Penyuluhan":
            _delete = await deletePenyuluhan(dataSelected);
          break;
          case "Kegiatan Sosial":
            _delete = await deleteKegiatanSosial(dataSelected);
          break;
          case "Screening":
            _delete = await deleteScreening(dataSelected);
          break;
          case "Pengobatan Massal":
            _delete = await deletePengobatanMassal(dataSelected);
          break;
          case "Sirkumsisi":
            _delete = await deleteSirkumsisi(dataSelected);
          break;
          case "Kunjungan Pasien":
            _delete = await deleteKunjunganPasien(dataSelected);
          break;
          case "Tim Medis FKUI":
            _delete = await deleteTimMedisFKUI(dataSelected);
          break;
        }

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData("INIT");
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = (type) => {
    switch(showDataActive.value) {
      case "Penyuluhan":
        handleLoadPenyuluhan(type);
      break;
      case "Kegiatan Sosial":
        handleLoadKegiatanSosial(type);
      break;
      case "Screening":
        handleLoadScreening(type);
      break;
      case "Pengobatan Massal":
        handleLoadPengobatanMassal(type);
      break;
      case "Sirkumsisi":
        handleLoadSirkumsisi(type);
      break;
      case "Kunjungan Pasien":
        handleLoadKunjunganPasien(type);
      break;
      case "Tim Medis FKUI":
        handleLoadTimMedisFKUI(type);
      break;
      case "Rekap IKM":
        handleLoadRekapIKM(type);
    }
  };

  useDidMountEffect(() => {
    if(dialogActiveActionType) {
      setDialogTitleActive(`${dialogActiveActionType} ${showDataActive.value}`);
    }
  }, [dialogActiveActionType]);

  useOnClickOutside(exportToExcelOptionRef, () => {
    if (isExportToExcelOptionActive) {
      setIsExportToExcelOptionActive(false);
    }
  });

  useEffect(() => {
    handleLoadData("INIT");
  }, [showDataActive]);

  useEffect(() => {
    (async () => {
      const _mahasiswa = await getPengguna({
        fields: ["id_pengguna", "nama_lengkap", "npm"],
        search: null,
        order: {
          by: "nama_lengkap",
          mode: "ASC",
        },
        offset: null,
        limit: null,
      });
  
      if(_mahasiswa.success) {
        setOptionsMahassiwa(
          _mahasiswa.data.map((item) => {
            return {
              value: item.id_pengguna,
              label: `${item.nama_lengkap} - ${item.npm}`,
              npm: item.npm,
              nama_lengkap: item.nama_lengkap,
            };
          })
        );
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper pengabdian-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Pengabdian</h1>
            <div className="header-right-button-area">
              <div className="single-button export-to-excel-button" onMouseDown={() => setIsExportToExcelOptionActive(!isExportToExcelOptionActive) }
              >
                <span className="button-text">Export to Excel</span>
                <span
                  className={`caret-down-icon${
                    isExportToExcelOptionActive ? " rotate" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
              <div className={`export-to-excel-option${isExportToExcelOptionActive ? " active" : ""}`} ref={exportToExcelOptionRef}>
                <ul className="list-export-wrapper">
                  {exportToExcelList.map((item, index) => (
                    <li
                      className="single-list"
                      onClick={() => handleClickSingleListExport(item.value)}
                      key={index}
                    >
                      {item.isLoading ? (
                        <CircularProgress
                          size={13}
                          className="loading-circle"
                        />
                      ) : (
                        item.value
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {showDataActive.value !== "Rekap IKM" && (
                <div
                  className="single-button"
                  onClick={handleClickTambahDataButton}
                >
                  <span className="button-text">Tambah Data</span>
                </div>
              )}
            </div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      {showDataActive.value === "Penyuluhan" ? (
                        <Search
                          setData={setDataPenyuluhan}
                          getData={getPenyuluhan}
                          dataFields={dataFieldsPenyuluhan}
                          sortItem={sortItemPenyuluhan}
                          searchInput={searchInputPenyuluhan}
                          setSearchInput={setSearchInputPenyuluhan}
                          optionsSearchFilterBy={optionsSearchFilterPenyuluhan}
                          searchFilterBy={searchFilterPenyuluhan}
                          setSearchFilterBy={setSearchFilterPenyuluhan}
                          numberPerPage={numberPerPagePenyuluhan}
                          setNumberPerPageFrom={setNumberPerPageFromPenyuluhan}
                          setNumberPerPageTo={setNumberPerPageToPenyuluhan}
                          getTotalData={getTotalPenyuluhan}
                          setTotalData={setTotalDataPenyuluhan}
                          setTotalPage={setTotalPagePenyuluhan}
                          setCurrentPage={setCurrentPagePenyuluhan}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Kegiatan Sosial" ? (
                        <Search
                          setData={setDataKegiatanSosial}
                          getData={getKegiatanSosial}
                          dataFields={dataFieldsKegiatanSosial}
                          sortItem={sortItemKegiatanSosial}
                          searchInput={searchInputKegiatanSosial}
                          setSearchInput={setSearchInputKegiatanSosial}
                          optionsSearchFilterBy={optionsSearchFilterKegiatanSosial}
                          searchFilterBy={searchFilterKegiatanSosial}
                          setSearchFilterBy={setSearchFilterKegiatanSosial}
                          numberPerPage={numberPerPageKegiatanSosial}
                          setNumberPerPageFrom={setNumberPerPageFromKegiatanSosial}
                          setNumberPerPageTo={setNumberPerPageToKegiatanSosial}
                          getTotalData={getTotalKegiatanSosial}
                          setTotalData={setTotalDataKegiatanSosial}
                          setTotalPage={setTotalPageKegiatanSosial}
                          setCurrentPage={setCurrentPageKegiatanSosial}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Screening" ? (
                        <Search
                          setData={setDataScreening}
                          getData={getScreening}
                          dataFields={dataFieldsScreening}
                          sortItem={sortItemScreening}
                          searchInput={searchInputScreening}
                          setSearchInput={setSearchInputScreening}
                          optionsSearchFilterBy={optionsSearchFilterScreening}
                          searchFilterBy={searchFilterScreening}
                          setSearchFilterBy={setSearchFilterScreening}
                          numberPerPage={numberPerPageScreening}
                          setNumberPerPageFrom={setNumberPerPageFromScreening}
                          setNumberPerPageTo={setNumberPerPageToScreening}
                          getTotalData={getTotalScreening}
                          setTotalData={setTotalDataScreening}
                          setTotalPage={setTotalPageScreening}
                          setCurrentPage={setCurrentPageScreening}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Pengobatan Massal" ? (
                        <Search
                          setData={setDataPengobatanMassal}
                          getData={getPengobatanMassal}
                          dataFields={dataFieldsPengobatanMassal}
                          sortItem={sortItemPengobatanMassal}
                          searchInput={searchInputPengobatanMassal}
                          setSearchInput={setSearchInputPengobatanMassal}
                          optionsSearchFilterBy={optionsSearchFilterPengobatanMassal}
                          searchFilterBy={searchFilterPengobatanMassal}
                          setSearchFilterBy={setSearchFilterPengobatanMassal}
                          numberPerPage={numberPerPagePengobatanMassal}
                          setNumberPerPageFrom={setNumberPerPageFromPengobatanMassal}
                          setNumberPerPageTo={setNumberPerPageToPengobatanMassal}
                          getTotalData={getTotalPengobatanMassal}
                          setTotalData={setTotalDataPengobatanMassal}
                          setTotalPage={setTotalPagePengobatanMassal}
                          setCurrentPage={setCurrentPagePengobatanMassal}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Sirkumsisi" ? (
                        <Search
                          setData={setDataSirkumsisi}
                          getData={getSirkumsisi}
                          dataFields={dataFieldsSirkumsisi}
                          sortItem={sortItemSirkumsisi}
                          searchInput={searchInputSirkumsisi}
                          setSearchInput={setSearchInputSirkumsisi}
                          optionsSearchFilterBy={optionsSearchFilterSirkumsisi}
                          searchFilterBy={searchFilterSirkumsisi}
                          setSearchFilterBy={setSearchFilterSirkumsisi}
                          numberPerPage={numberPerPageSirkumsisi}
                          setNumberPerPageFrom={setNumberPerPageFromSirkumsisi}
                          setNumberPerPageTo={setNumberPerPageToSirkumsisi}
                          getTotalData={getTotalSirkumsisi}
                          setTotalData={setTotalDataSirkumsisi}
                          setTotalPage={setTotalPageSirkumsisi}
                          setCurrentPage={setCurrentPageSirkumsisi}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Kunjungan Pasien" ? (
                        <Search
                          setData={setDataKunjunganPasien}
                          getData={getKunjunganPasien}
                          dataFields={dataFieldsKunjunganPasien}
                          sortItem={sortItemKunjunganPasien}
                          searchInput={searchInputKunjunganPasien}
                          setSearchInput={setSearchInputKunjunganPasien}
                          optionsSearchFilterBy={optionsSearchFilterKunjunganPasien}
                          searchFilterBy={searchFilterKunjunganPasien}
                          setSearchFilterBy={setSearchFilterKunjunganPasien}
                          numberPerPage={numberPerPageKunjunganPasien}
                          setNumberPerPageFrom={setNumberPerPageFromKunjunganPasien}
                          setNumberPerPageTo={setNumberPerPageToKunjunganPasien}
                          getTotalData={getTotalKunjunganPasien}
                          setTotalData={setTotalDataKunjunganPasien}
                          setTotalPage={setTotalPageKunjunganPasien}
                          setCurrentPage={setCurrentPageKunjunganPasien}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Tim Medis FKUI" ? (
                        <Search
                          setData={setDataTimMedisFKUI}
                          getData={getTimMedisFKUI}
                          dataFields={dataFieldsTimMedisFKUI}
                          sortItem={sortItemTimMedisFKUI}
                          searchInput={searchInputTimMedisFKUI}
                          setSearchInput={setSearchInputTimMedisFKUI}
                          optionsSearchFilterBy={optionsSearchFilterTimMedisFKUI}
                          searchFilterBy={searchFilterTimMedisFKUI}
                          setSearchFilterBy={setSearchFilterTimMedisFKUI}
                          numberPerPage={numberPerPageTimMedisFKUI}
                          setNumberPerPageFrom={setNumberPerPageFromTimMedisFKUI}
                          setNumberPerPageTo={setNumberPerPageToTimMedisFKUI}
                          getTotalData={getTotalTimMedisFKUI}
                          setTotalData={setTotalDataTimMedisFKUI}
                          setTotalPage={setTotalPageTimMedisFKUI}
                          setCurrentPage={setCurrentPageTimMedisFKUI}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Rekap IKM" ? (
                        <Search
                          setData={setDataRekapIKM}
                          getData={getRekapIKM}
                          dataFields={dataFieldsRekapIKM}
                          sortItem={sortItemRekapIKM}
                          searchInput={searchInputRekapIKM}
                          setSearchInput={setSearchInputRekapIKM}
                          optionsSearchFilterBy={optionsSearchFilterRekapIKM}
                          searchFilterBy={searchFilterRekapIKM}
                          setSearchFilterBy={setSearchFilterRekapIKM}
                          numberPerPage={numberPerPageRekapIKM}
                          setNumberPerPageFrom={setNumberPerPageFromRekapIKM}
                          setNumberPerPageTo={setNumberPerPageToRekapIKM}
                          getTotalData={getTotalPengguna}
                          setTotalData={setTotalDataRekapIKM}
                          setTotalPage={setTotalPageRekapIKM}
                          setCurrentPage={setCurrentPageRekapIKM}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      ) : null }
                      <ShowDataToggle
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      {showDataActive.value === "Penyuluhan" ? (
                        <Pagination
                          setData={setDataPenyuluhan}
                          getData={getPenyuluhan}
                          dataFields={dataFieldsPenyuluhan}
                          sortItem={sortItemPenyuluhan}
                          searchInput={searchInputPenyuluhan}
                          searchFilterBy={searchFilterPenyuluhan}
                          optionsNumberPerPage={optionsNumberPerPagePenyuluhan}
                          numberPerPage={numberPerPagePenyuluhan}
                          setNumberPerPage={setNumberPerPagePenyuluhan}
                          numberPerPageFrom={numberPerPageFromPenyuluhan}
                          setNumberPerPageFrom={setNumberPerPageFromPenyuluhan}
                          numberPerPageTo={numberPerPageToPenyuluhan}
                          setNumberPerPageTo={setNumberPerPageToPenyuluhan}
                          totalData={totalDataPenyuluhan}
                          getTotalData={getTotalPenyuluhan}
                          setTotalData={setTotalDataPenyuluhan}
                          totalPage={totalPagePenyuluhan}
                          setTotalPage={setTotalPagePenyuluhan}
                          currentPage={currentPagePenyuluhan}
                          setCurrentPage={setCurrentPagePenyuluhan}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Kegiatan Sosial" ? (
                        <Pagination
                          setData={setDataKegiatanSosial}
                          getData={getKegiatanSosial}
                          dataFields={dataFieldsKegiatanSosial}
                          sortItem={sortItemKegiatanSosial}
                          searchInput={searchInputKegiatanSosial}
                          searchFilterBy={searchFilterKegiatanSosial}
                          optionsNumberPerPage={optionsNumberPerPageKegiatanSosial}
                          numberPerPage={numberPerPageKegiatanSosial}
                          setNumberPerPage={setNumberPerPageKegiatanSosial}
                          numberPerPageFrom={numberPerPageFromKegiatanSosial}
                          setNumberPerPageFrom={setNumberPerPageFromKegiatanSosial}
                          numberPerPageTo={numberPerPageToKegiatanSosial}
                          setNumberPerPageTo={setNumberPerPageToKegiatanSosial}
                          totalData={totalDataKegiatanSosial}
                          getTotalData={getTotalKegiatanSosial}
                          setTotalData={setTotalDataKegiatanSosial}
                          totalPage={totalPageKegiatanSosial}
                          setTotalPage={setTotalPageKegiatanSosial}
                          currentPage={currentPageKegiatanSosial}
                          setCurrentPage={setCurrentPageKegiatanSosial}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Screening" ? (
                        <Pagination
                          setData={setDataScreening}
                          getData={getScreening}
                          dataFields={dataFieldsScreening}
                          sortItem={sortItemScreening}
                          searchInput={searchInputScreening}
                          searchFilterBy={searchFilterScreening}
                          optionsNumberPerPage={optionsNumberPerPageScreening}
                          numberPerPage={numberPerPageScreening}
                          setNumberPerPage={setNumberPerPageScreening}
                          numberPerPageFrom={numberPerPageFromScreening}
                          setNumberPerPageFrom={setNumberPerPageFromScreening}
                          numberPerPageTo={numberPerPageToScreening}
                          setNumberPerPageTo={setNumberPerPageToScreening}
                          totalData={totalDataScreening}
                          getTotalData={getTotalScreening}
                          setTotalData={setTotalDataScreening}
                          totalPage={totalPageScreening}
                          setTotalPage={setTotalPageScreening}
                          currentPage={currentPageScreening}
                          setCurrentPage={setCurrentPageScreening}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Pengobatan Massal" ? (
                        <Pagination
                          setData={setDataPengobatanMassal}
                          getData={getPengobatanMassal}
                          dataFields={dataFieldsPengobatanMassal}
                          sortItem={sortItemPengobatanMassal}
                          searchInput={searchInputPengobatanMassal}
                          searchFilterBy={searchFilterPengobatanMassal}
                          optionsNumberPerPage={optionsNumberPerPagePengobatanMassal}
                          numberPerPage={numberPerPagePengobatanMassal}
                          setNumberPerPage={setNumberPerPagePengobatanMassal}
                          numberPerPageFrom={numberPerPageFromPengobatanMassal}
                          setNumberPerPageFrom={setNumberPerPageFromPengobatanMassal}
                          numberPerPageTo={numberPerPageToPengobatanMassal}
                          setNumberPerPageTo={setNumberPerPageToPengobatanMassal}
                          totalData={totalDataPengobatanMassal}
                          getTotalData={getTotalPengobatanMassal}
                          setTotalData={setTotalDataPengobatanMassal}
                          totalPage={totalPagePengobatanMassal}
                          setTotalPage={setTotalPagePengobatanMassal}
                          currentPage={currentPagePengobatanMassal}
                          setCurrentPage={setCurrentPagePengobatanMassal}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Sirkumsisi" ? (
                        <Pagination
                          setData={setDataSirkumsisi}
                          getData={getSirkumsisi}
                          dataFields={dataFieldsSirkumsisi}
                          sortItem={sortItemSirkumsisi}
                          searchInput={searchInputSirkumsisi}
                          searchFilterBy={searchFilterSirkumsisi}
                          optionsNumberPerPage={optionsNumberPerPageSirkumsisi}
                          numberPerPage={numberPerPageSirkumsisi}
                          setNumberPerPage={setNumberPerPageSirkumsisi}
                          numberPerPageFrom={numberPerPageFromSirkumsisi}
                          setNumberPerPageFrom={setNumberPerPageFromSirkumsisi}
                          numberPerPageTo={numberPerPageToSirkumsisi}
                          setNumberPerPageTo={setNumberPerPageToSirkumsisi}
                          totalData={totalDataSirkumsisi}
                          getTotalData={getTotalSirkumsisi}
                          setTotalData={setTotalDataSirkumsisi}
                          totalPage={totalPageSirkumsisi}
                          setTotalPage={setTotalPageSirkumsisi}
                          currentPage={currentPageSirkumsisi}
                          setCurrentPage={setCurrentPageSirkumsisi}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Kunjungan Pasien" ? (
                        <Pagination
                          setData={setDataKunjunganPasien}
                          getData={getKunjunganPasien}
                          dataFields={dataFieldsKunjunganPasien}
                          sortItem={sortItemKunjunganPasien}
                          searchInput={searchInputKunjunganPasien}
                          searchFilterBy={searchFilterKunjunganPasien}
                          optionsNumberPerPage={optionsNumberPerPageKunjunganPasien}
                          numberPerPage={numberPerPageKunjunganPasien}
                          setNumberPerPage={setNumberPerPageKunjunganPasien}
                          numberPerPageFrom={numberPerPageFromKunjunganPasien}
                          setNumberPerPageFrom={setNumberPerPageFromKunjunganPasien}
                          numberPerPageTo={numberPerPageToKunjunganPasien}
                          setNumberPerPageTo={setNumberPerPageToKunjunganPasien}
                          totalData={totalDataKunjunganPasien}
                          getTotalData={getTotalKunjunganPasien}
                          setTotalData={setTotalDataKunjunganPasien}
                          totalPage={totalPageKunjunganPasien}
                          setTotalPage={setTotalPageKunjunganPasien}
                          currentPage={currentPageKunjunganPasien}
                          setCurrentPage={setCurrentPageKunjunganPasien}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Tim Medis FKUI" ? (
                        <Pagination
                          setData={setDataTimMedisFKUI}
                          getData={getTimMedisFKUI}
                          dataFields={dataFieldsTimMedisFKUI}
                          sortItem={sortItemTimMedisFKUI}
                          searchInput={searchInputTimMedisFKUI}
                          searchFilterBy={searchFilterTimMedisFKUI}
                          optionsNumberPerPage={optionsNumberPerPageTimMedisFKUI}
                          numberPerPage={numberPerPageTimMedisFKUI}
                          setNumberPerPage={setNumberPerPageTimMedisFKUI}
                          numberPerPageFrom={numberPerPageFromTimMedisFKUI}
                          setNumberPerPageFrom={setNumberPerPageFromTimMedisFKUI}
                          numberPerPageTo={numberPerPageToTimMedisFKUI}
                          setNumberPerPageTo={setNumberPerPageToTimMedisFKUI}
                          totalData={totalDataTimMedisFKUI}
                          getTotalData={getTotalTimMedisFKUI}
                          setTotalData={setTotalDataTimMedisFKUI}
                          totalPage={totalPageTimMedisFKUI}
                          setTotalPage={setTotalPageTimMedisFKUI}
                          currentPage={currentPageTimMedisFKUI}
                          setCurrentPage={setCurrentPageTimMedisFKUI}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Rekap IKM" ? (
                        <Pagination
                          setData={setDataRekapIKM}
                          getData={getRekapIKM}
                          dataFields={dataFieldsRekapIKM}
                          sortItem={sortItemRekapIKM}
                          searchInput={searchInputRekapIKM}
                          searchFilterBy={searchFilterRekapIKM}
                          optionsNumberPerPage={optionsNumberPerPageRekapIKM}
                          numberPerPage={numberPerPageRekapIKM}
                          setNumberPerPage={setNumberPerPageRekapIKM}
                          numberPerPageFrom={numberPerPageFromRekapIKM}
                          setNumberPerPageFrom={setNumberPerPageFromRekapIKM}
                          numberPerPageTo={numberPerPageToRekapIKM}
                          setNumberPerPageTo={setNumberPerPageToRekapIKM}
                          totalData={totalDataRekapIKM}
                          getTotalData={getTotalPengguna}
                          setTotalData={setTotalDataRekapIKM}
                          totalPage={totalPageRekapIKM}
                          setTotalPage={setTotalPageRekapIKM}
                          currentPage={currentPageRekapIKM}
                          setCurrentPage={setCurrentPageRekapIKM}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                        />
                      ) : null}
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                {showDataActive.value === "Penyuluhan" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataPenyuluhan}
                    setData={setDataPenyuluhan}
                    getData={getPenyuluhan}
                    dataFields={dataFieldsPenyuluhan}
                    sortItem={sortItemPenyuluhan}
                    setSortItem={setSortItemPenyuluhan}
                    searchInput={searchInputPenyuluhan}
                    searchFilterBy={searchFilterPenyuluhan}
                    numberPerPage={numberPerPagePenyuluhan}
                    setNumberPerPageFrom={setNumberPerPageFromPenyuluhan}
                    setNumberPerPageTo={setNumberPerPageToPenyuluhan}
                    totalData={totalDataPenyuluhan}
                    getTotalData={getTotalPenyuluhan}
                    setTotalData={setTotalDataPenyuluhan}
                    setTotalPage={setTotalPagePenyuluhan}
                    setCurrentPage={setCurrentPagePenyuluhan}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentPenyuluhan
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Kegiatan Sosial" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataKegiatanSosial}
                    setData={setDataKegiatanSosial}
                    getData={getKegiatanSosial}
                    dataFields={dataFieldsKegiatanSosial}
                    sortItem={sortItemKegiatanSosial}
                    setSortItem={setSortItemKegiatanSosial}
                    searchInput={searchInputKegiatanSosial}
                    searchFilterBy={searchFilterKegiatanSosial}
                    numberPerPage={numberPerPageKegiatanSosial}
                    setNumberPerPageFrom={setNumberPerPageFromKegiatanSosial}
                    setNumberPerPageTo={setNumberPerPageToKegiatanSosial}
                    totalData={totalDataKegiatanSosial}
                    getTotalData={getTotalKegiatanSosial}
                    setTotalData={setTotalDataKegiatanSosial}
                    setTotalPage={setTotalPageKegiatanSosial}
                    setCurrentPage={setCurrentPageKegiatanSosial}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentKegiatanSosial
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Screening" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataScreening}
                    setData={setDataScreening}
                    getData={getScreening}
                    dataFields={dataFieldsScreening}
                    sortItem={sortItemScreening}
                    setSortItem={setSortItemScreening}
                    searchInput={searchInputScreening}
                    searchFilterBy={searchFilterScreening}
                    numberPerPage={numberPerPageScreening}
                    setNumberPerPageFrom={setNumberPerPageFromScreening}
                    setNumberPerPageTo={setNumberPerPageToScreening}
                    totalData={totalDataScreening}
                    getTotalData={getTotalScreening}
                    setTotalData={setTotalDataScreening}
                    setTotalPage={setTotalPageScreening}
                    setCurrentPage={setCurrentPageScreening}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentScreening
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Pengobatan Massal" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataPengobatanMassal}
                    setData={setDataPengobatanMassal}
                    getData={getPengobatanMassal}
                    dataFields={dataFieldsPengobatanMassal}
                    sortItem={sortItemPengobatanMassal}
                    setSortItem={setSortItemPengobatanMassal}
                    searchInput={searchInputPengobatanMassal}
                    searchFilterBy={searchFilterPengobatanMassal}
                    numberPerPage={numberPerPagePengobatanMassal}
                    setNumberPerPageFrom={setNumberPerPageFromPengobatanMassal}
                    setNumberPerPageTo={setNumberPerPageToPengobatanMassal}
                    totalData={totalDataPengobatanMassal}
                    getTotalData={getTotalPengobatanMassal}
                    setTotalData={setTotalDataPengobatanMassal}
                    setTotalPage={setTotalPagePengobatanMassal}
                    setCurrentPage={setCurrentPagePengobatanMassal}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentPengobatanMassal
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Sirkumsisi" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataSirkumsisi}
                    setData={setDataSirkumsisi}
                    getData={getSirkumsisi}
                    dataFields={dataFieldsSirkumsisi}
                    sortItem={sortItemSirkumsisi}
                    setSortItem={setSortItemSirkumsisi}
                    searchInput={searchInputSirkumsisi}
                    searchFilterBy={searchFilterSirkumsisi}
                    numberPerPage={numberPerPageSirkumsisi}
                    setNumberPerPageFrom={setNumberPerPageFromSirkumsisi}
                    setNumberPerPageTo={setNumberPerPageToSirkumsisi}
                    totalData={totalDataSirkumsisi}
                    getTotalData={getTotalSirkumsisi}
                    setTotalData={setTotalDataSirkumsisi}
                    setTotalPage={setTotalPageSirkumsisi}
                    setCurrentPage={setCurrentPageSirkumsisi}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentSirkumsisi
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Kunjungan Pasien" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataKunjunganPasien}
                    setData={setDataKunjunganPasien}
                    getData={getKunjunganPasien}
                    dataFields={dataFieldsKunjunganPasien}
                    sortItem={sortItemKunjunganPasien}
                    setSortItem={setSortItemKunjunganPasien}
                    searchInput={searchInputKunjunganPasien}
                    searchFilterBy={searchFilterKunjunganPasien}
                    numberPerPage={numberPerPageKunjunganPasien}
                    setNumberPerPageFrom={setNumberPerPageFromKunjunganPasien}
                    setNumberPerPageTo={setNumberPerPageToKunjunganPasien}
                    totalData={totalDataKunjunganPasien}
                    getTotalData={getTotalKunjunganPasien}
                    setTotalData={setTotalDataKunjunganPasien}
                    setTotalPage={setTotalPageKunjunganPasien}
                    setCurrentPage={setCurrentPageKunjunganPasien}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentKunjunganPasien
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Tim Medis FKUI" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataTimMedisFKUI}
                    setData={setDataTimMedisFKUI}
                    getData={getTimMedisFKUI}
                    dataFields={dataFieldsTimMedisFKUI}
                    sortItem={sortItemTimMedisFKUI}
                    setSortItem={setSortItemTimMedisFKUI}
                    searchInput={searchInputTimMedisFKUI}
                    searchFilterBy={searchFilterTimMedisFKUI}
                    numberPerPage={numberPerPageTimMedisFKUI}
                    setNumberPerPageFrom={setNumberPerPageFromTimMedisFKUI}
                    setNumberPerPageTo={setNumberPerPageToTimMedisFKUI}
                    totalData={totalDataTimMedisFKUI}
                    getTotalData={getTotalTimMedisFKUI}
                    setTotalData={setTotalDataTimMedisFKUI}
                    setTotalPage={setTotalPageTimMedisFKUI}
                    setCurrentPage={setCurrentPageTimMedisFKUI}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentTimMedisFKUI
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Rekap IKM" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataRekapIKM}
                    setData={setDataRekapIKM}
                    getData={getRekapIKM}
                    dataFields={dataFieldsRekapIKM}
                    sortItem={sortItemRekapIKM}
                    setSortItem={setSortItemRekapIKM}
                    searchInput={searchInputRekapIKM}
                    searchFilterBy={searchFilterRekapIKM}
                    numberPerPage={numberPerPageRekapIKM}
                    setNumberPerPageFrom={setNumberPerPageFromRekapIKM}
                    setNumberPerPageTo={setNumberPerPageToRekapIKM}
                    totalData={totalDataRekapIKM}
                    getTotalData={getTotalPengguna}
                    setTotalData={setTotalDataRekapIKM}
                    setTotalPage={setTotalPageRekapIKM}
                    setCurrentPage={setCurrentPageRekapIKM}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                  />
                ) : null}
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      {showDataActive.value === "Penyuluhan" ? (
        <PenyuluhanDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadPenyuluhan={handleLoadPenyuluhan}
        />
      ) : showDataActive.value === "Kegiatan Sosial" ? (
        <KegiatanSosialDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadKegiatanSosial={handleLoadKegiatanSosial}
        />
      ) : showDataActive.value === "Screening" ? (
        <ScreeningDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadScreening={handleLoadScreening}
        />
      ) : showDataActive.value === "Pengobatan Massal" ? (
        <PengobatanMassalDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadPengobatanMassal={handleLoadPengobatanMassal}
        />
      ) : showDataActive.value === "Sirkumsisi" ? (
        <SirkumsisiDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadSirkumsisi={handleLoadSirkumsisi}
        />
      ) : showDataActive.value === "Kunjungan Pasien" ? (
        <KunjunganPasienDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadKunjunganPasien={handleLoadKunjunganPasien}
        />
      ) : showDataActive.value === "Tim Medis FKUI" ? (
        <TimMedisFKUIDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          optionsMahasiswa={optionsMahasiswa}
          handleLoadTimMedisFKUI={handleLoadTimMedisFKUI}
        />
      ) : null}
    </React.Fragment>
  );
}

export default Pengabdian;
