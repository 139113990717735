import React, { useState, useEffect, useRef } from "react";
import "../../style.css";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import useDidMountEffect from "../../../../../../../Components/useDidMountEffect";
import { 
  getAllInfo,
  getSurvey,
  getTotalSurvey,
  deleteSurvey, 
  getSurveyByIdSurvey
} from "../../../../../../../Services/API/pengumuman";
import BallClipRotateMultipleCustom from "../../../../../../../Components/BallClipRotateMultipleCustom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactResizeDetectorCustom from "../ReactResizeDetectorCustom";
import ShowDataToggle from "../ShowDataToggle";
import Search from "../Search";
import Table from "../Table";
import Pagination from "../Pagination";
import useOnClickOutside from "../../../../../../../Components/useOnClickOutside";
import { generateExcel } from "../../../../../../../Services/File/excel";
import SurveyDialog from "./SurveyDialog";

function Pengumuman() {
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);

  const [isBodyPanelLoading, setIsBodyPanelLoading] = useState(false);

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [dataSelected, setDataSelected] = useState([]);

  const headBarRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const exportToExcelOptionRef = useRef(null);

  const [
    isExportToExcelOptionActive,
    setIsExportToExcelOptionActive,
  ] = useState(false);

  const [exportToExcelList, setExportToExcelList] = useState([
    {
      value: "Semua",
      isLoading: false,
    },
    {
      value: "Semua Sesuai Filter",
      isLoading: false,
    },
    {
      value: "Hanya Halaman Ini",
      isLoading: false,
    },
  ]);

  // Pengumuman ------------------------------------------------------------------

  // @ShowData Section
  // Pengumuman 

  const [optionsShowData, setOptionsShowData] = useState([
    { value: "Info", label: "Info" },
    { value: "Poll", label: "Poll" },
    { value: "Survey", label: "Survey" },
    { value: "Recruitment", label: "Recruitment" }
  ]);

  const [showDataActive, setShowDataActive] = useState(optionsShowData[0]);

  // @Table Section
  // Pengumuman

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);

  // @Table Section
  // Pengumuman - Info


  // @Table Section
  // Pengumuman - Poll


  // @Table Section
  // Pengumuman - Survey

  const [dataBySurvey, setDataBySurvey] = useState([]);

  const dataFieldsBySurvey = [
    {
      primary: false,
      value: "no",
      type: "text",
      label: "No",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 11,
        type: "text",
      },
    },
    {
      primary: true,
      checkbox: true,
      value: "id_survey",
      type: "text",
      label: "ID Survey",
      size: "default",
      position: "left",
      status: 0,
      exportToExcel: {
        visible: false,
      },
    },
    {
      primary: false,
      value: "status",
      type: "single-textbox",
      label: "Status",
      size: "xs",
      position: "left",
      status: 1,
      textboxClass: [
        { value: "Open", name: "textbox-green-smooth" },
        { value: "Closed", name: "textbox-red-smooth" },
      ],
      exportToExcel: {
        visible: true,
        bgColor: [
          { value: "Open", argb: "FF8DF792" },
          { value: "Closed", argb: "FFF9ADAD" },
        ],
        cellWidth: 18,
        type: "text",
      },
    },
    {
      primary: false,
      value: "judul",
      type: "text",
      label: "Judul",
      size: "default",
      contentFontType: "capitalize",
      position: "left",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 45,
        type: "text",
      },
    },
    {
      primary: false,
      value: "deskripsi",
      type: "text",
      label: "Deskripsi",
      size: "xxl",
      textFormat: "pre",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 85,
        type: "text",
      },
    },
    {
      primary: false,
      value: "embed_html",
      type: "text",
      label: "Embed HTML",
      size: "xl",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 60,
        type: "text"
      }
    },
    {
      primary: false,
      value: "slug",
      type: "text",
      label: "Slug",
      size: "l",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 55,
        type: "text"
      }
    },
    {
      primary: false,
      value: "tanggal_publikasi",
      type: "text",
      label: "Tanggal Publikasi",
      size: "default",
      position: "right",
      status: 1,
      exportToExcel: {
        visible: true,
        bgColor: "FFFFFFFF",
        cellWidth: 25,
        type: "text",
      },
    },
  ];

  const [sortItemBySurvey, setSortItemBySurvey] = useState({
    by: "tanggal_publikasi",
    mode: "DESC"
  });

  const [
    searchInputBySurvey,
    setSearchInputBySurvey
  ] = useState("");

  const optionsSearchFilterBySurvey = [
    { value: "status", label: "Status" },
    { value: "judul", label: "Judul" },
    { value: "deskripsi", label: "Deskripsi" },
    { value: "embed_html", label: "Embed HTML" },
    { value: "slug", label: "Slug" },
    { value: "tanggal_publikasi", label: "Tanggal Publikasi" }
  ];

  const [
    searchFilterBySurvey,
    setSearchFilterBySurvey
  ] = useState([optionsSearchFilterBySurvey[1]]);

  // @Table Section
  // Pengumuman - Recruitment


  // @Pagination Section
  // Pengumuman - Info


  // @Pagination Section
  // Pengumuman - Poll


  // @Pagination Section
  // Pengumuman - Survey

  const optionsNumberPerPageBySurvey = [10, 20, 30, 40, 50];

  const [
    numberPerPageBySurvey,
    setNumberPerPageBySurvey,
  ] = useState(optionsNumberPerPageBySurvey[0]);

  const [
    numberPerPageFromBySurvey,
    setNumberPerPageFromBySurvey,
  ] = useState(0);

  const [
    numberPerPageToBySurvey,
    setNumberPerPageToBySurvey,
  ] = useState(0);

  const [
    totalDataBySurvey,
    setTotalDataBySurvey,
  ] = useState(0);

  const [
    totalPageBySurvey,
    setTotalPageBySurvey,
  ] = useState(0);

  const [
    currentPageBySurvey,
    setCurrentPageBySurvey,
  ] = useState(0);

  // @Pagination Section
  // Pengumuman - Recruitment


  // @Dialog Section
  // Pengumuman

  const dialogFullScreenClassName = "pengumuman-full-screen-bar";

  const [isDialogFullScreenActive, setIsDialogFullScreenActive] = useState(
    false
  );

  const [dialogDataActive, setDialogDataActive] = useState(null);

  const [dialogTitleActive, setDialogTitleActive] = useState("");

  const [dialogActiveActionType, setDialogActiveActionType] = useState("");

  // @Export Section
  // Pengumuman

  const handleClickSingleListExport = async (exportTarget) => {
    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: true,
            }
          : item;
      })
    );

    const data = await (async () => {
      let _data = {};

      _data.bgHeader = "FFB9CDF7";

      _data.title = showDataActive.value;

      _data.fileName = `${showDataActive.value}.xlsx`;

      switch(showDataActive.value) {
        case "Info":
        break;
        case "Poll":
        break;
        case "Survey":
          _data.target = dataFieldsBySurvey.filter((item) => item.exportToExcel.visible ? true : false);

          _data.header = dataFieldsBySurvey
            .filter((item) => (item.exportToExcel.visible ? true : false))
            .map((x) => x.label);

          const bySurvey = await getSurvey({
            search:
              exportTarget === "Semua"
                ? null
                : {
                    groupBy: searchFilterBySurvey.length > 1 ? "OR" : "",
                    rules: searchFilterBySurvey.map((item) => {
                      return {
                        field: item.value,
                        op: "LIKE",
                        data: searchInputBySurvey
                      };
                    }),
                  },
            order: sortItemBySurvey,
            limit: 
              exportTarget === "Hanya Halaman Ini"
                ? numberPerPageBySurvey
                : null,
            offset: 
              exportTarget === "Hanya Halaman Ini"
                ? (currentPageBySurvey - 1 < 0
                    ? 0
                    : currentPageBySurvey - 1) * numberPerPageBySurvey
                : null,
          });

          if(bySurvey.success) {
            let no = 1;

            _data.value = bySurvey.data.map((x) => {
              let item = [no++];

              _data.target.slice(1, _data.target.length).forEach((t) => {
                const val = x[t.value];

                item.push(
                  val
                    ? t.exportToExcel.type === "textlink"
                      ? t.exportToExcel.prefixLink + val
                      : val
                    : "-"
                );
              });

              return item;
            });

            _data.total = bySurvey.data.length;
          }
        break;
        case "Recruitment":
        break;
      }

      return _data;
    })();

    generateExcel({
      title: data.title,
      subTitle: `Total Data: ${data.total}`,
      header: data.header,
      bgHeader: data.bgHeader,
      fileName: data.fileName,
      data: data.value,
      cell: data.target,
    });

    setExportToExcelList(
      exportToExcelList.map((item, index) => {
        return item.value === exportTarget
          ? {
              ...item,
              isLoading: false,
            }
          : item;
      })
    );
  };

  
  const handleClickSingleBodyContentSurvey = async (item) => {
    setIsFullScreenLoading(true);

    const detailSurvey = await getSurveyByIdSurvey(item.id_survey);

    if(detailSurvey.success) {
      setDialogDataActive({
        idSurvey: item.id_survey,
        status: item.status,
        judul: item.judul,
        deskripsi: item.deskripsi,
        embedHTML: item.embed_html,
        slug: item.slug,
        tanggalPublikasi: new Date(item.tanggal_publikasi.split("-").reverse().join("-"))
      });
    } else {
      setIsDialogFullScreenActive(false);
      setIsFullScreenLoading(false);
      
      return;
    }

    setDialogActiveActionType("Update");

    setTimeout(() => {
      setIsDialogFullScreenActive(true);
      setIsFullScreenLoading(false);
    }, 50);
  };

  const handleClickTambahDataButton = () => {
    setDialogActiveActionType("Tambah");
    setIsDialogFullScreenActive(true);
  };

  const handleLoadSurvey = async (type) => {
    let surveyObject = {};

    setIsBodyPanelLoading(true);

    switch(type) {
      case "REFRESH":
        surveyObject.search = {
          groupOp: searchFilterBySurvey.length > 1 ? "OR" : "",
          rules: searchFilterBySurvey.map((item) => {
            return {
              field: item.value,
              op: "LIKE",
              data: searchInputBySurvey
            };
          })
        };
        surveyObject.order = sortItemBySurvey;
        surveyObject.limit = numberPerPageBySurvey;
        surveyObject.offset =
          (currentPageBySurvey - 1 < 0
            ? 0
            : currentPageBySurvey - 1) * numberPerPageBySurvey;
      break;
      case "INIT":
      default:
        surveyObject.order = sortItemBySurvey;
        surveyObject.limit = numberPerPageBySurvey;
        surveyObject.offset = 0;
    }

    if((dataBySurvey.length === 0 && totalDataBySurvey === 0) || type === "REFRESH" || type === "INIT") {
      const [_survey, _totalSurvey] = await Promise.all([
        getSurvey(surveyObject),
        getTotalSurvey({})
      ]);

      if(_survey.success && _totalSurvey.success) {
        setDataBySurvey(_survey.data);
        setTotalDataBySurvey(_totalSurvey.data.total);
        setTotalPageBySurvey(
          Math.ceil(_totalSurvey.data.total / numberPerPageBySurvey)
        );
        setCurrentPageBySurvey(_totalSurvey.data.total > 0 ? 1 : 0);
        setNumberPerPageFromBySurvey(
          _totalSurvey.data.total > 0 ? 1 : 0
        );
        setNumberPerPageToBySurvey(
          _totalSurvey.data.total > 0 ? _survey.data.length : 0
        );
      }
    }

    setIsBodyPanelLoading(false);
  };

  const deleteDataSelected = () => {
    swal({
      title: "Yakin ingin hapus?",
      text: "Data akan dihapus secara permanen.",
      buttons: {
        catch: {
          text: "Batal",
          value: "reject",
          className: "natural-color-custom"
        },
        defeat: {
          text: "Hapus",
          value: "accept",
          className: "warning-color-custom"
        }
      }
    }).then(async (value) => {
      if(value === "accept") {
        let _delete;

        setIsDeleteLoading(true);

        switch(showDataActive.value) {
          case "Info":
          break;
          case "Poll":
          break;
          case "Survey":
            _delete = await deleteSurvey(dataSelected);
          break;
          case "Recruitment":
          break;
        }

        setIsDeleteLoading(false);

        if(_delete.success) {
          setDataSelected([]);
          handleLoadData("INIT");
        } else {
          swal("Oops!", _delete.message ? _delete.message : "Internal Server Error", "warning");
        }
      }
    });
  };

  const handleLoadData = (type) => {
    switch(showDataActive.value) {
      case "Info":
      break;
      case "Poll":
      break;
      case "Survey":
        handleLoadSurvey(type);
      break;
      case "Recruitment":
      break;
    }
  };

  useDidMountEffect(() => {
    if(dialogActiveActionType) {
      setDialogTitleActive(`${dialogActiveActionType} ${showDataActive.value}`);
    }
  }, [dialogActiveActionType]);

  useOnClickOutside(exportToExcelOptionRef, () => {
    if (isExportToExcelOptionActive) {
      setIsExportToExcelOptionActive(false);
    }
  });

  useEffect(() => {
    handleLoadData("INIT");
  }, [showDataActive]);

  return (
    <React.Fragment>
      <BallClipRotateMultipleCustom isLoading={isFullScreenLoading} />
      {!isFullScreenLoading && (
        <div className="content-panel-wrapper pengumuman-content-panel">
          <div className="header-panel">
            <div className={`data-selected-action-area${dataSelected.length > 0 ? " active" : ""}`}>
              <span className="text-selected">
                <span className="total-selected">{dataSelected.length}</span> Data terpilih.
              </span>
              <span className="delete-icon" onClick={deleteDataSelected}>
                {isDeleteLoading ? (
                  <CircularProgress size={17} className="loading-circle" />
                ) : (
                  <FontAwesomeIcon icon={faTrash} />
                )}
              </span>
            </div>
            <h1 className="topic-panel">Pengumuman</h1>
            <div className="header-right-button-area">
              <div className="single-button export-to-excel-button" onMouseDown={() => setIsExportToExcelOptionActive(!isExportToExcelOptionActive) }
              >
                <span className="button-text">Export to Excel</span>
                <span
                  className={`caret-down-icon${
                    isExportToExcelOptionActive ? " rotate" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
              <div className={`export-to-excel-option${isExportToExcelOptionActive ? " active" : ""}`} ref={exportToExcelOptionRef}>
                <ul className="list-export-wrapper">
                  {exportToExcelList.map((item, index) => (
                    <li
                      className="single-list"
                      onClick={() => handleClickSingleListExport(item.value)}
                      key={index}
                    >
                      {item.isLoading ? (
                        <CircularProgress
                          size={13}
                          className="loading-circle"
                        />
                      ) : (
                        item.value
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="single-button" onClick={handleClickTambahDataButton}>
                <span className="button-text">Tambah Data</span>
              </div>
            </div>
          </div>
          <React.Fragment>
            <BallClipRotateMultipleCustom
              isLoading={isBodyPanelLoading}
              styleCustom={{
                position: "absolute",
                top: "56px",
                left: "initial",
                height: "calc(100% - 56px)",
              }}
            />
            {!isBodyPanelLoading && (
              <div className="body-panel">
                <ReactResizeDetectorCustom
                  headBarRef={headBarRef}
                  tableWrapperRef={tableWrapperRef}
                >
                  <div className="head-bar" ref={headBarRef}>
                    <div className="single-head-bar">
                      { showDataActive.value === "Info" ? (
                        null
                      ) : showDataActive.value === "Poll" ? (
                        null
                      ) : showDataActive.value === "Survey" ? (
                        <Search
                          setData={setDataBySurvey}
                          getData={getSurvey}
                          dataFields={dataFieldsBySurvey}
                          sortItem={sortItemBySurvey}
                          searchInput={searchInputBySurvey}
                          setSearchInput={setSearchInputBySurvey}
                          optionsSearchFilterBy={optionsSearchFilterBySurvey}
                          searchFilterBy={searchFilterBySurvey}
                          setSearchFilterBy={setSearchFilterBySurvey}
                          numberPerPage={numberPerPageBySurvey}
                          setNumberPerPageFrom={setNumberPerPageFromBySurvey}
                          setNumberPerPageTo={setNumberPerPageToBySurvey}
                          getTotalData={getTotalSurvey}
                          setTotalData={setTotalDataBySurvey}
                          setTotalPage={setTotalPageBySurvey}
                          setCurrentPage={setCurrentPageBySurvey}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Recruitment" ? (
                        null
                      ) : null }
                      <ShowDataToggle
                        showDataActive={showDataActive}
                        optionsShowData={optionsShowData}
                        setShowDataActive={setShowDataActive}
                        setDataSelected={setDataSelected}
                      />
                    </div>
                    <div className="single-head-bar">
                      { showDataActive.value === "Info" ? (
                        null
                      ) : showDataActive.value === "Poll" ? (
                        null
                      ) : showDataActive.value === "Survey" ? (
                        <Pagination
                          setData={setDataBySurvey}
                          getData={getSurvey}
                          dataFields={dataFieldsBySurvey}
                          sortItem={sortItemBySurvey}
                          searchInput={searchInputBySurvey}
                          searchFilterBy={searchFilterBySurvey}
                          optionsNumberPerPage={optionsNumberPerPageBySurvey}
                          numberPerPage={numberPerPageBySurvey}
                          setNumberPerPage={setNumberPerPageBySurvey}
                          numberPerPageFrom={numberPerPageFromBySurvey}
                          setNumberPerPageFrom={setNumberPerPageFromBySurvey}
                          numberPerPageTo={numberPerPageToBySurvey}
                          setNumberPerPageTo={setNumberPerPageToBySurvey}
                          totalData={totalDataBySurvey}
                          getTotalData={getTotalSurvey}
                          setTotalData={setTotalDataBySurvey}
                          totalPage={totalPageBySurvey}
                          setTotalPage={setTotalPageBySurvey}
                          currentPage={currentPageBySurvey}
                          setCurrentPage={setCurrentPageBySurvey}
                          isDataTableLoading={isDataTableLoading}
                          setIsDataTableLoading={setIsDataTableLoading}
                          setDataSelected={setDataSelected}
                        />
                      ) : showDataActive.value === "Recruitment" ? (
                        null
                      ) : null }
                    </div>
                  </div>
                </ReactResizeDetectorCustom>
                { showDataActive.value === "Info" ? (
                  null
                ) : showDataActive.value === "Poll" ? (
                  null
                ) : showDataActive.value === "Survey" ? (
                  <Table
                    ref={tableWrapperRef}
                    data={dataBySurvey}
                    setData={setDataBySurvey}
                    getData={getSurvey}
                    dataFields={dataFieldsBySurvey}
                    sortItem={sortItemBySurvey}
                    setSortItem={setSortItemBySurvey}
                    searchInput={searchInputBySurvey}
                    searchFilterBy={searchFilterBySurvey}
                    numberPerPage={numberPerPageBySurvey}
                    setNumberPerPageFrom={setNumberPerPageFromBySurvey}
                    setNumberPerPageTo={setNumberPerPageToBySurvey}
                    totalData={totalDataBySurvey}
                    getTotalData={getTotalSurvey}
                    setTotalData={setTotalDataBySurvey}
                    setTotalPage={setTotalPageBySurvey}
                    setCurrentPage={setCurrentPageBySurvey}
                    isDataTableLoading={isDataTableLoading}
                    setIsDataTableLoading={setIsDataTableLoading}
                    setIsFullScreenLoading={setIsFullScreenLoading}
                    handleClickSingleBodyContent={
                      handleClickSingleBodyContentSurvey
                    }
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                  />
                ) : showDataActive.value === "Recruitment" ? (
                  null
                ) : null }
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      { showDataActive.value === "Info" ? (
        null
      ) : showDataActive.value === "Poll" ? (
        null
      ) : showDataActive.value === "Survey" ? (
        <SurveyDialog 
          isDialogFullScreenActive={isDialogFullScreenActive}
          setIsDialogFullScreenActive={setIsDialogFullScreenActive}
          dialogFullScreenClassName={dialogFullScreenClassName}
          dialogActiveActionType={dialogActiveActionType}
          setDialogActiveActionType={setDialogActiveActionType}
          dialogTitleActive={dialogTitleActive}
          setDialogTitleActive={setDialogTitleActive}
          dialogDataActive={dialogDataActive}
          setDialogDataActive={setDialogDataActive}
          handleLoadSurvey={handleLoadSurvey}
        />
      ) : showDataActive.value === "Recruitment" ? (
        null
      ) : null }
    </React.Fragment>
  );
}

export default Pengumuman;
