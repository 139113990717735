import axios from "axios";
import config from "../../Config";

const getBisnisByPengguna = async (id) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengguna/${id}`,
  });

  const response = request.data;
  return response;
};

const getBisnis = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getAllJenisBisnis = async () => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis/jenis`,
  });

  const response = request.data;
  return response;
};

const insertBisnis = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/bisnis`,
    data,
  });

  const response = request.data;
  return response;
};

const insertDetailBisnis = async (data) => {
  const request = await axios({
    method: "POST",
    baseURL: `${config.SERVER_URI}/bisnis/detail`,
    data,
  });

  const response = request.data;
  return response;
};

const getAllBisnisByBisnis = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis/by_bisnis`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalBisnisByBisnis = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/bisnis/by_bisnis/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const deleteBisnisByBisnis = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/bisnis/by_bisnis`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const getAllBisnisByPengajuan = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengajuan`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalBisnisByPengajuan = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengajuan/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const deleteBisnisByPengajuan = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengajuan`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const getAllBisnisByPengguna = async (data) => {
  const request = await axios({
    method: "GET",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengguna`,
    params: data,
  });

  const response = request.data;
  return response;
};

const getTotalBisnisByPengguna = async (data) => {
  const request = await axios({
    method: "get",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengguna/total`,
    params: data,
  });

  const response = request.data;
  return response;
};

const deleteBisnisByPengguna = async (data) => {
  const request = await axios({
    method: "DELETE",
    baseURL: `${config.SERVER_URI}/bisnis/by_pengguna`,
    data: {
      dataSelected: data
    }
  });

  const response = request.data;
  return response;
};

const verifikasiBisnis = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/bisnis/verifikasi`,
    data,
  });

  const response = request.data;
  return response;
};

const updateLogoBisnis = async (data) => {
  const request = await axios({
    method: "PUT",
    baseURL: `${config.SERVER_URI}/bisnis/logo_bisnis`,
    data,
  });

  const response = request.data;
  return response;
};

export {
  getBisnisByPengguna,
  getBisnis,
  getAllJenisBisnis,
  insertBisnis,
  insertDetailBisnis,
  getAllBisnisByBisnis,
  getTotalBisnisByBisnis,
  deleteBisnisByBisnis,
  getAllBisnisByPengguna,
  getTotalBisnisByPengguna,
  deleteBisnisByPengguna,
  getAllBisnisByPengajuan,
  getTotalBisnisByPengajuan,
  deleteBisnisByPengajuan,
  verifikasiBisnis,
  updateLogoBisnis,
};
