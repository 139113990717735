import Axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function Dashboard() {
  const [data, setData] = useState([]);
  const [dataSudah, setDataSudah] = useState([]);
  const [showData, setShowData] = useState("belom");
  const [search, setSearch] = useState("");

  useEffect(() => {
    Axios.get("/api/pemira/getYangBelomMilih").then((res) => {
      setData(res.data.results);
    });
    Axios.get("/api/pemira/getYangSudahMilih").then((res) => {
      setDataSudah(res.data.results);
    });
  }, []);

  const searched = data.filter(
    (data) =>
      data.npm.startsWith(search.toLowerCase()) ||
      data.nama_lengkap.toLowerCase().includes(search.toLowerCase())
  );

  const searchedSudah = dataSudah.filter(
    (data) =>
      data.npm.startsWith(search.toLowerCase()) ||
      data.nama_lengkap.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
console.log('searchedSudah', searched.length)
  }, [searched])

  const [startsWith, setStartsWith] = useState(false);

  return (
    <div className="pemira-dashboard__container">
      <h1>Pemira Dashboard</h1>
      <div className="keterangan">
        Cari Berdasarkan Nama, NPM, atau Angkatan (contoh: "17" untuk
        menampilkan angkatan 2017). 
      </div>
      <div className="table-component__container">
        <div className="searchbox">
          <input
            type="text"
            placeholder="Search"
            autoFocus
            className="small"
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="tracker-buttons">
            <div
              className={
                showData === "belom" ? "query-tracker active" : "query-tracker"
              }
              onClick={() => setShowData("belom")}
            >
              Belum Memilih ({searched.length})
            </div>
            <div
              className={
                showData === "sudah" ? "query-tracker active" : "query-tracker"
              }
              onClick={() => setShowData("sudah")}
            >
              Sudah Memilih ({searchedSudah.length})
            </div>
          </div>
        </div>
        <section>
          <div className="tbl-header">
            <table>
              <thead>
                <tr>
                  <th>Nama Lengkap</th>
                  <th>NPM</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tbl-content">
            <table>
              <tbody>
                {showData === "belom" ? (
                  <>
                    {searched.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{value.nama_lengkap}</td>
                          <td>{value.npm}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {searchedSudah.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{value.nama_lengkap}</td>
                          <td>{value.npm}</td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
